.loader{}/*==== ALL TIME DEFAULTS ====*/
body {
    margin: 0px;
    background-color: #fff; /* #F7F7F7 */
    overflow-y: scroll;
    height: 100%;/*new value remove if theres some problem*/

}
html{
    height:100%;
    font-size:15px;
}
a, a:link, a:hover, a:visited, a:active {
    color: inherit;
    text-decoration: none;
}
/* --- Frequently Used Classes ---*/
.top {
    vertical-align: top;
}
.dropShadow {
    box-shadow: 0px 0px 16px #333;
}


.topStripeDropShadow {
   /* box-shadow: 0px 16px 16px -16px #333; */
}
.overflowScroll {
    overflow-y: scroll;
}
.overflowHidden {
    overflow: hidden;
}
/* ==== Accordion styles  ==== */
.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
    color: #999;
    background-color: transparent;
    background: none;
    border: none;
}
.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active {
    border: 0px;
    background-color: transparent;
}
.ui-widget-content {
    border: 0px;
}
.ui-accordion .ui-accordion-header{
    border: 0px;
    background-color: transparent;
}
#modul_accordion .ui-accordion-content {
    width: 100%;
    background-color: red;
    color: blue;
    font-size: 10pt;
    line-height: 16pt;
}
#accordion .ui-accordion-content {
    border: 0px;
}
ui-accordion-header {
    background-color: red;
}
/* ==== END OF Accordion styles  ==== */

.striketrough {
    text-decoration: line-through;
}
/* ==== Let's get this party STARTED  ==== */
/* ==== =========SCROLL BAR=========  ==== */

::-webkit-scrollbar {
    /*width: 12px;*/
}

/* Track */
::-webkit-scrollbar-track {
  background: #ccc;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.0);
    -webkit-border-radius: 0px;
    border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 0px;
    border-radius: 0px;
    background: #999;
    -webkit-box-shadow: inset 0 0 0px rgba(0,0,0,0.5);
}
::-webkit-scrollbar-thumb:active {
  background-color: #888;
}

::-webkit-scrollbar-thumb:window-inactive {
 background: rgba(153,153,153,0.4);
}

::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar
{
    width: 12px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
   border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #555;
}

.container {
    width: 100%;
}
.masterStripe {
    width: 100%;
}
.fa-facebook:before {
  content: "\f09a";
}
.stripe {
    /*width: -webkit-calc(100% - 10px);
    width: -moz-calc(100% - 10px);
    width: -ms-calc(100% - 10px);
    width: -o-calc(100% - 10px);*/
    width: 100%;
    padding: 5px;
   
}
.stripeShadow{ box-shadow: 20px 0px 20px 20px rgba(0, 0, 0, 0.32);}
.content {
    position: relative;
    width: 1300px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;
}
.content_path{
    position: relative;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;
}
.content_courses{
    position: relative;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;
    overflow:hidden;
}
#subPathsNavWrapperCourses{

}
#subPathsNavWrapperCourses .activeMenu{
    font-weight:bold;
}
#subPathsNavWrapperHome{
    left:-50%;
    top:28%;
}
.narrowContent {
    width: 100%;
    margin: 0 auto;
}
.half {
    width: 48%;
    height: auto;
    display: inline-block;
}

.oneThird {
    width: 32%;
    display: inline-block;
}

.twoThirds {
    width: 65%;
    display: inline-block;
}
.quarter {
    width: 22%;
    height: auto;
    display: inline-block;
}
.justifier {
    display: inline-block;
    width: 100%;
    height: 0px;
}
.bold {
    font-weight: bold;
}
.bolder {
    font-weight: 700;
}
.margin_40 {
    margin: 40px 0px;
}
.margin_60 {
    margin: 60px 0px;
}
/*==== CURRENT DOCUMENT DEFAULTS ====*/
/* STYLES FOR FIXING THE FOOTER AT THE BOTTOM OF THE PAGE*/
#pageWrap{
    min-height:100%;
    margin-bottom:-447px;
    overflow:hidden;
}
#pageWrap:after{
    content:"";
    display:block;
    height:447px;
}
#pageWrap.pageWrap:after{
    height:0;
    display:none;
}
/* END OF THEES STYLES*/
.done {
    background-color: #099;
}
.undone {
    background-color: #999;
}

/* ==== Let's get this party STARTED  ==== */
/* ==== =========SCROLL BAR=========  ==== */

/*::-webkit-scrollbar {
    width: 6px;
}

 Track
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.0);
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

 Handle
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #009999;
    -webkit-box-shadow: inset 0 0 0px rgba(0,0,0,0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
	background: rgba(0,153,153,0.4);
}*/

/*=====LOGIN REGISTER BODY BACKGROUNDS =======*/
.bodyBackgrounds{
   /* background:url('../images/secondGameBG.png');*/
    background-repeat: no-repeat;
    background-size:cover;
    position:relative;
}
.login_register_overlay{
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
   /* background:rgba(3, 156, 230, 0.38);*/
}

/*==== CURRENT DOCUMENT ====*/

#tab2 {
    text-align: right;
}

/*== Typed Plugin ==*/


.typed-cursor{
    opacity: 1;
    -webkit-animation: blink 1.5s infinite;
    -moz-animation: blink 1.5s infinite;
    animation: blink 1.5s infinite;
}
@keyframes blink{
    0% { opacity:1; }
    50% { opacity:0; }
    100% { opacity:1; }
}
@-webkit-keyframes blink{
    0% { opacity:1; }
    50% { opacity:0; }
    100% { opacity:1; }
}
@-moz-keyframes blink{
    0% { opacity:1; }
    50% { opacity:0; }
    100% { opacity:1; }
}

/*== header ==*/
header {
    position: relative;
}
#firstTopStripe {
    position: fixed;
    top: 0;
    z-index: 3;
    background: #039CE6;
    padding:0px 0;
    margin-bottom:4px;

    box-shadow:0 2px 24px rgba(0, 0, 0, 0.4);
    z-index:21 !important;
}
.languageBar{
    background:#039CE6;

}
#firstTopStripe.navigationBar{

}
#userAvatarFixed{
    position:absolute;
    top:0;
    z-index:7;

}
.secondTopStripe.marginTop20{

}
.fixedMenu {
    position: relative;
/*   top: 77px; */
    z-index: 2;
}
.topStripe {
    padding: 0;
    margin: 0;
       background: #099;
    width: 100%;
}
.secondTopStripe {
/*   margin-top: 125px;
      rgba(3, 156, 230, 0.7)
    ), url('../images/home.jpg');
*/
/*background: linear-gradient(
      rgba(3, 156, 230, 1),
      rgba(3, 156, 230, 1)
    );*/
    margin-top:50px;

}
.secondTopStripe .hero_text{
    color:#039be6;
}
.test {
    display: block;
    height: 200px;
}
#wrapperMenu #topRightMenu {
    /*
    position: absolute;
    top: 50%;
    right: 50px;*/
    -ms-transform: translateY(-11%);
    -webkit-transform: translateY(-11%);
    transform: translateY(-11%);
    /*-ms-transform: translateY(-4%);
    -webkit-transform: translateY(-4%);
    transform: translateY(-4%);*/
   margin-right: 30px;
}
#wrapperMenuNotUser{
    display:inline-block;
    float:right;
    margin-right:50px;
}
#wrapperMenuNotUser #topRightMenu{
    -ms-transform:translateY(-4%);
    -webkit-transform: translateY(-4%);
    transform: translateY(-4%);
}
#wrapperMenu{
    display:inline-block;
    float:right;
    margin-right:28px;
}
#topRightMenu .activeLink{
    border-bottom: 3px solid #fff;
    color: #fff;
}
.activeLeftLink{
    font-weight:bold;
}
.logo {
    display: inline-block;
    text-align: center;
    padding: 10px 0;
}
.logoText {
    font-family: monospace;
    /*font-size: 2rem;*/
    font-size:1.7rem;
    font-weight: 700;/*500*/
    color:#fff !important;
}
.nextCourse .courseTile{
    width:100%;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .logoText{
       /* font-size: 1.85rem !important;*/

    }
}
@-moz-document url-prefix() {

   .logoText{font-size:1.5rem;}

}
.logoTextLR{
    color:#039CE6 !important;
}
.logoRL{
    text-align:center;
    display:block;
}
/* --- User name and user profile chunk --- */
.userStatus {
    font-size: 0.7em;
}
/* --- End of User name and user profile chunk --- */
.adminUlHeader{
    vertical-align: top;
    display: inline-block;
    list-style: none;
    padding: 0;
    margin: 0;
    margin-left: 30px;
}
.adminUlHeader li a{
    font-size: 1.2em;
    font-weight: 500;
    display: block;
    line-height: 3em;
    text-transform: uppercase;
}
.contact_info {
    color: #aaa;
    font-size: 0.8em;
    text-align: left;
    display: inline-block;
    vertical-align: top;
    position: relative;
    top: 10px;
    left: 20px;
}
.phone_num {
    font-size: 1.8em;
}
.menu {
    position: relative;
    display: inline-block;
}
.header_ul {
    position: relative;
    display: inline-block;
    padding-left: 0px;
    margin: 0;
}
.header_ul li {
    margin-left: 1rem;
    display: inline-block;
}
a.menuLi{
     display: block;
    font-size: 0.8rem;
    font-weight: 500;
    text-transform: uppercase;
    padding: 18px 5px;
    position:relative;
    color:#fff;
    font-weight:bold;
}
a.menuLi:hover {
    color: #fefefe;
}
#toSchools{
  position:relative;
}
.navigation-dropdown{
  position: absolute;
  padding:0;
  margin:0;
  display: none;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
  border-radius: 3px;
}
.navigation-dropdown li{
  margin:0;
  display: block;
  width:100%;
}
.navigation-dropdown li a{
  padding: 10px 20px;
  display: block;
  width:100%;
  box-sizing: border-box;
  text-transform: uppercase;
}
#dropdown_menu{
  position: relative;

}
/*a.menuLi:before{
  content: "";
position: absolute;
width: 100%;
height: 3px;
bottom: 0;
left: 0;
background-color: #fff;
visibility: hidden;
-webkit-transform: scaleX(0);
transform: scaleX(0);
-webkit-transition: all 0.3s ease-in-out 0s;
transition: all 0.3s ease-in-out 0s;
}

a.menuLi:hover:before{
  visibility: visible;
 -webkit-transform: scaleX(1);
 transform: scaleX(1);
}*/
.header_ul li.firstMenuLi {
    margin-left: 0;
}
#sign_up {
    font-weight: 300;
    color: white;
}

#profile, #payment{
    font-weight: 300;
    color: white;
}
.menu_button {
    display: none;
    color: white;
    font-size: 1.5em;
    font-weight: 500;
    width: 40px;
    padding: 8px 0px;
    background-color: #666;
    text-align: center;
}
/*== hero ==*/
.heroTitle, .heroSubTitle, .heroOverTitle {
    margin-left: 100px;
    margin-right: 250px;
}
.heroTitle {
    font-size: 3rem;
    font-weight: 600;
    color: white;
}
.heroOverTitle, .heroSubTitle {
    font-size: 1.5rem;
}
.heroDescription {
    margin-left: 200px;
    font-size: 1.5rem;
    width: 70%;
}
#index_header {

}

.hero {
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: #666;
    height: auto;
}

.hero_text {
    color: white;
    position: relative;
    width: 100%;
    display: inline-block;
    text-align: center;
    z-index: 1;

}

.hero_text h1 {
    margin: 0;
    font-size: 4rem;
    font-weight: 300;
    font-family: "Lucida Console", Monaco, monospace;
}
.hero_text h3 {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 2rem;
    font-weight: 400;
    /*font-family: Roboto;*/font-family: "Lucida Console", Monaco, monospace;
    /* color: rgba(255, 255, 255, 0.8); */
}
.hero_text p {
    font-size: 1.4em;
}
/* ---- CALL TO ACTION STYLES ----*/
.courseBackground {
    position: fixed;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    background-attachment: fixed;
}
.courseBackgroundOverlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);

}
.heroButton {
    cursor: pointer;
    position: relative;
    text-transform: uppercase;
    font-size: 1.4rem;
    border-width: 2px;
    border-style: solid;
    display: inline-block;
    padding: 16px 60px;
    font-weight: 500;
    border-radius: 5px;
    z-index: 1;
    color: black;
}
#butaforenDivHeroButton {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.overSelect {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
    z-index: 1;
}
#callToActionLinkWrapper {
    position: relative;
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: transparent;
    padding: 0 25px 0 0;
    margin: 0;
    border-radius: 0;
    border: none;
    font-size: 1.4rem;
    font-weight: 500;
    text-transform: uppercase;
    width: 200px;
    padding-left: 30px;
    padding-top: 3px;
    padding-bottom: 3px;
}
#callToActionLinkWrapper option {
    text-align: center;
}
.callToActionArrow {
    position: absolute;
    right: 5px;
    top: -2px;
    z-index: -1;
}

#firstHeader{
  /*background:url('../images/kids_bg.jpg');*/
  /*background:url('../images/secondGameBG.png');*/
  /*background-repeat:no-repeat;
  background-size:cover;

  background-position:bottom center;*/
  background-attachment: fixed;
  margin-top:55px;
  padding-bottom:80px;
  padding-top:80px;
}
#firstHeader .hero_text{
  color:#fff;
  padding-bottom:20px;
}
#firstHeader #start_now,#firstHeader #play{
    color: #fff !important;
   text-align: center;
   background: #FF9F00 !important;
   border-radius:0;
   cursor: pointer;
   width: auto;
   height:auto;
   line-height: normal;
   font-size: 2rem ;
   display:inline-block;
   padding: 10px 20px;
   font-weight: bold;
   margin:0 auto;
   margin-top:20px;
   border-radius: 10px;
}
.play_now.start_in_section{
  color: #fff !important;
   text-align: center;
   background: #FF9F00 !important;
   border-radius:0;
   cursor: pointer;
   width: auto;
   height:auto;
   line-height: normal;
   font-size: 1rem ;
   display:inline-block;
   padding: 10px 20px;
   font-weight: bold;
   margin:0 auto;
   margin-top:20px;
   border-radius: 20px;
}
#firstHeader #start_now:hover{
  background:#fff !important;
  color:#234465 !important;
}
.start_now.play_now{
  color: #fff !important;
   text-align: center;
   /*background: #FF9F00 !important;*/
   background:transparent !important;
   border:2px solid #FF9F00;
   border-radius:0;
   cursor: pointer;
   width: auto;
   height:auto;
   line-height: normal;
   font-size: 1rem ;
   display:inline-block;
       padding: 8px 15px;
   font-weight: bold;
   margin:0 auto;
   margin-top:20px;
   text-transform: uppercase;
   border-radius: 20px;
}
.start_now.pl_now{
   color: #fff !important;
   text-align: center;
   /*background: #FF9F00 !important;*/
   background:#FF9F00 !important;
   border:2px solid #FF9F00;
   border-radius:0;
   cursor: pointer;
   width: auto;
   height:auto;
   line-height: normal;
   font-size: 1rem ;
   display:inline-block;
       padding: 8px 15px;
   font-weight: bold;
   margin:0 auto;
   margin-top:20px;
   text-transform: uppercase;
   border-radius: 20px;
}
.start_now.play_now:hover{
  background:#fff;
  color:#234465;
}
#firstHeader .header-overlay{
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  background:rgb(62, 62, 62) ;
  opacity: 0.5;
}
#firstHeader .prColBorder{

}
#username{
    color:#fff;
    
}
.user_profile{
  -ms-transform: translateY(0%) !important;
  -webkit-transform: translateY(0%) !important;
  transform: translateY(0%) !important;
  margin-top:10px;
}

/* ---- END of CALL TO ACTION STYLES ----*/
.headerBigIcon {
    display: inline-block;
    font-size: 14rem;
    position: absolute;
    top: 50%;
    right: 10%;
    transform: translateY(-50%);
}
/*== HOME SECTION ==*/

.test_img_badge {
    box-shadow: none;
    margin-top: 80px;
}
.padding-left-10 {
    padding-left: 8%;
}
.padding-right-10 {
    padding-right: 8%;
}
#test_info {

}
#other_info {

}
#test_info h1, #other_info h1, #badge_info h1 {
    font-size: 2rem;
    font-weight: 200;
    color: #666;
    margin-top: 0;
}
.verticalCenterAbsolute {
    position: absolute;
    top: 54%;
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    /*margin-top: 40px;*/
    margin-bottom: 20px;
}
.timeLineUp, .timeLineDown {
    position: absolute;
    width: 2px;
    left: 50%;
    transform: translateX(-50%);

}
.timeLineUp {
    top: 0;
    bottom: 50%;
}
.timeLineDown{
    top: 50%;
    bottom: 0;
}
.timeLineCircle {
    position: absolute;
    top: 50%;
    left: 50%;
    -mos-transform:translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%, -50%);
    height: 10px;
    width: 10px;
    border-radius: 50%;
    border-width: 4px;
    border-style: solid;
}
/*== 1st section - PATHS Structure ==*/
.pathStructureTile {
    height: 100%;
}
.tileImg {
  /* position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 140px;*/
    position: absolute;
    top: 36px;
    left: 33px;
    width: 39px;
}
.tileImg{

}
.tileIcon {
    position: absolute;
    top: 50%;
    left: 57%;
    -ms-transform:translate(-50%,-50%);
    -webkit-transform:translate(-50%,-50%);
    transform: translate(-50%, -50%);
    font-size: 4rem;
}
.tileText {
    color: #333;
    /*margin-left: 140px;*/
    padding: 16px;
}
.tileText h1{
    /*padding-top:20px;*/
    padding-left: 80px;
}
.startPathTitle{
    margin:50px 0;
}
.tileText .tileTextP{
    padding-top:20px;
    display:inline-block;
}
.tileText h1 {
    color: #666;
    margin: 0;
    font-size:1.15rem;
}
tileText h3 {
    color: #999;
    margin: 0;
}
.tileSideLine {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 8px;
}
.path-icon{
  font-size:2rem;
  margin-bottom:20px;
  float:left;
}
#iconTerminal{
  font-size:5rem;
  text-align: center;
  padding:0 10px;
  border:5px solid #fff;
  border-radius: 10px;
  display:inline-block;
}
#iconGame{
  font-size:5rem;
  text-align: center;
  padding:0 10px;
  border:5px solid #fff;
  border-radius: 10px;
  display:inline-block;
  padding-bottom:10px;
}
#iconWeb{

  text-align: center;
  width:90px;
  border:5px solid #fff;
  border-radius: 10px;
  display:inline-block;

}
#iconBuildGame{
  font-size:5rem;
  text-align: center;
  padding: 10px 10px;
  border:5px solid #fff;
  border-radius: 10px;
  display:inline-block;
  padding-bottom:10px;
  float:left;
}
.webBrowser{
overflow:hidden;


}
.urlBrowser{
  padding: 6px;
    border-bottom: 5px solid #fff;
    display: block;
    padding-bottom:15px;

}
.mainWindowBrowser{
  height:40px;
  display:inline-block;
}
.dotBrowser{
  width:10px;
  height:10px;
  background:#fff;
  display:inline-block;
  float:left;
  border-radius: 50%;
}
.pathStructureCenter{
  text-align:center;
}
.pathStructureBG1{
  background:#3DB0A1 !important;
}
.pathStructureBG2{
  background:#FFA500 !important;
}
.pathStructureBG3{
  background:#3F51B5 !important;
}
.pathStructureBG4{
  /*background:#607D8B !important;*/
  background:#c66 !important;

}
.pathStructureBGconsole{
    background:#FFA500 !important;
}
.pathStructureBGkids{
    background:#3DB0A1 !important;
}
.borderPath1{
  border-left:6px solid #3DB09E;
}
.borderPath2{
  border-left:6px solid #FFA500;
}
.borderPath3{
  border-left:6px solid #3F51B5;
}
.borderPath4{
  border-left:6px solid #c66;
}

.pathsStartButtonC1{
  border-radius: 20px;
  background:#3DB0A1;
  color:#fff;
}
.pathsStartButtonC2{
  border-radius: 20px;
  background:#FFA500;
  color:#fff;
}
.pathsStartButtonC3{
  border-radius: 20px;
  background:#3F51B5;
  color:#fff;
}
.pathsStartButtonC4{
  border-radius: 20px;
  background:#c66;
  color:#fff;
}
.pathsStartButtonkids{
     border-radius: 20px;
    background:#3DB0A1;
    color:#fff;
}
.pathsStartButtonconsole{
     border-radius: 20px;
  background:#FFA500;
  color:#fff;
}
.pathsStartButtonweb{
      border-radius: 20px;
  background:#3F51B5;
  color:#fff;
}
.pathsStartButtonbuilder{
    border-radius: 20px;
  background:#c66;
  color:#fff;
}
.why_start_section .tileText{
    text-align: justify;
}
.pcColBorder2{
  border-color:#FFA500;
}
.pcPic2{
  background-color:#f4dfb9 !important;
}
#firstStructureTile .tileSideLine{
    background-color: #96c; /* light color: #fcf; */
}
#firstStructureTile .tileIcon {
    color: #96c;
}
#secondStructureTile .tileSideLine{
    background-color: #c66; /* light color: #fcc; */
}
#secondStructureTile .tileIcon {
    color: #c66;
}
#thirdStructureTile .tileSideLine{
    background-color: #FFA500; /* light color: #fe9; */
}
#thirdStructureTile .tileIcon {
    color: #FFA500;
}
#fourthStructureTile .tileSideLine{
    background-color: #393; /* light color: #cfc; */
}
#fourthStructureTile .tileIcon {
    color: #393;
}
#fifthStructureTile .tileSideLine{
     background-color: #039CE6;
}
#border1{
    background-color: #96c;
}
#border2{
    background-color: #c66;
}
#border3{
   background-color: #FFA500;
}
#border4{
    background-color: #393;
}
#border5{
   background-color: #039CE6;
}
#border6{
    background-color: #3B5572;
}
#fifthStructureTile .tileIcon{
    color: #039CE6;
}
.imgWrapper {
    display: block;
}
.pathPicture {
    display: block;
    width: 100%;
    height: 200px;
    height: auto;
    background-color: #eee;
}
.imgOverText {
    position: absolute;
    bottom: 0px;
    width: 100%;
    color: white;
    background: -webkit-linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.7));
    background: -moz-linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.7));
    background: -ms-linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.7));
    background: -o-linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.7));
    background: linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.7));
}
.pathStrTitleWrapper {
    font-size: 1.5rem;
    padding: 24px 16px;
}
.pathStrSubTitleWrapper {
    font-size: 1rem;
}
.pathStrTitleBottomStripe {
    padding: 8px 16px;
}
.pathStrParagraph {
    font-size: 0.9rem;
}
.congratsText {
    font-size: 1rem;
    font-style: italic;
}
.pathStrCallToActionWrap{
    position: absolute;
    bottom: 16px;
    left: 75%;
    transform: translateX(-50%);

}
.pathStrCallToAction {
    padding: 12px 24px;
    font-size: 1rem;

}
.lineBox {
    position: absolute;
    top: 80px;
    left: 74px;
    right: 74px;
    height: 150px;
    z-index: -1;
}
.lineBox.typeB,.lineBox.typeA{
   /* height:60px;*/
}

.typeA {
    /*border-bottom-width: 1px;
    border-bottom-style: solid;
    border-left-width: 1px;
    border-left-style: solid;*/
}
.typeB {
   /* border-right-width: 1px;
    border-right-style: solid;*/
    top: 200px;
}
.typeAnew{
   /* border-bottom-style:dashed;
    border-bottom-width:2px;
    border-left-width:2px;
    border-left-style:dashed;
    border-bottom-left-radius:15px;
   */
}
.typeBnew{
    /*border-right-width:2px;
    border-right-style:dashed;
    border-top-width:1px;

    border-top-right-radius:15px;*/
    top: 200px;

}
.typeARight {
   /* border-bottom-width: 1px;
    border-bottom-style: solid;
    border-right-width: 1px;
    border-right-style: solid;*/
}
.typeBRight {
    /*border-left-width: 1px;
    border-left-style: solid;*/
    top: 200px;
}
.typeARightNew {
    /*order-bottom-width: 2px;
    border-bottom-style: dashed;
    border-right-width: 2px;
    border-right-style: dashed;*/
   /* border-left-width: 2px;
    border-left-style: none;
    border-bottom-right-radius:15px;*/
}
.typeBRightNew {
   /* border-left-width: 2px;
    border-left-style: dashed;*/
    top: 200px;
     /*border-right-style: none;
     border-top-width:2px;

    border-top-left-radius:15px;*/

}
.pathArrowBRoadCont{
    overflow: hidden;
    position: absolute;
    left: -5px;/*-1%*/
    width: 28px;/*2.3*/
    margin:0 auto;
    /*height:100%;
    top:20px;*/
    height:88%;
    top:63px;
}
.pathArrowBRoadCont2{
    overflow: hidden;
    position: absolute;
    right: -8px;/*1*/
    width: 27px;/*2.3*/
    margin:0 auto;
    /*height:100%;
    top:13px;*/
    height: 120%;
    top: 62px;
}
.pathArrowBRoadCont3{
    overflow: hidden;
    position: absolute;
    right: -8px;
    width: 27px;
    margin:0 auto;
    /*height:100%;
    bottom:20px;*/
    height:63%;
    bottom:36px;
}
.pathArrowBRoadCont4{
   overflow: hidden;
    position: absolute;
    left: -5px;
    width: 28px;
    margin:0 auto;
    height:132%;
    bottom:20px;
}
.pathArrowBRoad{
    /* background: url(../images/stack_logos/road-vert.png);*/
    background-size: contain;
    background-repeat: repeat-y;
    height:100%;
}
.pathArrowUp{
    position:absolute;
    bottom:-13px;
   /* background:#000;*/
    width:96%;
    left:2%;
    /*height:100%;*/
    /*left:2%;
    border-bottom:1px solid #000;
    border-bottom-left-radius: 15px;
    border-left:1px solid #000;*/
    /*background:url('../images/stack_logos/road.gif');*/
    height:28px;
    background-size:contain;
    background-repeat:repeat-x;
}
.pathArrowUpEven{
    position:absolute;
    bottom:-13px;
   /* background:#000;*/
    width:95%;
    height:100%;
    right:2%;
   /* border-bottom:1px solid #000;
    border-bottom-right-radius: 15px;
    border-right:1px solid #000;*/
    /*background:url('../images/stack_logos/road.gif');*/
    height:30px;
    background-size:contain;
    background-repeat:repeat-x;

}
.pathArrowDown{
   position:absolute;
    top:13px;
    /*background:#000;

    height:2px;*/
     width:90%;
    right:2%;
    height:100%;
    /*border-top:1px solid #000;
    border-top-right-radius: 15px;
     border-right:1px solid #000;*/
}
.pathArrowDownEven{
    position:absolute;
    top:13px;
    /*background:#000;

    height:2px;*/
     width:90%;
    left:1.5%;
    height:100%;
   /* border-top:1px solid #000;
    border-top-left-radius: 15px;
     border-left:1px solid #000;*/

}
.pathArrowDownOdd{

}
.pathArrowARight{
   position: absolute;
    /* background: #000; */
       width: 58px;
    height: 60px;
    bottom: -22px;
    right: -11px;
    /*border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    border-bottom-right-radius: 15px;*/
    /*background:url('../images/stack_logos/round-road-right.png');*/
    background-size:contain;
    background-repeat:no-repeat;
}
.pathArrowBRight{
   position: absolute;
    /* background: #000; */
   width: 68px;
    top: 8.5%;/*-15.5*/
    left: -17px;

    /*border.-left: 1px solid #000;
    border-top: 1px solid #000;
    border-top-left-radius: 15px;*/
    /*background:url('../images/stack_logos/round-road.gif');*/
    height:55px;/*30*/
    background-size:contain;
    background-repeat:no-repeat;
}
.pathArrowALeft{
   position: absolute;
    /* background: #000; */
    width: 56px;
    height: 61px;
    bottom: -14.5%;
    left: -8px;
    /*border-left: 1px solid #000;
    border-bottom: 1px solid #000;
    border-bottom-left-radius: 15px;*/
    /*background:url('../images/stack_logos/round-road-left.png');*/
    background-size:contain;
    background-repeat:no-repeat;
}
.pathArrowBLeft{
   position: absolute;
    /* background: #000; */


    width: 64px;
    height: 49px;
    top: 14px;
    right: -18px;
    /*border-right: 1px solid #000;
    border-top: 1px solid #000;
    border-top-right-radius: 15px;*/
    /*background:url('../images/stack_logos/round-road-right-down.png');*/
    background-size:contain;
    background-repeat:no-repeat;

}
.pathArrowBLeft{

}

.pathArrowBRightLeft{
    /*position:absolute;
    background:#000;
    width:2px;
    height:90%;
    top:10%;
    left:1.5%;*/
}

.pathroad_round-road-right-down, .pathroad_round-road, .pathroad_round-road-left, .pathroad_round-road-right, .pathroad_road-vert,
.pathroad_road
{ max-width: 100%; background-size: 100%; background-image: url('../images/sprites/path-page/pathroad_sprite.gif'); }

/*.pathroad_round-road-right-down { background-position: 0 0%; background-size: 100%; }
.pathroad_round-road { background-position: 0 23.148148%; background-size: 100%; }
.pathroad_round-road-left { background-position: 0 48.543689%; background-size: 120%; }
.pathroad_round-road-right { background-position: 0 77.669903%; background-size: 120%; }
.pathroad_road-vert { background-position: 0 89.285714%; background-size: 224.719101%; }
.pathroad_road { background-position: 0 100%; background-size: 12%; }*/


.pathroad_road { background-position: 0 0%; background-size: 7%; }
.pathroad_round-road-right-down { background-position: 0 9.084769%; background-size: 100%; }
.pathroad_round-road { background-position: 0 26.139818%; background-size: 100%; }
.pathroad_round-road-left { background-position: 0 44.704649%; background-size: 120%; }
.pathroad_round-road-right { background-position: 0 65.851101%; background-size: 120%; }
.pathroad_road-vert {background-image:url('../images/stack_logos/road-vert.gif'); background-position: 0 100%; /*background-size: 243.902439%;*/background-size:100%; }
.pcBox {
    margin-bottom: 95px;
    width:100%;
   /* padding-left:15%;*/
}
.pcBox.align_right{
    /*padding-right:15%;*/
}
.pcPic {
    position: relative;
    display: inline-block;
    height: 160px;
    width: 160px;
    background-color: lightblue;
    border-width: 1px;
    border-style: solid;
    border-radius: 50%;
    z-index: 0;
    overflow:hidden;
}
.pcPic img {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform:translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%, -50%);
}
.pcPic .paths_img{
    position: absolute;
    top: 50%;
    left: 50%;
    -mos-transform:translate(-50%,-50%);
    -webkit-transform:translate(-50%,-50%);
    transform: translate(-50%, -50%);
}
.pathArrowTop, .pathArrowBottom, .pathArrowMid {
    font-size: 2rem;
    position: absolute;
    left: 50%;
    -ms-transform: translateX(-49%);
    -webkit-transform: translateX(-49%);
    transform: translateX(-49%);
}
.pathArrowTop {
    top: -30px;
}
.pathArrowBottom {
/*    display: none;*/
    bottom: -10px;
    font-size: 0.7rem;
    z-index: -1;
}
.pathArrowMid {
    bottom: 0;
    -ms-transform: translateY(46%);
    -webkit-transform:translateY(46%);
    transform: translateY(46%);
}
.pcText {
    margin: 0 30px;
    vertical-align: top;
    display: inline-block;
    width:50%;
}
.pcText p {
    /*width: 400px;*/
    /*display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -ms-text-overflow:ellipsis;
    -text-overflow:ellipsis;
    height:60px;
    word-wrap: break-word;*/
}
.path_course_desc{
     display: -webkit-box;
     display:box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -ms-text-overflow:ellipsis;
    -text-overflow:ellipsis;
    height:75px;
    word-wrap: break-word;
    margin-bottom:8px;
}
.pcLink {
    text-transform: uppercase;
}
/*== 1st section - SIGN IN/SIGN UP - laravel - new==*/
#socialLogin {
    margin-top: 40px;
    margin-bottom: 40px;
}
.socialLoginIcon {
    display: inline-block;
    font-size: 2rem;
    line-height: 60px;
    height: 60px;
    width: 60px;
    border-radius: 50% !important;
}
.loginSubTitle {
    margin: 20px 0 10px;
}
.errorMsg {
    color: red;
    position: relative;
    bottom: 15;
    font-weight: bold;
}
.errorMsg ul {
    list-style: none;
    padding: 0;
}
.login_label {
    font-size: 1.5em;
    display: block;
    width: 100%;
    margin: 0 auto;
    font-weight: 500;
}
.panel {
    background-color: white;
    /*width: 300px;*/
    /*width:25%;*/
    width:30%;
    margin: 0 auto;
    padding: 24px 24px 24px;
}
#socialLogin a{
    width:100%;
}
#facebook{
    border-radius:0 !important;
    width:100%;
    background:#3b5998;
    text-align: left;
    padding:0 10px;
    box-sizing:border-box;
    border-radius:4px !important;
}
.login-title{
    font-size:1rem;
    vertical-align: top;
}
#facebook .fa{

}
.login_input_label {
    display: block;
    margin: 10px 0;
    color: #099;
}
.login_input {
    padding: 10px 0;
    width: 100%;
    font-size: 1rem;
}
#loginButton, #registerButton {
    text-transform: uppercase;
    font-size: 1rem;
    display: block;
    width: 100%;
    margin-top: 40px;
    border-radius: 50px;
    background: #FFA500;
    padding: 20px 0;
    box-sizing: border-box;
    outline:none;
}
.underPanelLink {
    display: block;
    margin: 20px 0;
    font-size: 1rem;
}
.activeInput{
    border-bottom-color: #039be6;
    border-right: 1px solid #039be6;
}
.form_row {
    margin-bottom: 40px;
    position: relative;
}
.top_row {
    margin-top: 40px;
}
.checkBox_row {
    margin: 20px 12px;
}
.checkBoxText_row {
    margin-left: 8px;
}
.buttonSeeAll {
    position: relative;
    font-size: 1rem;
    text-align: center;
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    padding: 6px 0;
}
.seeAllPathsArrow {
    position: absolute;
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 3rem;
}
.trnsprnt_button {
    color: #099;
}
a.trnsprnt_button {
    color: #099;
}
.imgBckground {
    background-image: url('../images/testBG.gif');
    background-repeat: no-repeat;
    background-position: bottom left;
    background-size: 350px;
}
.termsWrap {
    margin: 20px 12px;
}
.termsSmallText {
    display: inline-block;
    margin-left: 8px;
}
/*== 1st section - SIGN IN/SIGN UP - old ==*/
#regContent, #loginContent {
    padding-top: 60px;
    padding-bottom: 60px;
}
.sign_in, .sign_up {
    font-size: 1.2em;
    display: inline-block;
}

.sign_in {
    color: #333;
    padding: 10px 0px;
    background-color: #ccc;
    width: 50%;
}

.sign_up {
    color: white;
    padding: 6px 0px;
    background-color: #999;
    width: 49%;
    cursor: pointer;
}
.faqIcon {
    text-align: center;
    display: inline-block;
    height: 2rem;
    width: 2rem;
    border-radius: 3rem;
    background-color: #ccc;
    font-size: 1rem;
    color: white;
    line-height: 2rem;
    margin-right: 20px;
    position:relative;

}
.faqIcon i{
  position:absolute;
  top:50%;
  left:50%;
  -ms-transform:translate(-50%,-50%);
  -webkit-transform:translate(-50%,-50%);
  transform:translate(-50%,-50%);
  
}
.slideArticles {
    padding-top: 16px;
}
.form_body {
}
.lastInput {
    margin-bottom: 80px;
}
#lastInputOverride{
    margin-bottom:10px;
}
.supportMessageSentCaptcha {
    position: absolute;
    bottom: 16px;
    left: 16px;
    margin: 0;
}
h1.supportSection {
margin-bottom: 30px;
}
h1.faq_h1 {
    display: inline-block;
    padding: 0px;
    margin: 0;
    text-align: left;
}
.faqContainer {
    cursor: pointer;
    vertical-align: top;
    background: #fff;
    padding: 16px;
    border-bottom: 1px solid #ddd;
}
.inputRegName {
    display: inline-block;
    margin: 10px 0px;
}
#logcaptcha {
    margin-top: 10px;
}
.terms_conditions {
    margin: 20px 0px;
}
#terms_wrap > h1 {
    font-size: 3em;
    font-weight: 200;
    color: #666;
}
.terms_conditions span{
    font-size: 0.9em;
    font-weight: 200;
    vertical-align: top;
    margin-left: 10px;
    display: inline-block;
}
#agree_term {
    cursor: pointer;
    font-size: 0.7rem;
    font-weight: 400;
}
.form {
    width: 344px;
    margin-left: auto;
    margin-right: auto;
}
.popup_terms {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 3;
}
.terms_position {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.x_close {
    border-radius: 4px;
    display: inline-block;
    padding: 5px 8px;
    font-weight: 200;
    font-size: 1.5em;
    color: #099;
    text-align: right;
}
.x_close:hover {
    transform: translateY(-2px);
    box-shadow: 1px 2px 3px #666;
}
/* --- section - COURSES --- reNEWed - 18.01.2016*/
.coursesLevels{
  width:10%;/*15*/
  display:inline-block;
  float:left;
  position:fixed;
}
.pathLevels{
  width:18%;/*15*/
  display:inline-block;
  float:left;
}
.pathLevelsMobile{
    display:none;
}
.pathWrapper{
    width: 80%;
    display: inline-block;
    margin-left: 0;
    float: right;
}
.single_path_tile{
    width:31.3%;
    display:inline-block;
    background: transparent;
    /*box-shadow: 0 2px 7px rgba(0,0,0,0.4);*/
    height: 100%;
    position: relative;
    text-align: center;
    margin:5px;
    height:auto;
    padding: 5px;
    box-sizing: border-box;
    border:none !important;

}
.single_path_tile_faded{
    opacity: 0.5;
    overflow:hidden;
}
.kid_paths .single_path_tile:first-child{
    /*-ms-flex-order: 1;
    -webkit-order: 1;
    order:3;*/
}
.goto_path{
    display: inline-block;
    padding: 10px;
    text-transform: uppercase;
    background: #FFA500;
    font-weight: bold;
    display: inline-block;
    text-align: center;
    box-shadow: none;
    border-radius: 0;
    color: #fff !important;
    border-radius: 20px;
    font-size: 0.8rem;
    position:absolute;
    bottom:6px;
    left: 50%;
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform:translateX(-50%);
    width: 75%;
    box-sizing: border-box;

}
.goto_path_disabled{
    background:rgba(255, 165, 0, 0.49);
}
.path_title{
    margin-bottom: 40px;
    vertical-align: middle;
    height:90px;
    position:relative;
    margin-bottom:0;
}
.path_title span{
    position:absolute;
    top:50%;
    left:50%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform:translate(-50%,-50%);
    transform:translate(-50%,-50%);
    width:100%;
}
.number_courses{
  margin-bottom:45px;
  font-size:1.2rem;
  font-style: italic;
  margin-top:5px;
}
.border_kids{
    border-bottom:6px solid #3DB0A1;
}
.border_console{
    border-bottom:6px solid #FFA500;
}
.border_builder{
    border-bottom:6px solid #c66;
}
.border_apps{
     border-bottom:6px solid #3F51B5;
}
.pathLevels .activeCourseLevel{

}
.pathLevels .singleCourseLevel{
    background:rgba(255, 165, 0, 0.51);
}
.rest_paths{
    border-left:6px solid orange;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0,0,0,.4);
    border-radius: 3px;
    padding:20px;
    width:100%;
    /*background:#ccc;
    margin:10px;*/

}
.see_rest{
    display:inline-block;
    vertical-align: middle;
    color:#039be6;
    font-weight:bold;
    font-size:1.2rem;
}
.paths_rest{
    display:inline-block;
    vertical-align: middle;
}
.kid_color{
    color:#3DB0A1;
}
.icon_rest{
    width:45px;
    height:45px;
    border-radius: 50%;
    background-color: #EDEDED;
    margin: 0 auto;
    box-shadow: 0 3px 6px rgba(0,0,0,.12);
    border: 4px solid #fff;
    vertical-align: middle;
    display: inline-block;
}
.single_path_tile .icon{
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: #EDEDED;
    margin: 0 auto;
    box-shadow: 0 3px 6px rgba(0,0,0,.65);
    border: 4px solid #fff;
}
.justifyFlexStart{
        display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-justify-content: flex-start;
    -webkit-flex-wrap: wrap;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    -webkit-align-items: stretch;
    align-items: stretch;
}
.single_path_tile  .logo_path_vprogramming{
    background-size:518%;
}
.coursesWrapperInCourses{
  width:83%;
  display:inline-block;
  margin-left:15%;
}
.coursesWrapperInProfile {
     width:83%;
  display:inline-block;
  margin-left:0%;
}
.singleCourseLevel{
    padding: 12px;
    box-sizing: border-box;
    /*background: #3be3d1;*/
    background:rgba(255, 165, 0, 0.69);
    margin-bottom: 10px;
    font-weight: bold;
    color: #000;
    text-transform: uppercase;
    border-radius: 5px;

    position: relative;
    cursor: pointer;
     font-size:0.8rem;
}
.singleCourseLevel:hover{
  transition: box-shadow 0.3s;
  color:#fff;
}

.coursesWrapperInCourses .levels{
  background: transparent;
  box-shadow: none;

}
.coursesWrapperInHome .levels{
  background:#fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
  margin-left:20px;
}
.coursesWrapperInCourses .levels .profilePathHeader{
  border:none;
}
.singleCourseLevel.activeCourseLevel{
  color:#fff;
  background:#FFA500;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.singleCourseLevel.activeCourseLevel:after{
  content: "";
  width: 0;
  height: 0;
  border-top: 25.5px solid transparent;
  border-bottom: 25.5px solid transparent;
  border-left: 16px solid #FFA500;
  position: absolute;
  left: 98.8%;
  top: 0;

}
.levelConsolesCourses{
  display:none;

}
.levelSitesCourses{
  display:none;
}
.levelBuildGameCourses{
  display:none;
}
.coursesWrapperInCourses .angle_down{
/*  display:none;*/
}
.titleCoursesHome{
  position:relative;
  padding-bottom:20px;
}
.titleCoursesHome:after {
    position: absolute;
    bottom: 0;
    height: 2px;
    width: 10%;
    background: #039be6;
    content: "";
    left: 50%;
    transform: translateX(-50%);
}
.courseTile {
    position: relative;
    margin-bottom: 30px;
}
.commingSoon {
    position: absolute;
    top:35px;
    right: 10px;
    color: red;
    font-weight: 500;
    text-transform: uppercase;
    text-align: right;
}
.shortDesc {
    position: absolute;
    right: 16px;
}
.courseTileHeader {
    position: relative;
    height: 160px;
    overflow:hidden;
}
.courseTileHeaderOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.8;
}
.courseLogo {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width:100%;
    z-index:4;
}
.courseTileText {
    padding: 16px 16px 64px;
}
.consoleCourses.courseProfileList{
    padding-left:70px;
}
.courseTileTextNotOnCourses {
    padding: 16px 16px;
}
.percentageDone {
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: 500;
    color: #666;
    width: 36%;
    margin-top: 10px;
    /*-ms-transform: translateY(-60px);
    -webkit-transform:translateY(-60px);
    transform: translateY(-60px);*/
    -ms-transform: translateY(-10px);
    -webkit-transform:translateY(-10px);
    transform: translateY(-10px);
    z-index:5;
    display:inline-block;
}
.percentageDoneWithText {
    margin-top: 2px;
}
.percentageDoneWithoutText {
    margin-top: 16px;
}
.percentageDoneNotOnCourses {
    -ms-transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
}
.courseTitle {
    font-weight: 500;
    font-size: 1.2rem;
    margin: 0;
    /*margin-right: 150px;*/
    margin-right:0 !important;
    width:50%;
    display:inline-block;
    float:left;
}
.coursesProgrammingKids .courseTitle{
    width:100%;
}
.percentageDone:after{
    content:"";
    clear:both;
    display: table;
}
.courseSubTitle {
    font-size: 1.2em;
    font-weight: 400;
    margin: 0 0 16px;
}
.courseDescription {
    padding-top: 20px;
    display: block;
    display: -webkit-box;
    max-width: 100%;
    height: 43px;
    margin: 0 auto;
    font-size: 14px;
    line-height: 1;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    /*padding-bottom:2px;*/
    clear: both;
    height:77px;
    line-height: 20px;
    margin-bottom:10px;
}
#courseDescription {
    border: none;
}

.progressCourseBG {
    background-color: green;
}
.paidCourse {
    color: #900;
}
.freeCourse {
    color: #099;
}
.courseTileButtons {
    position: absolute;
    bottom: 16px;
    /*right: 16px;*/
    right:0 !important;
    width:100%;
    box-sizing:border-box;
    text-align: right;
}
.course_start_button{
    display:inline-block;
    width:48%;
    box-sizing:border-box;
    font-size:0.8rem;

}
.startCourseButton{
    display:inline-block;
    width:48%;
     box-sizing:border-box;
     font-size:0.8rem;
     text-align: right;
     text-transform: uppercase;

}
.footer-separator{
    height:80px;
    display:block;
}
.materialButton {
    text-align: center;
    cursor: pointer;
    display: inline-block;
    padding: 8px 20px;
    font-weight: 500;
}
.greenButton {
    background-color: #099;
    color: white;
}
.greenButton:hover {
    background-color: #0cc;
}
.transparentButton {
    color: #099;
}
.transparentButton:hover {
    color: #0cc;
}
#freeCourseBanner{
  margin-top:70px;
  background-color:#099;
}


#parentsBanner{
  padding:40px 0;
  text-align: center;
  /*background-image:url(../images/summer.png);
  background-size:cover;*/
  position:relative;
  /*background-position: center;*/
  width:100% !important;
}
#see_plans{
  display:inline-block;
  background-color:#FFA500;
  color:#fff;
  padding:10px;
  cursor: pointer;
  border-radius: 5px;
  font-size:1.5rem;
}
.bannerOverlay{
  position:absolute;
  top:0;
  left:0;
  bottom:0;
  right:0;
  background-color:rgba(0, 0, 0, 0.39);
}
.parentsBannerContent{
  position:relative;
  z-index:5;
  color:#fff;
}
.freeCourseBox{
    height:150px;
    width:32%;
    position:relative;
    overflow:hidden;
}
.parentsPaymentModal{
  position:fixed;
  top:50%;
  left:50%;
  -ms-transform:translate(-50%,-50%);
  -webkit-transform:translate(-50%,-50%);
  transform:translate(-50%,-50%);
  display:none;
  z-index: 21;
background-color: #fff;
padding:25px 15px;
width:80%;
padding-top:35px;
}
.parentsPaymentModal .payment_plan{
  box-shadow:0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}
.parentsPaymentOverlay{
  position:fixed;
  top:0;
  left:0;
  bottom:0;
  right:0;
  background-color:rgba(0, 0, 0, 0.7);
  z-index:20;
  display:none
}
.closeParentsModal{
  position:absolute;
  top:0;
  right:0;
  color:red;
  cursor: pointer;
  font-size:2rem;
}
/********** PAY BOXES **********************/
.payment_plan{
    text-align: center;
    /*width:48%;*/
  /*  width:32%;*/
    width:33.3% !important;
    box-shadow:0 2px 24px rgba(0,0,0,0.4);
    border-radius: 8px;
    box-sizing: border-box;
    
    margin-left:5px;  
    margin-right:5px;  
    box-shadow:0 1px 3px 2px rgba(0, 0, 0, 0.4), 0 1px 2px rgba(0, 0, 0, 0.16) !important;
}
.payment_title{
    text-transform: uppercase;
    font-weight:bold;
    margin-top:20px;
    color:#fff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin:0;
    font-size:2rem;
    height:170px;
    vertical-align: middle;
    position:relative;
    overflow:hidden;
}
.payment_title .corner-ribbon{
    font-size:0.8rem;
    transform: none;
    -ms-transform: none;
    -webkit-transform: none;
    left:0;
    top:0;
    width:auto;
    line-height: normal;
    padding:5px;
}
.payment_title .bigPlan{
    position:absolute;
    top:50%;
    left:50%;
    -ms-transform:translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    width:100%;
}
.smallPlan{
    font-size:2rem;/*0.9*/
    width:100%;
}
.payment_title_free{
    background:#FFA500;
    cursor: pointer;
}
.payment_title_premium{
    background:#039ce6;
}
.payment_title_extra1{
  background:#099;
}
.payment_title_extra2{
  background:#607d8b;
}
.payment_title_share{
    background:#3B5998;
}
.payments_plans{
   
    margin:0 auto;
    position:relative;

    
}
.itemsWrapper{
    width:100%;
    position:relative;
    /*left:-33%;*/
    margin: 0px;  
    padding:5px 0;
}
.carousel_inner{
    float:left;
    overflow:hidden;
    width:100%;
}
#cannotExtra{
  position:fixed;
  top:50%;
  left:50%;
  -ms-transform:translate(-50%,-50%);
  -webkit-transform:translate(-50%,-50%);
  transform:translate(-50%,-50%);
  z-index:36;
  background-color:#fff;
  display: none;
  padding:15px;
  text-align: center;
}
#cannotExtraOverlay{
  position: fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background-color:rgba(0, 0, 0, 0.69);
  z-index:35;
  display: none;
}
#closeExtra{
  position:absolute;
  top:0;
  right:0;
  color:red;
  font-size:1.2rem;
}
.planBtn{
    position:absolute;
    top:50%;
    -ms-transform:translateY(-50%);
    -webkit-transform:translateY(-50%);
    transform:translateY(-50%);
    font-size:4rem;
    font-weight:bold;
    z-index:5;
    cursor:pointer;
}
#nextPlan{
    right:-2%;
    transform: translate(50%,-50%);
    -ms-transform:translate(50%,-50%);
    -webkit-transform:translate(50%,-50%);
}
#prevPlan{
    left: -2%;
    transform: translate(-50%,-50%);
    -ms-transform:translate(-50%,-50%);
    -webkit-transform:translate(-50%,-50%);
}
.activePlan{
    display: inline-block;
}
.inactivePlan{
   display:none;
}
.justifyFlexSlider{
    display: flex;
    display: -ms-flexbox;
    display: -webkit-flex;
}
/****************** END PAY BOX **************/
/*============= GIFS SECTION =======================*/
.contentCenter{
    text-align: center;
}
.clearFix{
    overflow: hidden;
}
#gif1{
    /*background-image:url(../images/levels/slide1CH.gif);
    background-repeat:no-repeat;
    background-size:contain;
    background-position: center center;*/
    height:250px;
    width:100%;
    z-index:10;
    overflow:hidden;
}
#gif2{
   /* background-image:url(../images/levels/slide2CH.gif);
    background-repeat:no-repeat;
    background-size:contain;
    background-position: center center;*/
    height:250px;
    width:100%;
    z-index:10;
    overflow:hidden;
}
#gif3{
    /*background-image:url(../images/levels/frameworks_new.gif);
    background-repeat:no-repeat;
    background-size:contain;
    background-position: center center;*/
    height:220px;
    width:100%;
    z-index:10;
    overflow:hidden;
}
#gif4{
    background-image:url(../images/devices-screen-new.png);
    background-repeat:no-repeat;
    background-size:contain;
    background-position: center center;
    height:250px;
    width:100%;
    z-index:10;
    overflow:hidden;
}
.firstGif{
    padding:50px 0;
    box-sizing: border-box;
}
.gif_title{
    color:#000 !important;
}
.gifText{
    height:300px;
    padding:25px;
    box-sizing:border-box;
    padding-top:60px;
    color:#545454;
}
.gifText h1{
    font-size:1.5rem;
    font-weight:bold;

}
.gifText h1:hover{
    color:red;
}
.gifText p{

}
.rowGifChineese:first-child .gifText h1{
    color:red;
}
.rowGifChineese:nth-child(2n+0) .gifText h1{
    color:red;
}
.gifFrame{
    text-align: center;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border:1px solid #ccc;
    -moz-box-shadow: 10px 10px 30px #949494;
    -webkit-box-shadow: 10px 10px 30px #949494;
    box-shadow: 10px 10px 30px #949494;
}

.gifFrameRight{
    -moz-box-shadow: -10px -10px 30px #949494;
    -webkit-box-shadow: -10px -10px 30px #949494;
    box-shadow: -10px -10px 30px #949494;
}
.top-bar{
    background:#ccc;
    height:17px;
    border-radius: 5px 5px 0 0;
}

.circle{
    height: 8px;
    width: 8px;
    display: inline-block;
    border-radius: 50%;
}
.circles{
    margin: 5px 11px;
    height: 100%;
    transform: translateY(-17%);
    text-align: left;
}
.circle-red{
    background:red;
}
.circle-yellow{
    background:yellow;
}
.circle-green{
    background:green;
}
.leftPart{
    /*float:left;*/
    width:49%;
    display:inline-block;
    vertical-align:middle;
}
.rightPart{
    /*float:right;*/
    width:49%;
    display:inline-block;
    vertical-align:middle;
}
#gifsPanel{
  background:#F5F6F7;
}
#gifsPanel .half{
  width:50%;
}
#gifsPanel .prColBG{
  background:#ccc;
}
#gifsPanel .prColBorder{
    border-color:#9DB0B5;
}
#gifsPanel .accColBG{
  background-color:#fff;
}
.gifsSectionTitle{
  text-align: center;
  font-size:3.2rem;
  margin-top:0;
  position: relative;
  padding-bottom:10px;
  font-weight: normal;
}
.gifsSectionTitle:after{
  content:"";
  position:absolute;
  bottom:0;
  width:10%;
  height:2px;
  background:#0989F0;
  left:50%;
  transform: translateX(-50%);
}
.play_gif{
    font-size: 3rem !important;
    text-align: center;
    position: absolute;
    z-index: 10;
   top:50%;
   left:50%;
   -ms-transform:translate(-50%,-50%);
   -webkit-transform:translate(-50%,-50%);
   transform:translate(-50%,-50%);

    cursor:pointer;
    color:#fff;
    width:40px;
    height:40px;
    background:#545454;
    border-radius: 50%;
    padding:20px;
}
.play_gif span{
    position:absolute;
     top:50%;
   left:56%;
   -ms-transform:translate(-50%,-50%);
   -webkit-transform:translate(-50%,-50%);
   transform:translate(-50%,-50%);

}
.gifBeforePlayOverlay{
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    /*background:rgba(62, 62, 62, 0.45);*/
}
.greenShadow{
    /*-moz-box-shadow: 10px 10px 30px #3DB0A1;
    -webkit-box-shadow: 10px 10px 30px #3DB0A1;
    box-shadow: 10px 10px 30px #3DB0A1;*/
    border-bottom:6px solid #3DB0A1;
}
.redShadow{
    /*-moz-box-shadow: 10px 10px 30px #c66;
    -webkit-box-shadow: 10px 10px 30px #c66;
    box-shadow: 10px 10px 30px #c66;*/
    border-bottom:6px solid #c66;
}
.orangeShadow{
   /* -moz-box-shadow: 10px 10px 30px #FFA500;
    -webkit-box-shadow: 10px 10px 30px #FFA500;
    box-shadow: 10px 10px 30px #FFA500;*/
   border-bottom:6px solid #FFA500;
}
/*== 1st section - COURSES ==*/

.free_course {
    background-color: #dff;
}
.premium_course {
    background-color: #fdf;
}
/*== 1st section - COURSE_OVERVIEW ==*/
.courseName {
    font-size: 2rem;
}
.startCourse {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 0;
    -mos-transform:translateY(-50%);
    -webkit-transform:translateY(-50%);
    transform: translateY(-50%);
    padding: 5px 20px;
    font-size: 2rem;
    /*border: 1px solid white;*/
    font-weight: 500;
    z-index: 1;
    background: #FFA500;
    border-radius: 50px;
    padding: 6px 42px;
}
.flatStartCourseBtn{
  border-radius: 20px;
  background-color:#FF9F00;
  position:relative;
  z-index:19;
}
.flatStartCourseBtn .floatMenuNew{
    width:400%;
}
.blueColor{
    color: #039be6;
}
.a2 {
    display: none;
    color: #999;
    font-size: 2.1em;
    font-weight: 500;
    padding: 5px 20px;
    border: 1px solid #999;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 30px;
}
#hours {
    font-weight: 300;
    font-size: 0.7em;
}
.course_line {
    margin-top: 0;
    margin-bottom: 100px;
    font-size: 3em;
    font-weight: 300;
    padding: 10px 0px;
    text-align: center;
}

.side_box {
    padding: 10px;
    background-color: #666;
    width: 30%;
}
.pagePath {
    position: absolute;
    top: 10px;
    font-size: 1rem;

    font-weight: 500;
}
.breadCrumb {
    margin-right: 10px;
}
.courseContentTitle{
    position:relative;
    padding-bottom:20px;
}
.courseContentTitle:after{
  content:"";
  position:absolute;
  bottom:0;
  border-bottom:2px dashed #039be6;
  height:2px;
  width:50%;
  left:50%;
  -mos-transform:translateX(-50%);
  -webkit-transform:translateX(-50%);
  transform: translateX(-50%);
}
.courseTree {
    width: 40%;
    display: inline-block;
}
.juniorCourseTree{
    width:40%;
    display:inline-block;
    padding:20px;
    box-sizing:border-box;
}
.juniorCourseTree .exBoxes{
    /*display: flex;
    display: -webkit-flex;
    -webkit-justify-content:space-between;
    justify-content:space-between;
    -webkit-flex-wrap:wrap;
    flex-wrap: wrap;
    align-items: stretch;
    height:800px;*//*100*/
}
.juniorCourseTree .exerciseJuniorBox{
   /* width: 25%;
    height: 15%;*/
    width:30%;
    height:100px;
   float:left;
   margin-right:3%;
    padding:5px;
    box-sizing:border-box;
    text-align: center;
    position:relative;
    margin-bottom: 80px;
    background:#a8e0fb;/*#f1e124*/
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    cursor:pointer;
}
.juniorCourseTree .exerciseJuniorBox .fa-lock{
    font-size:40px;
    position:absolute;
    top:50%;/*50*/
    left:50%;
    -mos-transform:translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    color:#3a3a3a;
    opacity: 0.4;
}
.juniorCourseTree .exerciseJuniorBox .fa-unlock{
    font-size:40px;
    position:absolute;
    top:50%;/*50*/
    left:50%;
    -mos-transform:translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    color:#3a3a3a;
    opacity: 0.4;
}
.juniorCourseTree .exerciseJuniorBox .fa-check{
    font-size:40px;
    position:absolute;
    top:36%;
    left:50%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    color:#09a609;
    font-weight:bold;
    opacity: 0.4;
}
.juniorCourseTree .exerciseJuniorBox .juniorBoxExTitle{
    position: absolute;
    top: -24px;
    left: 0;
    font-weight: normal;
    color:#d99b0d ;/*#FFA500*/
    font-size:1rem;
    width:100%;
    z-index: 5;
    color:#000;
}
.juniorCourseTree .exerciseJuniorBox .arrowEx{
    height: 2px;
    background:#039be6;
    width:100%;
    position:absolute;
    right:-100%;
    top:50%;
}
.juniorCourseTree .exerciseJuniorBox .fa-angle-right{
    position:absolute;
    right:-25%;
    top:50%;
    -ms-transform:translate(-50%,-505);
    -webkit-transform:translate(-50%,-50%);
    transform: translate(-50%,-50%);
    color:#039be6;
    font-size:22px;
}
.juniorCourseTree .exerciseJuniorBox .fa-angle-left{
    position:absolute;
    right:-50%;
    top:127%;
    -ms-transform:translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    color:#039be6;
    font-size:22px;
}
.juniorCourseTree .exerciseJuniorBox .fa-angle-down{
    display:none;
    position:absolute;
    -ms-transform:translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    color:#039be6;
    font-size:22px;
}
.juniorCourseTree .exerciseJuniorBox:nth-child(3n+3) .fa-angle-right{
    display:none;
}
.juniorCourseTree .exerciseJuniorBox:nth-child(3n+3) .fa-angle-left{
    display:none;
}
.juniorCourseTree .exerciseJuniorBox:nth-child(3n+3) .fa-angle-down{
    display:block;
    bottom: -34%;/*21*/
    top: auto;
    right: auto;
    left: 49%;
}
.arrowExLong{
    /*display:none;*/
    width:100%;
    display:block;
    height:2px;
    background:#039be6;
    position:absolute;
    bottom:-28%;/*21*/
    right:50%;
    -mos-transform:translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(50%);
}
.arrowExDown{
    display:none;
}
.juniorCourseTree .exerciseJuniorBox:nth-child(3n+3) .arrowEx{
    height: 13%;/*12*/
    width: 2px;
    bottom: -28%;/*20*/
    top: auto;
    right: auto;
    left: 49%;

}
.juniorCourseTree .exerciseJuniorBox:nth-child(3n+1) .arrowExDown{
    display:block;
    position:absolute;
    height: 28%;/*19*/
    width: 2px;
    /*top: -39%;*/
    top:-54%;
    right: auto;
    left: 50%;/*33*/
    background:#039be6;
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index:-1;

}
.juniorCourseTree .exerciseJuniorBox .junniorBoxTitle{
    text-transform: uppercase;
    font-size:14px;
}
.juniorCourseTree .exerciseJuniorBox .junniorBoxSubTitle{
    text-transform: lowercase;
    font-size:11px;
    position: absolute;
    bottom: 12px;
    left: 0;
    font-weight: bold;
    font-size:1rem;
    left:50%;
    -ms-transform:translateX(-50%);
    -webkit-transform:translateX(-50%);
    transform:translateX(-50%);
    width:100%;
    color:#0058ff;

}
.juniorCourseTree .exerciseJuniorBox  .junniorBoxMax{
    font-size:14px;
    position:absolute;
    top:68%;
    left:50%;
    -mos-transform:translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    /*font-weight:bold;*/
}
.juniorCourseTree .exerciseJuniorBox  .junniorBoxTitle{
    font-size:14px;
    position:absolute;
    top:25%;
    left:50%;
    transform: translate(-50%,-50%);
    font-weight:bold;
}
.juniorCourseTree .exerciseJuniorBox  .junniorBoxCoinsCount{
    display: block;
    z-index:5;
    position: absolute;
    bottom: 13px;
    left:10px;
    width:100%;
    line-height:0;
}
.juniorCourseTree .exerciseJuniorBox .junniorBoxCoinsCount img{
    transform: translate(-17%,30%);
}
.juniorCourseTree .exerciseJuniorBox:nth-child(3n+4) .fa-angle-down{
    display:block;
    top: -11%;
    right: auto;
    left: 50%;/*33*/
}
.juniorCourseTree .exerciseJuniorBox:last-child{
    display:none;
}
.juniorCourseTree .exerciseJuniorBox:first-child .arrowExDown{
    display:none
}
.juniorCourseTree .exerciseJuniorBox:nth-child(10) .arrowEx{
    display:none;
}
.juniorCourseTree .exerciseJuniorBox:nth-child(10) .arrowExLong{
    display:none;
}
.juniorCourseTree .exerciseJuniorBox:nth-child(10) .fa-angle-right{
    display:none;
}
.juniorCourseTree .exerciseJuniorBox:nth-child(10) .arrowEx{
    display:none;
}
.juniorCourseTree .exerciseJuniorBox:nth-child(10) .fa-angle-right{
    display:none;
}
.juniorCourseTree .exerciseJuniorBox:nth-child(10) .fa-angle-left{
    display:none;
}
.juniorCourseTree .exerciseJuniorBox:nth-child(3n+3) .arrowExLong{
    display:block;
    height:2px;
    background:#039be6;
    position:absolute;
    bottom:-28%;/*21*/
    right:100%;/*50*/
}
.juniorCourseTree .exerciseJuniorBox:nth-child(3n+1) .arrowExLong{
    left:0;
}
.juniorCourseTree .exerciseJuniorBox .screensEx{
    display: flex;
    width: 47%;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: stretch;
    margin: 0 auto;
}
/*.juniorCourseTree .exerciseJuniorBox:nth-child(7) .arrowEx{
    display:none;
}
.juniorCourseTree .exerciseJuniorBox:nth-child(7) .arrowExLong{
    display:none;
}
.juniorCourseTree .exerciseJuniorBox:nth-child(7) .fa-angle-right{
    display:none;
}
.juniorCourseTree .exerciseJuniorBox:nth-child(7) .fa-angle-left{
    display:none;
}
.juniorCourseTree .exerciseJuniorBox:nth-child(8) .arrowEx{
    display:none;
}*/
.juniorCourseTree .exerciseJuniorBox:nth-child(8) .arrowExLong{
    /*display:none;*/
}
.exBoxes8 .exerciseJuniorBox:nth-child(8) .arrowEx{
    display:none;
}
.exBoxes8 .exerciseJuniorBox:nth-child(8) .arrowExLong{
    display:none;
}
.exBoxes8 .exerciseJuniorBox:nth-child(8) .fa-angle-right{
    display:none;
}
.exBoxes8 .exerciseJuniorBox:nth-child(8) .fa-angle-left{
    display:none;
}
.exBoxes8 .exerciseJuniorBox:nth-child(7) .arrowExLong{
    display:none;
}
.exBoxes8 .exerciseJuniorBox:nth-child(7) .fa-angle-left{
    display:none;
}
.exBoxes7 .exerciseJuniorBox:nth-child(7) .arrowEx{
    display:none;
}
.exBoxes7 .exerciseJuniorBox:nth-child(7) .arrowExLong{
    display:none;
}
.exBoxes7 .exerciseJuniorBox:nth-child(7) .fa-angle-left{
    display:none;
}
.exBoxes7 .exerciseJuniorBox:nth-child(7) .fa-angle-right{
    display:none;
}
.juniorCourseTree .exerciseJuniorBox:nth-child(12) .fa-angle-down{
    display:none;
}
.juniorCourseTree .exerciseJuniorBox:nth-child(12) .arrowExLong{
    display:none;
}
.juniorCourseTree .exerciseJuniorBox:nth-child(12) .arrowEx{
    display:none;
}
.juniorCourseTree .exerciseJuniorBox:nth-child(11) .fa-angle-left{
    display:none;
}
.juniorCourseTree .exerciseJuniorBox:nth-child(11) .arrowExLong{
    display:none;
}
.exBoxes12 .exerciseJuniorBox:nth-child(10) .arrowEx{
    display:block;
}
.juniorCourseTree .exerciseJuniorBox .screensEx .fa-dot-circle-o{
    font-size:22px;
    transform:translateY(75%);
    color:#fcff2d;
    font-weight:bold;
}
.juniorStructure .infoRowIcon{
    width: 40px;
    height:40px;
}
.juniorStructure .infoRowIcon img{
    width:100%;
    height:100%;
}
#part1Block{
    /*width:70px;
    height:70px;*/
    margin-right:15px;
}
#part1Block{
    width:139px;
    height:70px;/*70*/
    margin-right:7%;
    margin-left:7%;
}
#part1Code{
    /*width:220px;
    height:47px;*/

    width:222px;
    height:70px;/*70*/
    margin-left:7%;
    margin-right:7%;
}
.levels .angle_down{
    right:auto;
    left:20px;
    font-size:3.5rem;
}
.levels .angle_down .fa{
    /*color:#000;*/
    color:#039be6;
}
.arrow_connection{
    display: inline-block;
    position: absolute;
    top: 50%;
    font-weight: bold;
    font-size: 39px;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.levels{
    text-align:left;
    position:relative;
}
.levels .levelsTitle,.levels_on_course .levelsTitle{
    text-transform: uppercase;
    margin-bottom:0px;/*5*/
    font-size:20px;
    font-weight:bold;
    margin-left:62px;/*50*/
    padding-top: 10px;
    overflow:hidden;
    cursor: pointer;
}
.levels .sub_stack_logos{
    width:50px;
    height:50px;
    display:inline-block;
}
.levels .courseTitleLevels{
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    display: inline-block;
}
.levels .courseTitleProfile .fa-plus,.levels .courseTitleProfile .fa-minus{
    -moz-transform: translateY(-67%);
    -ms-transform: translateY(-67%);
    -webkit-transform: translateY(-67%);
    transform: translateY(-67%);
    display: inline-block;
    font-size:16px;
    color: #039be6;
    margin-right: 10px;
    background: #fff;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
}
.levels .level_title{
    /*vertical-align: top;*/
    display:inline-block;
    -ms-transform:translateY(-100%);
    -webkit-transform: translateY(-100%);
    transform: translateY(27%);
    height:70px;
}
.rightPicCourses{
    position: relative;
    float:right;
}
.levels #panda{
    /* transform: translateY(-30%);*/
    height:70px;
   /* margin-left:34%;*/
    width:100px;
    display:inline-block;

}
.levels #coin{
     transform: translateY(-70%);
     margin-left:10px;
}
.levels #ruby{
     transform: translateY(-60%);
     margin-left:10px;
}
.levels #box{
     transform: translateY(-60%);
      margin-left:10px;
}
.levels .panda-items{
    display:inline-block;
    margin-left:40px;

}
.levels .profilePathHeader{
    padding: 0px 16px 0 16px;
}
.stack_logos{
    width:73px;/*68*/
    height:70px;
    /*margin-right:1%;*/
}
#python_stack_logos.stack_logos{
    /*width:100px;*/
}
#python_stack_logos.sub_stack_logos{
   /* width:100px;*/
}
#java_logo.sub_stack_logos{
    width:60px;
    height:60px;
}
.stack_logos_fr{
    /*width:100px;*/
    height: 70px;
    margin-right:2%;/*5*/
    margin-left:2%;
}
#stack_console{
    /*margin-left:126px;*/
    height:70px;
    width:70px;
    display:inline-block;

}
.logo_laravel{
    width:170px;
}
.juniorCourses{
    /*margin-top:40px;*/
}
.consoleCourses{
    /*margin-top:40px;*/
}
.mvcCourses.courseProfileList{
    /*margin-top:40px;*/

}
.mvcCourses .sub_stack_logos{
    width:auto;
}
.mvcCourses .sub_stack_logos {
    width: 103px;
    height: 50px;
    display: inline-block;
}
.mvcCourses .logo_ruby-on-rails.sub_stack_logos {
    width:150px;
}
.mvcCourses .logo_laravel.sub_stack_logos {
    width:150px;
}
.levels_on_course{
    text-align: left;
}
.courseProfileList{
    padding:20px;
}
.coursePathList{
    /*padding:0 20px;*/
}
.courseTitleProfile{
   text-align: left;
    text-transform: uppercase;
    font-weight: bold;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
    cursor: pointer;
    margin: 0;
    padding: 0 18px;
}
#railsLogo{
    margin-right:0;
}
.pathCoursePic{
    /*width:138%;*/
    width:100%;
    height:auto;
}
.materialUl {
    list-style: none;
    padding: 0;
}
.materialUl>li {

}
.slideItems {
    cursor: pointer;
}
.tileUl {
    display: inline-block;
    width: 100%;
    /*height: 100%;*/
}
.modLi {
    margin-left: 0;
}
.modLiTitle{
    width:80%;
    display:inline-block;
}
.BoxFa{
    vertical-align:top;
}
.modBox {
    margin-bottom: 16px;
}
.lesLi {
    margin-left: 1em;
}
.exLi {
    margin-left: 2em;
}
.innerRow {
    font-size: 1rem;
    border-left-width: 2px;
    border-left-style: solid ;
    padding: 12px 10px;
    display: block;
}
/* ===================*/
.exercises {
    box-shadow: 1px 1px 4px #666;
}
.course_tree>.slideArticles {
    box-shadow: 1px 1px 4px #666;
}
.course_tree>.slideArticles>ul {
    box-shadow: 1px 1px 4px #666;
}
.module {
    display: inline-block;
}

/*========= Course Info Section ===========*/
.courseInfoWrapper {
    display: inline-block;
    width: 50%;
}
.infoRow, .infoRowFirst {
    margin-bottom: 30px;
    cursor: pointer;
}
.infoRowInline {
}
#projectPreview {
    width: 160px;
    height: 200px;
    background-color: #ccc;
    margin-bottom: 20px;
    overflow: hidden;
}
#projectPreview.previewConsole{
    height:auto ;
}
#projectPreview.previewConsole .project_image{
    height:68px;
}
#projectPreview img{
    width:100%;
    height:100%;
}
.infoRowHead {
   position: relative;
   border-bottom-width: 1px;
   border-bottom-style: solid;
}
.infoRowName {
    font-size: 1rem;
}
.infoRowIcon {
    position: absolute;
    left: -55px;
    top: -10px;
    color: #333;
    font-size: 2rem;
}
.infoRowBody, .infoRowBodyFirst{
    border-bottom: 1px solid #ccc;
    font-size: 1rem;
}
/*========= Quote Section ==========*/
.quoteCarusel {
    margin: 60px 0;
}
.manBox {
    width: 20%;
}
.manPicture {
    cursor: pointer;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: #ccc;
    margin: 30px auto;
    overflow: hidden;
}
.manPicture img {
    height: 120px;
    width: 120px;
}
.quoteCarArrow {
    line-height: 260px;
}
.manWordsWrap {
    position: relative;
    width: 100%;
    border-width: 1px;
    border-style: solid;
}
.manWordsWrap p {
    margin: 16px;
}
#quotePointer {
    position: absolute;
    display: inline-block;
    background-color: #f9f9f9;
    border-left-width: 1px;
    border-left-style: solid;
    border-top-width: 1px;
    border-top-style: solid;
    width: 20px;
    height: 20px;
    transform: translate(-50%, -50%) rotate(45deg);
}
.pointerMid {
    left: 50%;
}
.pointerLeft{
    left: 10%;
}
.pointerRight {
    left: 90%;
}
.blur {
    -webkit-filter: blur(2px);
    -moz-filter: blur(2px);
    -o-filter: blur(2px);
    -ms-filter: blur(2px);
    filter: blur(2px);
}
/*========= End of Quote Section ==========*/
.course_tree {
    text-align: left;
    display: inline-block;
    max-width: 840px;
    width: 50%;
    padding-left: 20px;
}

.time_module {
    margin-left: 20px;
}
.infoBit {
    font-size: 0.8rem;
    padding-left: 20px;
}
.time_lesson {
    font-weight: 300;
    font-size: 0.7em;
    margin-left: 20px;
}

.lesson_icon {
    color: #339933;
    margin-left: 20px;
}

.course_tree div ul li {
    margin-top: 20px;
    font-size: 18px;
    font-weight: 500;
    list-style-type: none;
}



.course_tree div ul li p {
    font-size: 0.8em;
    font-weight: 200;
    color: #666;
    padding-left: 40px;
}
.exercises:hover {
    color: #099;
}

.course_tree hr {
    color: #666;
    margin-bottom: 40px;
}
.course_tree hr:hover {

}
.showExercises:hover {
    color: #099;
}
.enroll, .connections {
    color: white;
    background-color: #099;
    float: right;
    display: inline-block;
    border-radius: 5px;
}
.enroll:hover {
    background-color: #6cc;
}
ul li.ex_title_hover a:hover {
    color: #099;
}
.enroll_modules {
    background-color: transparent;
}

a.enroll:link, a.enroll:visited, a.enroll:hover, a.enroll:active {
    color: white;
}
.fa_edit_icon, .fa_level_links_icon{
    font-size: 20px;
    color: #333;
}

.fa_edit_icon:hover {
    color: #099;
}
.fa_edit_icon:active {
    color: greenyellow;
}
.user_profile_course_name:hover {
    color: #099;
}
.user_profile_course_name:active {
    color: greenyellow;
}
#exercise li{
    color: white;
    padding: 5px;
    margin: 5px;
    font-size: 0.8em;
    display: inline-block;
    cursor: pointer;
}
/* Login and Register */
.submitBtn {
    background-color: #099;
    color: white;
    width: 100px;
    display: inline-block;

}
.submitWrap{
    text-align: center;
}
#Log.sign_in {
    padding-bottom: 0px;
}

#Log.sign_in, #Log.sign_up, #Reg.sign_in, #Reg.sign_up {
    border-radius: 10px 10px 0 0;
}

#Log.sign_in, #Reg.sign_in {
    background-color: #f0f0f0;
}

#Reg.sign_in {
    box-shadow: 4px 0px 2px -2px #666;
}

#Reg.sign_up {
    box-shadow: 4px 2px 2px -2px #666;
}

#Log.sign_up, #Reg.sign_up {
    background-color: #d5d5d5;
}

/*== 1st section - SUPPORT ==*/
#supportFloatingButton {
    cursor: pointer;
    display: inline-block;
    position: fixed;
    bottom: 40px;
    right: 40px;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    font-size: 2em;
    line-height: 60px;
    text-align: center;
    transition: box-shadow 0.3s;
}
#supportFloatingButton:hover {
    box-shadow: 2px 2px 16px #999;
}
.form_body_support > h1 {
    font-size: 2.4em;
    font-weight: 300;
    margin-top: 30px;
    color: #666;
    text-align: center;
}
#butaforenDiv {
    cursor: pointer;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0.3;
}
#messageWrapper {
    position: relative;
    margin-left: auto;
    width: auto;
}
.messageButtonSection {
    text-align: right;
}
#cancelMessageBtn {
    margin-right: 8px;
}
#sendRequest {
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 500;
    display: block;
    text-align: right;
    font-size: 0.8rem;
}
#mailForm {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}
.support_wrap {
    max-width: 800px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.support_box {
    padding: 1px 20px;
    background-color: #ddd;
    margin: 40px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #bbb;
}
.support_box h1 {
    display: inline-block;
    color: #333;
}
.subtitle {
    font-weight: 200;
    color: #333;
}
.view_post {
    color: #996666;
    font-size: 1.2em;
    font-weight: 600;
    padding: 5px 20px;
    margin-top: 20px;
    float: right;
    display: inline-block;
    background-color: #ddd;

}
.view_post:hover {
    box-shadow: 1px 2px 5px #666;
    color: #ff6666;
}
#to_form, #from_form {
    width: 100%;
    display: inline-block;
}
#subject{

}
.messageForm {
    padding: 16px;
}
.messageFormInput {
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: #039be6;
}
textarea{
    position: relative;
    display: block;
}
.inputTextEditor {
    height: 300px;
}
#bug_description{
    margin-top: 5px;
    padding: 5px 0px;
    width: 98%;
    height: 120px;
}
#bug_subject{
    padding: 5px 0px;
    width: 98%;
    display: block;
}
#sendBug, #bugReport {
    margin: 15px;
}
#expand_parent {
    display: block;
}
#expand {
    font-family: Roboto, sans-serif;
    font-size: 1.2em;
    display: inline-block;
    cursor: pointer;
    padding: 4px 8px;
    margin-bottom: 10px;
}
.course_list {
    padding: 4px 0px;
}
#userCredsForm {
    margin: 0px;

}
.form_body_support {
    text-align: left;
    padding: 1px;
    background-color: #ccc;
}
.form_body_support input {
    margin: 10px;
    margin-bottom: 20px;
    display: inline-block;
}
a.enroll_edit {
    background-color: transparent;
}
.a.enroll_edit:hover {
    background-color: transparent;
}
/*== 1st section - PROFILE ==*/
.uploadClose{
    cursor: pointer;
    float: right;
    font-size: 1.6em;
    color: #f66;
    display: inline-block;
    transform: translateY(3px);

}
#uploadInfo{
     cursor: pointer;
    float: left;
    font-size: 1.6em;
    color: #fff;
    display: inline-block;
    transform: translateY(3px);
    position:relative;
    z-index:2
}
.tooltip-upload{
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    font-size:1rem;
    position: absolute;
    z-index: 1;
    display:none;
    top:0;
    left:40px;
}
.tooltip-upload::after{
    content: " ";
    position: absolute;
    top: 50%; /* At the bottom of the tooltip */
    left: 0;
    margin-left: -10px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent  transparent transparent;
    -ms-transform: translateY(-50%) rotate(90deg);
    -transform: translateY(-50%) rotate(90deg);
    transform: translateY(-50%) rotate(90deg);
}
#uploadForm {
    cursor: auto;
    padding: 10px;
    position: relative;
    margin: 0px;
    transform: translateY(30px);
}
.customFileUpload {
    position: relative;
    display: inline-block;
    overflow: hidden;
    height: 30px;
}
.customFileUpload input {
    padding: 5px 10px;

}
#uploadAvatar {
    position: relative;
    vertical-align: top;
    overflow: hidden;
    z-index: 1;
}
#uploadAvatar #user_profile_icon{
    line-height:80px;
    display:block;
    margin-top:15px;
}
#user_icons{
    background:#5fcaff;
}
#user_icons span{
    line-height: 40px;
    display:block;
    margin-top:5px;
}
#uploadForm {
    position: relative;
    z-index: 1;
}
#profilePicture{
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%, -50%);
}
#submitAvatar, #noAvatar {
    vertical-align: top;
    float: right;
    margin-right: 20px;
}
.user_icon {
    cursor: pointer;
    margin-left: 15%;
    text-align: center;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    color: white;
    box-shadow: 1px 2px 5px #444;
    display: inline-block;
    font-size: 3rem;
    line-height: 80px;

}
.prof_text {
    margin-left: 5%;
    display: inline-block;
    z-index: 1;
}
.prof_text h1 {
    margin: 0px 0px;
    padding: 0px;
    font-size: 3em;
    font-weight: 300;
    color: white;
}
.prof_text h3 {
    margin: 0px 0px;
    padding: 0px;
    font-size: 1.2em;
    font-weight: 300;
    color: white;
}
.continueWrapper {
    margin: auto;
    width: 50%;
    text-align:center;
}
.continueInnerWrapper {
    display: block;

}
.current_course {
    display: inline-block;
}
.current_courseTitle {
    font-size: 2rem;
    font-weight: 500;
}
#continueButton {

    text-transform: uppercase;
    font-size: 0.7rem;
    text-align: center;
    padding: 8px 12px;
}
a.continue:link, a.continue:visited {
    color: white;
}
a.continue:active {
    color: greenyellow;
}
.continue div {
    position: relative;
    bottom: 2px;
    font-size: 0.7em;
}
.progress {
    width: 100%;
}
.progress h1{
    text-align: left;
    width: 40%;
    font-size: 2em;
    font-weight: 300;
    color: #666;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0px;

}
.progress_bar {
    display: inline-block;
    width: 40%;
    background-color: #ccc;
}
.progress_bar div{
    width: 37%;
    height: 5px;
    background-color: #099;
}
/* delete external user*/
   .profile_user_external_list{

    vertical-align: top;
    padding: 10px;
    text-align: center;
    margin-top: 80px;
    background-color: #ddd;
    border-radius: 10px;
    box-shadow: 1px 2px 5px #666;
}
 .profile_user_external_list h1 {
    text-align: center;
    color: #666;
}
.profile_user_external_list {
    text-align: justify;
    cursor: pointer;
}
/* end delete external user*/
.profile_courses ul {
    color: #666;
    padding-left: 0px;
    text-align: left;
    font-size: 1.2em;
    list-style-type: none;
}
.profile_courses ul li{
    margin-top: 10px;
}
.overBar {
    position: absolute;
    bottom: 10px;
}
.leftOverBar {
    left: 0;
    transform: translateX(-50%);
}
.floatOverBar {
    right: 0;
    transform: translateX(50%);
}
.rightOverBar {
    right: 0;
/*    transform: translateX(50%); */
}
/* ---- Profile Path Styles ---- */
.profilePathWrapper {
    margin-bottom: 30px;
}
.profilePathHeader {
    position: relative;
    padding: 16px;
    border-bottom: 1px solid #ccc;
}
.profilePathTitleWrap {

}
.profilePathTitle{
    display: inline-block;
    margin: 0;
}
.pathProgress {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 30px;
    width: 50%;
}
.profilePathMenuWrap {
    position: relative;
}
.profilePathMenuWrap span {
    vertical-align: top;
}
.profilePathMenu {
    font-size: 1.5rem;
    padding: 0 0 0 10px;
}
.profilePathMenuDropDown {
    position: absolute;
    top: 100%;
    right: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    z-index: 1;
}
.profilePathDropDownItem {
    padding: 8px 0;
    background-color: white;
    text-align: center;
}
.profileProgressWrap {
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
}
.profileProgressBack{
    position: relative;
    height: 4px;
    width: 100%;
    background-color: #ccc;
}
.profileProgressFront {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
}
.barStart {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 0px;
    background-color: green;
}
.courseProgressBar {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
}
.mainCourseProgress {
    margin-top: 30px;
}
.profilePathTile {
    padding: 16px 16px 1px;
    width: -webkit-calc(100% - 32px);
    width: -moz-calc(100% - 32px);
    width: -ms-calc(100% - 32px);
    width: -o-calc(100% - 32px);
    width: calc(100% - 32px);
}
.profilePathTile>ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.profileCourseItem {
    padding: 4px 0;
    margin-bottom: 16px;
}
.profileCourseItem h3 {
    display: inline-block;
    margin: 0;
    font-weight: 500;
    color: #666;
    width:50%;
}

.profileCourseTitle {
    display: inline-block;
    margin: 0;
}
.profileCoursePercent {

    color: #999;
}
.courseStatus {
    text-align: center;
    font-size: 0.8rem;
    position: absolute;
    left: 50%;
    width: 100px;
    height: 20px;
    border-radius: 4px;
}
.profileCourseProgressWrap {
    position: absolute;
    right: 0;
    top: 50%;
    width: 18%;
    transform: translateY(-50%);
}
.profileCourseProgressBack {
    width: 100%;
    height: 4px;
    background-color: #ccc;
}
.profileCourseProgressFront {
    width: 100%;
    height: 100%;
}
.smallProgressText, .smallProgressTextPath {
    text-transform: none;
    color: #0645AD;
    display: block;
    position: relative;
    -ms-transform:translateY(25px);
    -webkit-transform:translateY(25px);
    transform: translateY(25px);
    font-size: 0.7rem;
}
.smallProgressTextPath {
    display: inline-block;
    transform: none;
}
.smallProgressText:hover, .smallProgressTextPath:hover {
    cursor: pointer;
    border-bottom: 1px solid #0645AD;
}
.courseSmallProgressText {
    display: inline-block;
    position: absolute;
    right: 16px;
    bottom: 8px;
    transform: none;
}
/* ---- End of Profile Path Styles ---- */


#profileProblemList {
    margin-bottom:30px;
}

/* ---- Settings SlideDown Options ----*/
.advanced_settings_container {

}
.angle_down {
    display: block;
    position: absolute;
    right: 16px;
    top: 50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 2rem;

}
.settings_title {
    cursor: pointer;
    font-size: 1.2em;
    display: inline-block;
}
.one_third_settings {

    width: 26%;
    display: inline-block;
}
.editUserInfoButton {
    position: absolute;
    top: 0px;
    right: 0px;
}
.settings_wrap,#user-message,.paymentInfo,#invoices {
    text-align: justify;
    padding: 16px;
}
.settingsButtons {
    text-align: right;
    margin: 30px 0 0;
    clear: both;
}
.settingsMSG {
    position: relative;
    bottom: 0;
    left: 0;
    display:block;
    text-align:left;
}
#save_settings {
    width: auto;
}
#pay_plan_settings {
    width: auto;
}
.choose_avatar {
    text-align: center;
    vertical-align: top;
}
.settings_slide_image {
    width: 60%;
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    position: relative;
    box-shadow: 1px 2px 5px #666;
    border-radius: 10px;
    float: right;
}
.settings_slide_image img {
    width: 100%;
}
.user_settings_box{
    width: 100%;
}
.itemLabel {
    display: inline-block;
}
.itemDesc {
    font-size: 1.2em;
}
.avatar_placeholder {
    margin-top: 4%;
    background-color: #cff;
    width: 100%;
    height: 180px;
}
.settings_save_button {
    border: none;
}

/* ---- end of Settings Slide Down Options ---- */

/* ---- Profile Badge inPage and pop-up ---- */
/* ---- new design ---- */
.boxHeader {
    border-bottom: 1px solid #ccc;
}
.boxTab {
    text-transform: uppercase;
    display: inline-block;
    padding: 8px 16px;
    font-size: 0.8rem;
}
.activeBoxTab {
    border-bottom-width: 2px;
    border-bottom-style: solid;
}
.boxContainer {
    padding: 16px;
}
/* ---- end new design ---- */
.overlayBadges {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
}

.overlayBadgesText {
    text-align: center;
    font-size: 1rem;
    width: 80%;
    text-transform: uppercase;
    font-weight: 500;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.badges {
    height: auto;
    width: 100px;
}
#no_badges_yet {
    text-align: center;
}
.no_badge {
    margin-top: 20px;
}
.no_badge img{
    margin-right: 20px;
    width: 100px;
    height: auto;
}
.badge_out {
    margin: 10px;
    display: inline-block;
    text-align: center;
    width: 100px;
    height: 100px;
    cursor: pointer;
}

.badge_out img {
    width: 100%;
    height: auto;
}

.badge_screen {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
    z-index: 2;
}
.x_close_badge {
    margin: 40px;
    text-align: right;
    font-size: 2em;
    font-weight: 200;
    color: white;
    cursor: pointer;
}
.badge_info_box {
    padding: 60px;
    width: 50%;
    height: 60%;
    background-color: #eee;
    position: relative;
    left: 50%;

    transform: translatex(-50%);
    box-shadow: 5px 5px 10px #333;
}
.badge_wrapper {
    height: 100%;
    overflow-y: scroll;
    margin-bottom: 20px;

}
.badge_box{
    clear: both;
}

.badge_box img{
    margin-top: 30px;
    display: inline-block;
    width: 150px;
    height: auto;
}
.badge_text{
    width: 70%;
    display: inline-block;
    margin-left: 6%;
}
.badge_text h1 {
    font-size: 2em;
    font-weight: 300;
}

.second_badge_box{
    text-align: right;
    margin-right: 6%;
    margin-left: 0px;
}
.second_badge_box .badge_text{
    vertical-align: top;

}
.badge_text hr {
    display: inline-block;
    width: 60%;
}
.badge_ok_outer {

    text-align: right;
    clear: both;
}

.badge_ok_inner {
    padding: 8px 30px;
    color: #099;
    font-weight: 500;
    font-size: 1.2em;
    cursor: pointer;
}
.badge_ok_inner:hover {
    position: relative;
    transform: translateY(-20px);
    box-shadow: 1px 2px 3px #666;
}
/* ---- End of Badges ---- */
/*== Pricing Page ==*/
.payWrapper {
    vertical-align: top;
    display: inline-block;
    width: 24%;
}
.payTitle {
    text-align: center;
    color: #099;
}
.payBox {
    background-color: #eee;
    padding: 20px;
    box-shadow: 2px 2px 6px #999;
}
.payPrice {
    position: relative;
}
.payPriceNum {
    font-weight: 300;
    font-size: 5rem;
    color: #099;
    text-align: center;
}
.payPeriodText {
    text-align: center;
}
.carArrow {
    cursor: pointer;
    padding: 2.5rem 1rem;
    display: inline-block;
    font-size: 2rem;
    color: #099;
    position: absolute;
    top: 0;
}
.carArrow:hover {
    color: #0cc;
}
.leftPriceArr {
    padding-left: 0;
    left: 0px;
}
.rightPriceArr {
    padding-right: 0;
    right: 0px;
}
.payInfo {
    height: 200px;
}
.payInfo h1 {
    font-weight: 500;
}
.payInfo ul {
    list-style: none;
    padding: 0;
}
.feature_heading {
    font-size: 1rem;
    font-style: italic;
}
.payBtn {
    padding: 10px 30px;
    margin: 30px auto 0;
    display: block;
    border: none;
}
.payBtn:hover {
    background-color: #e70;
}
/*== PAYMENT_FORM ==*/

.payment-wrapper {
    width: 1200px;
    margin: 0 auto;
    font-weight: bold;
    font-family: "Open Sans";
    text-align: left;
}

.form-section {
    background-color: #f7f8fa;
    border-radius: 5px;
    border-top: 5px solid #18c784;
    padding: 20px;
    width: 80%;
    margin: 0px auto;
}

.icon {
    height: 40px;
    position: relative;
    top: 10px;
}

input[type=radio] {
    /* All browsers except webkit*/
    transform: scale(1.5);
    margin-left: 40px;
    /* Webkit browsers*/
    -webkit-transform: scale(1.5);
}

.text-field {
    width: 98%;
    margin-bottom: 20px;
}

.payment-section-title {
    font-size: 35px;
    color: #8e8f91;
    text-align: center;
}

.empty-div {
    height: 40px;
}

.text-description {
    margin-bottom: 10px !important;
}

.subscribe-button {
    background-color: #19c684;
    padding: 10px 30px;
    color: #fff;
    border-radius: 3px;
    border: none;
    margin: 20px auto;
    margin-bottom: 5px;
    display: block;
}

.secure-payment-label {
    color: #8d8e90;
    width: 160px;
    margin: 0 auto;
}

.locker-icon {
    height: 20px;
    position: relative;
    top: 3px;
}

.terms {
    color: #e15f5d;
    margin: 20px 0px;
    text-decoration: none;
}

.expiration-date {
    margin-top: 20px;
}

.sign-in {
    text-decoration: none;
    color: #e15f5d;
}
div.submit_payment {
    margin-left: auto;
    margin-right: auto;
    color: white;
    width: 26%;
    background-color: #099;
    border-radius: 5px;
    padding: 8px 20px;
    cursor: pointer;
}
/*== COURSE STRUCTURE ==*/
.structure_step {
    width: 30%;
    cursor: pointer;
    margin: 10px;
    padding: 10px;
    font-size: 1.2em;
}
/* ===== ABOUT US ===== */
.about_section {
  margin-bottom: 5%;
}
#mid_about_section {
  text-align: right;
}
.image_icon {
  border-radius: 75px;
  border: 1px solid #34495e;
  display: inline-block;
  font-size: 5em;
  width: 150px;
  height: 150px;
  position: relative;
  color: #34495e;
}
#icon_one {
  display: block;
  position: absolute;
  top: 32px;
  left: 32px;
}
#icon_two, #icon_two1 {
  display: block;
  position: absolute;
  top: 34px;
  left: 24px;
}
#icon_two_box_mediaQuery {
  display: none;
}
#icon_three {
  display: block;
  position: absolute;
  top: 34px;
  left: 44px;
}
.about_sec_textbox {
  display: inline-block;
  width: -webkit-calc(100% - 200px);
  width: -moz-calc(100% - 200px);
  width: -ms-calc(100% - 200px);
  width: -o-calc(100% - 200px);
  width: calc(100% - 200px);
  vertical-align: top;
  margin-left: 2%;
}
#mid_about_section .about_sec_textbox {
  margin-left: 0;
  margin-right: 2%;
}
.about_sec_text {
  margin-top: 2%;
}
h1.about_sec_title {
  font-size: 1.4em;
  color: #34495e;
  font-weight: 500;
  padding: 0px;
  margin: 0px;
  text-transform: uppercase;
}
.about_sec_subtitle {
  font-size: 1em;
  font-weight: 100;
  color: #999;
}
/*== =======  ABOUT US  ======= ==*/
.fa_icon_logo {
    line-height: 0px;
    margin-top: 50px;
    text-align: justify;
    font-size: 8em;
}
.fa_icon_logo:after {
    content: " ";
    display: inline-block;
    width: 100%;
}
/*== =======  CONTACTS  ======= ==*/
#map {
    box-shadow: 2px 3px 4px #666;
    width: 40%;
    height: auto;
}
.contact_sep {
    width: 70%;
    margin: 0px;
    padding: 0px;
    position: relative;
    left: 0px;
}
.contact_info {
    display: inline-block;
    margin-left: 6%;
    width: 50%;
}
.contact_logo {
    font-size: 3em;
    font-weight: 500;
    color: #999;
}
.address {
    color: #666;
    line-height: 1.4em;/*1.8*/
    font-size: 2em;
    font-weight: 100;
}
.address p {
    font-size: 1.5em;
}
/*== STUDENTS ==*/
.uppercase {
    text-transform: uppercase;
}

/*== TEACHERS ==*/
.graph_icon {
    font-size: 18em;
}
.separator_small_line {

    display: inline-block;
    height: 1px;
    width: 60px;
    background-color: #f93;

}
.teachers_button {
    color: #fff;
    font-weight: 500;
    border-radius: 5px;
    display: inline-block;
    padding: 5px 20px;
    background-color: #f93;
    box-shadow: 2px 3px 4px #333;
}
.teachers_button:hover {

    background-color: #099;
    color: white;
}
.teachers_button:active {
    position: relative;
    top: 2px;
    left: 2px;
}
.teachers_headtext {
    color: white;
    line-height: 2em;
    font-size: 1.2em;
    font-weight: 100;
}
.t_header_text {
    margin: 40px 0px;
}
.teachers_headtext h1 {
    font-weight: 100;
    font-size: 3em;
}
.t_header_text p {
    margin: 0px;
    padding: 0px;
}
/*== =======  404 Not Found  ======= ==*/
.infoPageHead {
    margin: 5%;
    text-transform: uppercase;
    font-size: 2.4em;
    color: #666;
}
.infoPageButton {
    border-radius: 5px;
    font-size: 1.2em;
    margin-top: 20px;
    display: inline-block;
    padding: 10px 20px;
    background-color: #099;
}
a.infoPageButton:link {
    color: #fff;
}
a.infoPageButton:visited {
    color: #fff;
}
a.infoPageButton:hover {
    color: #fff;
    background-color: #0cc;
    box-shadow: 2px 2px 2px #999;
    transform: translateY(-2px);
}
a.infoPageButton:active {
    color: #fff;
    transform: translateY(2px);
    box-shadow: 0px 0px 0px transparent;
}

/*== 1st section - TERMS & CONDITIONS ==*/
#terms_wrap {
    text-align: left;
    background-color: #d6d6d6;
    max-width: 800px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding: 30px;
    box-shadow: 2px 3px 4px #666;
}
.terms_inner {
    padding-right: 30px;
    overflow-y: scroll;
    height: 350px;
}

.terms_inner h2 {
    margin-top: 30px;
    font-weight: 400;
}
.outer_ok_button {
    margin-top: 30px;
    text-align: right;
}
.ok_button {
    margin-right: 10%;

    display: inline-block;
    font-size: 1.2em;
    font-weight: 500;
    text-transform: uppercase;
    color: #099;
    padding: 6px 35px;
    cursor: pointer;

}

.ok_button:hover {
    box-shadow: 1px 2px 3px #666;
    transform: translateY(-2px);
}
/*=== footer - NEW - 21.01.2016 ===*/
#footer {
    margin-bottom: 0px;
    background-color: #444;
    color: white;
    /*margin-top:35px;*/
    position:relative;
}
.paddingFooter {
    padding-top: 90px;/*40*/
    padding-bottom:120px;
}
.footerBreaker {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 60px;
    height: 60px;
    border-radius: 80px;
    background-color: white;
    text-align: center;
    z-index:10;
}
.butaforenDiv {
    cursor: pointer;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: 80px;
}

.subscribeIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 1.8rem;
    line-height: 75px;
    color: #333;

}
.subscribeWrapper {
    display: inline-block;
    padding: 10px 0;
}
.subscribeTitle {
    font-size: 2rem;
    font-weight: 300;
    text-transform: uppercase;
    margin: 10px 0 20px;
}
.subscribeForm {
    display: inline-block;
    margin: 0;
    text-align: left;

}
.subscribeInput {
    padding: 8px;
    border: 0;
    border-bottom: 1px solid #039be6;
}
.subscribeInput:focus, .subscribeButton:focus {
    outline: 0;
}
.subscribeButton {
    background-color: transparent;
    padding: 8px;
    border: 0;
    text-transform: uppercase;
    font-weight: 600;
}
.captchaInline {
    display: inline-block;
    transform:scale(0.77);
    transform-origin:0 0;
}
.captchaRegister {
/*  transform:scale(0.85); */
    transform-origin:0 0;
    margin: auto;
}
.captchaFaq {

}
.footerBtn {

    border: 1px solid white;
}
.footerBox {
    position: relative;
    display: inline-block;
    height: 300px;
    width: 22%;
}
.footerBoxHeightAuto{
    height:auto;
}
.footerBoxTitle {
    font-size: 1.4rem;
    font-weight: 500;
    border-top: 1px solid #666;
    padding: 10px 0;
    margin-bottom: 0px;/*20*/
}
#footerCourseWrapper, #footerPathWrapper{
    display: inline-block;
    position: relative;
    height: calc(100% - 72px);
    width: 80%;
}
#footerCourseWrapper::-webkit-scrollbar, #footerPathWrapper::-webkit-scrollbar{
    display: none;
}
#footerCourseWrapper {
    overflow: hidden;
}
.hideScroll {
    height: 100%;
    width: 300px;
    margin-right: -20px;
    overflow-y: scroll;
    overflow-x: hidden;
}
.scrollThumb {
    color: #666;
    display: inline-block;
    position: relative;
    height: calc(100% - 72px);
    width: 10px;
    border-left: 1px solid #666;
/*  border-right: 1px solid white; */
    padding: 0 8px;
}
.scrollArrow {
    position: absolute;
    font-size: 1rem;
    color: white;
    cursor:pointer;
}
.scrollArrowUp {
    top: 30%;
}
.scrollArrowDown {
    bottom: 30%;
}
.footerCourseLogo {
    display: inline-block;
    height: 20%;
    width: 20%;
    background-color: white;
    border: none;
    padding: 8px;
}
.footerCourseItem {
    color: white;
    padding-bottom: 8px;
    margin-bottom: 16px;
    border-bottom: 1px solid #666;
}
.footerCourseText {
    display: inline-block;
}
.flex2x {
    flex-grow: 2;
}
.footerUl {
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style: none;
    line-height: 2rem;
    font-size: 0.8rem;
    color: white;
}
.footerUl a {
    display: inline-block;
    width: 100%;
}
.footerLiHalf1, .footerLiHalf2 {
    display: inline-block;
    vertical-align: top;
}
.footerLiHalf1 {
     width: 10%;/*13*/
}
.footerLiHalf2 {
    width: 88%;/*66*/
    line-height: 21px;
}
.footerLiHalf1 span i{
    font-size:1.5rem;
}
.footerMap {
    display: inline-block;
    width: 40%;
}
.mapOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 500px 500px rgba(0, 0, 255, 0.5) inset;
}
.social_icons {
    font-size: 2em;

}

#bottom_stripe {
    position: relative;
    margin: 0px;
    padding: 12px 0px;
    color: white;
    width:100%;
    background-color: #333;
}
.bottomStripeMenu {
    display: inline-block;
    list-style: none;
    padding: 0;
    margin: 0;
}
.bottomStripeMenu li {
    text-transform: uppercase;
    padding: 10px 10px;
    display: inline-block;
}
.bottomRight {
    position: absolute;
    right: 0px;
    top: 50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.bottomLeft {
    display: inline-block;
    padding: 10px;
    width:50%;
    text-align: left;
}
.bottom_terms a {
    text-decoration: underline;
}

.unsuccessfulVerif {
    color: red;
}
.hidden {
    display: none;
}
#personEmail {
    padding: 5px;
    border-radius: 5px;
}
#goUp {
  cursor: pointer;
  font-size: 3rem;
  line-height: 54px;
  text-align: center;
  display: inline-block;
  position: fixed;
  bottom: 50px;
  right: 50px;
  width: 60px;
  height: 60px;
  color: #fff;
  border-radius: 50%;
  display: none;
  border: 2px solid white;
  /*z-index: 1;*/
  z-index:21 !important;
}
#goUp span{
    position:absolute;
    top:48%;
    left:50%;
    -ms-transform:translate(-50%,-50%);
    -webkit-transform:translate(-50%,-50%);
    transform:translate(-50%,-50%);
}
/* ==== ADAPTIVITY CONTROL ==== */
.ac_title {
    margin: 0px;
    padding: 2% 0px;
    color: #666;
    text-align: center;
    font-weight: 100;
    font-size: 2.5em;
}
.ac_subtitle {
    font-size: 1.5em;
    font-weight: 100;
    color: #099;
    padding: 2px;
    margin-bottom: 25px;
}
.tile_wrapper {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding: 1%;
}
.ac_left_section {
    vertical-align: top;
    display: inline-block;
    width: 30%;
}
.ac_right_section {
    margin-left: 3%;
    text-align: left;
    vertical-align: top;
    display: inline-block;
    width: 60%;
}
.ac_right_section:after {
    content: " ";
    display: inline-block;
    width: 100%;
}
.ac_tile {
    margin-left: 2%;
    margin-bottom: 3%;
    border-radius: 5px;
    vertical-align: top;
    cursor: pointer;
    padding: 3% 1%;
    text-align: center;
    font-weight: 100;
    font-size: 1.3em;
    display: inline-block;
    box-shadow: 2px 2px 2px #666;
    width: 28%;
    height: 10%;
    background-color: white;
}
.ac_tile:hover {
    font-weight: 700;
    color: white;
    background-color: #9cc;
    transform: translateY(-5px);
    box-shadow: 4px 4px 4px #999;
}
.ac_hr {
    margin-bottom: 5px;
    display: inline-block;
    width: 100%;
    border-color: #ccc;
}
.add_indicator {
    color: #099;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 0.5em;
}
#raw_indicators, #raw_pools {
    font-size: 2em;
    margin: 0;
    margin-bottom: 20px;
    width: 100%;
    padding: 15% 0px;
}

/* ==== Exercise Edit ==== */

.editFlag {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

/* ==== AC POOL LIST ==== */

.pool_sum {
    font-weight: 700;
    color: white;
    margin-top: 20px;
    margin-left: 3%;
    margin-right: 3%;
    text-align: right;
}
.pool_sum input {
    margin-left: 10px;
    height: 15px;
    width: 50px;
}
.indicator_wrap {
    margin-top: 20px;
}
.indicator_item {
    padding: 10px 0px;
}
.indicator_item:nth-child(odd) {
    background-color: #8aa;
}
.indicator_name {
    padding-left: 3%;
    font-size: 1.2em;
    color: white;
}
.indicator_value {
    margin-left: 5%;
}
.indicator_weight {
    text-align: right;
    color: white;
    padding-right: 3%;
    width: 50%;
}
.indicator_weight input {
    margin-left: 10px;
    height: 15px;
    width: 50px;
}
.add_button {
    font-weight: 700;
    padding: 5px 10px;
    margin-right: 4%;
    cursor: pointer;
    color: white;
}
.add_button:hover {
    transform: translateY(-2px);
    color: #f96;
}
.dropdown_item {
    padding: 0px 20px;
}
.dropdown {
    width: 30%;
    text-transform: uppercase;
    font-weight: 100;
    font-size: 1.1em;
    margin: 20px;
    padding: 0px 20px;
}
#cp_right_sec{
    background-color: #699;
    padding: 0px;
}
.cp_pool {
    cursor: pointer;
    padding: 15px;
    color: #666;
    text-transform: uppercase;
    font-size: 1.1em;
}
.cp_pool:hover {
    color: white;
    background-color: #699
}
.pool_list {
    margin-top: 30px;
    padding: 10px;
}
.material_up {
    background-color: #eee;
    box-shadow: 2px 2px 2px #666;
    margin: 0px;
}
.button_stripe {
    text-align: right;
    width: 90%;
    margin-left: auto;
    margin-right: auto;

}
.cp_button {
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
    text-align: center;
    color: #099;
    text-transform: uppercase;
    font-size: 1em;
    font-weight: 500;
    display: inline-block;
    padding: 10px;
}
.cp_button:hover {
    transform: translateY(-2px);
    color: #f96;
}
/* ==== AC ADAPTIVITY RULES ==== */
.course_option {
    padding: 10px;

    text-align: right;
}
.save_button {
    cursor: pointer;
    color: white;
    background-color: #099;
    padding: 10px 40px;
    box-shadow: 2px 2px 2px #666;
}
.save_button:hover {
    background-color: #6aa;
    transform: translateY(-2px);
    box-shadow: 4px 4px 4px #999;
}
.top_div {
    text-align: center;
    margin-bottom: 40px;
}
.name_input {
    color: #099;
    font-weight: 100;
    font-size: 2em;
    border: none;
    border-bottom: 1px solid #099;
    background-color: #ccc;
}
.name_input:focus {
    color: #6aa;
    outline: none;
}
#adaptivity_category {
    margin-left: 30px;
    display: inline-block;
}
#adaptivity_category select{
    outline: none;
    border: none;
    background-color: #ccc;
    color: #099;
    font-weight: 100;
    font-size: 2em;
}
#adaptivity_category select optgroup {
    font-size: 0.5em;
    background-color: #eee;
}
#adaptivity_category select option {
    display: inline-block;
    background-color: #eee;
    font-size: 0.6em;
    box-shadow: 2px 2px 2px #666;
}

#adaptivity_name {
    display: inline-block;
}
#add_rule_button {
    color: #099;
}
#add_rule_button:hover {
    color: #f96;
}
.local_factor {
    border: 1px solid red;
}
.global_factor {
    border: 1px solid #099;
}
.rule_conditions input {
    width: 80px;
}
#localCourseTree {
    overflow: hidden;
    display: inline-block;
    width: 0px;
    background-color: #eee;
    box-shadow: 2px 2px 2px #666;
}
#localCourseTree ul {
    text-align: right;
    text-transform: uppercase;
    list-style: none;
    padding: 10px;
    margin: 0px;
}
#localCourseTree ul li {
    cursor: pointer;
}
#localCourseTree ul li:hover {
    color: white;
    background-color: #6aa;
}

.left_side {
    margin-top: 36px;
    margin-right: 10px;
    vertical-align: top;
    width: 4%;
    height: 400px;
    display: inline-block;
}
.settings_name {
    display: block;
    font-size: 2em;
    color: white;
    transform: rotateZ(-90deg) translateX(-70px);
}
#switch_button2 {
    position: absolute;
    z-index: 10;
    width: 20px;
    height: 100%;
}

#switch_button1 {
    width: 40px;
    vertical-align: top;
    display: inline-block;
    box-shadow: 2px 2px 2px #666;
    cursor: pointer;
    height: 100%;
    background-color: #099;
}
.switch_button:hover {
    background-color: #6aa;
}
.right_side {
    width: 93%;
    display: inline-block;
}

#title_div, #actions_div {
    vertical-align: top;
    width: 20%;
}

#title_div {
    margin-top: 20px;
}

#conditions_div {
    width: 50%;
    margin-left: 5%;
}

#title_div, #actions_div, #conditions_div {
    display: inline-block;
}

.rule_name, .rule_logic, .rule_conditions {
    display: inline-block;
    vertical-align: top;
}
#create_variable_overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: rgba(0,0,0,0.5);
}
#create_variable_tile {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin-right: auto;
    margin-left: auto;
    width: 1000px;

}
#name_input {
    display: block;
}
#create_variable_button {
    cursor: pointer;
    margin-top: 20px;
    display: inline-block;
   background-color: #099;
    text-transform: uppercase;
    color: white;
    padding: 10px 30px;
}
#create_variable_button:hover {
    background-color: #3aa;
}
.ar_hr {
    margin-top: 20px;
    margin-bottom: 5px;
    border-color: #ccc;
}
.textarea {
    width: 90%;
}
.first_select {
    margin-left: 20%;
}
.icon_button {
    padding: 5px 10px;
}
.icon_button:hover {
    transform: translateY(-2px);
    border-bottom: 2px solid #999;
}
.section_tile {
    padding: 10px;
    width: 100%;
    background-color: #eee;
    box-shadow: 2px 2px 2px #666;
    margin-bottom: 40px;
}
#variable_tile, .thirds, #logic_tile, #logic_tile2 {
    text-align: justify;
}
#variable_tile:after, .thirds:after, #logic_tile:after, #logic_tile2:after {
    content: " ";
    width: 100%;
    height: 0px;
    display: inline-block;
}
.pl_subtitle {
    font-size: 1.6em;
    font-weight: 100;
    color: #666;
    padding-bottom: 10px;
}
.a_third {
    vertical-align: top;
    display: inline-block;
    width: 30%;

}
/* ==== Exercise Edit ==== */

#htmlEditor, #cssEditor, #myEditor {
    height: 300px;
}
/* ==== AC_level_of_details ==== */
.select_line {
    color: #666;
    border-radius: 5px;
    margin: 10px 0px;
    padding: 6px;
    width: 100%;
}
.ac_exercise {
    border-radius: 5px;
    background-color: white;
    width: 100%;
    box-shadow: inset 1px 1px 2px #333;
}
.ac_exercise ul {
    padding: 10px;
}
.ac_exercise li {
    list-style: none;
    padding: 0px;
    margin: 10px 0px;
}
#emailMsg {
    display: none;
}
/* ---- particles.js container ---- */

#particles1, #particles2{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    background-image: url('');
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

/* === inactive class is at the bottom because of all the display declarations of the elements like display: inline-block === */
.inactive {
    display: none;
}
.white {
    color: white;
}

.class1024Show {
    display: none;
}
.class780Show {
    display: none;
}
#secondTitleToShow {
    display: none;
}
#old-structure{
    display:none;
}
#pathsWrapper {
    justify-content: space-around;
    -webkit-justify-content:space-around;
}
.path_sub_title{
    text-transform: uppercase;
    margin-bottom: 5px;
    font-size: 20px;
    font-weight: bold;
        display: inline-block;
}
.sub_paths .profilePathHeader{
    padding:30px 16px 30px 16px;
}
.sub_paths .profilePathWrapper{
    margin-bottom:10px;
}
.sub_section .narrowContent{
    margin:0;
}
.sub_section .narrowContent .courseProfileList:last-child .typeB{
    border-right-width: 0;
}
.sub_section .narrowContent .courseProfileList:first-child .pathArrowTop{
    display:none;
}
.sub_path_description{
   /* display: inline-block;
    float: right;
    width: 20%;*/
}
.sub_path_description{
    display: inline-block;
    /*float: right;*/
    width: 100%;
    padding:20px;
    box-sizing: border-box;
    border:1px solid #ccc;
    border-radius: 10px;
}
.sub_path_description_title{
  text-transform: uppercase;
  font-weight: bold;
  font-size:2rem;
  position:relative;
  padding-bottom: 5px;
  /*border-bottom:4px solid #FFA500;*/

}
.sub_path_description_title:after{
  position:absolute;
  width:60px;
  height:4px;

  left:0;
  content: "";
  bottom:0;
}
.path_desc_1:after{
  background:#3DB0A1;
}
.path_desc_2:after{
  background:#FFA500;
}
.path_desc_3:after{
  background:#3F51B5;
}
.path_desc_4:after{
  background:#c66;
}
.path_desc_kids:after{
     background:#3DB0A1;
}
.path_desc_console:after{
     background:#FFA500;
}
.path_desc_web:after{
     background:#3F51B5;
}
.path_desc_builder:after{
     background:#c66;
}
.sub_path_description_title .fa{
  margin-right:5px;
}
.sub_path_description_container{
  padding:10px 0;
}
.global_path_description{
    text-align:center;
}
.journey{
    text-align: center;
    color: #323232;
    /* font-size: 25px; */
    margin-bottom: 35px;
    font-size: 3.2rem;
    /* font-style: italic; */
    /* margin-top: -120px; */
    padding-bottom: 0px;
    position: relative;
    font-weight: normal;
}
.sub_title_journey{
    text-align: center;
    font-size:1.5rem;
}
.arr_con_onHome{
    color:#039ce6;
    top: 58%;
    -ms-transform: translate(-50%);
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
}
.single_path_label{
   color: #666;
    font-size: 20px;
    /* width: 25%; */
    display: inline-block;
    float: left;
    text-align: center;
    padding: 5px;
    box-sizing: border-box;
    height: 100px;
    position:relative;
    height:100px;
}
.single_path_label span{
    position:absolute;
    top:50%;
    left:50%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    width:100%;
}
.path_labels{
    display: block;
    margin-top:30px;
}
#path_2{
    width:34%;
}
#path_3{
    width:30%;
}
#path_4{
    width:10%;
}
.single_path_label:last-child{
    border:none;
}
/* SPRITE CLASSES */




/*
.all_matdesbg, .all_jquery, .all_csharp-pic, .all_default, .all_webdev,
.all_database, .all_java-path, .all_juniorcourse, .all_php, .all_html,
.all_js, .all_arflagsmall, .all_esflagsmall, .all_hiflagsmall, .all_ruflagsmall,
.all_zhflagsmall, .all_bgflagsmall, .all_enflagsmall
{ max-width: 100%; background-size: 100%; background-image: url('../images/sprites/all-page/all.gif'); }

.all_matdesbg {
    background-position: 0 0%;
    background-size: 445px;
    height: 160px;
}
.all_jquery { background-position: 0 12.770898%; background-size: 244.444444%; }
.all_csharp-pic { background-position: 0 17.065391%; background-size: 366.666667%; }
.all_default { background-position: 0 24.753289%; background-size: 366.666667%;  width: 100px;  height: 100px;}
.all_webdev { background-position: 0 34.466019%; background-size: 440%; }
.all_database { background-position: 0 42.961165%; background-size: 440%; }
.all_java-path { background-position: 0 51.456311%; background-size: 440%; }
.all_juniorcourse { background-position: 0 59.951456%; background-size: 440%; }
.all_php { background-position: 0 68.446602%; background-size: 440%; }
.all_html { background-position: 0 78.207237%; background-size: 517.647059%; }
.all_js { background-position: 0 88.486842%; background-size: 517.647059%; }
.all_arflagsmall { background-position: 0 90.915973%; background-size: 1913.043478%; }
.all_esflagsmall { background-position: 0 92.429977%; background-size: 1913.043478%; }
.all_hiflagsmall { background-position: 0 93.943982%; background-size: 1913.043478%; }
.all_ruflagsmall { background-position: 0 95.457986%; background-size: 1913.043478%; }
.all_zhflagsmall { background-position: 0 96.971991%; background-size: 1913.043478%; }
.all_bgflagsmall { background-position: 0 98.485995%; background-size: 2000%; }
.all_enflagsmall { background-position: 0 100%; background-size: 2000%; }

*/
/*NEW ALL SPRITES */
/*.all_matdesbg, .all_jquery, .all_csharp-pic, .all_default, .all_webdev,
.all_database, .all_java-path, .all_juniorcourse, .all_php, .all_html,
.all_js, .all_blueribbon, .all_greenribbon, .all_purpleribbon
{ display: inline-block; background: url('../images/sprites/all-page/all_new.gif') no-repeat; overflow: hidden; text-indent: -9999px; text-align: left; }
all_matdesbg { background-position: -5px -0px; width: 440px; height: 160px; }
.all_jquery { background-position: -5px -165px; width: 180px; height: 44px; }
.all_csharp-pic { background-position: -190px -165px; width: 120px; height: 82px; }
.all_default { background-position: -315px -165px; width: 120px; height: 120px; }
.all_webdev { background-position: -5px -214px; width: 100px; height: 100px; }
.all_database { background-position: -110px -252px; width: 100px; height: 100px; }
.all_java-path { background-position: -215px -290px; width: 100px; height: 100px; }
.all_juniorcourse { background-position: -320px -290px; width: 100px; height: 100px; }
.all_php { background-position: -5px -319px; width: 100px; height: 100px; }
.all_html { background-position: -110px -357px; width: 85px; height: 120px; }
.all_js { background-position: -200px -395px; width: 85px; height: 120px; }
.all_blueribbon { background-position: -290px -395px; width: 80px; height: 119px; }
.all_greenribbon { background-position: -5px -424px; width: 80px; height: 119px; }
.all_purpleribbon { background-position: -90px -482px; width: 80px; height: 119px; }*/

/*NOVI SPRAITOVE PETAR */
#brain_new{
    /* background-image: url('../images/brain.png');
     background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;*/
}
.all_matdesbg, .all_jquery, .all_csharp-pic, .all_default, /*.all_webdev,
.all_database,*/ .all_java-path, /*.all_juniorcourse, .all_php,*/ .all_html,
.all_js, .all_arflagsmall, .all_esflagsmall, .all_hiflagsmall, .all_ruflagsmall,
.all_zhflagsmall, .all_bgflagsmall, .all_enflagsmall
{ max-width: 100%; background-size: 100%; background-image: url('../images/sprites/all-page/all_new2.gif');  }

.all_matdesbg { background-position: 0 0%; background-size: 150%; }
.all_jquery { background-position: 0 13.256007%; background-size: 244.444444%; }
.all_csharp-pic { background-position: 0 17.450813%; background-size: 366.666667%; }
.all_default { background-position: 0 25.287356%; background-size: 366.666667%; width:120px !important; height:120px !important;}
.all_webdev { /*background-position: 0 35.273675%; background-size: 440%; */}
.all_database { /*background-position: 0 43.961772%; background-size: 440%;*/ }
.all_java-path { background-position: 0 52.64987%; background-size: 440%; }
.all_juniorcourse { /*background-position: 0 61.337967%; background-size: 440%;*/ }
.all_php { /*background-position: 0 70.026064%; background-size: 440%;*/
   /* background-image: url('../images/pathLogos/mvc.png');
    background-position: 0 43.961772%;
    background-size: 102%;
    background-repeat: no-repeat;*/
}
.all_html { background-position: 0 80.106101%; background-size: 517.647059%; }
.all_js { background-position: 0 90.71618%; background-size: 517.647059%; }
.all_arflagsmall { background-position: 0 92.718447%; background-size: 1913.043478%; }
.all_esflagsmall { background-position: 0 93.932039%; background-size: 1913.043478%; }
.all_hiflagsmall { background-position: 0 95.145631%; background-size: 1913.043478%; }
.all_ruflagsmall { background-position: 0 96.359223%; background-size: 1913.043478%; }
.all_zhflagsmall { background-position: 0 97.572816%; background-size: 1913.043478%; }
.all_bgflagsmall { background-position: 0 98.786408%; background-size: 2000%; }
.all_enflagsmall { background-position: 0 100%; background-size: 2000%; }
.all_ruby{max-width: 100%; background-size: 100%;/* background-image: url('../images/stack_logos/ruby.png'); */width:120px;height:120px;}
/*.all_junior{max-width: 100%; background-size: 100%; background-image: url('../images/stack_logos/junior.png') ;background-repeat: no-repeat; z-index: 5;width:150px;height:150px;top:60%;}*/
.all_csharp{max-width: 100%; background-size: 100%;/* background-image: url('../images/stack_logos/csharp.png');*/ width:120px;height:120px;}
.all_java{max-width: 100%; background-size: 100%; /*background-image: url('../images/stack_logos/java.png');*/ width:120px;height:120px;}
.all_c_cpp{max-width: 100%; background-size: 100%;/* background-image: url('../images/stack_logos/cpp.png');*/ width: 100px;height: 112px;}
.all_python{max-width: 100%; background-size: 100%; /*background-image: url('../images/stack_logos/python.png');*/ width:120px;height:120px;background-repeat:no-repeat;/*margin-top:20px;*/}
.all_javascript{max-width: 100%; background-size: 100%;/* background-image: url('../images/stack_logos/javascript.png');*/ width:120px;height:120px;}
.home_angear-stationary, .home_brain, .home_metal-caps-bg, .home_metal-caps-en, .home_metal-caps-hi, .home_metal-caps-ar, .home_metal-caps-zh, .home_metal-caps-es, .home_metal-caps-ru
{ max-width: 100%; background-size: 100%; background-image: url('../images/sprites/home-page/home.gif'); }

.home_angear-stationary { background-position: 0 0%; background-size: 100%;  padding-bottom: 105%; }
.home_brain { background-position: 0 38.634399%; background-size: 100%;}
.home_metal-caps-bg { background-position: 0 69.606164%; background-size: 114.285714%; padding-bottom: 100%;}
.home_metal-caps-en,.home_metal-caps-hi, .home_metal-caps-es, .home_metal-caps-zh, .home_metal-caps-ar, .home_metal-caps-ru  { background-position: 0 100%; background-size: 114.285714%; padding-bottom: 100%; }


 /*.home_home-background{
     background-size:cover;
     background-position: center bottom;
     background-image: url('../images/sprites/home-page/home-background.gif');
 }*/



/*.home_home-background, .home_devices-screen-new, .home_laravel-mvc, .home_webappnew, .home_brain,
.home_consoles, .home_level1
{ max-width: 100%; background-size: 100%; background-image: url('../images/sprites/home-page/home-sprite.gif'); }

.home_home-background { background-position: 0 0%; background-size: 100%; }
.home_devices-screen-new { background-position: 0 26.528067%; background-size: 117.323327%; }
.home_laravel-mvc { background-position: 0 58.415214%; background-size: 256.986301%; }
.home_webappnew { background-position: 0 72.836767%; background-size: 256.986301%; }
.home_brain { background-position: 0 89.879203%; background-size: 312.666667%; }
.home_consoles { background-position: 0 95.513748%; background-size: 625.333333%; }
.home_level1 { background-position: 0 100%; background-size: 625.333333%; }*/
/*.logo_vinternship, .logo_mvc, .logo_matdesbg, .logo_course1, .logo_course2, .logo_path_4,.logo_path_ruby,.logo_path_csharp,.logo_path_javascript,
.logo_path_python,.logo_path_cplus,.logo_path_java,
.logo_course3, .logo_course4, .logo_laravel, .logo_mysql, .logo_java,
.logo_c_cpp, .logo_csharp, .logo_django, .logo_javascript, .logo_node-js,
.logo_ruby, .logo_ruby-on-rails, .logo_python, .logo_default, .logo_new-game-setup,
.logo_css, .logo_html, .logo_terminal,.logo_path_kcoder,.logo_path_vprogramming
{ max-width: 100%; background-size: 100%;  background-image: url('../images/sprites/all-page/courses/logo_courses.gif');  }

.logo_vinternship { background-position: 0 0%; background-size: 100%; }
.logo_mvc { background-position: 0 5.333333%; background-size: 100%; }
.logo_matdesbg { background-position: 0 10.59752%; background-size: 197.954545%; }
.logo_course1 { background-position: 0 15.286804%; background-size: 185.060976%; }
.logo_course2 { background-position: 0 20.203447%; background-size: 185.060976%; }
.logo_path_4 { background-position: 0 20.203447%; background-size: 185.060976%; }
.logo_path_vprogramming { background-position: 0 20.203447%; background-size: 185.060976%; }
.logo_course3 { background-position: 0 25.12009%; background-size: 185.060976%; }
.logo_course4 { background-position: 0 30.036734%; background-size: 185.060976%; }
.logo_laravel { background-position: 0 34.114727%; background-size: 202.333333%; }
.logo_mysql { background-position: 0 37.211917%; background-size: 202.333333%; }
.logo_java { background-position: 0 42.881995%; background-size: 234.362934%; }
.logo_c_cpp { background-position: 0 50.043066%; background-size: 303.5%; }
.logo_csharp { background-position: 0 56.242873%; background-size: 303.5%; }
.logo_django { background-position: 0 60.215648%; background-size: 303.5%; }
.logo_javascript { background-position: 0 64.823261%; background-size: 303.5%; }
.logo_node-js { background-position: 0 68.708426%; background-size: 303.5%; }
.logo_ruby { background-position: 0 73.660205%; background-size: 303.5%; }
.logo_ruby-on-rails { background-position: 0 80.864019%; background-size: 303.5%; }
.logo_python { background-position: 0 85.27933%; background-size: 474.21875%; }
.logo_default { background-position: 0 88.795987%; background-size: 505.833333%; }
.logo_new-game-setup { background-position: 0 91.011545%; background-size: 607%; }
.logo_css { background-position: 0 94.343828%; background-size: 714.117647%; }
.logo_html { background-position: 0 97.826087%; background-size: 714.117647%; }
.logo_terminal { background-position: 0 100%; background-size: 831.506849%; }
.logo_path_ruby{background-position: 0 73.660205%; background-size: 303.5%;}
.logo_path_ruby{background-position: 0 73.660205%; background-size: 303.5%;}
.logo_path_csharp{background-position: 0 56.242873%; background-size: 303.5%;}
.logo_path_javascript{background-position: 0 64.823261%; background-size: 303.5%;}
.logo_path_python{background-position: 0 85.27933%; background-size: 474.21875%;}
.logo_path_cplus{background-position: 0 50.043066%; background-size: 303.5%;}
.logo_path_java{ background-position: 0 42.881995%; background-size: 234.362934%; }*/
.logo_path_kcoder{}
.logo_courses{
    /*height: 95px;
    width: 185px;*/
    width:71%;
    height:100%;
    background-repeat: no-repeat;
    /*z-index: 1;*/
}
.home_courses{
    height: 75px;
    width: 185px;
    background-repeat: no-repeat;
    z-index: 1;
}

/*.logo_secondgamebg,.logo_vinternship, .logo_mvc, .logo_matdesbg,._matdesbg, .logo_course1, .logo_course2, .logo_path_4,.logo_path_ruby,.logo_path_csharp,.logo_path_javascript,
.logo_path_python,.logo_path_cplus,.logo_path_java,
.logo_course3, .logo_course4, .logo_laravel, .logo_mysql, .logo_java,
.logo_c_cpp, .logo_csharp, .logo_django, .logo_javascript, .logo_node-js,
.logo_ruby, .logo_ruby-on-rails, .logo_python, .logo_default, .logo_new-game-setup,
.logo_css, .logo_html, .logo_terminal,.logo_path_kcoder,.logo_path_vprogramming
{ max-width: 100%; background-size: 100%; background-image: url('../images/sprites/all-page/courses/logo-course.png'); }

.logo_secondgamebg { background-position: 0 0%; background-size: 100%; }
.logo_vinternship { background-position: 0 15.592028%; background-size: 173.146623%; }
.logo_mvc { background-position: 0 20%; background-size: 173.146623%; }
.logo_matdesbg { background-position: 0 24.277052%; background-size: 438.863636%; }
._matdesbg { background-position: 0 24.277052%; background-size: 738.863636%; }
.logo_course1 { background-position: 0 28.184155%; background-size: 320.426829%; }
.logo_course2 { background-position: 0 32.250526%; background-size: 320.426829%; }
.logo_path_vprogramming {background-position: 0 32.250526%; background-size: 320.426829%;  }
.logo_course3 { background-position: 0 36.316896%; background-size: 320.426829%; }
.logo_course4 { background-position: 0 40.383267%; background-size: 320.426829%; }
.logo_laravel { background-position: 0 43.563903%; background-size: 350.333333%; }
.logo_sql { background-position: 0 46.277338%; background-size: 350.333333%; }
.logo_java { background-position: 0 51.181666%; background-size: 405.791506%; }
.logo_mvc { background-position: 0 55.002284%; background-size: 460.964912%; }
.logo_c_cpp { background-position: 0 58.797064%; background-size: 525.5%; }
.logo_csharp { background-position: 0 63.865348%; background-size: 525.5%; }
.logo_django { background-position: 0 66.972688%; background-size: 525.5%; }
.logo_javascript { background-position: 0 70.951036%; background-size: 525.5%; }
.logo_node-js { background-position: 0 74.034039%; background-size: 525.5%; }
.logo_ruby { background-position: 0 78.248588%; background-size: 525.5%; }
.logo_ruby-on-rails { background-position: 0 84.24445%; background-size: 525.5%; }
.logo_python { background-position: 0 87.800926%; background-size: 821.09375%; }
.logo_default { background-position: 0 90.711645%; background-size: 875.833333%; width:120px; height:120px; }
.logo_new-game-setup { background-position: 0 92.530836%; background-size: 1051%; }
.logo_css { background-position: 0 95.310695%; background-size: 1236.470588%; }
.logo_html { background-position: 0 98.197782%; background-size: 1236.470588%; }
.logo_terminal { background-position: 0 100%; background-size: 1439.726027%; }
.logo_path_kcoder{background-position: 0 0%; background-size: 200%; }
.logo_path_ruby{background-position: 0 78.248588%; background-size: 525.5%;}
.logo_path_csharp{ background-position: 0 63.865348%; background-size: 525.5%; }
.logo_path_javascript{background-position: 0 70.951036%; background-size: 525.5%;}
.logo_path_python{ background-position: 0 87.800926%; background-size: 821.09375%;}
.logo_path_cplus{background-position: 0 58.797064%; background-size: 525.5%; }
.logo_path_java{ background-position: 0 51.181666%; background-size: 405.791506%; }*/

.logo_secondgamebg,.logo_vinternship, .logo_mvc, .logo_matdesbg,._matdesbg, .logo_course1, .logo_course2, .logo_path_4,.logo_path_ruby,.logo_path_csharp,.logo_path_javascript,
.logo_path_python,.logo_path_cplus,.logo_path_java,
.logo_course3, .logo_course4, .logo_laravel, .logo_mysql, .logo_java,
.logo_c_cpp, .logo_csharp, .logo_django, .logo_javascript, .logo_node-js,
.logo_ruby, .logo_ruby-on-rails, .logo_python, .logo_default, .logo_new-game-setup,
.logo_css, .logo_html, .logo_terminal,/*.logo_path_kcoder,*/.logo_path_vprogramming,.logo_parents,.logo_path_html,.logo_js, .courseLogo.all_ruby, .courseLogo.all_csharp, .courseLogo.all_c_cpp, .courseLogo.all_javascript, .courseLogo.all_java
{ max-width: 100%; background-size: 100%; background-image: url('../images/sprites/all-page/courses/courses_logos.png');  /*z-index:5;*/}
 .logo_default { background-position: 0 90.960198%; background-size: 875.833333%; width:120px; height:120px; }
.logo_secondgamebg { background-position: 0 0%; background-size: 100%; }
.logo_parents { background-position: 0 15.951067%; background-size: 105.1%; }
.logo_webapps { background-position: 0 24.361314%; background-size: 173.146623%; }
.logo_matdesbg { background-position:0 28.500113%; background-size: 438.863636%; }
._matdesbg { background-position: 0 28.500113%; background-size: 738.863636%;  }
.logo_course1 { background-position: 0 32.310141%; background-size: 320.426829%; }
.logo_course2 { background-position: 0 36.266485%; background-size: 320.426829%; }
.logo_path_vprogramming { background-position: 0 36.266485%; background-size: 320.426829%; }
.logo_course3 { background-position: 0 40.222829%; background-size: 320.426829%; }
.logo_course4 { background-position: 0 44.179172%; background-size: 320.426829%; }
.logo_laravel { background-position: 0 47.201784%; background-size: 350.333333%; }
.logo_sql { background-position: 0 49.898121%; background-size: 350.333333%; }
.logo_java,.courseLogo.all_java { background-position: 0 54.758589%; background-size: 405.791506%; }
.logo_mvc { background-position: 0 58.327774%; background-size: 460.964912%; }
.logo_c_cpp,.courseLogo.all_c_cpp { background-position: 0 62.137264%; background-size: 525.5%; }
.logo_csharp, .courseLogo.all_csharp { background-position: 0 67.048317%; background-size: 525.5%; }
.logo_javascript, .courseLogo.all_javascript { background-position: 0 71.742615%; background-size: 525.5%; }
.logo_node-js { background-position: 0 74.725767%; background-size: 525.5%; }
.logo_ruby, .courseLogo.all_ruby { background-position: 0 78.84131%; background-size: 525.5%; }
.logo_ruby-on-rails { background-position: 0 84.679666%; background-size: 525.5%; }
.logo_python,.courseLogo.all_python { background-position: 0 88.127957%; background-size: 821.09375%; }
/*.all_default{background-position: 0 88.127957%; background-size:821.09375%;}*/
.logo_new-game-setup { background-position: 0 92.728486%; background-size: 1051%; }
.logo_css { background-position: 0 95.436151%; background-size: 1236.470588%; }
.logo_html { background-position: 0 98.246009%; background-size: 1236.470588%; }
.logo_terminal { background-position: 0 100%; background-size: 1439.726027%; }
/*.logo_path_kcoder{background-position: 0 0%; background-size: 200%; }*/
.logo_path_ruby{ background-position: 0 78.84131%; background-size: 525.5%; }
.logo_path_csharp{  background-position: 0 67.048317%; background-size: 525.5%;}
.logo_path_javascript{ background-position: 0 71.742615%; background-size: 525.5%;}
.logo_path_python{background-position: 0 88.127957%; background-size: 821.09375%;}
.logo_path_cplus{background-position: 0 62.137264%; background-size: 525.5%;  }
.logo_path_java{ background-position: 0 54.758589%; background-size: 405.791506%;  }
.logo_path_html { background-position: 0 98.246009%; background-size: 1236.470588%; }
.logo_js{background-position: 0 100%; background-size: 1236.470588%;}
.logo_path_web{background-image:url(../images/html-css-path2.png);background-size:170%;background-position: center;background-repeat:no-repeat;}
.logo_path_js-examples{background-image:url(../images/js-examples.png);background-size:170%;background-position: center;background-repeat:no-repeat;}
.logo_path_dynamic-js{background-image:url(../images/dynamic-js.png);background-size:120%;background-position: center;background-repeat:no-repeat;}

.logo_js-html-css{background-image:url(../images/html-css.png);background-size: contain;  background-repeat: no-repeat;  background-position: center;}
.all_ruby{max-width: 100%; /*background-size: 100%; background-image: url('../images/stack_logos/ruby.png');*/ width:120px;height:120px;}
/*.all_junior{max-width: 100%; background-size: 100%; background-image: url('../images/stack_logos/junior.png') ;background-repeat: no-repeat; z-index: 5;width:150px;height:150px;top:60%;}*/
.all_csharp{max-width: 100%; /*background-size: 100%; /*background-image: url('../images/stack_logos/csharp.png'); */ width:120px;height:120px;}
.all_java{max-width: 100%;/* background-size: 100%; /*background-image: url('../images/stack_logos/java.png');*/ width:120px;height:120px;}
.all_c_cpp{max-width: 100%; /*background-size: 100%;/* background-image: url('../images/stack_logos/cpp.png');*/ width: 100px;height: 112px;}
.all_python{max-width: 100%; /*background-size: 100%; /*background-image: url('../images/stack_logos/python.png');*/ width:120px;height:120px;background-repeat:no-repeat;/*margin-top:20px;*/}
.all_javascript{max-width: 100%; /*background-size: 100%; /*background-image: url('../images/stack_logos/javascript.png');*/ width:120px;height:120px;}


/****
*** NEW COURSES LOGOS
***/
.logo_secondgamebg, .logo_matdesbg, .logo_course1, .logo_course2, .logo_course3,
.logo_course4, .logo_java, .logo_cpp, .logo_csharp, .logo_javascript,
.logo_ruby, .logo_python, .logo_default, .logo_css, .logo_html,.logo_path_vprogramming,.logo_path_kcoder,.logo_path_csharp,.logo_path_ruby,.logo_path_javascript,.logo_path_java,
.logo_path_python,.logo_path_cplus,
 .courseLogo.all_ruby, .courseLogo.all_csharp, .all_csharp, .courseLogo.all_c_cpp, .all_c_cpp,.courseLogo.all_javascript, .all_javascript,.courseLogo.all_java,.all_java, .courseLogo.all_default,
 .logo_course .all_ruby,.logo_course .all_csharp, .logo_course .all_c_cpp, .logo_course .all_javascript,.logo_course .all_java, .logo_course .all_python, .logo_path_kcoder
{ max-width: 100%; background-size: 100%; background-image: url('../images/sprites/all-page/courses/cours_logos.png'); }
.logo_default, .courseLogo.all_default { background-position: 0 91.746768%; background-size: 875.833333%; display:none;}
.logo_secondgamebg,.logo_path_kcoder { background-position: 0 0%; background-size: 170%; }
.logo_matdesbg { background-position: 0 22.337924%; background-size: 400.863636%; }
.logo_course1 { background-position: 0 27.96496%; background-size: 320.426829%; }
.logo_course2,.logo_path_vprogramming { background-position: 0 33.827493%; background-size: 320.426829%; }
.logo_course3 { background-position: 0 39.690027%; background-size: 320.426829%; }
.logo_course4 { background-position: 0 45.552561%; background-size: 320.426829%; }
.logo_java,.logo_path_java, .courseLogo.all_java,.all_java, .logo_course .all_java { background-position: 0 53.022933%; background-size: 405.791506%; }
.logo_cpp,.logo_path_cplus, .courseLogo.all_c_cpp,.all_c_cpp,.logo_course .all_c_cpp { background-position: 0 61.46978%; background-size: 525.5%; }
.logo_csharp,.logo_path_csharp,.courseLogo.all_csharp,.all_csharp,.logo_course .all_csharp { background-position: 0 68.777664%; background-size: 525.5%; }
.logo_javascript,.logo_path_javascript,.courseLogo.all_javascript,.all_javascript , .logo_course .all_javascript{ background-position: 0 75.757576%; background-size: 525.5%; }
.logo_ruby,.logo_path_ruby,  .courseLogo.all_ruby,.all_ruby,.logo_course .all_ruby{ background-position: 0 82.737487%; background-size: 525.5%; }
.logo_python,.logo_path_python,.courseLogo.all_python, .all_python,.logo_course .all_python{ background-position: 0 87.570621%; background-size: 821.09375%; }

.logo_css { background-position: 0 95.858184%; background-size: 1236.470588%; }
.logo_html { background-position: 0 100%; background-size: 1236.470588%; }



.mobile_email, .mobile_email-new, .mobile_arflagsmall, .mobile_esflagsmall, .mobile_hiflagsmall,
.mobile_ruflagsmall, .mobile_zhflagsmall, .mobile_bgflagsmall, .mobile_enflagsmall, .mobile_trflagsmall,.footer_email-new
{ max-width: 100%; background-size: 100%; background-image: url('../images/sprites/all-page/flags_footer.gif'); }

.footer-email {
    height: 23px;
    width: 100%;
    display:inline-block;
}
.footer_email-new {
    height: 25px;
    width: 100%;
    background-position: 0 15%; background-size: 125%;
    background-repeat:no-repeat;
}

.mobile_arflagsmall { background-position: 0 27.225131%; background-size: 804.347826%; }
.mobile_esflagsmall { background-position: 0 37.696335%; background-size: 804.347826%; }
.mobile_hiflagsmall { background-position: 0 48.167539%; background-size: 804.347826%; }
.mobile_ruflagsmall { background-position: 0 58.638743%; background-size: 804.347826%; }
.mobile_zhflagsmall { background-position: 0 69.109948%; background-size: 804.347826%; }
.mobile_bgflagsmall { background-position: 0 79.581152%; background-size: 840.909091%; }
.mobile_enflagsmall { background-position: 0 90.052356%; background-size: 840.909091%; }
.mobile_trflagsmall { background-position: 0 100%; background-size: 840.909091%; }



/*
*/
/*


*/

/*
*//*
.home_kids-bg, .home_home-old, .home_devices-screen-new, .home_webapps, .home_brain_new,
.home_secondgif, .home_thirdgif, .home_matdesbg, .home_course1, .home_course2,
.home_course3, .home_course4, .home_consoles, .home_gametocode, .home_level1,
.home_sql, .home_java, .home_webappnew2, .home_cpp, .home_csharp,
.home_javascript, .home_ruby, .home_email, .home_email-new, .home_python,
.home_default, .home_hand, .home_new-game-setup, .home_css, .home_html,
.home_terminal, .home_arflagsmall, .home_esflagsmall, .home_hiflagsmall, .home_ruflagsmall,
.home_zhflagsmall, .home_bgflagsmall, .home_enflagsmall, .home_trflagsmall
{ max-width: 100%; background-size: 100%; background-image: url('../images/sprites/home-page/sprite_home_page.png'); }

.home_kids-bg { background-position: 0 0%; background-size: 100%; }
.home_home-old { background-position: 0 10.812087%; background-size: 120.470588%; }
.home_devices-screen-new { background-position: 0 15.653595%; background-size: 100.285714%; }
.home_webapps { background-position: 0 22.727979%; background-size: 168.698517%; }
.home_brain_new { background-position: 0 27.163541%; background-size: 170.666667%; }
.home_secondgif { background-position: 0 34.162263%; background-size: 220.666667%; }
.home_thirdgif { background-position: 0 38.266859%; background-size: 170.666667%; }
.home_matdesbg { background-position: 0 42.894737%; background-size: 232.727273%; }
.home_course1 { background-position: 0 45.512324%; background-size: 312.195122%; }
.home_course2 { background-position: 0 48.20958%; background-size: 312.195122%; }
.home_course3 { background-position: 0 50.906836%; background-size: 312.195122%; }
.home_course4 { background-position: 0 53.604092%; background-size: 312.195122%; }
.home_consoles {    background-position: -69px 56.136012%;
    background-size: 641.333333%;}
.home_gametocode { background-position: 0 59.760139%; background-size: 341.333333%; }
.home_level1 { background-position: 0 62.982998%; background-size: 341.333333%; }
.home_sql { background-position: 0 65.378671%; background-size: 341.333333%; }
.home_java { background-position: 0 68.935702%; background-size: 395.366795%; }
.home_webappnew2 {   background-position: 0 71.854714%; background-size: 621.399177%;}
.home_cpp { background-position: 0 75.121188%; background-size: 512%; }
.home_csharp { background-position: 0 78.411215%; background-size: 512%; }
.home_javascript { background-position: 0 81.604361%; background-size: 512%; }
.home_ruby { background-position: 0 84.797508%; background-size: 512%; }
.home_email { background-position: 0 85.629832%; background-size: 553.513514%; }
.home_email-new { background-position: 0 86.002121%; background-size: 687.248322%; }
.home_python { background-position: 0 87.815773%; background-size: 800%; }
.home_default { background-position: 0 89.753846%; background-size: 853.333333%; }
.home_hand { background-position: 0 91.395706%; background-size: 1024%;}
.home_new-game-setup { background-position: 0 92.580153%; background-size: 1024%; }
.home_css { background-position: 0 94.431626%; background-size: 1204.705882%; }
.home_html { background-position: 0 96.353846%; background-size: 1204.705882%; }
.home_terminal { background-position: 0 97.571407%; background-size: 1402.739726%; }
.home_arflagsmall { background-position: 0 97.895534%; background-size: 4452.173913%; }
.home_esflagsmall { background-position: 0 98.198335%; background-size: 4452.173913%; }
.home_hiflagsmall { background-position: 0 98.501136%; background-size: 4452.173913%; }
.home_ruflagsmall { background-position: 0 98.803936%; background-size: 4452.173913%; }
.home_zhflagsmall { background-position: 0 99.106737%; background-size: 4452.173913%; }
.home_bgflagsmall { background-position: 0 99.409538%; background-size: 4654.545455%; }
.home_enflagsmall { background-position: 0 99.712339%; background-size: 4654.545455%; }
.home_trflagsmall { background-position: 0 100%; background-size: 4654.545455%; }

*/
/*.home_kids-bg, .home_home-old, .home_devices-screen-new, .home_webapps, .home_brain_new,
.home_gif1, .home_secondgif, .home_thirdgif, .home_matdesbg, .home_course1,
.home_course2, .home_course3, .home_course4, .home_consoles, .home_gametocode,
.home_sql, .home_java, .home_webappnew2, .home_cpp, .home_csharp,
.home_javascript, .home_ruby, .home_email, .home_email-new, .home_python,
.home_default, .home_hand, .home_new-game-setup, .home_css, .home_html,
.home_terminal, .home_arflagsmall, .home_esflagsmall, .home_hiflagsmall, .home_ruflagsmall,
.home_zhflagsmall, .home_bgflagsmall, .home_enflagsmall, .home_trflagsmall
{ max-width: 100%; background-size: 100%; background-image: url('../images/sprites/home-page/sprite_home_page.png'); }

.home_kids-bg { background-position: 0 0%; background-size: 100%; }
.home_home-old { background-position: 0 10.702602%; background-size: 120.470588%; }
.home_devices-screen-new { background-position: 0 15.489086%; background-size: 100.285714%; }
.home_webapps { background-position: 0 22.500769%; background-size: 168.698517%; }
.home_brain_new { background-position: 0 26.876119%; background-size: 170.666667%; }
.home_gif1 { background-position: 0 33.972179%; background-size: 290.666667%; }
.home_secondgif { background-position: 0 37.205724%; background-size: 310.666667%; }
.home_thirdgif { background-position: 0 41.360178%; background-size: 170.666667%; }
.home_matdesbg { background-position: 0 45.83908%; background-size: 232.727273%; }
.home_course1 { background-position: 0 48.434622%; background-size: 312.195122%; }
.home_course2 { background-position: 0 51.104972%; background-size: 312.195122%; }
.home_course3 { background-position: 0 53.775322%; background-size: 312.195122%; }
.home_course4 { background-position: 0 56.445672%; background-size: 312.195122%; }
.home_consoles { background-position: -60px 58.944147%; background-size: 541.333333%; }
.home_gametocode { background-position: 0 62.589816%; background-size: 341.333333%; }
.home_sql { background-position: 0 65.72303%; background-size: 341.333333%; }
.home_java { background-position: 0 69.249922%; background-size: 395.366795%; }
.home_webappnew2 { background-position: 0 72.13466%; background-size: 621.399177%; }
.home_cpp { background-position: 0 75.371517%; background-size: 512%; }
.home_csharp { background-position: 0 78.627602%; background-size: 512%; }
.home_javascript { background-position: 0 81.788743%; background-size: 512%; }
.home_ruby { background-position: 0 84.949884%; background-size: 512%; }
.home_email { background-position: 0 85.770039%; background-size: 553.513514%; }
.home_email-new { background-position: 0 86.138614%; background-size: 487.248322%; }
.home_python { background-position: 0 87.936556%; background-size: 800%; }
.home_default { background-position: 0 89.855293%; background-size: 853.333333%; }
.home_hand { background-position: 0 91.480638%; background-size: 1024%; }
.home_new-game-setup { background-position: 0 92.653061%; background-size: 1024%; }
.home_css { background-position: 0 94.48675%; background-size: 1204.705882%; }
.home_html { background-position: 0 96.389947%; background-size: 1204.705882%; }
.home_terminal { background-position: 0 97.595281%; background-size: 1402.739726%; }
.home_arflagsmall { background-position: 0 97.916042%; background-size: 4452.173913%; }
.home_esflagsmall { background-position: 0 98.215892%; background-size: 4452.173913%; }
.home_hiflagsmall { background-position: 0 98.515742%; background-size: 4452.173913%; }
.home_ruflagsmall { background-position: 0 98.815592%; background-size: 4452.173913%; }
.home_zhflagsmall { background-position: 0 99.115442%; background-size: 4452.173913%; }
.home_bgflagsmall { background-position: 0 99.415292%; background-size: 4654.545455%; }
.home_enflagsmall { background-position: 0 99.715142%; background-size: 4654.545455%; }
.home_trflagsmall { background-position: 0 100%; background-size: 4654.545455%; }*/
/*.home_kids-bg, .home_home-old, .home_devices-screen-new, .home_google-play-badge, .home_brain_new,
.home_gif1, .home_secondgif, .home_thirdgif, .home_consoles, .home_gametocode,
.home_webappnew2, .home_appstore, .home_email-new, .home_hand, .home_arflagsmall,
.home_esflagsmall, .home_hiflagsmall, .home_ruflagsmall, .home_zhflagsmall, .home_bgflagsmall,
.home_enflagsmall, .home_trflagsmall
{ max-width: 100%; background-size: 100%;  background-image: url('../images/sprites/home-page/homes_sprites.png'); }

.home_kids-bg { background-position: 0 0%; background-size: 100%; }
.home_home-old { background-position: 0 18.413294%; background-size: 120.470588%; }
.home_devices-screen-new { background-position: 0 27.39491%; background-size: 146.285714%; }
.home_google-play-badge { background-position: 0 39.044569%; background-size: 150.513932%; }
.home_brain_new { background-position: 0 49.725036%; background-size: 170.666667%; }
.home_gif1 { background-position: 0 59.888948%; background-size: 290.666667%; }
.home_secondgif { background-position: 0 65.205983%; background-size: 310.666667%; }
.home_thirdgif { background-position: 0 73.922652%; background-size: 170.666667%; }
.home_consoles { background-position: -60px 80.490512%; background-size: 580.333333%; }
.home_gametocode { background-position: 0 86.510501%; background-size: 341.333333%; }
.home_webappnew2 { background-position: 0 91.005979%; background-size: 681.399177%; }
.home_appstore { background-position: 0 92.697769%; background-size: 668.024845%; }
.home_email-new { background-position: 0 93.363499%; background-size: 687.248322%; }
.home_hand { background-position: 0 95.919938%; background-size: 1024%; }
.home_arflagsmall { background-position: 0 96.509292%; background-size: 4452.173913%; }
.home_esflagsmall { background-position: 0 97.011552%; background-size: 4452.173913%; }
.home_hiflagsmall { background-position: 0 97.513812%; background-size: 4452.173913%; }
.home_ruflagsmall { background-position: 0 98.016072%; background-size: 4452.173913%; }
.home_zhflagsmall { background-position: 0 98.518332%; background-size: 4452.173913%; }
.home_bgflagsmall { background-position: 0 99.020593%; background-size: 4654.545455%; }
.home_enflagsmall { background-position: 0 99.522853%; background-size: 4654.545455%; }
.home_trflagsmall { background-position: 0 100%; background-size: 4654.545455%; }*/
/*.home_kids-bg, .home_home-old, .home_plans, .home_devices-screen-new, .home_roditeli-kontrol,
.home_google-play-badge, .home_brain_new, .home_gif1, .home_secondgif, .home_thirdgif,
.home_tripplebadge, .home_consoles, .home_gametocode, .home_webappnew2, .home_appstore,
.home_email-new, .home_hand, .home_arflagsmall, .home_esflagsmall, .home_hiflagsmall,
.home_ruflagsmall, .home_zhflagsmall, .home_bgflagsmall, .home_enflagsmall, .home_trflagsmall
{ max-width: 100%; background-size: 100%;  background-image: url('../images/sprites/home-page/homes_last_sprite.png');}

.home_kids-bg { background-position: 0 0%; background-size: 100%; }
.home_home-old { background-position: 0 13.850806%; background-size: 120.470588%; }
.home_plans { background-position: 0 20.096497%; background-size: 123.076923%; height:230px;}
.home_devices-screen-new { background-position: 0 30.08887%; background-size: 146.285714%; }
.home_roditeli-kontrol { background-position: 0 40.87469%; background-size: 148.62119%; height:250px; border:1px solid #FFA500;border-radius: 5px;}
.home_google-play-badge { background-position: 0 48.171222%; background-size: 158.513932%; }
.home_brain_new { background-position: 0 56.618275%; background-size: 170.666667%; }
.home_gif1 { background-position: 0 63.839553%; background-size: 290.666667%; }
.home_secondgif { background-position: 0 67.837302%; background-size: 310.666667%; }
.home_thirdgif { background-position: 0 74.44834%; background-size: 170.666667%; }
.home_tripplebadge { background-position: 0 81.022935%; background-size: 162.666667%;height:250px; }
.home_consoles { background-position: -60px 84.63357%; background-size: 580.333333%; }
.home_gametocode { background-position: 0 90.04653%; background-size: 341.333333%; }
.home_webappnew2 { background-position: 0 93.360914%; background-size: 681.399177%; }
.home_appstore { background-position: 0 94.4423%; background-size: 636.024845%; }
.home_email-new { background-position: 0 94.929902%; background-size: 687.248322%; }
.home_hand { background-position: 0 96.898166%; background-size: 1024%; }
.home_arflagsmall { background-position: 0 97.332566%; background-size: 4452.173913%; }
.home_esflagsmall { background-position: 0 97.716369%; background-size: 4452.173913%; }
.home_hiflagsmall { background-position: 0 98.100173%; background-size: 4452.173913%; }
.home_ruflagsmall { background-position: 0 98.483976%; background-size: 4452.173913%; }
.home_zhflagsmall { background-position: 0 98.86778%; background-size: 4452.173913%; }
.home_bgflagsmall { background-position: 0 99.251583%; background-size: 4654.545455%; }
.home_enflagsmall { background-position: 0 99.635387%; background-size: 4654.545455%; }
.home_trflagsmall { background-position: 0 100%; background-size: 4654.545455%; }
.home_parents_control{background: url('../images/roditeli-kontrol.png') no-repeat; background-size:contain; height:250px;}*/
/*.home_roditeli-kontrol, .home_kids-bg, .home_home-old, .home_plans, .home_devices-screen-new,
.home_google-play-badge, .home_brain_new, .home_gif1, .home_secondgif, .home_thirdgif,
.home_tripplebadge, .home_consoles, .home_gametocode, .home_webappnew2, .home_appstore,
.home_email-new, .home_hand, .home_arflagsmall, .home_esflagsmall, .home_hiflagsmall,
.home_ruflagsmall, .home_zhflagsmall, .home_bgflagsmall, .home_enflagsmall, .home_trflagsmall
{ max-width: 100%; background-size: 100%; background-image: url('../images/sprites/home-page/home_spritte.png'); }

.home_roditeli-kontrol { background-position: 0 0%; background-size: 100%; height:250px; border:1px solid #FFA500;border-radius: 5px;}
.home_kids-bg { background-position: 0 22.929245%; background-size: 121.582031%; }
.home_home-old { background-position: 0 30.904797%; background-size: 146.470588%; }
.home_plans { background-position: 0 37.130484%; background-size: 180.076923%; height:230px;}
.home_devices-screen-new { background-position: 0 46.223909%; background-size: 177.857143%; }
.home_google-play-badge { background-position: 0 53.278986%; background-size: 192.724458%; }
.home_brain_new { background-position: 0 61.132364%; background-size: 207.5%; }
.home_gif1 { background-position: 0 67.380738%; background-size: 318.5%; }
.home_secondgif { background-position: 0 70.97063%; background-size: 400.5%; }
.home_thirdgif { background-position: 0 77.025774%; background-size: 207.5%; }
.home_tripplebadge { background-position: 0 82.909889%; background-size: 198.666667%; height:250px;}
.home_consoles { background-position: 0 86.120996%; background-size: 692.333333%; }
.home_gametocode { background-position: 0 91.033352%; background-size: 415%; }
.home_webappnew2 { background-position: 0 94.003559%; background-size: 818.345679%; }
.home_appstore { background-position: 0 94.970311%; background-size: 773.291925%; margin-bottom:4px;}
.home_email-new { background-position: 0 95.409494%; background-size: 835.57047%; }
.home_hand { background-position: 0 97.195767%; background-size: 1245%; }
.home_arflagsmall { background-position: 0 97.584709%; background-size: 5413.043478%; }
.home_esflagsmall { background-position: 0 97.932233%; background-size: 5413.043478%; }
.home_hiflagsmall { background-position: 0 98.279757%; background-size: 5413.043478%; }
.home_ruflagsmall { background-position: 0 98.627281%; background-size: 5413.043478%; }
.home_zhflagsmall { background-position: 0 98.974805%; background-size: 5413.043478%; }
.home_bgflagsmall { background-position: 0 99.322328%; background-size: 5659.090909%; }
.home_enflagsmall { background-position: 0 99.669852%; background-size: 5659.090909%; }
.home_trflagsmall { background-position: 0 100%; background-size: 5659.090909%; }*/
.home_win-store{
    background-image:url('../images/win-store-badge.png');
    background-size: 96%;
    background-repeat: no-repeat;
    background-position: bottom left;
    /*margin-bottom:8px;*/
}

/***************** LAST BACKUP HOME *******************************/
/*
.home_roditeli-kontrol, .home_kids-bg, .home_home-old, .home_devices-screen-new, .home_google-play-badge,
.home_brain_new, .home_gif1, .home_secondgif, .home_thirdgif, .home_tripplebadge,
.home_consoles, .home_gametocode, .home_plans, .home_webappnew2, .home_appstore,
.home_email-new, .home_hand, .home_arflagsmall, .home_esflagsmall, .home_hiflagsmall,
.home_ruflagsmall, .home_zhflagsmall, .home_bgflagsmall, .home_enflagsmall, .home_trflagsmall
{ max-width: 100%; background-size: 100%; background-image: url('../images/sprites/home-page/home-page-sprite.png'); }

.home_roditeli-kontrol { background-position: 0 0%; background-size: 100%;height:250px; border:1px solid #FFA500;border-radius: 5px; }
.home_kids-bg { background-position: 0 19.929245%; background-size: 121.582031%; }
.home_home-old { background-position: 0 28.879733%; background-size: 146.470588%; }
.home_devices-screen-new { background-position: 0 35.842073%; background-size: 177.857143%;}
.home_google-play-badge { background-position: 0 43.96636%; background-size: 183.724458%; }
.home_brain_new { background-position: 0 51.918689%; background-size: 207.5%;  }
.home_gif1 { background-position: 0 59.246309%; background-size: 318.5%; }
.home_secondgif { background-position: 0 63.163995%; background-size: 400.5%;  }
.home_thirdgif { background-position: 0 69.41436%; background-size: 207.5%; }
.home_tripplebadge { background-position: 0 75.888626%; background-size: 187.333333%; }
.home_consoles { background-position: 0 79.551122%; background-size: 692.333333%;  }
.home_gametocode { background-position: 0 84.685039%; background-size: 415%;  }
.home_plans { background-position: 0 90.318772%;  background-size:196.556391%; height:250px}
.home_webappnew2 { background-position: 0 93.535392%; background-size: 818.345679%; }
.home_appstore { background-position: 0 94.585448%; background-size: 773.291925%; }
.home_email-new { background-position: 0 95.05988%; background-size: 835.57047%; }
.home_hand { background-position: 0 96.978909%; background-size: 1245%;}
.home_arflagsmall { background-position: 0 97.400898%; background-size: 4886.956522%; }
.home_esflagsmall { background-position: 0 97.774869%; background-size: 4886.956522%; }
.home_hiflagsmall { background-position: 0 98.148841%; background-size: 4886.956522%; }
.home_ruflagsmall { background-position: 0 98.522812%; background-size: 4886.956522%; }
.home_zhflagsmall { background-position: 0 98.896784%; background-size: 4886.956522%; }
.home_bgflagsmall { background-position: 0 99.270755%; background-size: 5109.090909%; }
.home_enflagsmall { background-position: 0 99.644727%; background-size: 5109.090909%; }
.home_trflagsmall { background-position: 0 100%; background-size: 5109.090909%; }*/


/*************************** END BACKUP HOME ****************************************/

/*.home_roditeli-kontrol, .home_kids-bg, .home_home-old, .home_devices-screen-new, .home_google-play-badge,
.home_brain_new, .home_gif1, .home_secondgif, .home_thirdgif, .home_tripplebadge,
.home_consoles, .home_gametocode, .home_plans, .home_webappnew2, .home_appstore,
.home_email-new, .home_hand, .home_arflagsmall, .home_esflagsmall, .home_hiflagsmall,
.home_ruflagsmall, .home_zhflagsmall, .home_bgflagsmall, .home_enflagsmall, .home_trflagsmall
{ max-width: 100%; background-size: 100%; background-image: url('../images/sprites/home-page/home-page-sprite-last.png'); }

.home_roditeli-kontrol { background-position: 0 0%; background-size: 100%; height:250px; border:1px solid #FFA500;border-radius: 5px; }
.home_kids-bg { background-position:0 19.929245%;background-size: 121.582031%;}
.home_home-old { background-position: 0 28.982083%; background-size: 146.470588%; }
.home_devices-screen-new { background-position: 0 35.975232%; background-size: 177.857143%; }
.home_google-play-badge { background-position: 0 43.96636%; background-size: 183.724458%;  }
.home_brain_new { background-position: 0 52.113263%; background-size:  207.5%; }
.home_gif1 { background-position: 0 59.454191%; background-size: 318.5%;}
.home_secondgif { background-position: 0 63.384377%; background-size: 400.5%; }
.home_thirdgif { background-position: 0 69.665862%; background-size: 207.5%; }
.home_tripplebadge { background-position: 0 76.159334%; background-size: 187.333333%;  }
.home_plans { background-position: 0 81.601732%; background-size: 290.439276%;height:230px; }
.home_consoles { background-position: 0 84.985563%; background-size: 692.333333%; }
.home_gametocode { background-position: 0 90.280522%; background-size:415%;  }
.home_webappnew2 { background-position: 0 93.512993%; background-size: 818.345679%; }
.home_appstore { background-position: 0 94.567063%; background-size: 773.291925%;  }
.home_email-new { background-position: 0 95.043184%; background-size:835.57047%; }
.home_hand { background-position: 0 96.968541%; background-size: 1245%; }
.home_arflagsmall { background-position: 0 97.39212%; background-size: 4886.956522%; }
.home_esflagsmall { background-position: 0 97.767355%; background-size: 4886.956522%; }
.home_hiflagsmall { background-position: 0 98.142589%; background-size: 4886.956522%; }
.home_ruflagsmall { background-position: 0 98.517824%; background-size: 4886.956522%; }
.home_zhflagsmall { background-position: 0 98.893058%; background-size: 4886.956522%; }
.home_bgflagsmall { background-position: 0 99.268293%; background-size: 5109.090909%; }
.home_enflagsmall { background-position: 0 99.643527%; background-size: 5109.090909%; }
.home_trflagsmall { background-position: 0 100%; background-size: 5109.090909%; }*/

.home_webappnew2, .home_roditeli-kontrol, .home_kids-bg, .home_hand, .home_home-old,
.home_consoles, .home_devices-screen-new, .home_brain_new, .home_gif1, .home_secondgif,
.home_thirdgif, .home_tripplebadge, .home_google-play-badge, .home_plans, .home_gametocode,
.home_email-new, .home_appstore, .home_arflagsmall, .home_esflagsmall, .home_hiflagsmall,
.home_ruflagsmall, .home_zhflagsmall, .home_bgflagsmall, .home_enflagsmall, .home_trflagsmall
{ max-width: 100%; background-size: 100%; background-image: url('../images/sprites/home-page/home-page-sprite-new-images.png'); }

.home_webappnew2 { background-position: -33px -0.5%; background-size: 143%; }
.home_roditeli-kontrol { background-position: 0 13.574872%; background-size: 100%; height:250px; border:1px solid #FFA500;border-radius: 5px}
.home_kids-bg { background-position: 0 25.624138%; background-size: 125%; }
.home_hand { background-position: -25px 36.376168%; background-size:182.333333%; }
.home_home-old { background-position: 0 44.305256%; background-size: 150.588235%; }
.home_consoles { background-position: -44px 49.792715%; background-size: 296.58299%; }
.home_devices-screen-new { background-position: 0 57.21949%; background-size: 177.857143%;}
.home_brain_new { background-position: 0 65.377118%; background-size: 213.333333%; }
.home_gif1 { background-position: 0 70.354488%; background-size: 318.5%; }
.home_secondgif { background-position: 0 73.409253%; background-size: 400.5%; }
.home_thirdgif { background-position: 0 78.475271%; background-size: 213.333333%; }
.home_tripplebadge { background-position: 0 83.333333%; background-size: 187.333333%;  }
.home_google-play-badge { background-position: 0 86.312651%; background-size: 226.950355%; }
.home_plans { /*background-position: 0 90.198618%; background-size: 330.749354%;*/ background-image:url(../images/free-plan2.png); background-position:center; background-size:60%; background-repeat: no-repeat; height:200px;}
.home_gametocode { background-position: 0 94.54388%; background-size: 426.666667%; }
.home_email-new { background-position: 0 95.286429%; background-size: 859.060403%; }
.home_appstore { background-position: 0 96.109329%; background-size: 948.148148%; }
.home_arflagsmall { background-position: 0 96.609227%; background-size: 5565.217391%; }
.home_esflagsmall { background-position: 0 97.095609%; background-size: 5565.217391%; }
.home_hiflagsmall { background-position: 0 97.58199%; background-size: 5565.217391%; }
.home_ruflagsmall { background-position: 0 98.068371%; background-size: 5565.217391%; }
.home_zhflagsmall { background-position: 0 98.554753%; background-size: 5565.217391%; }
.home_bgflagsmall { background-position: 0 99.041134%; background-size: 5818.181818%; }
.home_enflagsmall { background-position: 0 99.527515%; background-size: 5818.181818%; }
.home_trflagsmall { background-position: 0 100%; background-size: 5818.181818%; }


/*.profile_arflagsmall, .profile_esflagsmall, .profile_hiflagsmall,
.profile_ruflagsmall, .profile_zhflagsmall, .profile_bgflagsmall, .profile_enflagsmall, .profile_trflagsmall
{ max-width: 100%; background-size: 100%; background-image: url('../images/sprites/home-page/home_new_sprite.gif'); }

.profile_arflagsmall { background-position: 0 98.579041%; background-size: 8156.521739%; }
.profile_esflagsmall { background-position: 0 98.783987%; background-size: 8156.521739%; }
.profile_hiflagsmall { background-position: 0 98.988933%; background-size: 8156.521739%; }
.profile_ruflagsmall { background-position: 0 99.193879%; background-size: 8156.521739%; }
.profile_zhflagsmall { background-position: 0 99.398825%; background-size: 8156.521739%; }
.profile_bgflagsmall { background-position: 0 99.603771%; background-size: 8527.272727%; }
.profile_enflagsmall { background-position: 0 99.808717%; background-size: 8527.272727%; }
.profile_trflagsmall { background-position: 0 100%; background-size: 8527.272727%; }
*/
.path_goalsscreenshot-bg, .path_goalsscreenshot-en, .path_paths-bg, .path_paths-en, .path_tripplebadge.path_goalsscreenshot-bg,
.path_goalsscreenshot-en,
.path_goalsscreenshot-zh,
.path_goalsscreenshot-hi,
.path_goalsscreenshot-es,
.path_goalsscreenshot-ar,
.path_goalsscreenshot-ru,
.path_paths-bg,
.path_paths-en,
.path_paths-ru,
.path_paths-ar,
.path_paths-hi,
.path_paths-es,
.path_paths-zh,
.path_tripplebadge
{ max-width: 100%; background-size: 100%;/*background-image: url('../images/sprites/path-page/path.gif'); */}

.path_goalsscreenshot-bg { background-position: 0 0%; background-size: 100%;  padding-bottom: 100%;}
.path_goalsscreenshot-en,
.path_goalsscreenshot-zh,
.path_goalsscreenshot-hi,
.path_goalsscreenshot-es,
.path_goalsscreenshot-ar,
.path_goalsscreenshot-ru { background-position: 0 21.691843%; background-size: 100%; padding-bottom: 100%;}
.path_paths-bg { background-position: 0 47.299078%; background-size: 100%; padding-bottom: 100%; }
.path_paths-en,
.path_paths-ru,
.path_paths-ar,
.path_paths-hi,
.path_paths-es,
.path_paths-zh { background-position: 0 79.97365%; background-size: 100%;  padding-bottom: 100%;}
.path_tripplebadge { background-position: 0 100%; background-size: 100%; }


.path_goalsscreenshot-bg, .path_goalsscreenshot-en, .path_paths-bg, .path_paths-en, .path_tripplebadge,
.all_php, .all_webdev, .all_database, .path_java-path, .all_juniorcourse,
.path_php
{ max-width: 100%; background-size: 100%; background-image: url('../images/sprites/path-page/path_sprite.gif'); }


.path_goalsscreenshot-bg { background-position: 0 0%; background-size: 100%; }
.path_goalsscreenshot-en { background-position: 0 14.811715%; background-size: 100%; }
.path_paths-bg { background-position: 0 31.424767%; background-size: 100%; }
.path_paths-en { background-position: 0 53.217932%; background-size: 100%; }
.path_tripplebadge { background-position: 0 69.120654%; background-size: 100%; }
.all_php { background-position: -9px 79.911611%; background-size: 230.675497%; }
.all_webdev { background-position: 0 84.871407%; background-size: 600%; }
.all_database { background-position: 0 88.653555%; background-size: 600%; }
.path_java-path { background-position: 0 92.435703%; background-size: 600%; }
.all_juniorcourse { background-position: 0 96.217852%; background-size: 600%; }
.path_php { background-position: 0 100%; background-size: 600%; }

/*.path_paths-new, .path_goalsscreenshot-bg, .path_goalsscreenshot-en, .path_paths-bg, .path_paths-en,
.path_tripplebadge, .path_mvc, .path_levels, .path_webdev, .path_database,
.path_java-path, .path_juniorcourse, .path_php
{ max-width: 100%; background-size: 100%; background-image: url('../images/sprites/path-page/paths_sprite.gif');  }

.path_paths-new { background-position: 0 0%; background-size: 70%; background-repeat:no-repeat;}
.path_goalsscreenshot-bg { background-position: 0 22.144522%; background-size: 152.5%; }
.path_goalsscreenshot-en { background-position: 0 32.604895%; background-size: 152.5%; }
.path_paths-bg { background-position: 0 44.855842%; background-size: 152.5%; }
.path_paths-en { background-position: 0 59.908953%; background-size: 152.5%; }
.path_tripplebadge { background-position: 0 70.834528%; background-size: 152.5%; }
.path_mvc { background-position: 0 78.561314%; background-size: 302.980132%; }
.path_levels { background-position: 0 85.248665%; background-size: 320.845188%; }
.path_webdev { background-position: 0 88.605534%; background-size: 915%; }
.path_database { background-position: 0 91.454151%; background-size: 915%; }
.path_java-path { background-position: 0 94.302767%; background-size: 915%; }
.path_juniorcourse { background-position: 0 97.151384%; background-size: 915%; }
.path_php { background-position: 0 100%; background-size: 915%; }*/
/*.path_paths_new, .path_paths-new, .path_content-courses, .path_goalsscreenshot-bg, .path_goalsscreenshot-en,
.path_paths-bg, .path_paths-en, .path_tripplebadge, .path_mvc, .path_paths2,
.path_webdev, .path_database, .path_java-path, .path_juniorcourse, .path_php
{ max-width: 100%; background-size: 100%; background-image: url('../images/sprites/path-page/paths_last.gif'); ; }

.path_paths-new { background-position: 0 0%; background-size: 100%; }
.path_paths_new { background-position: 0 16.590264%; background-size: 100.686813%; }
.path_content-courses { background-position: 0 28.262387%; background-size: 86.033058%; background-repeat:no-repeat;}
.path_goalsscreenshot-bg { background-position: 0 41.736156%; background-size: 152.5%; }
.path_goalsscreenshot-en { background-position: 0 49.412016%; background-size: 152.5%; }
.path_paths-bg { background-position: 0 58.810573%; background-size: 152.5%; }
.path_paths-en { background-position: 0 69.735683%; background-size: 152.5%; }
.path_tripplebadge { background-position: 0 77.387997%; background-size: 152.5%; }
.path_mvc { background-position: 0 83.040201%; background-size: 302.980132%; }
.path_paths2 { background-position: -72% 88.949695%; background-size: 135.164835%; }
.path_webdev { background-position: 0 91.482458%; background-size: 915%; }
.path_database { background-position: 0 93.611843%; background-size: 915%; }
.path_java-path { background-position: 0 95.741229%; background-size: 915%; }
.path_juniorcourse { background-position: 0 97.870614%; background-size: 915%; }
.path_php { background-position: 0 100%; background-size: 915%; }*/
/*.path_paths-new, .path_content-coursesbg, .path_paths-newbg, .path_paths-newen, .path_content-coursesen,
.path_levelsbg, .path_levelsen
{ max-width: 100%; background-size: 100%;background-image: url('../images/sprites/path-page/paths_last.png'); }

.path_paths-new { background-position: 0 10%; background-size: 200%; }
.path_content-coursesbg { background-position: -40px 25.85034%; background-size: 182.983871%; background-repeat:no-repeat;}
.path_paths-newbg { background-position: 32px 45.809027%; background-size: 200.648649%; }
.path_paths-newen { background-position: 0 59.278831%; background-size: 200.686813%; }
.path_content-coursesen { background-position: 0 80.477002%; background-size: 86.033058%; background-repeat:no-repeat;}
.path_levelsbg { background-position: 24px 91.578014%; background-size: 300.32491%; }
.path_levelsen { background-position: 0 100%; background-size: 300.164835%; }*/
.path_paths-new, .path_paths-, .path_content-coursesbg, .path_paths-newbg, .path_paths-newen,
.path_content-coursesen, .path_levelsbg, .path_levelsen
{ max-width: 100%; background-size: 100%; background-image:url('../images/sprites/path-page/paths_sprite_last.png'); }

.path_paths-new { background-position: 0 8%; background-size: 260%; }
.path_paths- { background-position: 0 22.263748%; background-size: 146.338798%; }
.path_content-coursesbg { background-position: 0 42.236354%; background-size: 179.973118%; }
.path_paths-newbg { background-position: 32px 56.856514%; background-size: 300.945946%; }
.path_paths-newen { background-position: 0 67.662306%; background-size: 200.928571%; }
.path_content-coursesen { background-position: 0 84.797028%; background-size: 184.435262%; }
.path_levelsbg { background-position: 0 93.243243%; background-size: 483.393502%; }
.path_levelsen { background-position: 0 100%; background-size: 490.47619%; }

.logo_parents{
     height:350px;
   /* background-size:100%;

    background-position: 0 5%;
    background:url('../images/parents.png');
    background-repeat:no-repeat;
    background-size:cover;*/
}
#footerCourseWrapper .footerCourseName{
        width:80%;
        word-wrap:break-word;
 }
 #hideGearMobile{
     width:100%;
     height:100%;
 }
 #hideGearMobile #threeGearAnim{
    background-image:url('../images/anGear.gif');
    background-size:100%;
    max-width:100%;
    width: 100%;
    height: 100%;
    padding-bottom: 20px;
    background-repeat: no-repeat;

 }

 #logo_cpp{
     height:60px;
 }

 .logo_java,.logo_c_cpp, .logo_csharp, .logo_javascript,.logo_ruby,.logo_python,.logo_html,.logo_css{
     width:120px;
     height:120px;
 }
 .logo_mysql{
     height:60px;
 }

 .home_logos{
     width:120px;
     height: 120px;
 }



/****** PARENTS SPRITE **/
.parents_webappnew2, .parents_summer, .parents_hand, .parents_consoles, .parents_youtube-cover1, 
.parents_gametocode
{ max-width: 100%; background-size: 100%; background-image: url('../images/sprites/parents/parents-sprite.png'); }
 
.parents_webappnew2 { background-position: -98px 1%; background-size: 175%; }
.parents_summer { background-position: 0 33.568075%; background-size: 117.001828%; }
.parents_hand { background-position: -69px 60.321821%; background-size: 206.333333%; }
.parents_consoles { background-position:-109px 80.201947%; background-size: 337.58299%; }
.parents_youtube-cover1 { background-position: 0 90.316073%; background-size: 274.678112%; }
.parents_gametocode { background-position: 0 100%; background-size: 460.666667%; }

.parentsShowMail{
    display:block;
}


/*.logo_vinternship { background-image: url('../images/stack_logos/small_webapps.png'); background-size:contain; background-repeat:no-repeat; width:228px;}*/
/*.logo_mysql{
    background-image: url('../images/stack_logos/sql.png');
    width:150px;
    height:150px;
    background-size:contain;
    background-repeat:no-repeat;
    z-index:1;
}
.logo_html{
    background-image: url('../images/stack_logos/html.png');
    width:120px;
    height:120px;
    background-size:contain;
    background-repeat:no-repeat;
    z-index:1;
}
.logo_css{
    background-image: url('../images/stack_logos/css.png');
    width:120px;
    height:120px;
    background-size:contain;
    background-repeat:no-repeat;
    z-index:1;
}*/
/*
.logopayment_card, .logopayment_checkout, .logopayment_econt, .logopayment_visamastercard, .logopayment_epay,
.logopayment_easypay
{ max-width: 100%; background-size: 100%; background-image: url('../images/sprites/all-page/payment_logos.gif'); }
.payment_logos{
    display:inline-block;
    width:90px;
    height: 30px;
    margin-right:10px;
}
.logopayment_card { background-position: 0 0%; background-size: 100%; }
.logopayment_checkout { background-position: 0 17.647059%; background-size: 100%;height:33px; }
.logopayment_econt { background-position: 0 44.285714%; background-size: 100%; height:28px;}
.logopayment_visamastercard { background-position: 0 66.41791%; background-size: 100%; }
.logopayment_epay { background-position: 0 84.429066%; background-size: 134.532374%; }
.logopayment_easypay { background-position: 0 100%; background-size: 439%; width: 30px;}*/


.logopayment_vnosna-belejka, .logopayment_platejno-narejdane, .logopayment_card, .logopayment_checkout, .logopayment_econt,
.logopayment_visamastercard, .logopayment_epay, .logopayment_easypay
{ max-width: 100%; background-size: 100%; background-image: url('../images/sprites/all-page/logo_payments.gif'); }
 .payment_logos{
    display:inline-block;
    width:90px;
    height: 30px;
    margin-right:10px;
}
.logopayment_vnosna-belejka { background-position: 0 0%; background-size: 100%; width:100%;height:100%;}
.logopayment_platejno-narejdane { background-position: 0 37.686567%; background-size: 113.777778%; width:100%;height:100%;}
.logopayment_card { background-position: 0 59.916782%; background-size: 136.898396%; }
.logopayment_checkout { background-position: 0 69.432314%; background-size: 136.898396%;  height:33px;}
.logopayment_econt { background-position: 0 78.089888%; background-size: 136.898396%; height:28px;}
.logopayment_visamastercard { background-position: 0 87.142857%; background-size: 136.898396%; }
.logopayment_epay { background-position: 0 93.758669%; background-size: 184.172662%; }
.logopayment_easypay { background-position: 0 100%; background-size: 640%; width:30px;}

#platejno_narejdane{
    background-image: url('../images/logos-payment/platejno_narejdane.png');
    width:100%;height:100%;
        background-size: contain;
    background-repeat: no-repeat;
    height:140px;
}
#vnosna_belejka{
     background-image: url('../images/logos-payment/vnosna-belejka.png');
     width:100%;height:100%;
         background-size: contain;
    background-repeat: no-repeat;
    height:140px;
}



#facebookBtns.fb-like{
        width:100%;
        overflow:hidden;

    }
#fbLikePlaceholder{
    width:50px;
    height:20px;
    background:linear-gradient(#4c69ba, #3b55a0);
    text-shadow: 0 -1px 0 #354c8c;
    color:#fff;
    font-size:10px;
    font-weight:bold;
    border-radius: 2px;
    padding:0 7px;
    box-sizing: border-box;
    line-height:20px;
    white-space:nowrap;
}
#fbLikePlaceholder span{
    margin-right:3px;
    font-size:13px;
}
#fbLikeButaforen{
    position:absolute;
    width:98%;
    height:100%;
}
#fbLikeButaforen span{
    margin-right: 5px;
}
#devices{

  /* width:100%;*/
   /*margin-top:-80px;*/
   /*margin-top:-200px;*/
   /*margin-left: -150px;*/
   /*margin-bottom:-100px;*/
   /*margin: -200px auto -150px -100px;*/
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 100%;
    overflow:hidden;
    z-index: 0;

   /*background-image: url('../images/devices-screen-new.png');

    background-position:left center;
    background-size:contain;*/
           /* margin-top: -120px;*/

}
.deviceContainer{
    color:#000;
    text-align: right;
    height:500px;
}
.devicesContainer{
    height:250px;
}
.devicesText{
    font-size:1.7rem;
    font-weight:300;
    text-align:center;
     position: absolute;
    right: 0;
    top: 0;
    width: 50%;
    height: 100%;

}
.accessTitle{
    font-size:3rem;
    color: #039ce6;
    text-align:center;
        margin-top: 20px;
    margin-bottom: 23px;
}
.accessDesc{
    padding-left:20px;
    text-align:center;
    font-style: italic;
    font-size:1.7rem;
}
.devicesText .play_now.start_in_section{
    font-size:1.4rem;
    margin-top:0;
}
.devicesShowCase{
  background:#F5F6F7;
}
.oneFourth{
    width:22%;
    display:inline-block;
}
.paths_level .oneFourth {
    width:24%;
    position:relative;
    padding: 15px 2px;

    box-sizing: border-box;
    /*box-shadow: 0 2px 24px rgba(0, 0, 0, 0.4);*/
    padding-bottom:50px;

}
.paths_level .oneFourth:hover{
  /*background:#039CE6;
  color:#fff;*/
}
.path_tile{
  background:#fff;
  box-shadow:0 2px 24px rgba(0,0,0,0.4);
  /*height:100%;*/
  position:relative;
  text-align: center;
  margin-left:-6px;
  overflow:hidden;
}
.path_tile:hover{
  transform: scale(1.01);
}
.path_tile_border_purple{
  border-bottom:6px solid #3DB0A1 ;
}
.path_tile_border_red{
  border-bottom:6px solid #FFA500;
}
.path_tile_border_orange{
  border-bottom:6px solid #3F51B5;
}
.path_tile_border_green{
  border-bottom:6px solid #c66;
}
.pathTileBorderC1{
  border-bottom:6px solid #3DB0A1;
}
.pathTileBorderC2{
  border-bottom:6px solid #FFA500;
}
.pathTileBorderC3{
  border-bottom:6px solid #3F51B5;
}
.pathTileBorderC4{
  border-bottom:6px solid #c66;
}
.pathTileBorderC1:hover{


}
.arrowPaths .fa-arrow-right{
    position: absolute;
    top:50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right:-41px;
    font-size:30px;
    color: #039ce6;
    font-weight: 700;
}
.path_number{
  position: relative;
  text-align: center;
  margin-bottom: 30px;
}
.path_number span{
    text-align:center;
    width: 25px;
    height:25px;
    margin:0 auto;
    font-size:20px;
    line-height:25px;
    padding:5px;
    border:1px dashed #FFA500;
    border-radius:50%;
    font-weight: lighter;
    background:#fff;
    display:inline-block;
}
.path_number_c1 span{
  background:#3DB0A1;
  color:#fff;
}
.path_number_c2 span{
  background:#c66;
  color:#fff;
}
.path_number_c3 span{
  background:#FFA500;
  color:#fff;
}
.path_number_c4 span{
  background:#3F51B5;
  color:#fff;
}

.oneFourth:first-child .path_number:after{
  content: "";
  height:1px;
  width:60%;
  position:absolute;
  border-bottom:1px dashed #FF9F00;
  top:50%;
  z-index: -1;
  left:50%;
}
.oneFourth:nth-child(4) .path_number:after{
  content: "";
  height:1px;
  width:60%;
  position:absolute;
  border-bottom:1px dashed #FF9F00;
  top:50%;
    z-index: -1;
  left:0;
}
.oneFourth:nth-child(2) .path_number:after,.oneFourth:nth-child(3) .path_number:after{
  content: "";
  height:1px;
  width:60%;
  position:absolute;
  border-bottom:1px dashed #FF9F00;
  top:50%;
    z-index: -1;
  left:-7px;
}
.oneFourth:nth-child(2) .path_number:before,.oneFourth:nth-child(3) .path_number:before{
  content: "";
  height:1px;
  width:60%;
  position:absolute;
  border-bottom:1px dashed #FF9F00;
  top:50%;
    z-index: -1;
  right:-32px;
}
.oneFourth .arrow_up {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;

  border-bottom: 15px solid #fff;
  position: absolute;
  top:0;
  top: -15px;
z-index: 5;
left:50%;
transform: translateX(-50%);

}
.oneFourth .icon{

  width: 150px;
  height: 150px;

  border-radius: 50%;
  background-color: #EDEDED;
 /* background-repeat: no-repeat;
  background-position: center;*/
  margin:0 auto;
  box-shadow: 0 3px 6px rgba(0,0,0,.12);
  border:4px solid #fff;
}
/*.path_tile_border_purple .icon{
  border:4px solid #3DB0A1;
}
.path_tile_border_red .icon{
  border:4px solid #FFA500;
}
.path_tile_border_orange .icon{
  border:4px solid #3F51B5;
}
.path_tile_border_green .icon{
  border:4px solid #c66;
}*/
.oneFourth .iconBlock{
   /* background-image: url(../images/levels/gameToCode.png);
    background-size:contain;*/
}
.oneFourth .iconGameBuilder{
  /*background-image:url('../images/pathLogos/hand.png');
  background-size:contain;*/

}
.oneFourth .iconWebApps{
 /* background-image:url('../images/levels/webappnew.png');
  background-size:cover;*/
}
.oneFourth .iconConsoles{
  /*background-image:url('../images/levels/consoles.png');
  background-size:contain;*/
}
.paths_level .level_1{
    margin-top: 20px;
    /*background-image: url('../images/levels/level1.png');
    background-repeat: no-repeat;
    background-size:contain;*/
    width:100%;
    height: 134px;


}
.paths_level .level_2{
    margin-top: 20px;
   /* height:129px;*/
   height:58%;
    /*background-image: url('../images/levels/consoles_new.gif');
    background-repeat: no-repeat;
    background-size:contain;
    width:100%;
    height: 129px;
    background-position:center;*/

}
.paths_level .level_3{
    margin-top: 20px;
    /*background-image: url('../images/levels/laravel_mvc.png');
    background-repeat: no-repeat;
    background-size:contain;*/
    width:100%;
    /*height: 159px;*/
    height:58%;


}
.paths_level .level_4{
    margin-top: 20px;
    /*height:154px;*/
    height:58%;
    /*background-image: url('../images/levels/webappnew.gif');
    background-repeat: no-repeat;
    background-size:contain;
     background-position:center;
    width:100%;
    height: 154px;

    background-position: -52% 81.192816%;*/
     /*background-size:160%;*/

}
.paths_level .single_path_label {
    width:100%;
    font-size: 25px;
    height:100px;
}

.path-kid{
    /*width:22%;*/
    margin-right: 25px;
}
.path-tie{
    /*width:24%;*/
}
.path-console{
    width:23%;
    height:148px;
    margin-right: 25px;
    margin-left:30px;
}
.path_mvc{
    width:23%;
    margin-right: 25px;
    margin-left:30px;
}
.path_console{
    margin-right: 25px;
    margin-left:22px;
    max-width:400px;
}
#nodeLogo{
       /* margin-left:21%;*//*2*//*7*/
       /* margin-left: 21%;*/
    height: 70px;
    width: 128px;
    display: inline-block;
    width:228px;

}
#djangoLogo,railsLogo{
       /* width:80px;*/
}
#tie{
    display:inline-block;
    width: 228px;
    height: 70px;
    /*background:url('../images/levels/vinternship.png');
    background-repeat:no-repeat;
    background-size:contain;*/
    /*margin-left:32%;*/
}
#coursesWrapper .levels .profilePathHeader:hover{
    cursor:pointer;
}
#cookieBanner {


    display: none;
    font-size: 14px;
    width: 99%;
    margin: 0px auto;
    padding: 12px;
    background-color: #ddd;
    border-bottom: solid 1px #ccc;
    position: fixed;
    bottom: 0px;
    left: 50%;
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 100;

}

#cookieBannerClose {
    float: right;
    font-weight: bold;
    padding: 4px;
}

#cookieBannerActions {
    text-align: center;
    padding-top: 4px;
}
.btn-got-it{
    padding: 2px 10px;
background-color: #f1d600;
cursor: pointer;
text-align: center;
display: inline;
width: 33%;
margin-left: 10px;

max-width: 120px;
border-radius:5px;
}
.fb_iframe_widget span{
    width:100% !important;

}
.contact-row .fa-map-marker{
    font-size:50px;
}
.contact-row .front{
    font-size: 2em;
    margin-left:25px;
}
.contact-row .back{
    font-size: 2em;
    margin-left:25px;
}
.contact-row p{
    font-size: 22px;
}
.sub_paths{
    cursor:pointer;
}
.subPathsNavWrapper{
    position:fixed;
    left:15px;
    z-index:5;
    width:200px;
     /*background:rgba(204, 204, 204, 0.39);*/
     font-weight:400;
     top:55%;/*30*/

}
#subPathsNavWrapperCourses{
    /*top:30%;*/
}
.sub_paths_nav{
    padding:10px;
    cursor:pointer;
    color:#039be6;
}
.actveLeftLink{
    font-weight:bold;
}
/*START NOW MODAL IN HOME PAGE*/
.overlayStartTrial{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index:30;
    /*position:fixed;*/
}
.error-footer-subcribe{
    color:red;
    font-weight: bold;
}
.overlayApproveTrial{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index:30;
    /*position:fixed;*/
}
.play_now{
    color:#000 !important;
    text-align:center;
    background:-webkit-linear-gradient(top,#fff 0,#ffe6a0 2%,#ffba00 100%) !important;
    border-radius: 15px;
    font-size:22px;
    width: 200px;
    height: 40px;
    line-height:40px;
    font-weight:bold;
}
.pl_now{
     color:#000 !important;
    text-align:center;
    background:-webkit-linear-gradient(top,#fff 0,#ffe6a0 2%,#ffba00 100%) !important;
    border-radius: 15px;
    font-size:22px;
    width: 200px;
    height: 40px;
    line-height:40px;
    font-weight:bold;
}
.play_now:hover{
    color:#fff !important;
    background:-webkit-linear-gradient(top,#fff 0,#89cced 2%,#41ade3 100%) !important;
}
#modalStartTrial{
    position:fixed;
    background:#fff;
    top:50%;
    width:90%;
    -ms-transform:  translate(-50%,-50%);
    -webkit-transform:  translate(-50%,-50%);
    transform: translate(-50%,-50%);
    left:50%;
    z-index:31;
    border-radius:5px;
    padding:25px;
    text-align: center;
    box-sizing:border-box;
    max-height: 95%;
    overflow-y:auto;
}
#modalStartTrial .gif_title{
    color:#fff !important;
}
#modalStartTrial .plan_popup_width{
    width:100%;
}
#modalStartTrial .paymentBtns{
    margin:5px;
    padding-left:60px;
    position:relative;
    text-align: left;
    border-radius: 0;
    display: none;
}
#modalStartTrial .paymentBtns:hover{
    -ms-transform: none;
    -webkit-transform: none;
    transform:none;
    box-shadow:0 2px 5px rgba(0, 0, 0, 0.4);
}
#modalStartTrial .secondStepPremium .button{
    display:block;
    box-shadow:none;
    border:1px solid #ccc;
}
#modalStartTrial .secondStepPremium #checkout img{
    width:100px;
}
#modalStartTrial .secondStepPremium .payment_label{
    font-size: 1.8rem;
}
.valign_center{
    -ms-transform:translateY(140%);
    -webkit-transform:translateY(140%);
    transform: translateY(140%);
}
.emailPopup .valign_center{
   /* -ms-transform: translateY(100%);
    -webkit-transform: translateY(100%);
    transforn:translateY(100%);*/
    -ms-transform:none;
    -webkit-transform: none;
    transform:none;
}
.bank_cash_desc{
    font-size:12px;
}
.bankCashRadioBtns{
    margin-bottom:20px;
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content:space-between;
    flex-wrap: wrap;
    -webkit-align-items: stretch;
    align-items: stretch;
}
#modalStartTrial .secondStepPremium .payments-check{
    position:absolute;
   /* left:15px;
    top:50%;
    -ms-tranform:translateY(-50%);
    -webkit-transform:translateY(-50%);
    transform:translateY(-50%);*/
    top:0;
    left:0;
    bottom:0;
    color:green;
    font-size:2.5rem;
    width:55px;
    background:#24b124;
    color:#Fff;
}
#modalStartTrial .secondStepPremium .payments-check:before{
    position:absolute;
    top:50%;
    /*left:50%;*/
    left:40%;
   /* top:50%;*/
    -ms-transform:translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}
#modalStartTrial #econt_logo{
    height:40px;
}
#modalStartTrial #bank_transfer{
        font-weight: bold;
    color: #039be6;
    text-transform: uppercase;

}
#modalStartTrial #select-type-payment{
    text-align: left;
}
#modalStartTrial .thirdStepPremium .payment_label{
    font-size:2.2rem;
    margin-top:0;
}
#modalStartTrial .thirdStepPremium .sub_payment_title{
   color:#039be6;
   text-align: left;
   font-size:1.2rem;
}
.inline-title{
    font-size:1.2rem;
    font-weight:bold;
   color:#039be6;
}
#modalStartTrial .thirdStepPremium .econtDataPayment   .econtPaymentPerson {
    font-size:1.2rem;
    font-weight:bold;
    color: #039be6;
}
#modalStartTrial .thirdStepPremium .econtDataPayment .econt_same_height{
    font-size:1.2rem;
    font-weight:bold;
    color:#039be6;
}
.econt-person-details{
    font-size:1.2rem;
    font-weight:bold;
    color:#039be6;
}
#modalStartTrial .modern_input{
    width:90%;
}
#modalStartTrial .thirdStepPremium .modern_input{
    width:100%;
}
#modalStartTrial .thirdStepPremium .placeholder{
    left:0;
}
#modalStartTrial .placeholder {
    left:30px;
}
#modalStartTrial  .payments_star:after{
    content:" *";
    color:red;
}
#modalStartTrial .pay_steps{
    display:inline-block;
    margin-right:15px;
    text-align: center;
    width: 30px;
    height: 30px;

    font-size: 23px;
    line-height: 30px;
    padding: 5px;
    border: 1px solid #000;
    border-radius: 50%;
    font-weight: lighter;
}
#modalStartTrial .paymentBtns .methodPayment{
    /*position:absolute;
    top:20px;*/
    text-transform: uppercase;
    font-weight:bold;
    color:#099;
    margin-left:15px;
    height: 30px;
    display: inline-block;
    vertical-align: top;
    line-height: 30px;

}
#modalStartTrial .select-type-person-radio{
    cursor:pointer;
    font-size:18px;
    padding:5px;

}
#modalStartTrial .radioBtnsPersonPayment{
    margin: 10px 0;
}
.getFree{


}
.getPremium{

}
.planBG{
    color: #fff ;
    background: -webkit-linear-gradient(top,#fff 0,#89cced 2%,#41ade3 100%) ;
    font-weight:bold;
}
.plan_popup_center{
    margin:0 auto;
}
.plan_popup{
    width:50%;
    padding: 10px;
    box-sizing: border-box;
}
#modalStartTrial .plan_popup_row{
    width:50%;
    margin:0 auto;
    /*padding:20px;*/
    padding:12px;
    box-sizing:border-box;
    text-align: left;
}
#modalStartTrial .office_deliveryFlex{
    text-align: left;
    /*display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-justify-content: space-between;
    -webkit-flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    -webkit-align-items: stretch;
    align-items: stretch;*/
}
.officesLeft{
    width:100%;
}
.officesRight{
    width:100%;
    overflow-y:scroll;
    height:250px;
}
.officesRight{
    padding:5px;
    box-sizing:border-box;
}
#modalStartTrial .single-office-econt{
     display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-justify-content: space-between;
    -webkit-flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    -webkit-align-items: stretch;
    align-items: stretch;
    margin-bottom:9px;
    font-size:16px;
    cursor:pointer;
    padding:7px;
}
#modalStartTrial .radioButtonBox{
     display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-justify-content: space-between;
    -webkit-flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    -webkit-align-items: stretch;
    align-items: stretch;
    margin-bottom:9px;
    font-size:16px;
    cursor:pointer;
    padding:7px;
    /*width:45%;*/
    width:98%;
}
#modalStartTrial .radioButtonBoxCash{
     display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-justify-content: space-between;
    -webkit-flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    -webkit-align-items: stretch;
    align-items: stretch;
    margin-bottom:9px;
    font-size:16px;
    cursor:pointer;
    padding:7px;
    width:45%;
}
#modalStartTrial .radioButtonBoxCash .leftOfficeRadio{
     width:5%;
}
#modalStartTrial .radioButtonBoxCash .rightOfficeRadio{
     width:87%;
     float:left
}
#modalStartTrial .radioButtonBox .leftOfficeRadio{
     width:5%;
}
#modalStartTrial .radioButtonBox .rightOfficeRadio{
     width:87%;
}
#modalStartTrial .single-office-econt .leftOfficeRadio{
    width:5%;
}
#modalStartTrial .single-office-econt .rightOfficeRadio{
    width:95%;
    padding-left: 15px;
    box-sizing: border-box;
}
#modalStartTrial .single-office-econt input[type="radio"]{
    outline: none;
    margin-left:0;
    margin-left: 8px;
    margin-top: 13px;
}
#modalStartTrial .radioButtonBox input[type="radio"]{
    outline: none;
    margin-left:0;
     margin-left: 8px;

}
#modalStartTrial .radioButtonBoxCash input[type="radio"]{
    outline: none;
    margin-left:0;
    margin-left: 8px;

}
#modalStartTrial .thirdStepPremium .econtRadioBtns input[type="radio"]{
    outline:none;
}
#modalStartTrial .thirdStepPremium .econtRadioBtns {
    text-align: left;
    padding:12px;
}
#modalStartTrial .thirdStepPremium .pay_steps{
    color:#039be6;
}
#modalStartTrial .thirdStepPremium .econtRadioBtns label{
    font-size:18px;
}
#modalStartTrial .thirdStepPremium .econtDataPayment .econt_same_height{
    height:400px
}
#modalStartTrial #payment-third-step-econt{
    margin-bottom: 10px;
}
#modalStartTrial .econt_delivery{
    margin-top:0;
    margin-bottom:10px;
}
#modalStartTrial #econt_buy_form{
    margin-top:15px;
}
#modalStartTrial .thirdStepPremium .bank_cash_row{
    width:50%;
}
#bank_cash_preview{
    display:in
}
#modalStartTrial .thirdStepPremium .bankCashTransfer{
   display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-justify-content: space-between;
    -webkit-flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    -webkit-align-items: stretch;
    align-items: stretch;
    margin-bottom:9px;
    font-size:16px;
    cursor:pointer;
}
#modalStartTrial  .termsPaymentPopup{
    color:rgba(0,0,0,.55);
    font-size:0.9rem;
    width:50%;
    margin:10px auto 0 auto;

}

#modalStartTrial  .termsPaymentPopup .termsLink{
    font-weight:bold;
    color:#039be6;
    font-style: italic;
    text-decoration: underline;
}
#modalStartTrial  .termsPaymentPopupOnFree{
     color:rgba(0,0,0,.55);
    font-size:0.9rem;
    margin:10px auto 0 auto;
}
#modalStartTrial  .termsPaymentPopupOnFree .termsLink{
    font-weight:bold;
    color:#039be6;
    font-style: italic;
    text-decoration: underline;
}
#modalStartTrial .secondStepPremium .reg_person .payment_label{
    margin-bottom: 0;
}
#modalStartTrial .secondStepPremium .reg_person h4{
    margin-top: 2px;
}
.office_delivery #econt-towns{
   width:300px;
    height: 45px;
    margin: 8px 0;
    border: 0;
    border: 1px solid black;
    background: #fff url('https://cdn3.iconfinder.com/data/icons/google-material-design-icons/48/ic_keyboard_arrow_down_48px-128.png') no-repeat;
    background-size: 20px;
    background-position:  right 10px center;
    font-family: 'Arial';
    padding:5px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    outline:none;
}
.reg_popup{
    width:50%;
    margin:0 auto;
}
#modalStartTrial .reg_popup{
    width:100%;
    margin:0 auto;
}
.closeModalTrial{
    position:absolute;
    top: 4px;
    right:15px;
    color:red;
    font-size:22px;
    cursor:pointer;
   /* background:#ccc;
    border-radius: 50%;
    padding:7px 10px;*/
}
.continuePlan{
    margin-top:20px;
    width:340px;
    /*background:-webkit-linear-gradient(top,#fff 0,#ffda73 2%,#ffba00 100%) !important;*/
    background:#ffba00 !important;
     color:#000 !important;
     font-weight: bold;
     margin-left:auto;
     margin-right:auto;
     height:45px;
     font-size:17px;
     line-height:33px;
     border-color: #ffb900;
     box-sizing: border-box;
    margin-bottom:20px;
}
.button.continuePlan{
    font-size:1rem;
    padding-left:50px;
}
.continuePlan:hover{
    -ms-transform: none;
    -webkit-transform: none;
    transform:none;
}
.choosePlan{
    font-size:2.3rem;
}
.bankCashRadioBtns .bank_cash_desc{
    margin-left:40px;
}
#modalStartTrial .pay_price{
    color: #099;
    font-size: 3rem;
    font-weight: 300;
}
#modalStartTrial .reg-title{
    font-size: 24px;
    color: #039be6;
    margin-bottom:10px;
}
#modalStartTrial .thirdStepPremium .econtDataPayment .econtRadioBtnsTypePerson{

}
#modalStartTrial .thirdStepPremium .econtDataPayment .radioBtnsPersonPayment{
    width:25%;
}
#modalStartTrial .thirdStepPremium .econtDataPayment   .radioButtonBox {
    width: 100%;
    box-sizing: border-box;
}
#modalStartTrial .thirdStepPremium .econtDataPayment   .econtPaymentPerson{
    text-align: left;
    padding:12px;
}
#modalStartTrial .thirdStepPremium #bankCashTransfer .radioBtnsPersonPayment{
    width:25%;
}
#modalStartTrial .thirdStepPremium #bankCashTransfer  .radioButtonBox {
    width: 100%;
    box-sizing: border-box;
}
#modalStartTrial .thirdStepPremium .step_left{
    text-align: left;
    font-size:1.2rem;
    font-weight:bold;
}
#modalStartTrial .thirdStepPremium #bankCashTransfer  .econtPaymentPerson{
    text-align: left;
    padding:15px;
}
#modalStartTrial .thirdStepPremium .inputWrap{
    text-align: left;
}
#modalStartTrial .feature1{

}
.payment_steps_econt .single_step{
    width:23%;
    display:inline-block;
    position:relative;
}
.payment_steps_bank .single_step{
    width:32%;
    display:inline-block;
    position:relative;
}
.payment_steps_cards .single_step{
    width:49%;
    display:inline-block;
    position:relative;
}
.payment_steps_global .single_step{
    width:32%;
    display:inline-block;
    position:relative;
}
#global_steps_pay{
    display:block;
}
.payment_step_number{
    border-radius: 50%;
    background-color:#d9d9d9;
    display:inline-block;
    width:30px;
    height:30px;
    position:relative;
}
.payment_step_number span{
    position:absolute;
    top:50%;
    left:50%;
    -ms-transform:translate(-50%,-50%);
    -webkit-transform:translate(-50%,-50%);
    transform:translate(-50%,-50%);
    font-weight:bold;
    color:#4a4a4a;
}
.activeStep .payment_step_number{
    background-color:#24b124;
}
.activeStep .payment_step_number span{
    color:#fff;
}
.finishedStep .payment_step_number span{
    color:#24b124;
}
.payment_steps_container  .single_step:after{
    content:"";
    width:20%;
    height:2px;
    background:#d9d9d9;
    position:absolute;
    top:50%;
    right:0;
    -ms-transform: translateY(-50%);
    -webkit-transform:translateY(-50%);
    transform:translateY(-50%);
}
.payment_steps_container  .single_step:before{
    content:"";
    width:30%;
    height:2px;
    background:#d9d9d9;
    position:absolute;
    top:50%;
    left:-10%;
    -ms-transform: translateY(-50%);
    -webkit-transform:translateY(-50%);
    transform:translateY(-50%);
}
.payment_steps_container  .single_step:first-child:before{
    background:transparent;
}
.payment_steps_container  .single_step:last-child:after{
    background:transparent;
}
.payment_steps_container {
    display:none;
}
.secondStepPremium .emailPopup{
    position:relative;
}
.secondStepPremium .emailPopup:before{
    content:"";
    background:#000;
    position:absolute;
    height:50%;
    top:60%;
    left:0;
    width:1px;
    -webkit-transform: translateY(-50%);
    -ms-transform:translateY(-50%);
    transform:translateY(-50%);
}
/*END START NOW MODAL*/
.hiddenCourses{
    height:0px;
     -webkit-transition:height, 0.5s linear;
    -moz-transition: height, 0.5s linear;
    -ms-transition: height, 0.5s linear;
    -o-transition: height, 0.5s linear;
    transition: height, 0.5s linear;
}
.hiddenCourses.openCourses{
    height:auto;
    -webkit-transition:height, 0.5s linear;
    -moz-transition: height, 0.5s linear;
    -ms-transition: height, 0.5s linear;
    -o-transition: height, 0.5s linear;
    transition: height, 0.5s linear;
}
.getPremium{

}
.bottomHeaderStripBG{
   height:400px;
   min-height: 400px;
   color:#fff;
   text-align: center;
   margin-bottom:-11px;
   width:100%;
   padding:0;

}
#bottomHeaderStripBG{
    /*background:url('../images/home-background.png') no-repeat;
    background-size:cover;
    background-position: center bottom;*/
    height:400px;
    min-height: 400px;
    -webkit-filter: blur(1px);
    -moz-filter: blur(1px);
    -o-filter: blur(1px);
    -ms-filter: blur(1px);
    filter: blur(1px);
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;

}
.titleBig{
    font-size:3rem;
        margin-top: -23px;
}
.subTitleSmall{
    font-size:2rem;

}
.overlayZindex{
    z-index:5;

}
.overlay-headerStrip{
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    background-color:rgba(0, 0, 0, 0.14);
    text-align:center;
    font-size:5rem;
    z-index:16;
    color:#505050;
    line-height:300px;

}
.overlayBanner{
  position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba(61, 176, 161, 0.3);
    text-align: center;
    font-size: 5rem;
    z-index: 1;
    color: #505050;
    overflow: hidden;
    margin-left: -10px;

}
.overlay-headerStripBottom{
    position:absolute;
    width:98%;
    height:400px;
    top:0;
    background-color:rgba(34, 170, 221, 0.08);
    z-index:1;


}
.bannerBlue .content {
    z-index: 2;
}
.overlay-headerStrip .repeatGif{
    cursor: pointer;
    position:absolute;
    top:50%;
    transform:translate(-50%,-50%);
}
#homeSubTitle{
        color: #039be6;
}
#typing-text{
        font-size:2.5rem;
}
.errorJuniorStructure {
    display:inline-block;
    position:absolute;
    top:100%;
    width:150%;
    left:0;
    transform: translateX(-20%);
    background:#fff;
    color:red;
    padding:10px;
    box-sizing:border-box;
    z-index:10;
    border-radius: 15px;
    -webkit-box-shadow:0 2px 5px rgba(0, 0, 0, 0.4);
    box-shadow:0 2px 5px rgba(0, 0, 0, 0.4);
    border-radius: 10px;

}
.close-error-junior{
   color:red;
     position: absolute;
    top: 0;
    right: 0;
}
.showHideNavigationMenu{
    position: fixed;
    left: 0px;
    z-index: 5;
    background:rgba(204, 204, 204, 0.39);
    padding:15px;
    cursor:pointer;
    display:none;
        top:48%;/*23*/
    font-size: 20px;
    font-weight: bold;
    width: 20px;
    display:none;
}
.showHideNavigationMenuHome{
    position: fixed;
    left: 0px;
    z-index: 5;
    background:rgba(204, 204, 204, 0.39);
    padding:15px;
    cursor:pointer;
        top:23%;
    font-size: 20px;
    font-weight: bold;
    width: 20px;
}
#panda_success{
    position:absolute;
    top:-70px;
    right:20%;
    width:128px;
    height:70px;
    background:url('../images/levels/panda-success.png');
    background-repeat:no-repeat;
    background-size:contain;
}
#lion_success{
    position:absolute;
    top:-70px;
    left:20%;
    width:128px;
    height:70px;
    background:url('../images/levels/lion-success.png');
    background-repeat:no-repeat;
    background-size:contain;
}
#boy_success{
    position:absolute;
    top:-70px;
    right:20%;
    width:128px;
    height:70px;
    background:url('../images/levels/boy-success.png');
    background-repeat:no-repeat;
    background-size:contain;
}
#girl_success{
    position:absolute;
    top:-70px;
    left:20%;
    width:128px;
    height:70px;
    background:url('../images/levels/girl-success.png');
    background-repeat:no-repeat;
    background-size:contain;
}
.buttonYellow{
    color:#fff;
    text-align:center;
    /*background:-webkit-linear-gradient(top,#fff 0,#ffe6a0 2%,#ffba00 100%) !important;*/
    background:#FFA500;
    border-radius: 20px;
    display:inline-block;
    font-weight:bold;
}
.buttonYellow:hover{
    color:#fff !important;
    background:-webkit-linear-gradient(top,#fff 0,#89cced 2%,#41ade3 100%) !important;
}
/* HEADER CAROUSEL */
.slider{
    overflow:hidden;
    width: 100%;
    padding:0;
    margin:0;

}
.slide{


}
.textUpperSlider{
    z-index:17;
    position:relative;
    overflow:hidden;


}
.textSlider{
    display:inline-block;
    vertical-align:middle;

}
.textLeft{
    width:70%;
}
.textRight{
    width:15%;
}
.textUpperSlider h1{
    font-size:3rem;
    font-weight:bold;

}
.textUpperSlider h3{
    font-size:2rem;
    font-weight:bold;

}
#headerSlide1{
    background-image:url(../images/levels/slide1.gif);
    background-repeat:no-repeat;
    background-size:contain;
    background-position: center center;
    height:300px;
    width:100%;
    z-index:10;
    overflow:hidden;
}
#headerSlide2{
    background-image:url(../images/levels/slide2.gif);
    background-repeat:no-repeat;
    background-size:contain;
    background-position: center center;
     height:300px;
    width:100%;
    z-index:10;
    overflow:hidden;

    /*height:500px;
    width:100%;*/
}
#headerSlideConsole{
    background-image:url('../images/levels/frameworks_new.gif');
    background-repeat:no-repeat;
    background-position:center center;
    height:300px;
    width:100%;
    z-index:10;
    overflow:hidden;
}
.img_gif{
    margin:0 auto;
}
.slideCenter{
    text-align: center;
}
.justifyFlexHeader{
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-justify-content: space-between;
    -webkit-flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    -webkit-align-items: stretch;
    align-items: center;

}

.justifyFlexCenterBoth{
     display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-justify-content: space-between;
    -webkit-flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    -webkit-align-items: stretch;
    align-items: stretch;
    justify-content:center;
}
.textUpperSliderRight{
    width:49%;
    display:inline-block;
}
.activeSlide{
    display:block;
}
.inactiveSlide{
    display:none;
}
.carousel-nav{
    position:absolute;
    left:50%;
    bottom:0px;/* bottom:25px;*/
    transform: translateX(-50%);
    z-index:17;
}
.dot{
    display: inline-block;
    background:#ccc;
    width:15px;
    height:15px;
    border-radius: 50%;
    cursor:pointer;
}
.activeDot{
    background:#000;
}
.slides .play_now{
    /*position:absolute;
    bottom: -150%;
    right: 5%;*/
    margin-top:50px;
}
.arrow_controls{
    position:absolute;
    top:50%;
    transform:-o-transformY(-50%);
    transform:-moz-transformY(-50%);
    transform: translateY(-50%);
    font-size:5rem;
    cursor:pointer;
    z-index:17;
}
.prev_arrow{
    left:5%;
    cursor:pointer;
}
.next_arrow{
    right:5%;
    cursor:pointer;
    width: 70px;
    padding: 10px;
    box-sizing: border-box;
}
.viewFullProfile{
    text-align: center;
    margin-top:40px;
    font-size:2rem;
    cursor: pointer;
    color:#039be6;
}
.fullProfileText{
    display: block;
}
.userFirstEnter{
    text-align: center;
}
.userFirstEnter #continueButton{
    margin-top:20px;
}


/* =========== COURSE STRUCTURE SPRITE IMAGES ===========*/
.logo_course_structure{
    width:100%;
    height:100%;
}
.project_image{
    width: 160px;
    height:160px;
    padding-top:40px;

}
.logo_structure_course191_3, .logo_structure_project190, .logo_structure_project189, .logo_structure_course191_7, .logo_structure_course192_4,
.logo_structure_course191_4, .logo_structure_course191_6, .logo_structure_course191_1, .logo_structure_project192, .logo_structure_course192_5,
.logo_structure_course192_6, .logo_structure_project122, .logo_structure_course192_3, .logo_structure_course192_2, .logo_structure_course192_7,
.logo_structure_coursecourseFirst_7, .logo_structure_course191_5, .logo_structure_coursecourseFirst_8, .logo_structure_course189_8, .logo_structure_project191,
.logo_structure_coursecourseFirst_4, .logo_structure_course189_6, .logo_structure_course190_7, .logo_structure_course190_1, .logo_structure_coursecourseFirst_6,
.logo_structure_course191_2, .logo_structure_coursecourseFirst_5, .logo_structure_course192_1, .logo_structure_course189_1, .logo_structure_course189_3,
.logo_structure_course189_2, .logo_structure_course189_10, .logo_structure_course190_9, .logo_structure_course190_10, .logo_structure_course189_9,
.logo_structure_course190_2, .logo_structure_course189_7, .logo_structure_coursecourseFirst_2, .logo_structure_course190_8, .logo_structure_coursecourseFirst_1,
.logo_structure_coursecourseFirst_9, .logo_structure_course190_4, .logo_structure_course189_4, .logo_structure_coursecourseFirst_3, .logo_structure_coursecourseFirst_10,
.logo_structure_course190_5, .logo_structure_course189_5, .logo_structure_course190_6, .logo_structure_course190_3, .logo_structure_coin
{ max-width: 100%; background-size: 100%;  background-image: url('../images/sprites/course-page/course-structure-sprite.gif');}

.logo_structure_course191_3 { background-position: 0 0%; background-size: 100%; }
.logo_structure_project190 { background-position: 0 3.41833%; background-size: 117.647059%; }
.logo_structure_project189 { background-position: 0 9.087598%; background-size: 146.341463%; }
.logo_structure_course191_7 { background-position: 0 13.151765%; background-size: 156.25%; }
.logo_structure_course192_4 { background-position: 0 15.947025%; background-size: 159.574468%; }
.logo_structure_course191_4 { background-position: 0 19.594837%; background-size: 166.666667%; }
.logo_structure_course191_6 { background-position: 0 22.228193%; background-size: 178.571429%; }
.logo_structure_course191_1 { background-position: 0 24.639487%; background-size: 180.722892%; }
.logo_structure_project192 { background-position: 0 27.086357%; background-size: 181.818182%; }
.logo_structure_course192_5 { background-position: 0 30.400712%; background-size: 182.926829%; }
.logo_structure_course192_6 { background-position: 0 32.639138%; background-size: 192.307692%; }
.logo_structure_project122 {     background-position: 17px 36.002189%;
    background-size: 154.805195%; }
.logo_structure_course192_3 { background-position: 0 39.53281%; background-size: 212.765957%; }
.logo_structure_course192_2 { background-position: 0 41.496357%; background-size: 260.869565%; }
.logo_structure_course192_7 { background-position: 0 43.526466%; background-size: 265.486726%; }
.logo_structure_coursecourseFirst_7 { background-position: 0 45.539989%; background-size: 267.857143%; }
.logo_structure_course191_5 { background-position: 0 47.700323%; background-size: 285.714286%; }
.logo_structure_coursecourseFirst_8 { background-position: 0 49.778957%; background-size: 288.461538%; }
.logo_structure_course189_8 { background-position: 0 51.388889%; background-size: 291.262136%; }
.logo_structure_project191 { background-position: 0 53.404635%; background-size: 294.117647%; }
.logo_structure_coursecourseFirst_4 { background-position: 0 55.38352%; background-size: 297.029703%; }
.logo_structure_course189_6 { background-position: 0 57.441113%; background-size: 300%; }
.logo_structure_course190_7 { background-position: 0 59.61813%; background-size: 312.5%; }
.logo_structure_course190_1 { background-position: 0 61.641153%; background-size: 315.789474%; }
.logo_structure_coursecourseFirst_6 { background-position: 0 63.4168%; background-size: 333.333333%; }
.logo_structure_course191_2 { background-position: 0 65.046132%; background-size: 344.827586%; }
.logo_structure_coursecourseFirst_5 { background-position: 0 66.868435%; background-size: 352.941176%; }
.logo_structure_course192_1 { background-position: 0 68.370777%; background-size: 365.853659%; }
.logo_structure_course189_1 { background-position: 0 69.785575%; background-size: 370.37037%; }
.logo_structure_course189_3 { background-position: 0 71.168142%; background-size: 379.746835%; }
.logo_structure_course189_2 { background-position: 0 72.978269%; background-size: 384.615385%; }
.logo_structure_course189_10 { background-position: 0 74.78678%; background-size: 389.61039%; }
.logo_structure_course190_9 { background-position: 0 76.095406%; background-size: 389.61039%; }
.logo_structure_course190_10 { background-position: 0 77.659386%; background-size: 394.736842%; }
.logo_structure_course189_9 { background-position: 0 79.304224%; background-size: 400%; }
.logo_structure_course190_2 { background-position: 0 80.693683%; background-size: 400%; }
.logo_structure_course189_7 { background-position: 0 82.370713%; background-size: 405.405405%; }
.logo_structure_coursecourseFirst_2 { background-position: 0 83.81728%; background-size: 410.958904%; }
.logo_structure_course190_8 { background-position: 0 85.273791%; background-size: 416.666667%; }
.logo_structure_coursecourseFirst_1 { background-position: 0 86.944938%; background-size: 468.75%; }
.logo_structure_coursecourseFirst_9 { background-position: 0 88.336283%; background-size: 476.190476%; }
.logo_structure_course190_4 { background-position: 0 90.063989%; background-size: 483.870968%; }
.logo_structure_course189_4 { background-position: 0 91.499911%; background-size: 526.315789%; }
.logo_structure_coursecourseFirst_3 { background-position: 0 92.554693%; background-size: 545.454545%; }
.logo_structure_coursecourseFirst_10 { background-position: 0 93.959256%; background-size: 566.037736%; }
.logo_structure_course190_5 { background-position: 0 95.614347%; background-size: 588.235294%; }
.logo_structure_course189_5 { background-position: 0 96.819226%; background-size: 600%; }
.logo_structure_course190_6 { background-position: 0 98.141922%; background-size: 681.818182%; }
.logo_structure_course190_3 { background-position: 0 99.416342%; background-size: 714.285714%; }
.logo_structure_coin {
    background-position: 0 100%;
    background-size: 1071.428571%;
    width: 24px;
    height: 24px;
    display: inline-block;
    -mos-transform:translateY(30%);
    -webkit-transform: translateY(30%);
    transform: translateY(30%);
    margin-top:10px;
}

/*===== SCHOOL TEACHER STYLES ========*/
.btn-add-group{
    padding:15px;
    color:#000;
    border:1px solid #ccc;
    display:inline-block;
    cursor:pointer;
}
.single-group{
    padding:15px;
    /*display:inline-block;*/
}
.group-name{
    font-weight:bold;

}
.add-participants{
    font-size:12px;
    float:right;
    cursor:pointer;
}
.groupContent{
    display:none;
}
.group_options{
    float:right;
    cursor:pointer;
}
.courseSchoolCreate{
    width:100%;
    position:static;
}
.material_input{
    width:100%;
    border-radius:10px;
    padding:6px 12px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    box-sizing: border-box;
    border: 1px solid #ccc;
    margin-bottom:10px;
    outline:none;
}
.material_input_ex{
    border-radius: 10px;
    padding:10px 12px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    box-sizing: border-box;
    border: 1px solid #ccc;
    margin-bottom:10px;
    outline:none;
}
.material_input:focus,.material_input_ex:focus{
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);

}
.material_label{
    display: block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
}
.schoolCourseBtnCreate{
    border:none;
    border-radius: 20px;
    float:right;
    background:#FFA500;
    outline:none;

}
#advance-panel-btn{
    position: absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;
    width:100%;
    opacity: 0;
    cursor:pointer;
    height:100%;
}
.advanced_options{
    padding:15px;
    box-sizing: border-box;
    font-weight: bold;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
        transition: box-shadow 0.3s;
        text-transform: uppercase;
        border-radius: 5px;
}
.icons-edit-school{
    margin-right:10px;
    font-size:2rem !important;
}
.commands-exercise{
    padding:5px 0;
}
.material_btn{
    border-radius:20px;
    background:#FFA500;
    color:#fff;
    padding:5px 10px;
    outline: none;
    border:none;
    cursor:pointer;
    display:inline-block;
}
.advance-panel{
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    border-radius: 3px;
    padding:0 10px;
    box-sizing:border-box;
}
.advance-panel > h3{
    margin-top:0;
    padding-top:10px;
}
.testFunc_pic{
    width:20px;
    height:20px;
}
.testFunc_section input[type="number"]{
    display: inline-block;
    width:70px;
}
.testFunc_section .col-4{
    width:24%;
    box-sizing: border-box;
    display:inline-block;
}
.school-btns{
    margin-top:20px;
}
.school_meat, .school_ruby, .school_coin, .school_apple
{ max-width: 100%; background-size: 100%; background-image: url('../images/sprites/school/school_sprite.gif'); }

.school_meat { background-position: 0 0%; background-size: 100%; }
.school_ruby { background-position: 0 33.707865%; background-size: 100%; }
.school_coin { background-position: 0 72.043011%; background-size: 114.285714%; }
.school_apple { background-position: 0 100%; background-size: 160%; }
.exercises_school{
    text-align: center;
    text-transform: uppercase;
}
.change_course_state ul{
    padding:0;
    margin:0;
}
.change_course_state ul li{
    position: relative;
    list-style: none;
    overflow: hidden;
    display: inline-block;
}
.change_course_state ul li input[type=radio]{
  position: absolute;
  visibility: hidden;
}

.change_course_state ul li label{
  display: block;
  position: relative;
  font-weight: 300;
  padding: 25px 25px 25px 80px;
  margin: 10px auto;
  height: 30px;
  z-index: 9;
  cursor: pointer;
  -webkit-transition: all 0.25s linear;
  display:inline-block;
}

.change_course_state ul li:hover label{
	/*color: #FFFFFF;*/
}

.change_course_state ul li .check-course-state{
  display: block;
  position: absolute;
  border: 5px solid #AAAAAA;
  border-radius: 100%;
  height: 15px;
  width: 15px;
  top: 30px;
  left: 20px;
	z-index: 5;
	transition: border .25s linear;
	-webkit-transition: border .25s linear;
}

.change_course_state ul li:hover .check-course-state {
  /*border: 5px solid #FFFFFF;*/
}

.change_course_state ul li .check-course-state::before {
  display: block;
  position: absolute;
	content: '';
  border-radius: 100%;
  height: 7px;
  width: 7px;
  top: 4px;
	left: 4px;
  margin: auto;
	transition: background 0.25s linear;
	-webkit-transition: background 0.25s linear;
}

.change_course_state input[type=radio]:checked ~ .check-course-state {
  border: 5px solid #039CE6;
}

.change_course_state input[type=radio]:checked ~ .check-course-state::before{
  background: #039CE6;
}

.change_course_state input[type=radio]:checked ~ label{
  color: #039CE6;
}
.course_drag_and_drop select{
    padding:10px;
    border:1px solid #ccc;
    outline:none;
    border-radius: 5px;
}
.section_divider{
    padding:10px 0;
    border-bottom: 2px dashed #039CE6;

}
.course_groups{
    float:right;
    display:inline-block;
    cursor: pointer;
    border-radius: 20px;
    background:#FFA500;
    padding:5px 10px;
    color:#fff !important;
    text-transform: uppercase;
    font-weight:bold;
        transform: translateY(100%);
}
.course_groups:after{

}
.course_groups .fa{
    margin-right:15px;
}
.group-name .fa-users{
    margin-right: 15px;
}
.groups-overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 30;
    display:none;
}
.remove-group-modal{
    display:none;
    background:#fff;
    position:fixed;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    z-index: 31;
    border-radius: 5px;
    box-sizing: border-box;

}
.remove-group-modal h2{
    padding:15px;
    border-bottom:1px solid #ccc;
}
.remove_group_btns{
    float:right;
}
.btn_remove{
   display:inline-block;
   padding:5px;
   border-radius:5px;
   cursor: pointer;
}
.container-remove{
    padding:15px;
}
.clearfix:after{
    content:"";
    clear:both;
    display:table;
}
.remove_group{
    background:#c9302c;
    color:#fff;

}
.cancel_group{
    color: #333;
    background-color: #fff;
    border:1px solid #ccc;
}
.content .courseTile.oneThird.school-template{
    display:none !important;
}
.templateContainer{
    display:inline-block;
    width: 32%;
}
.singleTemplate{
    padding:100px 0 0 0;
    background:#ccc;
    position: relative;
    cursor: pointer;
    border-radius: 5px;
    height:100px;
}
.singleTemplate:hover {

}
.singleTemplate h2{
    background:rgba(86, 86, 86, 0.8);
    color:#fff;
    border-bottom-left-radius: 5px;
     border-bottom-right-radius: 5px;
     position: absolute;
    bottom: 0;
    width:100%;
    margin-bottom:0;
    padding:5px;
    box-sizing: border-box;
    z-index:30;
    display: inline-block;
}
.singleTemplate .plus-animation{
    color:#fff;
    display:none ;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    font-size:3rem ;
    z-index:30;
}

.template1{
    background:url('../images/secondGameBG.png');
    background-size:cover;
    background-repeat:no-repeat;
}
.template2{
    background:url('../images/summer.png');
    background-size:cover;
    background-repeat:no-repeat;

}
.tileOverlay{
    display: none;
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background:rgba(86, 86, 86, 0.8);
}
.use_btn{
    float:right;
    padding:5px;
    background:#FFA500;
    color:#fff;
    position: relative;
    z-index:30;
    font-size:12px;
    display:none;
}
.step_buttons{
    float:right;
}
.step_buttons .material_btn{
    text-transform: uppercase;
    font-weight:bold;
}
.edit-exercise-form{
    display:none;
}
.runtimeModal{
    position: fixed;
    display:none;
    top:10%;
    z-index:36;
    left:10%;
    right:10%;
    bottom:10%;
    overflow:hidden;
    border-radius: 5px;
}
.runtimeOverlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    display:none;
     z-index:35;
}
.showCanvas{
    background:#3DB0A1;
    color:#fff;
    padding:5px;
    cursor:pointer;
}
.showRedactor{
    background:#039CE6;
    color:#fff;
    padding:5px;
    cursor:pointer;
}
.exercise-redactor{
    padding:5px;

}
.closeCanvasPopup{
    position: absolute;
    color:red;
    right:5px;
    top:0px;
    cursor: pointer;
    font-size:1.5rem !important;
}
#save_level{

}
#clear_level{


}
.settings_level{
     position:absolute;
     bottom:0;
     margin: 15px;
}
.skip_step{
    color:#fff !important;
    float:right;
}
.steps_creating{
    display: inline-block;
    width:22%;
    position:relative;
}
.steps_number{
    padding:10px;
    background:#036291;
    border-radius: 50%;
    color:#ccc;
    width:25px;
    height:25px;
    display:inline-block;
    line-height: 25px;
    font-weight: bold;
}
.step_arrow{
    display:inline-block;
    height:5px;
    width:100%;
    background:#036291;
    margin-left:-10px;
    position:absolute;
    top:50%;
    z-index:-1;
}
.steps_creating:last-child .step_arrow{
    width:0;
}
.activeStep .steps_number{
    background:#FFA500;
    color:#fff;
}
.creating_course_steps{
    text-align: center;
    margin-bottom:50px;
}
.finisehdStep .step_arrow{
    background:#039CE6;
}
.steps_creating .fa-check{
    display:none;
}
.finisehdStep .fa-check{
    display:none;
    position:absolute;
    background:#3DB0A1;
    color:#fff;
    border-radius: 50%;
    padding:2px;
    transform: translateX(-100%);
    top:-5px;
}
.finisehdStep .steps_number{
    background:#048cce;
    color:#fff;
}
.loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    display:none;
    margin: 10px auto;
}
.loader_send_mail{
    border: 6px solid #f3f3f3; /* Light grey */
    border-top: 6px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
    display:none;
    margin: 10px auto;
}
.loader-canvas {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    display:none;
    position: absolute;
    top:40%;
    left:40%;
    margin: 10px auto;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
.step_desc{
    color:#ccc;
    position: absolute;
    bottom: -64%;
    left: 0%;
    width: 100%;
}
.mobileAskForAppIcon{
    position:fixed;
    width:90%;
    top:50%;
    left:50%;
    margin:0 auto;
    background: #fff;
    -ms-transform:translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform:translate(-50%,-50%);
    z-index:21;
    padding:15px;
    box-sizing:border-box;
}
.browser-menu-click{
    position:fixed;
    width:90%;
    left:50%;
    margin:0 auto;
    background: #fff;
    -ms-transform:translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform:translate(-50%,-50%);
    z-index:21;
    padding:15px;
    box-sizing:border-box;
}
.add-app-title{
    font-size:1.2rem;
    font-weight:bold;
    text-transform:uppercase;
    text-align: center;
}
.sureApp{
    text-align:center;
}
.no-app{
    display:inline-block;
    padding:10px;
    background:#FF9F00;
    color:#fff;
    font-weight:bold;
}
.yes-app{
    display:inline-block;
    padding:10px;
    background:#FF9F00;
    color:#fff;
    font-weight:bold;
}
.overlayAddApp{
     position:fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background:rgb(62, 62, 62) ;
    opacity: 0.5;
    z-index:20;
}
/*====== END SCHOOL TECHER STYLES ======*/

/*======= CUSTOMER SERVICE ==========*/
.customer_service_overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 31;
}
.leavePage{
    position:fixed;
    background:#fff;
    padding:10px;
    top:50%;
    left:50%;
    -ms-transform:translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform:translate(-50%,-50%);
    z-index:31;
    text-align: center;
    border-radius: 5px;
    width:50%;
}
.continuePaymentModal{
     position:fixed;
    background:#fff;
    padding:10px;
    top:50%;
    left:50%;
    -ms-transform:translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform:translate(-50%,-50%);
    z-index:32;
    text-align: center;
    border-radius: 5px;
    width:50%;
    overflow:hidden;
}
.close-payment-reminders{
    position:absolute;
    top:0;
    right:0;
    color:red;
}
.single_payment_problem{
    padding:15px;
    text-align:left;
    width:70%;
    margin:10px auto;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    cursor:pointer;
}
.single_payment_problem .check_circle{
    width:25px;
    height:25px;
    border-radius: 50%;
    border:2px solid #191D26;
    position:relative;
}
.problem_selected{
    background:green;
    color:#fff;
}
.single_payment_problem .check_circle .fa-check{
    position:absolute;
    top:50%;
    left:50%;
    -ms-transform:translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform:translate(-50%,-50%);
}
.single_payment_problem .check_problem{
    display:inline-block;
    vertical-align:bottom;
}
.single_payment_problem .problem_title{
    display:inline-block;
}
.leavePage h1{
    color:#575757;
}
.leavePage .ok_leave_page{
    background:#FFA500;
    color:#fff;
    border-radius: 5px;
    padding:10px 32px;
    cursor: pointer;
    display: inline-block;
    margin:20px 0 5px 0;
}
.leavePage .video_container{
    width:60%;
    background:url('../images/levels/game2N.gif');
    height:200px;
    background-size:contain;
    background-repeat:no-repeat;
   margin:0 auto;
}
.ok_leave_page{
    background:#FFA500;
    border-radius: 5px;
    padding:10px 32px;
    cursor: pointer;
    display: inline-block;
    margin:20px 0 5px 0;
    color:#fff;
}
.customer_container{

}
.error_home{
    display:none;
    color:red;
    font-weight: bold;
}
.subscrube-approve-msg{
    color:green;
    font-weight: bold;
}
.offerText{
    font-size:1.5rem;
}
.discount-period,.discount-price{
    display:inline-block;
    width:25%;
    box-sizing: border-box;
    font-size:2rem;
    background:#F0F0F0;
    height:auto;
    border-radius: 15px;
    padding:20px 0;

}
.discount_text{
    display:block;
    padding:20px 0;
    color:#fff;
    background:#039CE6;
    font-size:4rem;
}
.disocount-icon{
    font-size: 3rem;
    color: #039CE6;

}
.discount_header{
    padding-bottom:20px;
    display: block;
}
.label-dot{
    width:15px;
    height:15px;
    background:#ddd;
    border-radius: 50%;
    display:block;
    text-align:center;
    margin:0 auto;
}
.corner-ribbon{
     width: 200px;
  background: #e43;
  position: absolute;
  top: 25px;
  left: -50px;
  text-align: center;
  line-height: 50px;
  letter-spacing: 1px;
  color: #f0f0f0;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.top-left-ribbon{
    top: 25px;
  left: -50px;
  transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.top-right-ribbon{
    top: 25px;
  right: -50px;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  left:auto;
  font-size:0.8rem;
}
.ribbon-red{
    background:rgba(238, 68, 51, 0.81);
}
.ribbon-blue{
    background:#039ce6;
}
.videoFrame{
    background:url('../images/levels/secondGif.png');
    background-size:contain;
    background-repeat:no-repeat;
    display: inline-block;
    width: 100%;
    height: 200px;
}
.video_container .circles{
    text-align:left;
}
#playExVideo{
    width:40px;
    height:40px;
    border-radius: 50%;
    padding:15px;
    background:#fff;
}
#playExVideo .fa-play{
    color:#ccc;
    position:absolute;
    top:50%;
    left:50%;
    -ms-transform: translate(-36%,-50%);
    -webkit-transform: translate(-36%,-50%);
    transform:translate(-36%,-50%);
}
.play_text{
    color:#000;
    -ms-transform: translate(-50%,80%);
    -webkit-transform: translate(-50%,80%);
    transform: translate(-50%,80%);
}
.feedback-send{
    background:#FFA500;
    border-radius: 5px;
    padding:10px 32px;
    cursor: pointer;
    display: inline-block;
    margin:20px 0 5px 0;
    color:#fff;
}
.how_to_start_desc{
    font-size:1.2rem;
    text-align: center;
    display:block;
}
.register_now{
    display: inline-block;
    padding:15px;
    color:#fff !important;
    background:#FF9F00;
    text-align: center;
    margin-top:40px;
}
.pathsTitle{
    margin:30px 0;
    /*font-weight: bold;*/
}
.what-learn{
    font-weight:bold;
}
.course_path{
    font-weight: bold;
}
.clearfix{
    content:"";
    clear:both;
    display:table;
}
.method-title{
    text-align: center;
    font-size:2rem;
}
.method-sub-title{
    text-align:center;
    /*font-size:1.5rem;*/
    font-size:1.2rem;
    margin-bottom:20px;
}
.method-levels{
    text-align: center;
    font-size:1.2rem;
    margin-bottom:20px;
}
.free-courses{
    color:#039CE6;
    cursor: pointer;
}
.single_course_in_structure{
    display:inline-block;
    width:19%;
    box-sizing: border-box;
    vertical-align: top;
    text-align: center;
    margin-bottom:25px;
    box-shadow: 0 6px 20px #AFAFAF;
    position:relative;
}
.course_title{
    display:block;
    background:#fff;
    color:#039be6;
    padding:10px 0;
    font-size:1rem;
    font-weight:bold;
    height:80px;
    position:relative;

}
.single_course_in_structure .logo_default{
    height:120px;
    width:120px;
    display:inline-block;
}
.course_title span{
    width:100%;
    position:absolute;
    top:50%;
    left:50%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform:translate(-50%,-50%);
}
.see_more_courses{
    display:block;
    background:#FFA500;
    color:#Fff !important;
    padding:5px;
}
.logo_course{
    height:100px;
    width:100%;
    display:inline-block;
    overflow:hidden;
}
.logo_course span{
    display:block;
    margin:0 auto;
    height:100px !important;
}
.logo_course .pathCoursePic{
    width:auto;
    height:auto;
     max-width: 100%;
    max-height: 100%
}
.course_box_scale{
    -ms-transform: scale(1); /* IE 9 */
    -webkit-transform: scale(1); /* Safari */
    transform: scale(1); /* Standard syntax */
}
.current_course_arrow{
    position:absolute;
    top:-21%;
    font-size:2rem;
    left:50%;
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}
.course_in_path{
    text-align: center;
    margin-bottom:50px;
    font-size:2rem;
}
.underPanelLink{
    font-size:1.2rem;
}
.levels_desc{
    /*background:#365899;*/

}
.levels-background{
    /* background:#365899;*/
}
.levels-box{
    background:#fff;
    width:100%;
    margin:0 auto;
    text-align: center;
}
.single_level_box{
   background:#365899;
   width:23%;
   box-sizing:border-box;
   padding:5px;
   color:#fff;
   border-radius: 5px;
   position:relative;
   font-size:1.5rem;
   font-weight:bold;
   text-align: center;
   height:100px;
}
.single_level_box span{
    position:absolute;
    top:50%;
    left:50%;
    -ms-transform:translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}
.tooltip-levels{
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 1s;
}
.single_level_box::before{
    content: "";
    position: absolute;
    top: 50%;
    left: 5px;
    margin-left: -5px;
    border-width: 15px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
    -webkit-transform: translateY(-50%) rotate(-90deg);
    -ms-transform: translateY(-50%) rotate(-90deg);
    transform:translateY(-50%) rotate(-90deg);
}
.single_level_box::after{
    content: "";
    position: absolute;
    top: 50%;
    right: -29px;
    margin-left: -5px;
    border-width: 15px;
    border-style: solid;
    border-color: #365899 transparent transparent transparent;
    -webkit-transform: translateY(-50%) rotate(-90deg);
    -ms-transform: translateY(-50%) rotate(-90deg);
    transform:translateY(-50%) rotate(-90deg);
}
.levels_desc{
    margin:30px 0;
}
.levels-box{
    margin-bottom:20px;
}
.levels-box h2{
    font-weight: normal;
    font-size:3.2rem;/*2*/

}
.levels-box div{
    /*font-size:1.1rem;*/
    font-size:1.2rem;
}
.path_levels_pic{
    padding-bottom:50%;
}
.parents_box{
    background:#365899;
    color:#fff;
    display:inline-block;
     padding:60px 20px 20px 20px;
    box-sizing:border-box;
    width: 100%;
    position:relative;
   text-align: center;
}
.boxes-left{
    display:inline-block;
    width:25%;
    box-sizing:border-box;
    vertical-align: top;
    padding:15px;
}
.boxes-right{
    display: inline-block;
    width:74%;
    box-sizing:border-box;
    text-align: center;
    padding-left: 10px;
    padding:15px;
    /*position:absolute;
    top:50%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    left:50%;*/
}
.adaptive-courses-desc{
    color:#666;
    font-size:1.2rem;
}
.adaptive-courses-title{
    font-size:1.5rem;
    color:#3e3e3e;
}
.second_row_parents{
    margin-top:80px;

}

.second_row_parents .parents_box{
    width:25%;
    box-sizing:border-box;
    text-align: center;

}
.first_row_parents {
    position:relative;
    margin-top:20px;
}
.first_row_parents .justifyFlex{
    box-sizing: border-box;

}
.first_row_parents .parents_box{
     box-shadow:0 2px 24px rgba(0,0,0,0.4);
}
.second_row_parents .parents_box{
    padding: 0 15px;
    background: transparent;

}
.boxes_padding{
    padding:15px;
}
.boxes_padding .justifyFlex{
    padding:0 15px;
}
.parents_box_container{
    padding:60px 20px 20px 20px;
        background: #365899;
    color: #fff;
    display: inline-block;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    /*border-radius: 10px;*/
    width: -webkit-calc(25% - 30px);
    width: -moz-calc(25% - 30px);
    width: -ms-calc(25% - 30px);
    width: -o-calc(25% - 30px);
    width: calc(25% - 30px);
    position:relative;
    box-shadow:0 2px 24px rgba(0,0,0,0.4);
    margin:0 15px;
    text-align: center;
}
.parents_box_container h2{
    font-size:1.7rem;
}
.parents_box_container p{
    font-size:1.2rem;
}
.parents_box h2{
    font-size:1.7rem;
}
.parents_box p{
    font-size:1.2rem;
}
.featureIcon{
}
.pbox_title{
    text-transform: uppercase;
    font-weight:bold;
    display:block;
    text-align: center;
}
.parents_icon{
    font-size: 4.5rem;
    border-radius: 50%;
    background: #fff;
    width: 100px;
    height: 100px;
    display: inline-block;
    position: absolute;
    top:0;
    left:50%;
    -ms-transform:translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);

    border:10px solid #3db0a1;
    padding:10px 10px;
    box-shadow: 0 2px 24px rgba(0,0,0,0.4);
}
.parents_icon span{
    position:absolute;
    top:50%;
    left:50%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    font-size: 4rem;
    color: #848484;
}
.pboxorange{
    background:rgba(255, 126, 38, 0.72);
}
.pboxgreen{
    background:rgba(105, 188, 22, 0.81);
}
.pboxbrown{
    background:rgba(185, 122, 87, 0.85);
}
.pboxyellow{
    background:rgba(255, 200, 13, 0.85);
}
.pboxblue{
    background:rgba(54, 88, 153, 0.85);
}
.pboxgred{
    background:#c66;
}
#picturePathsSection, #pictureBadgesSection, #pictureGoalsSection {
    padding-bottom:20px;
    padding-top:20px;
}
.secondBigTitle{
    font-size:3.2rem;/*2.5*/
    text-align: center;
    font-weight:normal;
}
.secondBigSubTitle{
    text-align: center;
   /* font-size:1.5rem;*/
    font-size:1.2rem;
}
.parents_level_icon{
    float:left;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    background-color: #EDEDED;
    margin: 0 auto;
    box-shadow: 0 3px 6px rgba(0,0,0,.12);
    border: 4px solid #fff;
}
.parents_level_text{
   /* font-size: 1.3rem;*/
   font-size:1.2rem;
   position:absolute;
    top:50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
   display:inline;
   vertical-align:middle;
}
.coures_level_text{
    /*font-size:1.1rem;*/
    font-size:1.2rem;
}

.right_icon{
    float:right;
    margin-left:25px;
}
.left_icon{
    margin-right: 25px;
}
.parents_text{
    display: block;
    margin: 20px 0;
    position: relative;
    overflow: hidden;
    width: 100%;
    padding:20px 0;
}
.parents_levels_explain{
    margin-top: 30px;
    display: inline-block;
    width: 100%;
}
.border-kids{
    border: 4px solid #3DB0A1;
}
.border-console{
    border: 4px solid #FFA500;
}
.border-build{
    border: 4px solid #c66;
}
.border-web{
    border: 4px solid #3F51B5;
}
.parents_pay_title{
    text-align: center;
}
.parents_payment .payment_title_premium{
    background:#039ce6;
}
.parents_payment .payment_plan_premium{
    /*margin:0 auto;
    margin-top:20px;
    padding-bottom:20px;*/
}
.parents_payment .payment_title{
    text-transform: uppercase;
    font-weight:bold;
    margin-top:20px;
    color:#fff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin:0;
    font-size:2rem;
    height:170px;
    vertical-align: middle;
    position:relative;
    overflow:hidden;
}
.parents_payment .payment_title .bigPlan{
    position:absolute;
    top:50%;
    left:50%;
    -ms-transform:translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    width:100%;
}
.parents_payment .plan_description{
    padding:25px 5px;
    font-size:1.2rem;
}
.parents_payment .smallPlan{
    font-size:2rem;/*0.9*/
    width:100%;
}
.parents_payment .plan_prices{
    text-align: center;
    font-size:2rem;
    text-transform: uppercase;
}
.parents_level_text_left{
    margin-right:275px;
    text-align: right;
}
.parents_payment .payment_plan{
    text-align: center;
    width:60%;
    box-shadow:0 2px 24px rgba(0,0,0,0.4);
    border-radius: 8px;
    box-sizing: border-box;
}
.continue_with_pay{
    background:#FFA500;
    color:#fff;
    display:inline-block;
    padding:10px;
    font-weight: bold;
    cursor: pointer;
}
.see_more_prices{
    border:1px solid #FFA500;
    display:inline-block;
    padding:10px;
    font-weight: bold;
}
.testimonial_container{
    margin-top:40px;
}
.left_testimonial,.right_testimonial{
    width:49%;
    box-sizing:border-box;
    display:inline-block;
}
.left_testimonial{
    padding:15px;
}
.testimonial_box{
    width:80%;
    margin:0 auto;

    padding:20px 10px;
    box-sizing:border-box;
    color:#fff;

    background:#77C677;
}
.testimonial_person{
    font-style:italic;
    font-weight:bold;
    font-size:1.2rem;
    width:80%;
    margin:0 auto;
    border-bottom:1px solid #fff;
    padding-bottom:20px;
}
.testimonial_name{
     width:80%;
    margin:0 auto;
     font-style:italic;
    font-weight:bold;
    font-size:1rem;
    text-align: right;
     margin-top:10px;
}
.right_testimonial h2{
    font-size:1.7rem;
    font-weight:bold;
}
.right_testimonial li{
    font-size:1.2rem;
}
.testimonial-quotes{
    font-size:3rem;
    color:#525252;
}
.testimonial-quotes .fa-quote-left{
    float:left;
}
.testimonial-quotes .fa-quote-right{
    float:right;
}
.invite-message{
    display:inline-block;
    width:75%;
    text-align: center;
    font-style:italic;
}
.app_icons_home{
    width:150px;
    height:50px;
    display:inline-block;
    margin-top:22px;
    cursor:pointer;
}
.app_icons_bill{
    width:32%;
    height:25px;
    display:inline-block;
    cursor: pointer;
}
.app_icons_bill.home_appstore{
    margin-bottom: 0;
}
.app_icons_bill.home_win-store{
    margin-bottom:0;
}
.downloadApp{
    position:fixed;
    background:#fff;
    padding:15px;
    top:50%;
    left:50%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    z-index:31;
    display:none;
}
.downloadAppOverlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 30;
    display:none;
}
.downloadApp .fa{
    color:red;
    position:absolute;
    top:0;
    right:0;

}
#free_reg_title{
    display:none;
    text-align: center;
    font-size:2rem;
    margin-bottom:20px;
}
.continueButtonWithStart{
    padding:10px !important;
    font-size:1.5rem !important;
    margin-top:25px !important;
}
.first_course_logo{
    width: 120px;
    height: 60px;


}
.parents-why-box{

}
.why-box-left,.why-box-right{
    width:49%;
    display:inline-block;

}
.why-box-left{
    position:relative;
}
.check_parents{
    position:absolute;
    top:50%;
    left:50%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    width:100%;
}
.why-box-right{
    border:2px solid #039CE6;
    border-radius:10px;
    position:relative;
}
.why-banner{
    position:absolute;
    top:50%;
    left:50%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    background:#039CE6;
    width:105%;
    padding:15px;
    box-sizing:border-box;
    color:#fff;
    text-align: center;
    font-size:1.7rem;
    font-weight: bold;
    border-radius: 10px;
}
.check_parents{
    list-style: none;
}
.check_parents li{
    display:block;
    font-size:1.2rem;
}
.check_parents .check-circle{
    position:relative;
    background:#3db0a1;
    display:inline-block;
    width:25px;
    height:25px;
    border-radius: 50%;
    padding:10px;
    color:#fff;
    top:12px;
    margin-right:10px;
    /*list-style-type: none;*/

}
.check-circle span{
    position:absolute;
    top:50%;
    left:50%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    font-size:2rem;
}
.why-box{
    width:49%;
    display:inline-block;
    text-align: center;
    margin:20px 0;
}
.why-box span{
    width:170px;
    margin:0 auto;
    height:170px;
    display:inline-block;
    border-radius: 50%;
        box-shadow: 0 3px 6px rgba(0,0,0,.12);
    border: 4px solid #fff;
}
.free_course_desc{
    display:inline-block;
    width:65%;
    font-size:1.2rem;
    position:relative;
    color:#fff;
}
#free-courses .free_course_desc{
  color:#000;
}
.free_course_desc .courseDesc{
  color:#FFA500;
}
.free_btns{
  text-align: center;
  margin-top:20px;
}
.free_course_desc span{
    /*position:absolute;
    top:50%;
    left:50%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform:translate(-50%,-50%);
    width:100%;*/
    /*font-size:1.4rem;*/
    font-size:1.2rem;
}
.meshim_widget_components_chatButton_ButtonBar .button_text{
    width:0;
    background:transparent !important;
}
.meshim_widget_components_chatButton_Button .button_bar{
    background:transparent !important;
    display:none;
}
.dashboard{
    background:#313e44; /*  18283C*/
    /*position:fixed;*/
    top:0;
    bottom:0;
    padding-top:70px !important;
    /*height:100%;*/
    display:inline-block;
    box-shadow:0 1px 3px 2px rgba(0, 0, 0, 0.4), 0 1px 2px rgba(0, 0, 0, 0.16);
}
.dashboard-content{
    width:80%;
    margin-left: 20%;
    background:#F4F8FB;
}
.dashboard-content{
    padding:15px;
}
.dashboard .prof_text h1{
    font-size:1rem;
}
.col-dashboard-2{
    width:19%;
    box-sizing: border-box;
    padding-left:0;
    padding-right:0;
    position: relative;
    height:100%;
    overflow:hidden;
}
.col-dashboard-2 .customFileUpload input{
    width:100%;
}
.col-dashboard-2 #submitAvatar, #noAvatar{
    float:none;
    width:100%;
}
.col-dashboard-2 #uploadForm{
    -ms-transform: none;
    -webkit-transform: none;
    transform:none;
}
.col-dashboard-10{
    width:81%;
    box-sizing:border-box;
    overflow:hidden;
    margin-left:0;
    padding-top:50px;
}
.col-dashboard{

}
.dashboard .nav-menu ul li{
    list-style-type: none;
    padding:5px;
    margin-bottom:5px;
}
.dashboard .nav-menu ul li.separator{
    border-bottom:2px solid rgba(171, 171, 171, 0.55);
}
.dashboard .nav-menu ul li.share-win{
    font-weight: bold;
    text-align: center;
    cursor:auto !important;
}
.dashboard .nav-menu ul li.share-win span{
    display:block;
    text-align: center;
    font-weight:bold !important;
    width:100%;
    text-transform: uppercase;
     cursor:auto !important;
}
.dashboard .nav-menu .share-win .menu-title{
    font-weight:normal;
}
.activeLi{
    background: #F4F8FB;
    color: #18283c;
}
.dashboard .nav-menu ul{
    padding:0;
}
.dashboard .nav-menu{
    color:#fff;
}
.dashboard .nav-menu .fa{
    font-size:2rem;
    width:20%;
}
.dashboard .nav-menu .custom-fb-btn .fa{
    font-size:1rem;
    width:auto;
}
.dashboard .nav-menu .menu-title{
    width:79%;
    padding:5px;
    box-sizing: border-box;
    /*font-weight:bold;*/
    cursor: pointer;
}
.courseActivity{
       /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
       background:rgba(234, 234, 234, 0.28);*/
}
.courseActivity .pcPic{
    width:120px;
    height:120px;
    margin-bottom:20px;
}
.courseActivity .continueInnerWrapper{
    width: -webkit-calc(100% - 122px);
    width: -moz-calc(100% - 122px);
    width: -ms-calc(100% - 122px);
    width: -o-calc(100% - 122px);
    width: calc(100% - 122px);
    /*text-align: left;*/
    text-align: center;
}
.courseActivity .continueWrapper{

}
.courseActivity .profileProgressBack{
    width:70%;
}
.courseActivity .continueInnerWrapper,.pathsActivity .continueInnerWrapper{
    width:70%;
    margin-left:30px;
}
.courseActivity .continueInnerWrapper{
    width:100%;
}
.letBeginTitle{
    margin:20px 0;
    font-weight: bold;
    /*color: #039be6;
    text-transform: uppercase;*/
}
.courseActivity .current_courseTitle{
    color:#039be6;

}
.courseActivity{
    border-bottom:2px dashed #039CE6;
        padding: 20px;
        padding-top:0;
        box-sizing:border-box;
         border-bottom:none;
}
.courseActivity .beginCourse{
    border-radius: 5px;
}
.pathsStarted{
    width:100%;
    display: -ms-flexbox;
    display: -webkit-flex;
     display: flex;
    /*-webkit-justify-content: space-between;
    -webkit-flex-wrap: wrap;

    justify-content: space-between;
    flex-wrap: wrap;
    align-items: stretch;*/
    -webkit-align-items: stretch;
    /*flex-direction: column-reverse;
    -webkit-flex-direction: column-reverse;*/
    flex-direction: column;
    -webkit-flex-direction: column;
}
.path_icon_logo{
    position: relative;
    display: inline-block;
    height: 160px;
    width: 160px;
    background-color: lightblue;
    border-width: 1px;
    border-style: solid;
    border-radius: 50%;
    z-index: 0;
    overflow: hidden;

}
.pathsActivity{
        /*border-bottom: 2px dashed #039CE6;*/
        position:relative;
        padding:40px 20px;
        margin:15px 0;
        box-sizing:border-box;
            box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    background: #fff;
}
.orderTop{
     /*box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);*/
     border-bottom:0;
    order:-2;
    -webkit-order: -2;
    /*background:rgba(234, 234, 234, 0.28);*/
    border-left:none !important;
}
.orderTop2{
     order:-1;
    -webkit-order: -1;
    text-align: center;
    font-size: 2rem;
    /*color: #039be6;
    text-transform: uppercase;*/
}
.border_1{
    border-left: 4px solid #3DB0A1;
}
.border_2{
    border-left: 4px solid #FFA500;
}
.border_3{
    border-left: 4px solid #c66;
}
.border_4{
    border-left: 4px solid #3F51B5;
}
.pathsActivity .pcPic{
    width:120px;
    height:120px;
}
.logo_path_icon{
    width:120px;
    /*height:120px;*/
    height:70px;
}
.continueLater{
    display:block;
    text-align: center;
    width:100%;
    padding:15px;
    font-weight:bold;
}
.fullProfile{
    display:none;
    margin-top:60px;
}

.fullProfile .login_input{
    width:95%;
}
.fullProfile .profilePathWrapper{
    border-bottom:2px dashed #039ce6;
    box-shadow: none;
    margin-bottom:0;
}
.fullProfile #profileProblemList{
    margin-bottom:0;
}
.fullProfile .profilePathHeader{
    border-bottom:none;
}
#subscribtionWrapper{
    padding:30px 10px;
    text-align: center;
}
.subscribtion_left{
    background:#f9f9f9;

    display:block;
    margin:0 auto;
   /*  padding:35px 15px;
    width:30%;*/
    /*padding:20px;*/
    width:150px;
    height:150px;
    /*border-radius: 50%;*/
    border-radius: 20px;
    color:#5a5a5a;
    text-align: center;
    font-weight:bold;
    font-size:2.5rem;
    position:relative;
    /*border: 10px solid #FFA500;*/
    box-shadow: 0 2px 4px rgba(0,0,0,0.4);
}
.days-left-title{
    font-size:1rem;
    vertical-align:top;
    display:block;
    padding:15px 5px;
    background:#A61D1D;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    color:#fff;
}
.subscrioption_active{

    position:absolute;
    top:50%;
    right:115%;
    font-size:1.3rem;
    color:#FFA500;
    border:2px solid #039CE6;
    border-radius: 10px;
    width:100%;
    background:#fff;
    padding:10px;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform:translateY(-50%);
    display: none;
}

.subscrioption_active-mobile{
    display:none;
}
.tooltip-plan::after{
    content: " ";
    position: absolute;
    top: 50%;
    right: -28px;
    margin-left: -5px;
    border-width: 14px;
    border-style: solid;
    border-color: #039ce6 transparent transparent transparent;
    -ms-transform: translateY(-50%) rotate(-90deg);
    -webkit-transform: translateY(-50%) rotate(-90deg);
    transform: translateY(-50%) rotate(-90deg);

}
.learn-code{
    color:#099;
    font-weight:bold;
    font-size:2rem;
    text-align: center;
    display: block;
    padding:10px 5px;
    margin:20px 0;
    text-transform: uppercase;
    font-weight: bold;
  /*text-shadow:
    -3px -3px 0 rgb(255, 255, 255),
    3px -3px 0 #e8e8e8,
    -3px 3px 0 #ccc,
    3px 3px 0 #ccc,
    4px 4px 0 #fff,
    5px 5px 0 #fff,
    6px 6px 0 #fff,
    7px 7px 0 #fff;
  line-height: 0.8em;
  letter-spacing: 0.1em;
  transform: scaleY(0.7);
  -webkit-transform: scaleY(0.7);
  -moz-transform: scaleY(0.7);*/
  text-shadow: 1px 1px 3px rgba(0,0,0,0.4), 0px 8px 13px rgba(0,0,0,0.1), 0px 18px 23px rgba(0,0,0,0.1);
  margin:0;
  text-align: center;
}
.learn-code .logoText{
    color:#039CE6 !important;
    font-size:2rem !important;
}
.subscription-btn{
    display:inline-block;
    padding:10px 20px;
    color:#fff !important;
    font-weight:bold;
   border-radius: 30px;
   cursor:pointer;
   font-size:1.3rem;
}
.subscription-btn .fa{
    margin-right:5px;
}
.btn-orange{
    background:#FFA500;

}
.btn-blue{
    background:#039CE6;
}
.user_settings_box .itemLabel{
    display:block;
}
.one_third{
    width: 33%;
    height: auto;
    display: inline-block;
}
.col-dashboard-2 .parents_box_container{
    width:100%;
    margin:0;
    padding:10px;
    cursor:pointer;
}
.col-dashboard-2 .parents_box_container h2{
    font-size:1.2rem;
}
.col-dashboard-2 .parents_box_container p{
    font-size:1rem;
}
.courseActivity .textSize-smallTitle, .pathsActivity .textSize-smallTitle{
    font-size:1.5rem;
    font-weight: bold;
}
.courseActivity .textSize-title, .pathsActivity .textSize-title{
    font-size:1.3rem;
    color:#039be6;
    font-weight: normal;
    display:block;
}
.pathsActivity .hidePathsActivity, .pathsActivity .showPathsActivity{
    position:absolute;
    /*top:4px;
    right:7px;*/
    bottom:0;
    left:50%;
    -ms-transform: translate(-50%,50%);
    -webkit-transform: translate(-50%,50%);
    transform: translate(-50%,50%);
    color:#099;
    border-radius: 50%;
    border:1px solid #099;
    width:50px;
    height:50px;
    padding:0;
    cursor:pointer;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}
.pathsActivity .hidePathsActivity:hover, .pathsActivity .showPathsActivity:hover{
    color:#FFA500;
    border: 1px solid #FFA500;
}
.pathsActivity .hidePathsActivity span, .pathsActivity .showPathsActivity span{
    position:absolute;
    top:47%;
    left:50%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform:translate(-50%,-50%);
    font-size:2rem;
}
.col-dashboard-10 .content{
    width:auto;
    padding-top:0;
    padding-bottom:0;
}
.coursesPaths{
    width:100%;
}
.badgesProfile{
    display:none;
    flex-direction:column-reverse;
     -webkit-flex-direction: column-reverse;
}
.badges_cont{

    flex-direction:column;
     -webkit-flex-direction: column;
}
.badgesProfile .prof_badges:nth-child(2){
    /*order:1;
    -webkit-order: 1;*/
}
.prof_badges .path_title{
    margin:0;
}
.prof_badges .single_path_tile .icon{
    width:100px;
    height:100px;
}
.badge_placeholder{
    background:url('../images/badgeTypes/badges_ff2.png');
    width:150px;
    height:150px;
    background-size:contain;
    display:inline-block;
    opacity: 0.2;
    /*top:50%;
    position:absolute;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform:translateY(-50%);*/
}
.badge_placeholder_high{
     background:url('../images/badgeDefault.png');
    width:150px;
    height:150px;
    background-size:contain;
    background-repeat:no-repeat;
    background-position:center;
    display:inline-block;
    opacity: 0.2;
}
.prof_badges{
    background:#fff;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    padding:20px 0;
    margin:20px 0;

}
#profileProblemList, #userCredsForm{
     background:#fff;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    border-radius: 0;
}
.prof_badges:first-child{
    /*border-bottom:none;*/
}
.prof_badges .single_path_tile{
    width:25%;

}
.badges_placeholder{
    width:67%;
     position:relative;
}
.certificate_placeholder{
    background:url(../images/certificate.png);
    width:150px;
    height:150px;
    background-size:contain;
    display:inline-block;

}
.certificate_placeholder_color{
     background:url(../images/certificate-color.png);
    width:150px;
    height:150px;
    background-size:contain;
    display:inline-block;
    cursor:pointer;
    background-repeat:no-repeat;
    background-position:bottom;
}
.singlePathProgress  .half:first-child{
    text-align: center;
}
.singlePathProgress .half:first-child h2{
    margin:0;
}
.progressProfile{
    display:none;
    padding-bottom:60px;
}
.pathsActivitySlider {
    width:32.8%;
    display:inline-block;
    box-sizing:border-box;
    padding:10px;
        box-shadow: 0 2px 4px rgba(0,0,0,0.4);
        margin-right:0.5%;
        margin-bottom:5px;
        cursor: pointer;


}
.pathsActivitySlider:hover{
        /*transform: scale(1.01);*/
}
.userFirstEnter .continueInnerWrapper{
    /*width:80%;*/
}
.inactiveActivitySlide{
    display:none;
}
.activeActivitySlide{
    background:rgb(255, 255, 255);
    border-bottom: 4px solid #039CE6;
  /*  box-shadow:0 2px 18px 6px rgba(0, 0, 0, 0.4);*/
  box-shadow:0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transform: scale(1.05);
    -ms-transform: scale(1.05);
    -webkit-transform: scale(1.05);
}
.activeActivitySlide:after{
    content: " ";
    position: absolute;
    top: 109%;
    left: 50%;
    margin-left: -10px;
    border-width: 15px;
    border-style: solid;
    border-color: #039CE6 transparent transparent transparent;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%) ;
}
.prev-box{
    position:absolute;
    top:50%;
    left:0;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform:translateY(-50%);
    font-size:3rem;
    cursor:pointer;
    font-weight:bold;
}
.next-box{
    position:absolute;
    top:50%;
    right:0;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform:translateY(-50%);
    font-size:3rem;
    cursor:pointer;
    font-weight:bold;
}
.pathsActivitySlider .pcPic{
    display:block;
    margin:0 auto;
}
.pathsActivitySlider .continueInnerWrapper{
    width:100%;
    margin-left:0;
}
.singlePathProgress{
    display:none;
}
.singlePathProgress:first-child{
    display:flex;
}
.singlePathProgress ul{
    padding:0;
    margin:0;
}
.singlePathProgress ul h2{
    text-align: center;
}
.singlePathProgress ul li{
    list-style-type: none;
}
.sectionIcon{
    margin-right:8px;
}
.singlePathProgress ul h2{
    margin:0;
}
.progressProfile .pathsActivity{
    overflow:hidden;
    border-bottom:none;
    background:transparent;
    box-shadow: none;
    padding-bottom:20px;
    margin-bottom:0;
}

.pathActivityProgress{
    /*border-bottom: 2px dashed #039CE6;*/
    /*box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);*/

    padding-top: 20px;
    /*box-shadow:0 2px 7px 5px rgba(0, 0, 0, 0.4);*/
      box-shadow:0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}
.progress_prof{
    text-align: center;
}
.level_status_box{
    width:24%;
    display:inline-block;
    box-sizing:border-box;
    border:2px solid #FFA500;
    text-align: center;
    padding:10px;
    border-radius: 10px;
    position:relative;
    font-weight: bold;
}
.locked_path{
    position:absolute;
   top:0;
    right:5px;
    font-size:2rem;
    color:#039be6;
}
.unlocked_path{
    position:absolute;
   top:0;
    right:5px;
    font-size:2rem;
    color:green;
}
.badges_count{
    font-weight:bold;
}
.badges_placeholder .badges{
    width:auto;
    height:100px;
    cursor:pointer;

}
.badges_placeholder .badges:hover{
    opacity:0.8;
}
.badges_placeholder .badges:hover  + .fa{
    display:block;
}
.badges_placeholder .badge_fb .fa:hover{
   /*display:block;*/
}
.badge_fb{
    position:relative;
    display:inline-block;
    width:150px;
}
.certificate_fb{
    position:relative;
    display: inline-block;
    width: 150px;
}
.badge_fb .fa{
    /*display:block;*/
    /*position:absolute;
    top:50%;
    left:50%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform:translate(-50%,-50%);
    font-size:3rem;
    color: #365899;
    background: #fff;
    display:none;*/
}
.badges_placeholder .badges{
    /*height:150px;*/
    width:150px;
    height:auto;
    /*position:absolute;
    top:50%;
    -ms-transform:translateY(-50%);
    -webkit-transform:translateY(-50%);
    transform:translateY(-50%);*/
}
.badge_share{
    display:block;
    background:#365899;
    color:#fff;
    border-radius: 8px;
    text-align: center;
    cursor:pointer;
    position:relative;
        padding: 5px 0;
}
.share-badge-title{
    display:inline-block;
    margin-left:17%;
        line-height: 13px;
}
.badge_share .fa{
    position:absolute;
    top:50%;
    left:18%;
    -ms-transform:translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform:translate(-50%,-50%);
    font-size: 1.8rem;
}
.b_{
    /*position:absolute;
    top:50%;
    -ms-transform:translateY(-50%);
    -webkit-transform:translateY(-50%);
    transform:translateY(-50%);*/
}
.days{
    display:block;
    font-size:5rem;
}
.courseActivity .pcPic .logo_course1,.pathsActivity .pcPic .logo_path_vprogramming{
    height:60px;
}
.courseActivity .pcPic img{
    width:100%;
}
.pathsActivity .pcPic .paths_img{
    /*height:60px;*/
}
.receivedRecords{
    text-align: center;
}
.ai-brain{
    background:url('../images/ai_brain.png');
    background-repeat: no-repeat;
    background-size: contain;
    height:100px;
    background-position:center;
}
.block_icon{
     background:url('../images/move1.png');
    background-repeat: no-repeat;
    background-size: contain;
    height:50px;
    background-position:center;
    display:block;
}
.ai-icon{
    background:url('../images/ai_brain.png');
    background-repeat: no-repeat;
    background-size: contain;
    height:50px;
    background-position:center;
    display:inline-block;
    width:100%;
}
.records{
    width:100%;
    text-align: center;
}
.recordsCounter{
    width:100px;
    height:100px;
    margin:0 auto;
    color:#9DD7D8;
    border-radius: 50%;
    border:9px solid #6C7E8A;
    font-size:5rem;
    font-weight:bold;
}
.recordBox{
    width:14.25%;
    box-sizing:border-box;
    display:inline-block;
}
.most-badges{
     background:url('../images/ribbon.png');
    background-repeat: no-repeat;
    background-size: contain;
    height:100px;
    background-position:center;
}
.record-title{
    text-align: center;
    font-size:1rem;
    font-weight:bold;
}
.records{
    margin-bottom:40px;
}
.record-icon{
    font-size:3.5rem !important;
    color:#099;
}
/******** CERTIFICATES **********/
.certificate_wrapper{
    /*margin:0 auto;
    width:842px;
    height:595px;
    width: 100%;
    height: 850px;

    text-align: center;
    border: 10px solid #FFA500;
    box-sizing: border-box;*/

}
.left_col_certificate{
   /* width:20%;
    background:#039CE6;
    height:100%;
    float:left;*/
}
#certificate-template{
    height:100%;
}
.fb-share-end{
    padding: 8px 20px;
    cursor: pointer;
    border-radius: 20px;
    outline:none;
}
.record_badge{
    background:url('../images/badgeCert.png');
    height:100px;
    background-size:contain;
    background-repeat:no-repeat;
}
.closeChat{
    position:fixed;
    top:0;
    right:0;
    width: 46px;
    height: 44px;
    display:none;
    z-index:160000010;
}
.loader_chat{
    position: fixed;
    top: 37%;
    left: 32%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    z-index:42;
    display:none;
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 320px;
    height: 320px;
    animation: spin 2s linear infinite;
    margin: 10px auto;
}
.loader_chat_overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #3DB0A1;
    z-index: 41;
    display:none;
}
.download-overlay{
     position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(61, 176, 161, 0.8);
    z-index: 41;
    display:none;
    opacity: 0.8;
}
.download-loader{
    position: fixed;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    z-index:42;
    display:none;
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    margin: 10px auto;
}
.logo_structure_project_console{
    background:url('../images/browserv3.png');
    background-size:contain;
    background-repeat:no-repeat;
}
.heroSection_courses{
    /*display:none;*/
}
.heroSection_programming-kids{
  display: none;
}
.heroSection_parents {
    display:none;
}
.heroSection_bill {
    display:none;
}
.certificate-modal{
   position: fixed;
    background: #fff;
    top: 50%;
    /*width: 70%;
    height:auto;*/
    /*width: -webkit-calc(100% - 668px);
    width: -moz-calc(100% - 668px);
    width: -ms-calc(100% - 668px);
    width: -o-calc(100% - 668px);*/
    /*width: 78%;*/
    width:1150px;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    left: 50%;
    z-index: 31;
    border-radius: 5px;
    padding: 25px;
    text-align: center;
    box-sizing: border-box;
    max-height: 100%;
    overflow-y: auto;
    display: none;
}
.certificate-modal .badge_share {

    display:none;
    width:200px;
}
.rest_courses{
    margin-top:50px;
}
.pcText h1{
    display: -webkit-box;
    display: box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;
    -text-overflow: ellipsis;
    height: 39px;
    word-wrap: break-word;
    /*height:auto;*/
}
.stopping-site-overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(12, 12, 12, 0.8);
    z-index: 41;
    display:none;
    opacity: 1;
}
.stopping-site{
    position:fixed;
    top:50%;
    left:50%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform:translate(-50%,-50%);
    transform:translate(-50%,-50%);
    background:#fff;
    z-index:42;
    padding:15px;
    width:50%;
    text-align: center;
    display:none;
}
.ok-stop{
    color: #fff !important;
    text-align: center;
    background: #FF9F00 !important;
    border-radius: 0;
    cursor: pointer;
    width: auto;
    height: auto;
    line-height: normal;
    font-size: 1.3rem;
    display: inline-block;
    padding: 10px 20px;
    font-weight: bold;
    margin: 0 auto;
    margin-top: 20px;
    border-radius: 20px;
}
.invite-friend{
    text-align: center;
    padding: 0 15px 45px 15px;
    box-sizing:border-box;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    background: #fff;
    position:relative;

}
.invite-friend h2{
    margin:0;
}
.invite-icon{
    -ms-transform: translateY(-50%);
    -webkit-transform:translateY(-50%);
    transform:translateY(-50%);
    display:inline-block;
    position:relative;
    width:60px;
    height:60px;
    border-radius: 50%;
    /*background:#FFA500;*/
    /*border:1px solid #099;*/
    padding:5px;
}
.hideLinkShare{
    position: absolute;
    bottom: 0;
    left: 50%;
    -ms-transform: translate(-50%,50%);
    -webkit-transform: translate(-50%,50%);
    transform: translate(-50%,50%);
    color: #039be6;
    border-radius: 50%;
    border: 1px solid #039be6;
    width: 50px;
    height: 50px;
    padding: 0;
    cursor: pointer;
}
.hideLinkShare span{
        position: absolute;
    top: 47%;
    left: 50%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    font-size: 2rem;
}
.invite-icon .fa{
    font-size:4rem;
    /*background:#fff;*/
    color:#365899;
    position:absolute;
    top:50%;
    left:50%;
    -ms-transform:translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}

.link_url{
    width:50%;
    display:inline-block;
}
.link_url input{
    width:100%;
    background:#fff;
    border:none;
    outline:none;
    padding:10px;
}

.edit-link-info{
    font-size:2rem;
    cursor:pointer;
    vertical-align: top;
    position:absolute;
    top:0;
    right:0;
}
.edit-link-info span{
    vertical-align: top;
}
.friends_box{
    display:inline-block;
   /* width:32%;*/
   width:18%;
    padding:15px;
    background:#F0F0F0;
   /* border:1px solid #ccc;*/
    border-radius: 5px;
    text-align: left;
    box-sizing:border-box;
    position:relative;
    padding-left:20px;
    margin:5px 0;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
        margin-right:1%;
}
.friend_number{
    font-size:2rem;
    float:left;
}
.friends_icon{
    font-size:2rem !important;
    width:15%;
    display:inline-block;
    position:relative;
}
.friends_icon .fa{
    position:absolute;
    top:56%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left:0;
}
.friend_counter_text{
    display:inline-block;
    margin-left:4%;
    width:40%;
    vertical-align: top;
}
.friends_text{
    display:inline-block;
   /* transform: translateY(50%);
    -webkit-transform:translateY(50%);
    -ms-transform:translateY(50%);*/
    width:30%;
    text-align: center;
    position:absolute;
    top:0;
    bottom:0;
    right:0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background:#039CE6;
    color:#fff;
    height:100%;
    line-height:60px;
    font-weight:bold;
    font-size:1.2rem;
}
.green{
    color:#3DB0A1;
}
.landing-modal{
    position: fixed;
    background: #fff;
    top: 50%;
    width:98%;
    margin:0 auto;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    left: 50%;
    z-index: 42;
    border-radius: 5px;
    padding: 25px;
    text-align: center;
    box-sizing: border-box;
    max-height: 100%;
    overflow-y: auto;
    display: none;
    height:90%;
    max-height:100%;
}
.landing-overlay{
     position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(12, 12, 12, 0.8);
    z-index: 41;
    display:none;
    opacity: 1;
}
.invite-msg{
    width:80%;
    display:inline-block;

}
.closeLanding{
    position:absolute;
    top:0;
    right:0;
    color:red;
    cursor:pointer;
    font-size:2rem;
}
.save_msg{
    border:none;
    background:#FFA500;
    border-radius:20px;
    padding:5px 15px;
    color:#fff;
    margin-top:5px;
    cursor: pointer;
}
.promotionsProfile{
    display:none;
}
.statisticsProfile{
    display:none;
}
.settingsProfile{
    display:none;
}
.social_link {
    margin-top:15px;
}
.social_link .fa{
    font-size:2rem;
    margin:0 10px;
    cursor:pointer;
}
.look_landing{
    font-size:2rem;
}
.promotions{
    text-align: center;
    font-size:2rem;
    margin-bottom:50px;
}
.label-dot{
    position:absolute;
    width: 15px;
    height: 15px;
    background: #ddd;
    top:50%;
    left:5px;
    -ms-transform:translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.border-title{
    text-align: left;
    color:#636363;

    padding-bottom:5px;
    font-size:2rem;
    font-weight: normal;
}
.friends_text_bg{
    background:#3DB0A1;
}
.share-list{
    list-style: none;
}
.share-list li{
    width:98%;
    margin:10px 0;
    background:#EFEFEF;
    border-radius:20px;
    position:relative;
    font-size:1.2rem;
}
.todo-num{
    float:left;
    background:#3DB0A1;
    color:#fff;
    padding:8px 15px;
    border-radius: 50%;
}
.todo-question{
    float:left;
    background:#FFA500;
    cursor: pointer;
    color:#fff;
    padding:7px 15px;
    border-radius: 50%;
}
.todo-answer{
    display: none;
    position: absolute;
    left: 7%;
    top: 35px;
    font-size: 12px;
    color:white;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 10px;
    border-radius: 3px;
}
.social_link{
    margin-bottom:20px;
}
.todo-text{
    display:inline-block;
    padding:8px;
    width:90%;
}
.invite-fr-sub{
    font-size:1.8rem;
    color:#039CE6;
}
.wider-box-inactive{
    background:#ccc !important;
}
.social_link .custom-fb-btn{
    font-size:11px;
    vertical-align:top;

}
.social_link .fa{
    font-size:1rem;
    -ms-transform:translateY(10%);
    -webkit-transform: translateY(10%);
    transform:translateY(10%);
}
.invite-fr{
  font-size:2rem;
  text-transform: uppercase;
}
.extra{
    font-size: 1.5rem;
    margin-top: 23px;
    /*position:absolute;
    top:31%;
    left:50%;
    -ms-transform:translateX(-50%);
    -webkit-transform: translateY(-50%);
    transform:translate(-50%);*/
}
.promotion-amount-small {
    font-size:2rem;
}
.promotion-amount-small .percent{
    display:block;
    font-size:3rem;
}
.percent-big{
    font-size:4rem !important;
}
.promotion-amount-small span{
   /* position:static !important;
    transform:none !important;*/
}
.promotion-top{
    padding-top:20px;
}
.confirmAccount{
    box-sizing: border-box;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);


    margin-bottom:50px;
    position: fixed;
    background: #fff;
    top: 50%;
    width: 70%;
    transform: translate(-50%,-50%);
    left: 50%;
    z-index: 31;
    border-radius: 5px;
   /* padding: 25px;*/
    text-align: center;
    padding-top:0;
    display:none;
}
.confirmAccount h4{
    font-size:1.2rem;
}
.modal-head{
    padding:15px;
    border-bottom:1px solid #ccc;
    margin:0;
    text-align: left;
    background:#ececec;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.modal-container{
    padding:25px;
}
.confirmAccount .warningAccount{
    background:#FFA500;
    color:#fff;
    width:50px;
    height:50px;
    text-align: center;
    line-height:50px;
    display:inline-block;
    border-radius: 50%;
}
.confirmAccount .warningAccount:before{
    margin-top:10px;
}
.icon_apple{
    background:url('../images/appstore.png');
    background-repeat:no-repeat;
    background-size:cover;
}
.icon_google{
    background:url('../images/google-play-badge.png');
    background-repeat:no-repeat;
    background-size:cover;
}
.icon_win{
     background:url('../images/win-store-badge.png');
    background-repeat:no-repeat;
    background-size:cover;
    display:none !important;
}
.app_icons_down{
    width: 100px;
    height: 30px;
    display: inline-block;
    /*margin-top: 22px;*/
    cursor: pointer;
}
.app_icon{
  font-size:1.5rem;
}
.social-share{
    text-align: center;
    padding: 0 15px 45px 15px;
    box-sizing: border-box;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    background: #fff;
    position: relative;
    margin-top:100px;
    margin-bottom:50px;
}
.promotion-amount{
    /*position:absolute;
    top:0;
    font-size:4rem;
    -ms-transform:translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform:translateY(-50%);*/
    position: absolute;
    top: 0;
    font-size: 4rem;
    left: 0;
    width: 20%;
    height: 100%;
    background:#FFA500;
    color:#fff;
    display: none;
}
.promotion-amount span{
    position:absolute;
    top:50%;
    left:50%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}
.promotion-left{
    /*margin-left:20%;*/
}
.invite-friend .discount-window{
  width:49%;
  display: inline-block;
}
.discount-window-share{

}
.you{
    text-align: left;
    font-size:1.3rem;
    font-weight: bold;
}
.promotion-left .wider-box{
  text-align: left;
}
.promotion-left p{
  margin:0;
  font-size:1.4rem;
}
.promotion-left .payment_plan{
    width:49% !important;
    cursor: none;
}
.promotion-left .payment_title{
    text-transform: lowercase;
    padding:10px;
    box-sizing: border-box;
    cursor: none;
}
.promotion-left .payment_title_free{
  background-color:#099;
}
.promotion-left .payment_title_prem{
  background-color:#365899;
}
.promotion-left .plan_description{
  padding:10px;
  font-size:1.4rem;
}
.promotion-left .you{
  font-size:1.2rem;
  text-transform: uppercase;
}
.promotion-left .payment_title{
  text-transform: none;
}
.award{
  font-size:1.5rem !important;
  text-decoration: underline;
}
.social-share .custom-fb-btn{
    padding:10px;
}
.social-share .custom-fb-btn .fa{
    font-size:1.5rem;
}
.wider-box{
    width:100%;
    text-align: center;
    background: #3DB0A1;
    color: #fff;
}
.month-price{
    font-size:1.4rem;
    display:block;
}
.standart-price{
    font-size:2rem;
}
.promotion-price{
    font-size:2rem;
}
.price-underlined{
    text-decoration: line-through;
}
.price-darker{
    color:#c1c1c1;
    display: block;
}
.percent-number{
    font-size:2.4rem;
    font-weight: bold;
    color:#ffa500;
}
.badge_share_inactive{
    opacity: 0.5;
    cursor: auto;
}
.please_enter_name_overlay{
     position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 36;
    display: none;
}
.please_enter_name{
    position: fixed;
    background: #fff;
    top: 50%;
    /* width: 78%; */
    width: 1150px;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    left: 50%;
    z-index: 37;
    border-radius: 5px;
    padding: 25px;
    text-align: center;
    box-sizing: border-box;
    max-height: 100%;
    overflow-y: auto;
    display:none;
}
.recordsExplain{
    font-size:1.3rem;
    margin:15px 0;
}
.additional-feature-1,.additional-feature-2{
    width:80%;
    margin:0 auto;
    box-shadow:0 2px 5px rgba(0,0,0,0.4);
    /*background:#fff;*/
}
.standart-offer-price{
    width:30%;
    display:inline-block;
    background:#FFA500;
    color:#fff;
    position:relative;
    padding:15px;
    box-sizing:border-box;
}
.standart-offer-content{
    width:69%;
    display:inline-block;
    position:relative;
    padding:15px;
    box-sizing:border-box;
    background:#fff;
}
.standart-offer-content ul li{
    color:#000;
    text-align: left;
}
.standart-offer-price .fa-shopping-cart{
    font-size:4rem;
}
.standart-offer-price div{
    position:absolute;
    top:50%;
    left:50%;
    -ms-transform:translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform:translate(-50%,-50%);
    width:100%;
}
.newPrices{
    position:absolute;
    top:50%;
    left:50%;
    -ms-transform:translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform:translate(-50%,-50%);
    z-index: 57;
    background:#099;
    width:50%;
    color:#fff;
}
.newPrices h1{
    font-size:2.5rem;
    text-transform: uppercase;
     padding:15px;
}
.newPricesOverlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.83);
    z-index: 51;
}
.package_row{
    padding:15px;
    font-size:1.5rem;
    border-bottom:1px dashed #ccc;
    text-align: left;
}
.total_row{
    background:#ffa500;
    font-weight:bold;
}
.package_row span{
    float:right;
}
.continueLastStep{
    display:inline-block;
    margin-top:20px;
    padding:15px 30px;
    background:#c66;
    font-weight:bold;
    cursor:pointer;
}
#modalStartTrial .additional-plan-payment{
   padding:10px 30px;
   background:#ccc;
   margin:0 10px;
   font-weight:bold;
   borer-radius:20px;
   cursor:pointer;
    text-decoration: none;
    display: inline-block;
    color: #000;
    cursor: pointer;
    font-size: 1.8rem;
    background: #385470;
    color: #fff !important;
    padding: 20px 30px;
    border-radius: 15px;
    border:none;
    outline:none;
    position:relative;
    padding-left:90px;
    font-weight: bold;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.6);
}
.additional-plan-payment  .fa-shopping-cart{
    position:absolute;
    top:0;
    left:0;
    bottom:0;
    background:#da8800;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    width:80px;
}
.additional-plan-payment  .fa-shopping-cart:before{
    position:absolute;
    top:50%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    left:45%;
}
.no-thanks-link{
    display:block;
    color:#fff !important;
    text-decoration: underline !important;
    font-style: italic;
    font-size:1.2rem;
    cursor:pointer;
    margin-top:20px;
}
#middleStepFeature,.middleStepFeature{
    position:absolute;
    top:50%;
    left:50%;
    -ms-transform:translate(-50%,-50%);
    -webkit-transform:translate(-50%,-50%);
    transform:translate(-50%,-50%);
    width:100%;
    z-index:50;
    color:#fff;
    /*background-color:#fff;*/
}
.middleStepFeatureBill .feature1 ,.middleStepFeatureBill .feature2{
    overflow-y: scroll !important;
    width: 100%;
    height: 100%
}
.middleStepFeatureBill{
    position:fixed;
}   
.featureModal{
  position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: rgba(0, 0, 0, 0.69);
z-index: 35;
display: none;
}

.standart-offer-content h1{
    color:#000;
}
.feature2 .standart-offer-price{
    background:#099;
}
.footerCourseText{
    width:75%;
}
.closeConfirmAccount{
    position:absolute;
    top: 7px;
    right: 12px;
    color:red;
    font-size:2rem;
    cursor:pointer;
}
.panel.panelForget{
    width:50%;
}
#getLinkButton,#resetPassword{
    text-transform: uppercase;
    font-size: 1rem;
    display: block;
    width: auto;
    margin-top: 40px;
    border-radius: 50px;
    background: #FFA500;
    padding: 10px 20px;
    box-sizing: border-box;
    outline: none;
    margin:0 auto;
    border:none;
    color:#fff;
    cursor:pointer;
}
.invoice-header{
    background:#353535;
    color:#fff;
    font-weight:bold;
}
.invoicseOneThird{
    display:inline-block;
    width:32%;
    padding:15px;
    box-sizing:border-box;
    border-right:1px solid #ccc;
}
.billInfo span{
    display:inline-block;
    width:32%;
}
.invoicseOneThird:nth-child(3){
    text-align: right;
    border-right:none;
}
.special-offer-overlay{
    background-color:#4F5E4D;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity:0.9;
}
#modalStartTrial .special-offer-overlay{
    position:fixed;
    right:17px;
}
.plan_popup .button {
    position:relative;
    /*padding-right:30px;*/
}
.firstStep .plan_popup .button {
    padding-left:58px;
    font-size:1.5rem;
    /*text-transform: uppercase;*/
}
.plan_popup .icon-pay{
    position:absolute;
    left:0;
    top:0;
    bottom:0;
    background:#cecece;
    width:50px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}
.plan_popup .icon-pay:before{
    position:absolute;
    top:50%;
    left:40%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform:translate(-50%,-50%);
    transform: translate(-50%,-50%);
}
.planBG .icon-pay{
    background:#385470;
}
.continuePlan{
    position:relative;
}
.continuePlan .fa-shopping-cart{
    position:absolute;
    left:0;
    top:0;
    bottom:0;
    background:#385470;
    width:50px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    color:#fff;
    font-size:1.7rem;
}
.continuePlan .fa-shopping-cart:before{
    position:absolute;
    top:50%;
    left:40%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform:translate(-50%,-50%);
    transform: translate(-50%,-50%);
}
.invite-friend .placeholder{
    font-size:1rem;
}
/**ADDITIONAL PRICES *****/
.including-row{
    padding:15px 20px;
    padding-left:60px;
    border-left:6px solid #099;
    position:relative;

}
.including-row:first-child{

}
.including-icon{
    position:absolute;
    top:0;
    left:-3px;
    width:70px;
    height:70px;
    background-color:#fff;
    border-radius: 50%;
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform:translateX(-50%);
    z-index:5;
    font-size:3rem;
    color:#8a8a8a;
    border:7px solid #099;

}
.icon-teacher{
    background-image:url('../images/teacher.png');
    background-size:cover;
}
.feature-title{
    display:block;
    font-size:1.8rem;
    color:#000;
    font-weight:bold;
    color:#424242;
}
.including-icon span{
    position:absolute;
    top:50%;
    left:50%;
    -ms-transform:translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform:translate(-50%,-50%);
}
#middleStepFeature .incuding,.middleStepFeature .incuding{
    display:inline-block;
    width:100%;
}
#middleStepFeature .including-row .feature-title, .middleStepFeature .including-row .feature-title{
    color:#fff;
    font-size:2rem;

}
.including-row  .including-text{
    font-size:1rem;
    font-weight:bold;
}
#middleStepFeature h1, .middleStepFeature h1{
    font-size:2.5rem;
}
#middleStepFeature #price-middle,.middleStepFeature #price-middle{
    font-size:2.3rem;
}
.icon-teacher.firstGif{
    height:200px;
    background-size:contain;
    background-repeat:no-repeat;
    background-position: center center;
}
#middleStepFeature #picturePathsSection, #middleStepFeature #pictureBadgesSection, #middleStepFeature #pictureGoalsSection,
.middleStepFeature #picturePathsSection, .middleStepFeature #pictureBadgesSection, .middleStepFeature #pictureGoalsSection{
    padding-top:80px;
    padding-bottom:80px;
}
.calendar .fa-child{
    font-size:5rem;
}
#bgn-price,#usd-price{
    font-size:2rem;
}
.near{
    font-size:2.2rem;
}
.currencyCalculate{
    font-size:2rem;
    color:#039be6;
    display:none;
}
.share-reg-code {
    position:static;
}
.playNowFixed{
    position:fixed;
    left:0;
    top:50%;
    font-size:2rem;
    cursor:pointer;
    background:#b50027;
    color:#fff;
    padding:15px;
    z-index:100;
   /* width: 70px;*/
    height: 70px;
    box-sizing: border-box;
    color:#fff !important;
}
.playNowFixed .fa{
    /*position:absolute;
    top:50%;
    left:50%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform:translate(-50%,-50%);*/
    line-height:40px !important;
}
.promotionBtnFixed{
    position:fixed;
    left:0;
    top: 20%;
    z-index:25;
    padding:15px;
    cursor:pointer;
    background:#b50027;
    color:#fff;
    font-size:3rem;
    text-align:center;
}
.promotionBtnFixed .discount{
    font-size:1rem;
}
.igrai-sega{
    display:none;
    /*font-size:1rem;*/
}

.secondTopStripe{
  /*margin-top:110px !important;
  padding-top:70px !important;*/
}
/****** PANEL MENU IN PATHS OR COURSES ***************/
.navigation-courses{
  position:fixed;
  /*top:60%;*/
  top:50%;
  /*left:15px;*/
  left:5px;
  -ms-transform:translateY(-50%);
  -webkit-transform:translateY(-50%);
  transform:translateY(-50%);
  font-size:1.8rem;
  background:#039CE6;
  color:#fff;
  padding:15px 20px;
  vertical-align: top;
  cursor:pointer;
  z-index:30;
}

#navigationDrawer{
  position:fixed;
  top:0;
  left:0;
  width:15%;
  background:#F5F8FA;
 display: none;
  height:100%;
  box-shadow: 0 1px 3px 2px rgba(0, 0, 0, 0.4), 0 1px 2px rgba(0, 0, 0, 0.16);
}
#navigationDrawer ul{
  padding:0;
  margin:0;
}
#navigationDrawer ul li{
  list-style: none;
  padding:10px;
  cursor:pointer;
  padding-left:20px;
}
#navigationDrawer ul ul li{
  padding-left:40px;
}
#navigationDrawer #backHeader{
  padding:15px 10px;
  background:#546e7a;
  color:#fff;
  cursor:pointer;
}
.activeNavigationLink{
  font-weight: bold;
  color:#039be6;
}
#prof_name{
  background:rgba(63, 81, 181, 0.1);
  color:rgba(68, 68, 68, 0.7);
  font-weight: bold;
}
#prof_name span{
  float:right;
}
#collapse-panel{
  position:absolute;
  bottom:0;
  width:100%;
  text-align: center;
  background:#546e7a;
  padding:10px;
  box-sizing:border-box;
  color:#fff;
}
#contactsInfo{
  background-color:#fff;
  color:#000;
  /*font-weight: bold;*/
}
.emailInBanner{
    float:right;
}
.emailContactHeader{
  background-image:url('../images/email2.png');
  display: inline-block;
  height: 10px;
  width: 50px;
  background-size: contain;
}

/************* PROGRAMMING FOR KIDS PAGE *********************/
.col-btn{
  width: 19%;
    display: inline-block;
    padding: 7px;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
    font-size: 1.5rem;
    align-self:center;
}
.programmingKids{
  text-align: center;
  font-size:2.2rem;
}
.levelCourse{
  font-size: 2rem;
}
.activeFilter{
  background-color:#FFEB3B;
  color:#000;
  border-radius: 10px;
}
.all_courses_filter{
  display: inline-block;
  padding:10px;
}
.filterView{
  display:none;
}
#allCoursesFilter{

}
.seeMoreCourses{
  display: inline-block;
  padding:10px;
  color:#fff;
  cursor: pointer;
  background-color:#FFA500;
  font-size:1.3rem;
}
.icon_code_block{
  width: 70px;
  height: 70px;
  /*background-image: url(../images/code_block.png);*/
  background-size: contain;
  background-repeat: no-repeat;
  float: right;
  position: relative;
margin: 5px;
display:none;
}
.icon_code{
  width: 70px;
  height: 70px;
  /*background-image:url('../images/icon_code.png');*/
  background-size:contain;
  background-repeat: no-repeat;
  float: right;
  position: relative;
  margin: 5px;
  display:none;
}
.icon_console{
  width: 70px;
  height: 70px;
  /*background-image:url('../images/browserv3.png');*/
  background-size:contain;
  background-repeat: no-repeat;
  float: right;
  position: relative;
  margin: 5px;
  display:none;
}

.badge_in_course_tile{
  /*width:60px;
  float:left;
  margin-left:10px;*/
  width: auto;
height: 59px;
background-size: contain;
    background-repeat: no-repeat;

}
.badge_course_tile{
    opacity:0;
}
.fplan1{
  width:32%;
  display:inline-block;
  text-align: center;
  margin-bottom:30px;
}
.takeNewFeaturePlan{
  font-size:1rem;
  padding:5px;
  background:#FFA500;
  color:#fff;
  margin-top:10px;
  cursor: pointer;
  display:none;
}
.flexReverse{

  width:100%;
}

/************** PROGRAMMING FOR KIDS PAGE END *****************/
/*** PREVIEW COURSES FOR TEACHERS **/
.teacherTestAvailableCourses{
  background-color:#fff;
  position: relative;
  text-align: center;
    z-index: -1;
}
.teacherTestAvailableCourses h1{
  margin:0;
  margin-bottom: 5px;
}
.closeTeacherCourses{
  color:red;
  position:absolute;
  top:0;
  right:0;
}
.gradeLevel{
  font-weight: bold;
}
.teachers-course-box{
  text-align: center;
  width:90%;
  box-shadow:0 1px 3px 2px rgba(0, 0, 0, 0.4), 0 1px 2px rgba(0, 0, 0, 0.16);
  margin:10px auto;
  padding:10px;
}
.teacherBox{
text-align: center;
  width:25%;

}
.see-more-teacher{
    display: block;
    margin-top:10px;
    background-color:#FF9F00;
    color:#fff;
    display: inline-block;
    padding:10px;
    color:#fff !important;
}
.title-teacher{
  display: block;

}
.teacherTestAvailableCourses h1{
  text-align: center;
  margin-bottom: 15px;
  padding-top:15px;

}
.hideTeacherCourses .fa,.showTeacherCourses .fa{
  font-size:3rem;
  cursor: pointer;

}
.hideTeacherCourses,.showTeacherCourses{
  display: inline-block;
    border-radius: 50%;
    background: #fff;
    border: 1px solid #039CE6;
    width:50px;
    height:50px;
        transform: translateY(50%);
        -ms-transform:translateY(50%);
        -webkit-transform:translateY(50%);
        background:#ff9f00;
        color:#fff;
}
.showTeacherCourses{
  height:30px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  display: none;
}
.showTeacherCourses{
  position: absolute;
  top:120%;
  left:50%;
  -ms-transform:translate(-50%,-50%);
  -webkit-transform:translate(-50%,-50%);
  transform:translate(-50%,-50%);
}
.hideTeacherCourses span,.showTeacherCourses span{
  position: absolute;
  top:23%;
  left:50%;
  -ms-transform:translate(-50%,-50%);
  -webkit-transform:translate(-50%,-50%);
  transform:translate(-50%,-50%);
}
.showTeacherCourses span{
  top:44%;
}
.overflowedCourses{
  overflow: hidden;
}
.optional{
  font-size:0.8rem;
  display: block;
}
.feature-price{
  font-size:2rem;
  font-weight: bold;
}
/** END PREVIEW COURSES FOR TEACHERS **/
/*** ACTIVATION SCRATCH CARD MODAL  ****/

.activeScratchAccount{
    position:fixed;
    top:50%;
    left:50%;
    -ms-transform:translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform:translate(-50%,-50%);
    background-color:#fff;
    width:80%;
    z-index:51;
    border-radius: 5px;
    text-align: center;
}
.activeScratchCodeAccount{
    position:fixed;
    top:50%;
    left:50%;
    -ms-transform:translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform:translate(-50%,-50%);
    background-color:#fff;
    width:80%;
    z-index:51;
    border-radius: 5px;
    text-align: center;
}
.activeScratchAccountOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.91);
    z-index: 50;
}
.closeScratchAccount{
    position:absolute;
    top: 18px;
    right: 12px;
    color:red;
    font-size:1rem;
    cursor:pointer;
}
.activation_input{
    display:block;
    margin:5px auto;
    border-radius: 5px;
    padding:15px 10px;
    outline:none;
    border:1px solid #ccc;
    width:50%;
}
.activeScratchAccount button, .askingForTelephone button, .activeScratchCodeAccount  button{
    outline:none;
    border:none;
    margin:5px 0;
    padding: 15px 10px;
    cursor: pointer;
    width:50%;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight:bold;
}
.wrongInput{
    border:1px solid red !important;
}
.successActivationStatus{
    color:green;
}
.erroActivationStatus{
    color:red;
}
.no-code{
    font-style:italic;
    cursor:pointer;
    margin-top:10px;
}
/*** END ACTIVATION SCRATCH CARD MODAL  ****/

/**** MESSAGES NOTI *********/
#messages{
    display:inline-block;
    padding: 0 5px;
    color:#fff;
    font-size:2rem;
    cursor:pointer;
    position:relative;
}
#msg-counter{
    position:absolute;
    top:0;
    right:0;
    font-size:0.8rem;
    background-color:red;
    border-radius: 50%;
    width:15px;
    height:15px;
    text-align: center;
}

.msg-box{
    
}
.msg-content{
    background-color:#f3f3f3;
    padding:5px;
    border-left: 2px solid #099;
}
#user-message .login_input{
    width:100%;
}
.msgCount{
    font-size: 14px;
    color:#777;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    /*border: 1px solid #777;*/
    display:inline-block;
    text-align: center;
    position: relative;
    bottom: 10px;
    font-weight: bold;

}
.newMessage{
    background-color: red;
    color:white;

}
.answer{
    border-left: 2px solid #039be6;
    padding: 10px;
    margin-left:50px;
    background-color:#f3f3f3;
}
.statfont{
        color: #666;
    font-size: 20px;
}
.msg-divider{
    height:2px;
    width:100%;
    background-color:#000;
}
.single-msg{
    
    box-shadow:1px 2px 5px #444;
    margin:5px 0;
    
}
.statbox{
    width:24%;
    text-align:center;
    box-shadow:0 1px 3px 2px rgba(0, 0, 0, 0.4), 0 1px 2px rgba(0, 0, 0, 0.16);
    margin:0 5px;
    padding:10px;
    box-sizing:border-box;
    border-radius:5px;
    
}
.stat_title{
    display: block;
}
.stat_counter{
    display: block;
    font-size:3rem;
}
/******* END MESSAGES NOTI *********/
.statsBtn{
    width:32%;
    padding:10px;
    text-align:center;
    display:inline-block;
    box-sizing:border-box;
    background-color:#efefef;
    cursor:pointer;
}
.activeStatBtn{
     background-color:#039ce6;
     color:#fff;
}
/*** ASKING FOR TELEPHONE ********/
.askingForTelephone{
    position:fixed;
    top:50%;
    left:50%;
    -ms-transform:translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform:translate(-50%,-50%);
    background-color:#fff;
    width:80%;
    z-index:51;
    border-radius: 5px;
    text-align: center;
}
.askingForTelephoneOverlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.91);
    z-index: 50;
}

/****** END ASKING FOR TELEPHONE ********/

/******* IFRAME BUILDER ***************/
.iframeCopy{
    height:auto;
    width:100%;
    font-size:2rem;
    display:block;
}
.builder_input{
    width:100px;
    border-radius:5px;
    border: 2px solid #aaa;
    outline:none;
    text-align: right;
}
.propBuilder{
    display:inline-block;
    float:left;
    width:25%;
    font-weight: bold;
}
.clearFloat{
    clear:both;
}
.builder_row{
    display:block;
}
.propBuilder .valign_top{
    vertical-align:middle;
    font-weight:bold;
}
#fixedWidthInput, #fixedHeightInput{
    margin-top:10px;
}
.getCode{
    display:inline-block !important;
}
/******* END IFRAME BUILDER **********/
/******** PARTNERS *******************/
.single_partner{
    padding:25px 15px;
    box-sizing:border-box;
    border:1px solid transparent;
    background-color:#F8F8F8;
    margin:10px 0;
    box-shadow:0 1px 3px 2px rgba(0, 0, 0, 0.09), 0 1px 2px rgba(0, 0, 0, 0.16);
}
.single_partner:hover{
    border:1px solid #ccc;
    cursor:pointer;
}
.single_partner img{
    float:left;
    width:150px;
    height:auto;
}
.partnerDetails{
    display:inline-block;
    margin-left:10px;
}
.single_partner h1{
    margin-top:0;
    margin-bottom:15px;
}
.single_partner .partner_link{
    display:inline-block;
    padding:10px 15px;
    background-color:#FFA500;
    color:#fff;
    margin-top:15px;
    border-radius: 20px;
}
.partners-heading{
    text-align:center;
    font-size:2.4rem;
    color:#424242;
}
/********** END PARTNERS ***********/

/******** BACK TO SCHOOL PROMOTION ************/
#backToSchoolModal{
    position:fixed;
    top:50%;
    left:50%;
    -ms-transform:translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform:translate(-50%,-50%);
    background-color:#fff;
    width:80%;
    z-index:51;
    border-radius: 5px;
    text-align: center;
    padding:15px;
    box-sizing:border-box;
   /* background-image:url('../images/green-board.jpg');
    background-repeat:no-repeat;
    background-size:cover;*/
   background: #63856a; /* Old browsers */
background: -moz-radial-gradient(center, ellipse cover,  #436f4c 1%,#2f3c31 100%); /* FF3.6+ */
background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(1%, #436f4c), color-stop(100%,#2f3c31)); /* Chrome,Safari4+ */
background: -webkit-radial-gradient(center, ellipse cover,  #436f4c 1%,#2f3c31 100%); /* Chrome10+,Safari5.1+ */
background: -o-radial-gradient(center, ellipse cover,  #436f4c 1%,#2f3c31 100%); /* Opera 12+ */
background: -ms-radial-gradient(center, ellipse cover, #436f4c 1%,#2f3c31 100%); /* IE10+ */
background: radial-gradient(ellipse at center,  #436f4c 1%,#2f3c31 100%); /* W3C */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#63856a', endColorstr='#3c5a40',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

    color:#fff;
    overflow:hidden;
    max-height:80%;
 
}
.backToSchoolOverlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.91);
    z-index: 50;
}
.promotionLeft, .promotionRight{
    width:50%;
    float:left;
    position:relative;
}
.promotionAmount{
    width:250px;
    height:250px;
    background-color:#b50027;
    color:#fff;
    position:relative;
    margin:0 auto;
    border-radius:50%;
    padding:15px;
    position:absolute;
    top:50%;
    left:50%;
    -ms-transform:translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform:translate(-50%,-50%);
    box-shadow:0 1px 3px 2px rgba(0, 0, 0, 0.4), 0 1px 2px rgba(0, 0, 0, 0.16);
}
.promotionAmount span{
    position:absolute;
    top:50%;
    left:50%;
    -ms-transform:translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform:translate(-50%,-50%);
    font-size:7rem;
    font-weight:bold;
}
.promotionLeft h1{
    text-transform:uppercase;
    font-size:3rem;
}
#kidsImg{
    /*position:absolute;
    bottom:0%;
    left:50%;
    -ms-transform:translateX(-50%);
    -webkit-transform:translateX(-50%);
    transform:translateX(-50%);*/
    height: 150px;
    
}
.closeModalSchool{
    position:absolute;
    top:0;
    right:0;
    background-color:#b50027;
    color:#fff;
    padding:15px;
    cursor:pointer;
    font-size:2rem;
}
.closeModalFromBtn{
    position:absolute;
    top:0;
    right:0;
    background-color:#b50027;
    color:#fff;
    padding:15px;
    cursor:pointer;
    font-size:2rem;
}
.promotionValid{
    font-style:italic;
    text-align:center;
    display:block;
    font-weight:bold;
    width:100%;
    font-size:1.2rem;
}
.specialRibbon {
      width: 80%;
    height: 60px;
    margin: 10px auto;
  position: relative;
  color: #fff;
  font: 34px/60px sans-serif;
  text-align: center;
  text-transform: uppercase;
  background: #FF9800;
  font-weight:bold;
  -webkit-animation: main 250ms;
  -moz-animation: main 250ms;
  -ms-animation: main 250ms;
  animation: main 250ms;
}

.specialRibbon i {
  position: absolute;
}

.specialRibbon i:first-child, .specialRibbon i:nth-child(2) {
  position: absolute;
  left: -20px;
  bottom: -20px;
  z-index: -1;
  border: 20px solid transparent;
  border-right-color: #043140;
  
  -webkit-animation: edge 500ms;
  -moz-animation: edge 500ms;
  -ms-animation: edge 500ms;
  animation: edge 500ms;
}

.specialRibbon i:nth-child(2) {
  left: auto;
  right: -20px;
  border-right-color: transparent;
  border-left-color: #043140;
}

.specialRibbon i:nth-child(3), .specialRibbon i:last-child {
  width: 20px;
  bottom: -20px;
  left: -60px;
  z-index: -2;
  border: 30px solid #7d4b00;
  border-left-color: transparent;
  
  -webkit-animation: back 600ms;
  -moz-animation: back 600ms;
  -ms-animation: back 600ms;
  animation: back 600ms;
  
  -webkit-transform-origin: 100% 0;
  -moz-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0;
}

.specialRibbon i:last-child {
  bottom: -20px;
  left: auto;
  right: -60px;
  border: 30px solid #7d4b00;
  border-right-color: transparent;

  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
}


/* animations */

@-webkit-keyframes main {
  0% { -webkit-transform: scaleX(0); }
  100% { -webkit-transform: scaleX(1); }
}

@-webkit-keyframes edge {
  0%, 50% { -webkit-transform: scaleY(0); }
  100% { -webkit-transform: scaleY(1); }
}

@-webkit-keyframes back {
  0%, 75% { -webkit-transform: scaleX(0); }
  100% { -webkit-transform: scaleX(1); }
}


@-moz-keyframes main {
  0% { -moz-transform: scaleX(0); }
  100% { -moz-transform: scaleX(1); }
}

@-moz-keyframes edge {
  0%, 50% { -moz-transform: scaleY(0); }
  100% { -moz-transform: scaleY(1); }
}

@-moz-keyframes back {
  0%, 75% { -moz-transform: scaleX(0); }
  100% { -moz-transform: scaleX(1); }
}


@keyframes main {
  0% { transform: scaleX(0); }
  100% { transform: scaleX(1); }
}

@keyframes edge {
  0%, 50% { transform: scaleY(0); }
  100% { transform: scaleY(1); }
}

@keyframes back {
  0%, 75% { transform: scaleX(0); }
  100% { transform: scaleX(1); }
}
.banner_promotion_parents{
    /*background-image:url('../images/black-board.jpg');*/
    background: #63856a; /* Old browsers */
background: -moz-radial-gradient(center, ellipse cover,  #6262621%, #000 100%); /* FF3.6+ */
background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(1%,#626262), color-stop(100%,#000)); /* Chrome,Safari4+ */
background: -webkit-radial-gradient(center, ellipse cover,  #626262 1%,#000 100%); /* Chrome10+,Safari5.1+ */
background: -o-radial-gradient(center, ellipse cover,  #626262 1%,#000 100%); /* Opera 12+ */
background: -ms-radial-gradient(center, ellipse cover,  #626262 1%,#000 100%); /* IE10+ */
background: radial-gradient(ellipse at center,  #626262 1%,#000000 100%); /* W3C */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#63856a', endColorstr='#3c5a40',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

    background-repeat:no-repeat;
    background-size:cover;
    text-align:center;
    position:relative;
    width:100% !important;
}
.banner_promotion_home{
    background:rgb(63, 81, 181);
    background-size:30%;
    background-repeat:repeat;
    
    position:relative;
}
.overlayPromotionBanner{
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-image:url('../images/school-bg.png');
    opacity:0.3;
    background-repeat: repeat;
    background-size:30%;
}
.banner_promotion_parents h1{
    text-align:center;
    font-weight:bold;
    color:#FF9800;
    font-size:3rem;
    text-transform:uppercase;
    margin:0;
    
}
.banner_promotion_in_bill h1, .banner_promotion_in_parents h1{
    margin-top:40px;
}
.banner_promotion_in_parents{
    overflow:hidden;
}
.banner_promotion_in_parents .pencils{
    position:absolute;
    bottom:-15px;
    left:0;
   /* background-image:url('../images/pencils.png');*/
    width:300px;
    height:200px;
    background-size:cover;
    opacity:0.8;
    z-index:1;
}
.banner_promotion_in_parents .pencils_right{
    left:auto;
    right:0;
}
.banner_promotion_in_parents .content{
    position:relative;
    z-index:2;
}
.banner_promotion_parents h2{
    text-align:center;
    font-weight:bold;
    color:#fff;
    font-size:7rem;
    margin:0;
}
.banner_promotion_parents h3{
    text-align:center;
    font-weight:bold;
    color:#fff;
    font-size:2rem;
    margin:0;
}
.banner_promotion_parents h4{
     text-align:center;
    font-weight:bold;
    color:#fff;
    font-size:1rem;
    margin:0;
    font-style: italic;
}
.bannerBtn{
    display:inline-block;
    text-align:center;
    padding: 10px 20px;
    background: #FF9F00;
    color:#fff !important;
    border-radius: 39px;
    margin-top:15px;
    font-weight:bold;
    font-size:1.5rem;
}
 .bannerBtn:hover{
    color:#234465 !important;
    background:#fff;
}
/********* END BACK TO SCHOOL PROMOTION ************/


/****** VIDEO SECTION *****/
.videoContainer{
    width:50%;
    float:left;
}
.videoContainerText{
    width:50%;
    float:left;
}
.videoBg{
    background: #099;
    color: #fff;
}
.videoContainerText h1{
    font-size:3rem;
}
.videoContainerText p{
    font-size:1rem;
}

.videoInner {
    width: 100%;
    max-width: 900px;
    max-height: 400px;
    margin: 0 auto;
}
.videoInner div {
    position: relative;
    padding-top: 25px;
    padding-bottom: 67.5%;
    height: 0;
}
.videoInner iframe {
    background:#cbcbcb;
    box-sizing: border-box;
    background: url(../images/browser.png) center center no-repeat;
    background-size: contain;
    /*padding: 11.9% 15.5% 14.8%;*/
    padding-top: 13.6%;
    padding-bottom: 18.5%;
    padding-left: 13.2%;
    padding-right: 15.2%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.videoBtn{
    display:inline-block;
    padding:10px;
    margin-top:20px;
    background:#FFA500;
    font-weight: bold;
}
.outerVideo .buttaforen_img{
    padding-top: 13.9%;
    padding-bottom: 18.5%;
    padding-left: 13.2%;
    padding-right: 15.2%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(../images/browser.png);
        background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    box-sizing: border-box;
}
/****** END VIDEO SECTION *******/
/****** FREE COURSE BANNER *******/
.freeCourseBg{
    background: #039ce6;
    color:#fff;
}
.freeCourseBgProgramming{
    margin-top:60px;
}
.free-course-subtitle{
    font-size:2.2rem;
    font-weight:normal;
}
.freeCourseBtn{
    display: inline-block;
    padding: 10px;
    margin-top: 20px;
    background: #FFA500;
    font-weight: bold;
    border-radius:5px;
    cursor:pointer;
    color:#fff !important;
    font-size:2rem;
    text-align: center;
}
#parentsBanner #see_plans{
    font-size:unset;
    background:#039ce6;
    text-transform: uppercase;
    font-weight:bold;
    font-size:2rem;
}
.freeCourseBgProgramming{
    background:#607d8b;
}
.payment_document{
    background:#FFA500;
    border:none;
    padding:5px;
    cursor:pointer;
    border-radius:10px;
    color:#Fff;
}
/******** END FREE COURSE BANNER ********/

/************* MULTIPLAYER ************/

#multiplayerInvitation{
    position:fixed;
    top:50%;
    left:50%;
    -ms-transform:translate(-50%,-50%);
    -webkit-transform:translate(-50%,-50%);
    transform:translate(-50%,-50%);
    background:#fff;
    z-index:5;
    z-index:41;
    padding:15px;
    display:none;
}
#multiplayerInvitationOverlay{
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background:rgba(0, 0, 0, 0.7);
    z-index:40;
    display:none;
}
#multiplayerInvitation h1{
    text-align:center;
}
#multiplayerInvitation .multiplayer_invite{
    display:inline-block;
    padding:15px;
    box-sizing:border-box;
    width:49%;
    background:#099;
    text-align:center;
    color:#fff;
    cursor:pointer;
    font-weight:bold;
    text-transform:uppercase;
}
#multiplayerInvitation #inviteDenied{
    background:#b50027;
    
}
/************ END MULTIPLAYER ***********/
/******** END PANEL MENU IN PATHS OR COURSES *************/
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .pcText h1{
        width:150%;
    }
    .coursePathList .pcBox.align_right .pcText h1{
        width:100%;
    }
}
.paths_level_profile .oneFourth .icon{
    width:100px;
    height:100px;
}
.paths_level_profile .single_path_label{
    font-size:1.2rem;
}
/*** NEW SPRITES FILES **/
    .home_second_school-bg, .home_second_students, .home_second_win-store-badge, .home_second_free-plan2, .home_second_youtube-cover1, 
    .home_second_email3,.home_second_youtube-cover2
    { max-width: 100%; background-size: 100%; background-image: url('../images/sprites/home_second/home_second2.png'); }

    .home_second_school-bg { background-position: 0 0%; background-size: 57%; }
    .home_second_students { background-position: 0 35.806202%; background-size: 80%; background-repeat: no-repeat; display:none;}
    .home_second_win-store-badge { background-position:0 65.051755%; background-size: 120.300752%; display:none;}
    .home_second_free-plan2 { background-position:-73px 74.249292%; background-size: 283.38477%; }
    .home_second_youtube-cover1 { background-position: 0 85.987493%; background-size: 233.008584%; display:inline-block; width:100%;height:100%;}
    .home_second_youtube-cover2 { background-position: 0 99.371728%; background-size: 234.718826%;  display:inline-block; width:100%;height:100%;}
    .home_second_email3 { background-position: 0 100%; background-size: 494.845361%; }
    
    
    
    
.app_logoswin-store-badge, .app_logosgoogle-play-badge, .app_logosappstore
{ max-width: 100%; background-size: 100%; background-image: url('../images/sprites/app_logos.png'); }
 
.app_logoswin-store-badge { background-position: 0 0%; background-size: 100%; }
.app_logosgoogle-play-badge { background-position: 0 85.197368%; background-size: 141.489362%; }
.app_logosappstore { background-position: 0 100%; background-size: 591.111111%; }



#messagesModal{
    position:fixed;
    top:50%;
    left:50%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform:translate(-50%,-50%);
    -ms-transform:translate(-50%,-50%);
    background:#fff;
    z-index:61;
    padding:15px;
    display:none;
    height:90%;
    max-height:90%;
    width:70%;
}
#messagesOverlay{
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background:rgba(0, 0, 0, 0.67);
    z-index:60;
    display:none;
}
.msgBody{
    display:none;
    padding:5px;
}
.msgHead{
    padding:5px;
}
.closeMsgs{
    position:absolute;
    top:0;
    right:0;
    color:red;
    font-weight:bold;
}
.unread_msg {
    background: #099;
    color: #fff;
}
.msgHead h3{
    margin:0;
    
}
.msgHead h5{
    margin:0;
    
}
.swords{
    display:none !important;
}
.subscription-btn#activateCode{
    display:none;
}
/* === MEDIA QUERIES === */
@media screen and (-webkit-min-device-pixel-ratio:0) {

}
@media only screen  and (max-width : 1824px) {
    .certificate-modal{
        /*width:1150px;*/
    }
}
@media screen and (min-width: 1024px) and (max-width: 1280px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {

    .logoText{font-size:1.35rem !important;}
    a.menuLi{font-size:0.55rem !important;}
}
.home_tripplebadge{
    height:250px;
}
@media screen and (min-width: 1360px) and (max-width: 1440px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {

    .logoText{font-size:1.65rem !important;}
    a.menuLi{font-size:0.65rem !important;}
}
@media screen and (max-width: 1920px) {
    .singleCourseLevel.activeCourseLevel:after{
       border-top: 27px solid transparent;
        border-bottom: 27px solid transparent;
        border-left: 18px solid #FFA500;
        left:100%;
    }
}
@media screen and (min-width:1440px) and (max-width:1920px) and (-ms-high-contrast:none),(-ms-high-contrast:active){
    .singleCourseLevel.activeCourseLevel:after{
          left:99.9%;
    }
}
@media screen and (min-width:1366px) and (max-width:1440px) and (-ms-high-contrast:none),(-ms-high-contrast:active){
    .singleCourseLevel.activeCourseLevel:after{
        left:99.8%;
    }
}
@media screen and (max-width:1580px){
    .home_tripplebadge{
        height:210px;
    }
}
@media screen and (max-width: 1440px) {
  .logo_courses{
    width:85%;
  }
    .home_plans {
        /*background-size: 224.556391%;*/
    }
    .singleCourseLevel.activeCourseLevel:after{
        border-top: 27px solid transparent;
        border-bottom: 27px solid transparent;
        border-left: 18px solid #FFA500;
        left:100%;
    }
    .home_devices-screen-new{
        background-size: 115.285714%;
    }
    .pathArrowARight{
        width: 54px;
    height: 64px;
    bottom: -20px;
    right: -11px;
    }
    .pathArrowBRight{
        top:7.5%;
        width:68px;
    }
    .pathArrowBRight{
        width: 66px;
    }
    .subPathsNavWrapper {

        left:-40%;
    }
    .showHideNavigationMenu{


    }
    .home_home-background{
       /* background-position:0 0%;*/
    }
    .paths_level .level_1{
        height:116px;/*116*/
    }
    .paths_level .level_2{
        height:58%;/*112*/
    }
    .paths_level .level_3{
        height:58%;/*135*/
    }
    .paths_level .level_4{
        height:58%;/*154*/
        /*background-size:contain;*/
    }
    #devices{
        /*background-size:contain;
        background-repeat: no-repeat;*/
        margin-left:0;
    }
    .content {
        width: 1140px;
        margin-left: auto;
        margin-right: auto;
    }
    .content_path{
        /*width: 1000px;*/
        margin-left: auto;
        margin-right: auto;
    }
    #brain{
        width:25%;
    }
    .heroTitle{
        margin-right:355px;
    }
    .stack_logos{
        width:56px;
        height:60px;
        margin-right: -10px;
    }
    .levels .level_title{
        -ms-transform: translateY(27%);
        -webkit-transform: translateY(27%);
            /*transform: translateY(-50%);*/
            transform: translateY(27%);/*83*/
    }
    #part1Block{
        width: 129px;
        height: 60px;
         margin-right: 3%;
        margin-left: 4%;
    }
    #part1Code{
            width: 212px;
            height: 60px;

    }
    .stack_logos_fr{
        margin-right: 1%;/*3*/
        margin-left: 2%;/*4*/
        height:60px;
    }
    #tie{
        /*height:66px;
        width:40px;*/
    }
    .levels #panda{
        height:60px;
    }
    .path-kid{
        width:22%;
    }
    .path_console{
        width:22%;
    }
    .path_mvc{
        width:22%;
    }
    .path-tie{
        /*width:22%;*/
    }
    #nodeLogo{
       /* margin-left:2%;*//*2*/
}
#stack_console{
    /*margin-left:7%;*/

}
a.menuLi{
    padding:18px 9px;
}
.certificate-modal{
    width:1050px;
}

}
@media screen and (max-width: 1367px) {
    .levels .level_title{
        -ms-transform:translateY(27%);/*76*/
        -webkit-transform: translateY(27%);
            transform: translateY(27%);
    }
    /*#part1Block{
        width: 119px;
        height: 50px;
    }
    #part1Code{
            width: 202px;
            height: 50px;
    }*/
     .stack_logos{
        width:55px;
        height:60px;
    }
    .content_path{
        /*width:900px;*/
    }
}
@media screen and (max-width:1366px){
    .certificate-modal{
        width:950px;
    }
    .socialLoginIcon{
        width:50px;
        height:50px;
        line-height:50px;

    }
    .socialLoginIcon{
        font-size:1.5em;
    }
    .panel{
        width:25%;
    }
    .login_label{
        font-size:1em;
    }
    .form_row{
        margin-bottom:20px;
    }
    #socialLogin{
        margin-top:20px;
        margin-bottom:20px;
    }
    .termsWrap{
        margin:10px 12px;
    }
    #loginButton, #registerButton{
        height:45px;
        margin-top:20px;
        font-size:0.9em;
        line-height: 7px;
    }
    #typing-text{
        font-size:2rem;
    }
    .hero_text h3{
        font-size:1.8rem;
    }
    #headerSlide1{
        height:250px;
    }
    #headerSlide2{
        height:250px;
    }
    #headerSlide3{
        height:250px;
    }

    .textUpperSlider h1{
        font-size:2rem;
    }
    .textUpperSlider h3{
        font-size:1.5rem;
    }
    .play_now{
        width:150px;
        height:40px;
        font-size:18px;
    }
    .languageBar{
        top:25%;
    }
    .langLettersFlags{
        /*vertical-align: middle;*/
    }
    .paths_level .level_2{
        height:57%;
    }
    .paths_level .level_4{
        height:57%;
    }
    .paths_level .level_3{
        height:57%;
    }
    .paths_level .oneFourth{
        padding-bottom:13%;
    }
    #goUp{
        width:40px;
        height:40px;
        line-height:34px;
        font-size:2rem;
    }
    .paths_level .single_path_label{
        font-size:20px;
    }
    .see_more_level{
        font-size:13px;
        width:40%;
    }
    .middleBannerTitle{
        font-size:1.5rem;
    }
    .start_now_middle{
        width:100px;
        font-size:15px;
    }
    .logoText{
        font-size:1.7rem;
    }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        .logoText{
          /*  font-size:1.45rem !important;*/
        }
    }
    a.menuLi{
        font-size:0.7rem;
    }
    .singleCourseLevel{
        padding:7px;
        font-size:0.8rem;
    }
    .singleCourseLevel.activeCourseLevel:after{
        border-top: 22.5px solid transparent;
        border-bottom: 22.5px solid transparent;
        left: 100%;
    }
    .heroTitle{
        font-size:2.5rem;
    }
    .hero_text h1{
        font-size:3rem;
    }
    .thought{
        font-size:2rem;
    }
    .brainBannerList{
        font-size:1.2rem;
    }
    #test_info h1, #other_info h1, #badge_info h1{
        font-size:1.8rem;
    }
    #gif1{
        /*height:150px;*/
    }
    #gif2{
       /* height:150px;*/
    }
    #gif3{
       /* height:150px;*/
    }
    #gifsPanel .verticalCenterAbsolute{
        /*position: static;
        -mos-transform:none;
        -webkit-transform:none;
        transform: none;*/
        margin:0;
    }
    .play_now.start_in_section{
        font-size:0.8rem;
    }
    .journey{
        font-size:2.8rem;
    }
    .see_more_level{
        padding:5px;
    }
    .levels .levelsTitle, .levels_on_course .levelsTitle{

    }
    .startCourse{
        font-size:1.5rem;
    }
    .textSize-title{
        font-size:2.5rem;
    }
    .textSize-subTitle{
        font-size:1.4rem;
    }
    .pathTileLink{
        font-size:0.8rem;
    }
    .sub_paths .profilePathHeader{
        padding:16px;
    }
    .heroTitle, .heroSubTitle, .heroOverTitle{
        margin:0;
    }
    .heroTitlePaths{
        text-align:center;
    }
    .heroDescription{
        width:100%;
    }
    .sub_path_description_title{
        font-size:1.5rem;
    }
    .button {
        font-size:0.8rem;
    }
    .narrowContent{
        width:100%;
    }
    .pcText h1{
        font-size:1.5rem;
    }

    #iconTerminal{
        font-size:4rem;
    }
    #iconGame{
        font-size:4rem;
    }
    #iconWeb{

    }
    /*.header_ul li{
        margin-left:0.5rem;
    }
    .featuresDesc{
        font-size:0.75rem;
    }
    .featureTitle{
        font-size:0.9rem;
    }
    .accessTitle{
        font-size:2.5rem;
    }
    .accessDesc{
        font-size:1.5rem;
    }*/
    #devices{
        top:50%;
        -ms-tansform:translateY(-50%);
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 37%;
        height: 74%;
    }
    .logoText{
        font-size:1.5rem;
    }
     @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
         .logoText{
            /* font-size:1.35rem !important;*/
         }
     }
    .home_matdesbg{
            background-size: 335.727273%;
    }
   /* .econtRadioBtns{
        width:49%;
        display:inline-block;
         box-sizing: border-box;
    }
    .econtDataPayment .s2{
        width:49%;
        display:inline-block;
        box-sizing: border-box;
    }*/
   .econt-delivery{
       display:none;
   }
   .econt-person-details{
       display:none;
   }
   .bottomStripeMenu li{
       padding:10px 10px;
   }
   .juniorCourseTree .exerciseJuniorBox .juniorBoxExTitle{
       font-weight:bold;
   }
   @-moz-document url-prefix(){
       .logoText{
           font-size:1.5rem !important;
       }
   }
}
@media screen and (-moz-images-in-menus:0){
    .paths_level .oneFourth{
        padding-bottom:140px;
    }
}
@media screen and (-moz-images-in-menus:0) and (max-width:1366px){
    .paths_level .oneFourth{
        padding-bottom:140px;

    }
}
@media screen and (max-width: 1366px) and (max-height: 768px){
    /********.juniorCourseTree .exerciseJuniorBox .junniorBoxSubTitle {
        font-size:10px;
    }
    .arr_con_onHome{
        top:42%;
    }
    .path_labels{
        overflow:hidden;
    }
    #path_2{
        width:28%;
    }
    #path_3{
        width:23%;
    }
    .single_path_label{
        font-size:15px;
    }
    #path_4 {
        width: 20%;
    }
    .class1024{
        width:32%;
    }
    .pathTileText h1{
        font-size:0.8rem;
    }
    .pathTile:last-child{
        margin-left:0;
    }
    .levels #panda{
        width:80px;
        height:40px;
    }
    .all_matdesbg{

    }
    .courseTitle{
        font-size:0.8rem;
        margin-right:100px;
    }
    .levels .courseTitleLevels{
        font-size:1.2rem;
    }
    .levels .sub_stack_logos{
        height:40px;
        width:40px;
    }
    .stack_logos_fr{
        height:60px;
    }*/
    /*.journey{
        font-size:1.2rem;
    }*/
    /************.courseTileButtons {
        font-size: 0.6rem;
        right:9px;
    }
    .materialButton{
        padding: 8px 7px;
    }
    .juniorCourseTree .exerciseJuniorBox{

    }
    .juniorCourseTree .exerciseJuniorBox .juniorBoxExTitle{
        font-size:0.7rem;
        top:-30px;
    }
    .juniorCourseTree .exerciseJuniorBox .junniorBoxTitle{
        font-size:0.6rem;
    }
    .juniorCourseTree .exerciseJuniorBox .junniorBoxCoinsCount{
        font-size:0.6rem;
        left:3px;
    }
    .juniorCourseTree .exerciseJuniorBox .junniorBoxCoinsCount img{
        width:20px;

    }
    .juniorCourseTree .exerciseJuniorBox .junniorBoxMax{
        font-size:0.6rem;
    }
    .juniorCourseTree .exerciseJuniorBox .junniorBoxSubTitle{
        font-size:0.5rem;

        left:50%;
        -ms-transform:translateX(-50%);
        -webkit-transform:translateX(-50%);
        transform:translateX(-50%);
    }
    .narrowContent{

    }
    #cookieBanner{
        width:-webkit-calc(100% - 50px);
         width:-moz-calc(100% - 50px);
         width:-ms-calc(100% - 50px);
         width:-o-calc(100% - 50px);
        width: calc(100% - 50px);
    }
    .langLettersFlags{
        font-size:12px;
    }
    .courseTileHeader{
        height:120px;
    }*/
    /*.all_ruby{
        width:60px;
        height:60px;
    }
    .all_javascript{
        width:60px;
        height:60px;
    }
    .all_cpp{
        width:60px;
        height:60px;
    }
    .all_csharp{
        width:60px;
        height:60px;
    }
    .all_java{
        width:60px;
        height:60px;
    }
    .all_python{
        width:60px;
        height:60px;
    }*/
    /***********.smallProgressText{
        font-size:7px;
    }
    .levels .level_title{
        display:inline-block;
        font-size:0.9rem;//12
        height:auto;
    }
    .levels .angle_down{
        font-size:2.5rem;
    }*/
    /*.stack_logos{
        width:26px;
        height:26px;
    }
    #nodeLogo{
        width:50px;
        height:20px;
    }
    #laravelLogo{
        width:50px;
        height:20px;
    }
    #djangoLogo  {
        width:50px;
         height:20px;
    }
    #railsLogo{
        width:50px;
        height:40px;
    }
    #tie{
        width:50px;
         height:40px;
    }
    #part1Block{
        width: 65px;
        height: 35px;
    }
    #part1Code{
        width:120px;
        margin-left:7%;
        margin-right:0;
        height:35px;

    }
    .levels #panda{
        width:74px;
         height:35px;
    }*/
    /**********.courseDescription {
        font-size: 0.6rem;
    }
    .courseSubTitle {
        font-size: 0.6rem;
    }
    .profileCoursePercent{
        font-size: 0.6rem;
    }*/

}
@media screen and (min-width:1280px) and (max-width:1366px) and (-ms-high-contrast:none),(-ms-high-contrast:active){
    .singleCourseLevel.activeCourseLevel:after{
        left:99.8%;
    }
}

@media screen and (max-width: 1280px) {
    .home_second_free-plan2{
        background-position: -126px 74.249292%;
        background-size: 332.38477%;
    }
    .home_kids-bg{
        background-size: 137%;
    }
  .payment_plan{
    /*width:49% !important;*/
    margin:5px 0;
  }
  .logo_courses{
    width:100%;
  }
    .certificate-modal{
        width:900px;
    }
    .recordBox{
        width:25%;
        padding:15px;
    }
    .justifyFlexCenterBoth{
            align-items: stretch;
    }
    .home_gif1{
            background-size: 330.666667%;

    }
    .home_plans{
           /* background-size: 163.076923%;*/
          /* background-size:199.076923%;*/
           /*background-size: 302.556391%;*/
    }
    .home_tripplebadge{
        height:160px;
    }
    .home_secondgif{
            background-size: 390.666667%;
    }
    .home_thirdgif{
        background-size: 200.666667%;
    }
    .home_roditeli-kontrol{
         /*background-size: 158.666667%;*/
         background-size:114.666667%;
    }
    .home_email-new {/* background-position: 0 86.138614%;*/ background-size: 687.248322%; }
    .home_devices-screen-new{
        background-size: 145.285714%;
    }
    .pathroad_road{
        background-size:12%;
    }
    #headerSlide1{
       /* height:auto;*/
    }
    .pcText{
        width:50%;
    }
    .sub_path_description{
        float:none;
        width:100%;
    }
    .home_home-background{
        /*background-position:0 0%;*/

    }
    .paths_level .level_1{
        height:100px;
            background-size: 737.333333%;
    }
    .paths_level .level_2{
        height:58%;/*81*/
    }
    .paths_level .level_3{
        height:58%;/*99*/
    }
    .paths_level .level_4{
       height:58%;/*98*/
      /* background-size:120%;
           background-position: -182% 81.192816%;*/
    }
    .arrowPaths .fa-arrow-right{
        right:-37px;
    }
    .subPathsNavWrapper{
        width:200px;
    }
    .sub_paths .profilePathHeader{
        /*padding:30px 16px 30px 200px;
        text-align: center;*/
    }
    .sub_paths .angle_down{
        /*left:150px;*/

    }
    .logo_laravel{

    }
    .footerLiHalf2 {
        font-size: 11px;
        width:80%;
    }
    #laravelLogo{
          width:150px;
    }
    #nodeLogo{
        /*width:100px;*/
    }
    #djangoLogo,railsLogo{
        width:80px;
    }
    #tie{
        /*margin-left:31%;*/
    }
    #stack_console{
       /* margin-left:9%;*/
    }
    .stack_logos{
        width:53px;
        margin-right:0;
    }
    .levelsFrameworks{
        display:inline-block;
    }
    #part1Block{
            margin-left: 1%;
    }
    #nodeLogo{
        /*margin-left:5%;*/
    }
    .stack_logos_fr::first-child{

    }
    .stack_logos_fr{
        margin-right: 3%;
        margin-left: 3%;
        height:55px;
    }
    #tie{
        /*height:67px;*/
    }
    #part1Block{
        height:55px;
    }
    #part1Code{
        height:55px;
    }
    .levels #panda{
        height:55px;
    }
    #part1Code{
        margin-right: 4%;
    }
    .levels .level_title{
        font-size:16px;
        -ms-transform: translateY(27%);/*86*/
        -webkit-transform: translateY(27%);
        transform: translateY(27%);
    }
    #devices{

        /*width:100%;*/
       /* margin-top:-150px;*/
       /*margin-top:-80px;*/
        /* margin-left: -50px;
         margin-bottom:-100px;*/
     }
    .heroTitle, .heroSubTitle, .heroOverTitle {
        margin-left: 0px;
    }
    .heroTitle{
        margin-right:362px; /* old 342 */
        margin:0;
    }
    .content {
        /*width: auto;
        margin-left: 32px;
        margin-right: 32px;*/
        /*width:900px;*/
        width:1000px !important;
        margin-left:auto;
        margin-right:auto;
    }
    a.menuLi{
        font-size:0.7rem;
    }
    .logoText{
        font-size:1.5rem;
    }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        .logoText{
           /* font-size:1.35rem;*/
        }
    }
    .content_path{
        /*width:750px;*/
        margin:0 auto;
    }
    .narrowContent {
        width: 100%;
        margin:0 auto;
    }
    .sub_section .narrowContent{
        margin:0 auto;
    }
    .pcText p{
        margin-bottom: 13px;
    }
    .courseDescription {
        /*font-size: 0.8rem;*/
        font-size:1rem;
    }
    .courseTileButtons {
        font-size: 0.7rem;
    }
    .pathProgress {
        width: 30%;
    }
    .continueWrapper {
        width: 50%;
    }
    .current_courseTitle {
        font-size: 1rem;
    }
    .textSize-smallTitle {
        font-size: 1.8rem;
    }
    .floatMenuItem {
        font-size: 0.8rem;
    }
    .floatMsgBox {
        font-size: 0.8rem;
    }
    .professionName {
        font-size: 0.8rem;
    }
    .checkLabel {
        font-size: 1rem;
    }
    .captchaRegister, .captchaLogin {
        transform: scale(0.79);
    }
    .panel {
        width: 240px;
    }
    /*.socialLoginIcon {
        width: 50px;
        height: 50px;
    }*/
    .login_label {
        font-size: 1rem;
    }
    .faq_h1 {
        font-size: 1rem;
    }
    .paddingOn1280 {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    #gifsPanel .paddingOn1280{
        padding-top:20px;
        padding-bottom:20px;
    }
    #gif1, #gif2, #gif3{
        height:200px;
    }
    #gif2{
        height:200px;
    }
    a.menuLi {
        padding-left: 0;
        padding-right: 0;
    }
    .courseSubTitle {
        font-size: 0.8rem;
    }
    .textSize-subTitle {
        font-size: 1.2rem;
    }
    .textSize-p {
        font-size: 1rem;
    }
    .hero_text h1 {
        font-size: 3rem;
    }
    .textSize-title {
        font-size: 2.2rem;
    }
   .smallProgressText {
        font-size: 0.55rem;
        font-size:8px;
    }
    .subscribeButton {
        font-size: 0.8rem;/*old 0.6*/
    }
    .subscribeTitle{
        font-size:1.4rem;
    }
    .paddingFooter {
        padding-bottom: 120px;/*40*/
    }
    .footerCourseText{
        font-size: 0.8rem;
    }
    #goUp {
        height: 40px;
        width: 40px;
        font-size: 2rem;
        line-height: 37px;
        right: 20px;
    }
    #bottom_stripe {
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .bottomStripeMenu, .bottomLeft {
        font-size: 0.7rem;
    }
    .badge_info_box {
        width: 70%;
    }
    .footerContacts {
        margin-left: 0;
    }
    .footer_right {
        width: 70%;
    }

    .startCourse{
       /* font-size:1.6rem;*/
    }
    #footerCourseWrapper .footerCourseName{
        width:75%;
        word-wrap:break-word;
    }
    #brain_new{
        /*width:35%;*/
    }
    .courseDescription {
        /*height:37px;*/
    }
    .journey{
        font-size:2.5rem;
    }
    .start_now_middle{
        font-size:13px;
        height:30px;
        line-height:30px;
    }
    .titleBig{
        font-size:2.5rem;
    }
    .subTitleSmall{
        font-size:1.5rem;
    }
    .play_now{

    }
    #footerBreaker{
        width:50px;
        height:50px;
    }
    /*.subscribeIcon{
        font-size:1.5rem;
    }
    .levels .angle_down{
        font-size:2.5rem;
    }
    .thought{
        font-size:1.8rem;
    }
    .brainBannerList{
        font-size:1.1rem;
    }*/
    .see_more_level{
        font-size:11px;
    }
    .logo{
        padding:15px 0;
    }
    #username{
        font-size:0.9rem;
        display: none !important;
    }
    #wrapperMenu #topRightMenu{
      -ms-transform: translateY(0%) !important;
       -webkit-transform: translateY(0%) !important;
       transform: translateY(0%) !important;
    }
    .user_profile{
        -ms-transform: translateY(0%) !important;
      -webkit-transform: translateY(0%) !important;
       transform: translateY(0%) !important;
       float: right;
       margin-top: 10px;
    }
    #nodeLogo{
        margin:0;
    }
    #tie{
        width:200px;
        height:55px;
    }
    #stack_console{
        width:55px;
        height:55px;
    }
    #nodeLogo{
        height:55px;
    }
    html{
        font-size:14px;
    }
    .bottomStripeMenu li{
        padding:10px;
    }
    .singleCourseLevel.activeCourseLevel:after{
        border-top: 21.5px solid transparent;
        border-bottom: 21.5px solid transparent;
    }
    .logo_matdesbg{
            background-size: 587.954545%;
    }
    ._matdesbg{
         background-size: 987.954545%;
    }
    .home_matdesbg{
        background-size: 482.727273%;
    }
    #firstHeader{
        padding-top:40px;
        padding-bottom:40px;
    }
    #wrapperMenu #topRightMenu{
        margin-right:12px;
    }
    a.menuLi{
        padding:18px 5px;
    }
}
@media screen and (max-width: 1240px) {
    .stack_logos {
            width:50px;
    }
}
@media screen and (max-width: 1024px) {
    .navigation-courses{
      top: -18px;
      left: 6px;
    }
    #contactsInfo .content{
      padding-left:30px;
    }
    .logo_courses{
      width:50%;
    }
    #firstHeader{
      background-attachment: scroll;
    }
    .app_icons_home{
        width:100px;
        height:30px;
    }
    .home_appstore{
        margin-bottom:0;
    }
    .home_win-store{
        margin-bottom:0;
    }
    .certificate-modal{
        width:850px;
    }
    .fullProfile .user_settings_box .itemLabel{
        margin-bottom:30px;
    }
    .single_course_in_structure{
        margin-bottom:40px;
    }
    .current_course_arrow{
        top:-18%;
    }
    .singlePathProgress ul{
        padding:15px;
        box-sizing: border-box;
    }
    .one_third{
        width:100%;
    }
    .path_levels_pic{
        padding-bottom:25%;
    }
    #gifsPanel .half{
      width:50%;
    }
    #firstHeader{
      margin-top:36px;
    }
    .content{
      width:auto !important;

    }
    .home_tripplebadge{
          background-size: 138.666667%;
         /* background-position:-35% 82.909889%;*/
    }
    .pathroad_road{
        background-size:9%;
    }
    .pathArrowARight{
        width: 56px;
        height: 59px;
        bottom: -21px;
    }
    .pathArrowALeft{
       /* bottom:-26%;
        width:56px;
        height:63px;
        left:-8px;*/

    }
    .pathArrowBRight{
        /*top:-19.5%;
        width:70px;*/
    }

    .pathArrowBLeft{
        /*top:-16.5%;*/
    }
    .pathArrowBRoadCont2{
       /* top:32px;*/
    }
    .paths_level .single_path_label{
        font-size:19px;
    }
    .arrowPaths .fa-arrow-right{
        right:-29px;
    }
    .levels .level_title{
        font-size:17px;
    }
    .titleBig{
        font-size:2.5rem;
    }
    .subTitleSmall{
        font-size:1.5rem;
    }
    #brain_new{
       /* width:43%;*/
    }
    .thought{

        font-size:2.2rem;
    }
    #nodeLogo{
        margin-right:0;
    }
    #tie{
        margin-left:0;
        margin-right:0%;
    }
    #stack_console{
        margin-right:0%;
    }
    .levels #panda{
        margin-right:0%;
    }
    .content_path{
        /*width:500px;*/
    }
    .courseTileHeader {
        position: relative;
        height: 120px;
    }
    .footerLiHalf2 {
        font-size: 11px;
    }
    .path-tie{
        width:17%;
    }
     .levels .level_title{
         /*display:block;*/
         -ms-transform: translateY(27%);
         -webkit-transform: translateY(27%);
            transform: translateY(27%);
    }
    #devices{

       /* width:100%;
        margin-top:0px;
         margin-left: 0px;
         margin-bottom:0px;*/
       position: static;
       display: inline-block;
     }
     .devicesText{
         display:inline-block;
         position:static;
         width:49%;
         vertical-align: top;
     }
     .home_devices-screen-new{
         background-size:115.285714%;
     }
    .langBackGround {
        width: 40px;
    }
    #path_pic {
        padding-bottom: 49%;
    }
    #goal_pic {
        padding-bottom: 35.4%;
    }
    #badge-pic{
        padding-bottom:30%;
    }
    .fa_icon_logo {
        line-height: 0px;
        margin-top: 50px;
        text-align: justify;
        font-size: 4rem;
    }
    .about_sec_text {
        font-size: 0.8rem;
    }
    #icon_one {
        top: 26px;
        left: 26px;
    }
    #icon_two, #icon_two1 {
        top: 26px;
        left: 20px;
    }
    #icon_three {
        top: 26px;
        left: 32px;
    }
    .about_sec_textbox {
        width: -webkit-calc(100% - 150px);
        width: -moz-calc(100% - 150px);
        width: -ms-calc(100% - 150px);
        width: -o-calc(100% - 150px);
        width: calc(100% - 150px);
    }
    .image_icon {
        font-size: 3rem;
        width: 100px;
        height: 100px;
    }
    .heroDescription {
        font-size: 1rem;
    }
    .pcBox {
        /*margin-bottom: 60px;*/
    }
    .lineBox {
        /*
        left: 60px;
        right: 60px;
        height: 90px;*/
    }
    .typeB {
        /*top: 150px;*/
        top:200px;
    }
    .typeBRight {
        /*top: 150px;*/
        top:200px;
    }
    .pathArrowBottom {
        font-size: 0.5rem;
        bottom: -8px;
    }
    .pathArrowTop {
        top: -25px;
    }
    .pcText h1 {
        margin-top: 0;
    }
    .pcPic {
       /* width: 120px;
        height: 120px;*/
    }
    .pcPic img {
        /*width: 120px
        height: auto;*/
    }
    .narrowContent {
        width: 100%;
    }
    .supportMessageSentCaptcha {
        transform: scale(0.79);
        bottom: 0;
        left: 0;
    }
    .innerRow {
        font-size: 0.8rem;
        padding-top: 12px;
        padding-bottom: 12px;
    }
    #projectPreview {
        width: 100px;
        height: 125px;
    }
    #expand {
        font-size: 0.8rem;
    }
    .modLiTitle{
        width:73%;
    }
    .courseDescription {
        /*height: 37px;*//*auto*/
    }
    .breadCrumb {
        font-size: 0.7rem;
    }
    .startCourse {
        font-size: 1rem;
    }
    .infoRowBody {
        font-size: 0.8rem;
    }
    .infoRowName {
        font-size: 0.9rem;
    }
    .infoRowIcon {
        font-size: 1.4rem;
        left: -45px;
        top: -4px;
    }
    a.userLi {
        font-size: 0.7rem;
        padding: 5px 10px;
    }
    .subMenuItem {
        padding-top: 2px;
        padding-bottom: 2px;
    }
    .inputWrap {
        margin-bottom: 20px;
    }
    .lastInput {
        margin-bottom: 80px;
    }
    .top_row {
        margin-top: 20px;
    }
    .form_row {
        margin-bottom: 20px;
    }
    #regContent, #loginContent {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .login_input {
        font-size: 0.8rem;
    }
    #coursesList {
        font-size: 1.2rem;
    }
    .coursePathListInGoals {
        font-size: 0.7rem;
    }
    .floatMsgBox {
        padding: 11px;
    }
    .warningIcon {
        display: none;
    }
    .floatMsgBox {
        font-size: 0.8rem;
    }
    .profilePathTitle {
        font-size: 1rem;
    }
    #goalInfoBit {
        font-size: 0.8rem;
    }
    .button {
        font-size: 0.7rem;
        padding: 6px 12px;
    }
    .floater {
        width: 30px;
        height: 30px;
        line-height: 28px;
        font-size: 1.6rem;
    }
    .heroTitle {
        font-size: 1.8rem;
        margin-right: 140px;
    }
    .hero_text h1 {
        font-size: 2.5rem;
    }
    .hero_text h3 {
        font-size: 1.4rem;
    }
    .padding_40 {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    a.menuLi {
        font-size: 0.65rem;
        padding: 12px 6px;
    }
    .logo {
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .logoText {
        font-size: 1.5rem;
    }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        .logoText{
           /* font-size:1.35rem !important;*/
        }
    }
    .textSize-title {
        font-size: 1.6rem;
    }
    .half {
        display: block;
        width: 100%;
    }
    .pathsStarted{
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
    }
    .class1024Show {
        display: block;
    }
    .class1024Hide {
        display: none;
    }
    .test_img, .test_img_badge {
        width: 60%;
        margin-left: 20%;
    }
    #test_info h1, #other_info h1, #badge_info h1 {
        font-size: 1.5rem;
    }
    .pathTile:last-child {
        margin-left: 26%;
    }
    p {
        font-size: 0.8rem;
    }
    .verticalCenterAbsolute {
        position: relative;
    }
    .padding-left-10, .padding-right-10 {
        padding-left: 0;
        padding-right: 0;
    }
    .timeLineCircle, .timeLineDown, .timeLineUp {
        display: none;
    }
    .courseTile {
        margin-bottom: 30px;
    }
    #firstTitleToShow {
        display: none;
    }
    #secondTitleToShow {
        display: block;
        padding-top: 40px;
        padding-bottom: 50px;
    }
    .newsBullet {
        font-size: 3rem;
    }
    .newsBitTitle {
        font-size: 1rem;
    }
    #showAllPaths {
        display: none;
    }
    #hideAllPaths {
        display: none;
    }
    .sectionSubTitle {
        margin-top: 10px;
        margin-bottom: 30px;
    }
    .textSize-p {
      font-size: 0.8rem;
    }
    .class1024 {
        width: 48%;
    }
    .pathTile {

    }
    .pathTileText h1{
        font-size: 1rem;
    }
    .pathTileText p {
        font-size: 0.7rem;
    }
    .pathTileLink {
        font-size: 0.8rem;
    }
    .pathTilePic {
        width: 80px;
    }
    div.pathTilePic {
        width: 80px;
        height: 80px;
    }
    .subscribeIcon {
        font-size: 1rem;
    }
    #footerBreaker {
        height: 36px;
        width: 36px;
    }
    .subscribeTitle {
        font-size: 1rem;
    }
    .subscribeButton {
        font-size: 0.8em; /* old 0.6*/
    }
    .footerBoxTitle  {
        font-size: 1.2rem;
    }
    #footerButton {
        font-size: 0.8rem;
    }
    .footerUl {
        line-height: 1.5rem;
    }
    .paddingFooter {
        padding-bottom: 120px;/*30*/
    }
    #footerPathWrapper {
        width: 90%;
    }
    .footerCourseText{
        font-size: 0.7rem;
    }
    #goUp {
        height: 30px;
        width: 30px;
        font-size: 1.5rem;
        line-height: 30px;
        right: 20px;
        bottom: 35px;
    }
    .bottomStripeMenu, .bottomLeft {
        font-size: 0.6rem;
    }
    .contact_info {
        display: inline-block;
        margin-left: 2%;
        width: 44%;
    }
    .paddingOn1280{
        padding-bottom: 0;
    }
    #footerCourseWrapper .footerCourseName{
        width:63%;
        word-wrap:break-word;
    }
    #wrapperMenu #topRightMenu{
        margin-right: 5px;
    }
    #gif1{
        width:100%;
        margin:0 auto;
    }
    #gif2{
        width:100%;
        margin:0 auto;
    }
    #gif3{
        width:100%;
        margin:0 auto;
    }
    .top-bar{
        width:100%;
        margin:0 auto;
    }
    #devices{
        top: 0;
        -ms-transform: translateY(0);
        -webkit-transform: translateY(0);
        transform: translateY(0);
        width: 47%;
        height: 100%;
    }
    #gifsPanel{
        text-align:center;
    }
    #gifsPanel .verticalCenterAbsolute{
        text-align: center;
    }
    .featuresDesc{
        font-size:1rem;
    }
    .secondTopStripe{
        margin-top:37px;
    }
    .langLettersFlags{
        vertical-align: top;
    }
    .singleCourseLevel.activeCourseLevel:after {
        border-top: 21.5px solid transparent;
        border-bottom: 21.5px solid transparent;
        left: 100%;
    }
    .home_matdesbg{
            background-size: 352.727273%;
    }
    .all_matdesbg{
        /*background-size:195%;*/
    }
    .inputWrap:hover .placeholder, .modern_input:focus + .placeholder, .modern_input:hover + .placeholder {
       opacity: 1;
        top: -20px;
        z-index: 1;
    }
    .levels .courseTitleProfile .fa-plus, .levels .courseTitleProfile .fa-minus{
        -mos-transform:translateY(-44%);
        -webkit-transform:translateY(-44%);
        transform:translateY(-44%);
    }
    .featureTitle .fa-big{
        font-size:4.5rem;
    }
    .pagePath{
        top:-3px;
    }
    #gif1, #gif2, #gif3{
        height:150px;
    }
    #wrongOutput{

    }
    #right-output{

    }
    .courseDescription p{
      font-size:1rem;
    }
    /*.lineBox{
        left:56px;
        right:56px;
    }*/

}
@media screen and (min-width:1024px) and (max-width:1024px) and (-ms-high-contrast:none),(-ms-high-contrast:active){
    font-size:1rem;
}
@media screen and (max-width: 1000px) {
    .pathroad_road{
        background-size:12%;
    }
}
@media screen and (max-width: 1024px) and (max-height: 768px){
    .thought{
        font-size:2.2rem;
    }
    .path_labels{
        overflow: hidden;
    }
    .arr_con_onHome{
        top:42%;
    }
    .class1024{
        width:32%;
    }
    .pathTile:last-child{
        margin-left:0;
    }
    .all_matdesbg{
       /* background-size: 160%;*/
    }
    #path_2{
        width:32%;
    }
    #path_3{
        width:27%;
    }
    .single_path_label{
        font-size:15px;
    }
    .juniorCourseTree .exerciseJuniorBox{
       /* margin-right:0;
        width:27%;
        height:12%;*/
    }
    .juniorCourseTree .exerciseJuniorBox .juniorBoxExTitle{
        font-size:0.7rem;
        top:-30px;
    }
    .juniorCourseTree .exerciseJuniorBox .junniorBoxTitle{
        font-size:0.7rem;
    }
    .juniorCourseTree .exerciseJuniorBox .junniorBoxCoinsCount{
        font-size:0.7rem;
        left:7px;
        bottom:11px;
    }
    .juniorCourseTree .exerciseJuniorBox .junniorBoxCoinsCount img{
        width:20px;

    }
    .juniorCourseTree .exerciseJuniorBox .junniorBoxMax{
        font-size:0.7rem;
    }
    .juniorCourseTree .exerciseJuniorBox .junniorBoxSubTitle{
        font-size:0.5rem;
       /* bottom:-8px;*/
        left:50%;
        -ms-transform:translateX(-50%);
        -webkit-transform:translateX(-50%);
        transform:translateX(-50%);
    }
    .juniorCourseTree .exerciseJuniorBox:nth-child(3n+4) .arrowExDown {
        top: -81%;
    }
    .juniorCourseTree .exerciseJuniorBox:nth-child(3n+4) .fa-angle-down{
        top: -50%;

    }
    .materialButton {
        padding: 8px 14px;
    }
    .courseTitleLevels{
        font-size:1.1rem;
    }
    .levels .sub_stack_logos{
        width:35px;
        height:35px;
    }
    .consoleCourses{
        margin-top:0px;
    }
    .narrowContent{

    }
    .courseTitle{
        font-size:1rem;
    }

}

@media screen and (max-width: 1280px) and (max-height: 720px){
    #path_2{
        width:28%;
    }
    #path_3{
        width:27%;
    }
    .single_path_label{
        font-size:15px;
    }
    .juniorCourseTree .exerciseJuniorBox:nth-child(3n+4) .fa-angle-down {
        top: -45%;
    }
    .juniorCourseTree .exerciseJuniorBox:nth-child(3n+4) .arrowExDown{
            height: 37%;
    }
}
@media screen and (max-width: 1280px) and (max-height: 1024px){
    #path_2{
        width:28%;
    }
    #path_3{
        width:27%;
    }
    .single_path_label{
        font-size:15px;
    }
    .juniorCourseTree .exerciseJuniorBox:nth-child(3n+4) .fa-angle-down{
        top:-5%;
    }
    .juniorCourseTree .exerciseJuniorBox .junniorBoxCoinsCount{
        left:4px;
    }
    .juniorCourseTree .exerciseJuniorBox{
       /* margin-right:0;
        width:27%;
        height:12%;*/
    }
    .juniorCourseTree .exerciseJuniorBox .juniorBoxExTitle{
        font-size:0.7rem;
        top:-30px;
    }
    .juniorCourseTree .exerciseJuniorBox .junniorBoxTitle{
        font-size:0.7rem;
    }
    .juniorCourseTree .exerciseJuniorBox .junniorBoxCoinsCount{
        font-size:0.7rem;
        left:7px;
        bottom:11px;
    }
    .juniorCourseTree .exerciseJuniorBox .junniorBoxCoinsCount img{
        width:20px;

    }
    .juniorCourseTree .exerciseJuniorBox .junniorBoxMax{
        font-size:0.7rem;
    }
    .juniorCourseTree .exerciseJuniorBox .junniorBoxSubTitle{
        font-size:0.5rem;
        /*bottom:-8px;*/
        left:50%;
        -ms-transform:translateX(-50%);
        -webkit-transform:translateX(-50%);
        transform:translateX(-50%);
    }
    .juniorCourseTree .exerciseJuniorBox:nth-child(3n+4) .arrowExDown {
        /*top: -81%;*/
        top:-54%;
    }
    .juniorCourseTree .exerciseJuniorBox:nth-child(3n+4) .fa-angle-down{
        /*top: -50%;*/
        top:-27%;
    }

}
@media screen and (max-width:820px){
    .overflowedCourses{
      width:100% !important;
    }
    .teacherBox{
      width:100%;
    }
}
@media screen and (max-width:800px){
    exerciseJuniorBox .arrowExLong{
        left:-50%;
    }
     #gifsPanel{
        text-align:center;
    }
    .verticalCenterAbsolute{
        text-align: center;
    }
    .juniorCourseTree .exerciseJuniorBox:nth-child(3n+1) .arrowExLong{
        -mos-transform:translateX(50%);
        -webkit-transform:translateX(50%);
        transform:translateX(50%);
        left:0 !important;
    }
    .juniorCourseTree .exerciseJuniorBox:nth-child(3n+3) .arrowExLong{
        left:auto;
        right:0 !important;
        -mos-transform:translateX(-50%);
        -webkit-transform: translateX(-50%);
        transform:translateX(-50%);
    }
    .arrowExLong{
        width:100% !important;
    }
    .logoText{
        font-size:1.2rem;
    }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        .logoText{
            /*font-size:1rem !important;*/
        }
    }
    #devices{
        height:80%;
    }
    .padding_80{
        padding-top:20px;
        padding-bottom:20px;
    }
    .juniorCourseTree{
        width:100%;
    }
    .courseInfoWrapper{
        width:90%;
    }
}
@media screen and (max-width: 800px) and (min-width:780px) and (max-height: 600px){
    #devices{
        height:100%;
    }
    .content{

    }
    .thought{

        font-size:2.2rem;
    }
    .bottomStripeMenu li {
        padding:10px 5px;
    }
    .footerLiHalf2 {
        width: 80%;
    }
    .logoText{
        font-size:1.3rem;
    }
     @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
         .logoText{
             /*font-size:1.15rem !important;*/
         }
     }
    a.menuLi{
        font-size:8px;
    }
    .arrow_connection{
        font-size:30px;
    }
    .path_mvc{
        width:20%;
    }
    .path_kid{
        width:20%;
    }
    .path_console{
        width:20%;
    }
    .path_tie{

    }
    .arr_con_onHome{
        top:42%;
    }
    .path_labels{
        overflow:hidden;
    }
    #path_2{
        width:32%;
    }
    #path_3{
        width:27%;
    }
    .single_path_label{
        font-size:15px;
    }
    .thought{
        font-size:1.6rem;
    }
    .class1024{
        width:32%;
    }
    .pathTileText h1{
        font-size:0.8rem;
    }
    .pathTile:last-child{
        margin-left:0;
    }
    .levels #panda{
        width:144px;
    }
    .all_matdesbg{
        background-size:218%;
    }
    .courseTitle{
        font-size:0.8rem;
        margin-right:70px;
    }
    .levels .courseTitleLevels{
        font-size:1.2rem;
    }
    .levels .sub_stack_logos{
        height:40px;
        width:40px;
    }
    .stack_logos_fr{
        height:60px;
    }
    .journey{
        font-size:1.2rem;
    }
    .courseTileButtons {
        font-size: 0.6rem;
        right:9px;
    }
    .materialButton{
        padding: 8px 7px;
    }
    .juniorCourseTree .exerciseJuniorBox{
        margin-right:0;
        width:27%;
        height:11%;
    }
    .juniorCourseTree .exerciseJuniorBox .juniorBoxExTitle{
        font-size:0.7rem;
        top:-30px;
    }
    .juniorCourseTree .exerciseJuniorBox .junniorBoxTitle{
        font-size:0.5rem;
    }
    .juniorCourseTree .exerciseJuniorBox .junniorBoxCoinsCount{
        font-size:0.5rem;
        left:3px;
    }
    .juniorCourseTree .exerciseJuniorBox .junniorBoxCoinsCount img{
        width:20px;

    }
    .juniorCourseTree .exerciseJuniorBox .junniorBoxMax{
        font-size:0.5rem;
    }
    .juniorCourseTree .exerciseJuniorBox .junniorBoxSubTitle{
        font-size:0.4rem;
        /*bottom:-8px;*/
        left:50%;
        -ms-transform:translateX(-50%);
        -webkit-transform:translateX(-50%);
        transform:translateX(-50%);
    }
    .narrowContent{

    }
    #cookieBanner{
        width:-webkit-calc(100% - 50px);
         width:-moz-calc(100% - 50px);
         width:-ms-calc(100% - 50px);
         width:-o-calc(100% - 50px);
        width: calc(100% - 50px);
    }
    .langLettersFlags{
        font-size:12px;
    }
    .courseTileHeader{
        height:120px;
    }
    .all_ruby{
        width:60px;
        height:60px;
    }
    .all_javascript{
        width:60px;
        height:60px;
    }
    .all_cpp{
        width:60px;
        height:60px;
    }
    .all_csharp{
        width:60px;
        height:60px;
    }
    .all_java{
        width:60px;
        height:60px;
    }
    .all_python{
        width:60px;
        height:60px;
    }
    .smallProgressText{
        font-size:7px;
    }
    .levels .level_title{
        display:inline-block;
        font-size:12px;
    }
    .stack_logos{
        width:26px;
        height:26px;
        margin-right:-10px;
    }
    #nodeLogo{
        width:50px;
        height:20px;
    }
    #laravelLogo{
        width:50px;
        height:20px;
    }
    #djangoLogo  {
        width:50px;
         height:20px;
    }
    #railsLogo{
        width:50px;
        height:40px;
    }
    #tie{
        width:50px;
         height:40px;
    }
    #part1Block{
        width: 65px;
        height: 35px;
    }
    #part1Code{
        width:120px;
        margin-left:7%;
        margin-right:0;
        height:35px;

    }
    .levels #panda{
        width:74px;
         height:35px;
    }
    .courseDescription {
        /*font-size: 0.6rem;*/
        font-size:1rem;
    }

    .courseSubTitle {
        font-size: 0.6rem;
    }
    .profileCoursePercent{
        font-size: 0.6rem;
    }
    #gif1{
        width:100%;
    }
    #gif2{
        width:100%;
    }
    #gif3{
        /*width:100%;*/
        width:41%;
    }
    .top-bar{
        width:100%;
    }
    #gifsPanel{
        text-align:center;
    }
}
@media only screen
    and (min-device-width : 768px)
    and (max-device-width : 1024px)
    and (orientation : portrait) {
    .path_mvc{
        width:20%;
    }
    .path_kid{
        width:20%;
    }
    .path_console{
        width:20%;
    }
    .path_tie{

    }
    #path_2{
        width:28%;
    }
    #path_3{
        width:27%;
    }
    .arr_con_onHome{
        top:42%;
    }
    .path_labels{
        overflow:hidden;
    }
    .thought {
        font-size:2.2rem;
    }
    .justifyFlex{
        flex-direction:row !important;
        justify-content: space-around;
    }
    .juniorCourseTree .exerciseJuniorBox .junniorBoxCoinsCount {
        left: 50% !important;
        -ms-transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        font-size: 13px;
        bottom: 15px;
    }
}
@media screen and (max-width: 800px) {
  #username{
    display:none;
  }
}
@media screen and (max-width: 780px) {
    .videoInner iframe{
        padding-top: 15.6%;
        padding-bottom: 20.5%;
    }
    .outerVideo .buttaforen_img{
        padding-top: 15.9%;
        padding-bottom: 19.5%;
    }
    .badge_in_course_tile{
        height:39px;
    }
    .home_second_free-plan2{
        display:none;
    }
    .parents_summer{
        background-size: 416.001828%;
    }
    .home_second_school-bg{
        background-position: 0 0%;
        background-size: 213%;
    }
    .button.continuePlan{
       /* padding-left:0;*/
    }
    .stripe{
        width:100% !important;
    }
    .middleStepFeatureBill{
        position:fixed;
        width:95%;
    }
    .middleStepFeatureBill .closeFeatures{
        right:15px;
        font-size:1.5rem;
        top:6px;
        
    }
    .middleStepFeatureBill #picturePathsSection, .middleStepFeatureBill #pictureGoalsSection{
        padding-top:0;
        padding-bottom:0;
    }
    .middleStepFeatureBill .gif_desc{
        display:none;
    }
     
    .middleStepFeatureBill .feature-title:nth-child(2){
        display: none;
    }
    .middleStepFeatureBill .including-row .feature-title {
        font-size:1.3rem;
        font-weight: normal;
    }
    .inactivePlan{
        display:block;
    }
    .planBtn{
        display:none;
    }
    .justifyFlexSlider{
        display: block;
    }
    .banner_promotion_in_bill{
        margin-top:-15px;
    }
    .promotionLeft h1{
        font-size:2rem;
    }
    #kidsImg{
        height:100px;
    }
    .home_second_students{
        background-position:66px 35.806202%;
        background-size: 63%;
        background-repeat: no-repeat;
    }
    .bannerBtn{
        font-size:1rem;
    }
    .stripe.videoBg{
        width:100%;
    }
    .promotionBtnFixed{
        font-size:1.5rem;
    }
    #firstHeader #start_now, #firstHeader #play{
        font-size:1.5rem;
    }
    .freeCourseBgProgramming{
        margin-top:0;
    }
    
    .videoContainer{
        width:100%;
        float:left;
    }
    .videoContainerText{
        width:100%;
        float:left;
    }
    .videoBg{
        text-align:center;
    }
    #user_avatar{
        /*width:40px !important;
        height:40px !important;*/
    }
    .banner_promotion_in_bill h1, .banner_promotion_in_parents h1{
        margin-top:0;
    }
    .specialRibbon{
        display:none;
    }
    .promotionLeft, .promotionRight{
        width:100%;
        text-align:center;
       
    }
    .promotionAmount{
        position:static;
         -ms-transform:none;
        -webkit-transform: none;
        transform: none;
        width:60px;
        height:60px;
    }
    .promotionAmount span{
        font-size:2rem;
    }
    .closeModalSchool{
        padding:5px;
        font-size:1.5rem;
    }
    #backToSchoolModal{
        max-height:90%;
    }
    .promotionLeft h2{
        margin-top:5px;
    }
    .promotionLeft h1{
        margin-top:0;
    }
    #backToSchoolModal{
        padding:5px;
    }
    .single_partner img{
        float:none;
        width:100%;
    }
    #messages{
        display:none;
    }
    .activeScratchAccount button{
        width:100%;
    }
    .activeScratchAccount .activation_input{
        width:100%;
    }
  #navigationDrawer{
    width:90%;
  }
  .navigation-courses{
    /*top: 15px;*/
    top:-15px;
    left: -2px;
    color:#000;
  }
  .startCourse{
      -ms-transform:none !important;
      -webkit-transform: none !important;
      transform: none !important;
      display:inline-block !important;
  }
  #contactsInfo .content{
    padding-left:30px;
  }
  .payment_plan{
    width:95% !important;
    /*margin:5px 0;*/
    margin:20px auto;
    position:relative;
    overflow:hidden;
  }
  
  .payment_title,.parents_payment .payment_title{
      font-size:1.7rem;
      height:125px;
  }
  .seeInfo1, .seeInfo2{
      display:inline-block;
      margin-bottom: 15px;
      position: absolute;
        bottom: 0;
        left:5px;
  }
  .plan_description{
      padding:10px 5px;
      padding-bottom:35px !important;
  }
  .plan_description p{
      font-size:1.2rem;
  }
  .activePlan{
      display:block;
  }
  .promotion-left .payment_plan{
    width:100%;
  }
  .user_profile{
    margin-top:0;
  }
    .col-btn{
      width:100%;
      font-size:1rem;
    }
    #freeCourseBanner{
      margin-top:0;
    }
    .freeCourseBox{
      width:100%;
      background-size:338.863636% !important;
    }
    .emailInBanner{
      float:none;
      display:block;
    }
    .playNowFixed{
        display:none;
    }
    .panel.panelForget{
        width:100%;
    }
    .why-text{
        /*margin-left: 50px;*/
        display: inline-block;
        width: 80%;
    }
    .check_parents li{
        display: -ms-flexbox;
        display: -webkit-flex;
        -webkit-justify-content: space-between;
        -webkit-flex-wrap: wrap;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: stretch;
        -webkit-align-items: stretch;
        margin:10px 0;
    }
    .why-banner{
        font-size:1rem;
    }
    .why-box span{
        width:120px;
        height:120px;
    }
    .check_parents .check-circle{
        top:0;
    }
    .box_notification .pboxtitle{
        display:inline-block !important;
        font-size:0.8rem !important;
    }
    .copy_link{
        display:none;
    }
    .promotion-amount{
        position: absolute;
        width: 100%;
        height: 250px;
        margin-top: 10px;
        left: 0;
        top: 15px;

    }
    .promotion-left{
        margin-left:0;
      /*  margin-top:250px;*/
    }
    .promotion-amount span p{
        font-size:4rem;
    }
    .promotion-top{
        padding-top:250px;
    }
    .promotion-amount-small{
        height:200px;
    }
    .promotion-amount-small span{
        position:static;
        -ms-transform: none;
        -webkit-transform:none;
        transform:none;
    }
    .learn-code{
        text-shadow: none;
        font-size:1.5rem;
        line-height:normal;
        letter-spacing:normal;
    }
    .share-list{
        padding:0;
        margin:0;
    }
    .share-list li{
        width:100%;
    }
    .friends_box{
        width:24%;
    }
    .wider-box{
        width:100%;
    }
    .subscrioption_active.subscrioption_active-mobile{
        display:none;
    }
    .fplan1{
        width:100%;
    }
    .subscrioption_active{
        display: none;
    }
    .gifsSectionTitle{
        font-size:2.1rem;
    }
    .textSize-title{
        font-size:2.1rem;
    }
    .levels-box h2{
        font-size:2.1rem;
    }
    .orderTop2{
        font-size:1.2rem;
        font-weight: bold;
    }

    .top-bar.narrowBar{
        width:41%;
    }
    .home_plans{
        background-size: 81.076923%;
            background-position: 158% 37.130484%;
    }
    .startCourseButton{
        width:100%;
    }
    .popular-courses .courseDescription{
        display:block;
    }
    .app_icons_home{
        width:100px;
        height:35px;
        margin-top:0;
    }
    .app_icons_bill{
        width:100px;
        height:30px;
        margin-top:0;
    }
    .pathsActivitySlider{
        width:100%;
    }
    .home_kids-bg{
        background-size:170%;

    }
    #firstHeader{
        background-attachment: scroll;
    }
    .certificate-modal{
        padding:0;
        /*padding-top:32px;*/
        /*padding-bottom:32px;*/
    }
    .heroSection_programming-kids{
      display: block;
    }
    .heroSection_courses{
        display:block;
    }
    .heroSection_parents {
        display:block;
    }
    .heroSection_bill {
        display:block;
    }
    .flatStartCourseBtn .floatMenuNew{
        width:110%;
    }
    .why_start_section .half{
        margin-bottom:20px;
    }
    .subscrioption_active{
        font-size:1rem;
        right:110%;
    }
    .home_tripplebadge {
       /* background-size: 153.666667%;
        background-position: -18% 82.909889%;*/
        background-size: 242.666667%;
        background-position: 5% 82.909889%;
        height: 250px;
    }
    .courseActivity .textSize-smallTitle, .pathsActivity .textSize-smallTitle{
        font-size:1.2rem;
        /*font-weight:normal;*/
    }
    .rest_courses{
        display:none;
    }
    .pathActivityProgress .profileCourseProgressWrap{
        position:static;
        width:100%;
        -ms-transform: none;
        -webkit-transform: none;
        transform:none;
    }
    .pathActivityProgress .courseStatus{
        display:none;
    }
    .single_level_box{
        font-size:1rem  !important;
    }
    .prof_text{
        margin-left:0;
        font-size:0.8rem;
        display:none;
    }
    .home_roditeli-kontrol{
        /*height:250px;*/
        height:200px;
        width:80%;
        margin:0 auto;
    }
    .errorJuniorStructure{
        left:-25%;
    }
    .course_title{
        font-size:0.8rem;
    }
    .single_course_in_structure .logo_default{
        height:90px;
    }
    .pathsActivity .coursesPaths .courseTile{
        width:100%;
    }
    .home_plans{
        height:180px;
    }

    .check_parents{
        position:static;
        -ms-transform: none;
        -webkit-transform:none;
        transform:none;
        padding:0;
        margin:0;
    }
    .dashboard .nav-menu .fa{
        width:100%;
        text-align: center;
    }
    .recordBox{
        width:32%;
        padding:15px;
    }
    .courseActivity .continueInnerWrapper{
        width:100% !important;
    }
    .b_{
        position:static;
        -ms-transform:none;
        -webkit-transform: none;
        transform: none;
    }
    .courseActivity .pcPic,.pathsActivity .pcPic{
        position:relative;
        -ms-transform:none;
        -webkit-transform:none;
        transform: none;
        left:0;
    }
    .prof_badges .single_path_tile{
        width:100%;
    }
    .badges_placeholder{
        width:100%;
    }
    .courseActivity .continueInnerWrapper, .pathsActivity .continueInnerWrapper{
        width:60%;
    }
    .pathsActivitySlider .continueInnerWrapper{
        width:100%;
    }
    .bottomLeft{
        width:100%;
        padding-bottom:40px;
        text-align: center;
        font-size:0.9rem;
    }
    .dashboard .nav-menu .menu-title{
        /*display:none;*/
        width:100%;
        text-align: center;
        font-size:0.8rem;
    }
    .dashboard .user_icon{
       /* width:100%;*/
       width:60px;
       height:60px;
        margin-left:0;
        display:block;
        margin:0 auto;
    }
    .col-dashboard-2 .parents_box_container{
        /*display:none;*/
        padding:0;
        background:transparent;
        height:110px;
        box-shadow: none;
    }
    .col-dashboard-2 .parents_box_container .pboxtitle{
        display:none;
    }
    .col-dashboard-2 .parents_box_container p{
        display:none
    }
    .dashboard .nav-menu .custom-fb-btn {
        height:110px;
    }
    .dashboard .nav-menu .custom-fb-btn .fa{
        font-size:2rem;
        vertical-align: top;
        top: -12px;
        /*left: 15px;*/
        left:50%;
        -ms-transform:translateX(-50%);
        -webkit-transform:translateX(-50%);
        transform:translateX(-50%);
        position: absolute;
    }
    .dashboard .nav-menu ul li.share-win{
        display:none;
    }
    .dashboard .nav-menu .custom-fb-btn .share-desc{
            font-size: 0.8em;
            width: 76px;
            word-wrap: break-word;
            display: inline-block;
            margin: 0 auto;
            -ms-transform:translateX(-22%) rotate(-90deg);
            -webkit-transform: translateX(-36%) rotate(-90deg);
            transform: translateX(-22%) rotate(-90deg);
            text-align: left;
            /* color: #000; */
            text-align: center;
    }
    .level_status_box{
        width:49%;
    }

    .footer_email-new{
            background-size: 69%;
    }
    .free_course_desc{
        width:100%;
    }
    .free_course_desc span{
        position:static;
        -ms-transform: none;
        -webkit-transform: none;
        transform: none;
    }
    .logo_parents{
        height: 250px;
    }
    .why-box-left{
        width:100%;
    }
    .why-box-right{
        width:100%;
    }
    .parents_box_container{
        width: -webkit-calc(50% - 30px);
        width: -moz-calc(50% - 30px);
        width: -ms-calc(50% - 30px);
        width: -o-calc(50% - 30px);
        width: calc(50% - 30px);
        margin-bottom:50px;
    }
    .boxes-left{
        width:50%;
    }
    .boxes-right{
        width:50%;
    }
    .parents_level_icon{
        width:150px;
        height:150px;
        float:none;
        margin:0 auto;
    }
    .left_icon{
        margin:0 auto;
    }
    .left_testimonial, .right_testimonial{
        width:100%;

    }
    .parents_bg.logo_path_kcoder{
        height:200px;
    }
    .parents_level_text{
        position:static;
        text-align: center;
        display:inline-block;
        -ms-transform: none;
        -webkit-transform: none;
        transform: none;
    }
    .parents_level_text_left{
        margin-right:0;
    }
    .single_level_box{
        font-size:1.25rem;
    }
    .single_level_box{
        width:45%;
        margin-bottom: 25px;
    }

    .singleCourseLevel{
        font-size:0.65rem;
    }
    .singleCourseLevel.activeCourseLevel:after {
        border-top: 18.5px solid transparent;
        border-bottom: 18.5px solid transparent;
        left: 100%;
    }
    .paths_level .oneFourth{
        padding-bottom:50px;
    }
    #firstTopStripe{
        position:relative;
        margin-bottom:0px;
    }
    .bottomHeaderStripBG{
    height:250px;
    min-height:250px;
  }
  #firstHeader{
    margin-top:0px;
  }
  .titleBig{
    font-size:2rem;
  }
  .subTitleSmall{
    font-size:1rem;
  }
    .see_more_features{
      position:static;
    }
    .featuresDesc{
      margin-bottom:20px;
    }
    .gridFeature{
      padding:10px;
    }
    .gridFeature{
        width:100%;
    }
    .errorJuniorStructure{
        /*display:none;*/
    }
    #homeSubTitle{
        font-size:1.5rem;
    }
    .thought{

    }
    .deviceContainer{
        height:350px;

    }
    #path_way{
        padding-left:30px;
        padding-right:30px;
    }
    .arrowPaths .fa-arrow-right{
        display:none;
    }
    .paths_level .single_path_label{
        font-size:18px;
        /*height:auto;*/
    }
    .gridFeature{
        width:100%;
    }
    .errorJuniorStructure{
       /* display:none;*/
    }
    #homeSubTitle{
        font-size:1.5rem;
    }
    .thought{

    }
    .deviceContainer{
        height:350px;

    }
    #path_way{
        padding-left:30px;
        padding-right:30px;
    }
    .arrowPaths .fa-arrow-right{
        display:none;
    }
    .paths_level .single_path_label{
        font-size:24px;
    }
    .paths_level .level_1{
        height:150px;
            background-size: 737.333333%;
    }
    .paths_level .level_2{
        height:150px;
    }
    .paths_level .level_3{
        height:150px;
    }
    .paths_level .level_4{
        height:150px;
        /*background-size: 130.166667%;
        background-position: -100% 81.192816%;*/
    }
    .accessDesc{
        font-size:1.5rem;
        margin: 10px 0;
    }
    .play_now.start_in_section{
        margin-top:0;
    }
    .oneFourth{
        width:46%;
        margin-bottom: 35px;
    }
    .play_now{
        width:200px;
        font-size:1.2rem;
    }
    .journey{
        font-size:2.2rem;
    }
    #modalStartTrial{
        width:95%;
    }
    .continuePlan{
        width:200px;
        height:35px;
        line-height:27px;
        font-size:1rem;
    }
    .sub_path_description{
        float:none;
        width:100%;
    }
    .levels .angle_down {
        left:15px;
    }
    #devices{
        /*width: 100%;
        margin-top: 0px;
        margin-left: 0px;
        margin-bottom: 0px;*/
    }

    .juniorCourseTree .exerciseJuniorBox  .junniorBoxMax{
        top:45%;
        font-size:1rem;
    }

    .juniorCourseTree .exerciseJuniorBox  .junniorBoxCoinsCount{
        left:0;

        bottom:15px;
    }
.juniorCourseTree .exerciseJuniorBox .junniorBoxSubTitle {
    font-size:0.7rem;
}
    .juniorCourseTree{
        width: 100%;
        height: 600px;
        overflow: hidden;
    }
    .juniorCourseTree .exBoxes{
         height: 235px;
             /*justify-content: space-around;*/
    }
    .juniorCourseTree .exerciseJuniorBox{
        width:29%;
        height:39%;
        margin-right:0;
        margin-bottom:40px;

    }
    .juniorCourseTree .exerciseJuniorBox .juniorBoxExTitle{
        display:none;
    }
    .juniorCourseTree .exerciseJuniorBox .screensEx .fa-dot-circle-o{
        font-size:13px;
    }
.long-arrow{
    width:100%;
    height:2px;
    background:#039be6;
    position:absolute;
    left:0;
}
.arrowExLong{
    display:block;
    height:2px;
    position:absolute;
    bottom:-28%;
    left:-50px;
    right:auto;
    width:150%;
    background:#37a;
}
.juniorCourseTree .exerciseJuniorBox{
    background:#b5e6fd;
}
.juniorCourseTree .exerciseJuniorBox .arrowEx{
    background:#37a;
}
.juniorCourseTree .exerciseJuniorBox .fa-angle-left{
    color:#37a;
}
.juniorCourseTree .exerciseJuniorBox .fa-angle-down{
    color:#37a;
}
.juniorCourseTree .exerciseJuniorBox:nth-child(3n+3) .arrowExLong{
    background:#37a;
}
.juniorCourseTree .exerciseJuniorBox:nth-child(3n+3) .arrowExLong{
    left:0;
    right:auto;
}
.juniorCourseTree .exerciseJuniorBox:nth-child(3n+1) .arrowExLong{
    left:50%
}
.juniorCourseTree .exerciseJuniorBox:nth-child(3n+3) .arrowExLong{
    left:auto;
    right:50%
}
.juniorCourseTree .exerciseJuniorBox:nth-child(3n+1) .arrowExDown{
    left:50%;
    background:#37a;
}
.juniorCourseTree .exerciseJuniorBox:nth-child(3n+4) .fa-angle-down{
    left:50%;
}
.juniorCourseTree .exerciseJuniorBox:nth-child(10) .arrowExLong{
    display:none;
}
.juniorCourseTree .exerciseJuniorBox:nth-child(10) .fa-angle-left{
    display:none;
}
.juniorCourseTree .exerciseJuniorBox:nth-child(10) .fa-angle-right{
    display:none;
}
.juniorCourseTree .exerciseJuniorBox:nth-child(10) .arrowEx{
    display:none;
}
.juniorCourseTree .exerciseJuniorBox:first-child .arrowExDown{
    display:none;
}

.all_matdesbg, .all_jquery, .all_csharp-pic, .all_default, /*.all_webdev,
.all_database,*/ .all_java-path, /*.all_juniorcourse, .all_php,*/ .all_html,
.all_js, .all_arflagsmall, .all_esflagsmall, .all_hiflagsmall, .all_ruflagsmall,
.all_zhflagsmall, .all_bgflagsmall, .all_enflagsmall
{ max-width: 100%; background-size: 100%; background-image: url('../images/sprites/all-page/all_mob.gif');}

.all_matdesbg { background-position: 0 0%; background-size: 176%; }
.all_jquery { background-position: 0 12.862745%; background-size: 244.444444%; }
.all_csharp-pic { background-position: 0 17.138238%; background-size: 366.666667%; }
.all_default { background-position: 0 24.854045%; background-size: 366.666667%; }
.all_webdev { /*background-position: 0 34.61854%; background-size: 440%;*/ }
.all_database {/* background-position: 0 43.150123%; background-size: 440%;*/ }
.all_java-path { background-position: 0 51.681706%; background-size: 440%; }
.all_juniorcourse { /*background-position: 0 60.21329%; background-size: 440%; */}
.all_php { /*background-position: 0 68.744873%; background-size: 440%;*/
    /*background-image: url('../images/pathLogos/mvc.png');
    background-position: 0 43.961772%;
    background-size: 102%;
    background-repeat: no-repeat;*/
}
.all_html { background-position: 0 78.565471%; background-size: 517.647059%; }
.all_js { background-position: 0 88.907423%; background-size: 517.647059%; }
.all_arflagsmall { background-position: 0 91.257669%; background-size: 1913.043478%; }
.all_esflagsmall { background-position: 0 92.714724%; background-size: 1913.043478%; }
.all_hiflagsmall { background-position: 0 94.171779%; background-size: 1913.043478%; }
.all_ruflagsmall { background-position: 0 95.628834%; background-size: 1913.043478%; }
.all_zhflagsmall { background-position: 0 97.08589%; background-size: 1913.043478%; }
.all_bgflagsmall { background-position: 0 98.542945%; background-size: 2000%; }
.all_ruby{max-width: 100%;/* background-size: 100%; background-image: url('../images/stack_logos/ruby.png');*/ width:120px;height:120px !important;}
/*.all_junior{max-width: 100%; background-size: 100%; background-image: url('../images/stack_logos/junior.png') ;background-repeat: no-repeat; z-index: 5;width:150px;height:150px;top:60%;}*/
.all_csharp{max-width: 100%; /*background-size: 100%; background-image: url('../images/stack_logos/csharp.png'); */width:120px;height:120px !important;}
.all_java{max-width: 100%; /*background-size: 100%; background-image: url('../images/stack_logos/java.png');*/ width:120px;height:120px !important;}
.all_c_cpp{max-width: 100%; /*background-size: 100%; background-image: url('../images/stack_logos/cpp.png');*/ width: 100px;height: 112px !important;}
.all_python{max-width: 100%;/* background-size: 100%; background-image: url('../images/stack_logos/python.png');*/ width:120px;height:120px !important;background-repeat:no-repeat;/*margin-top:20px;*/}
.all_javascript{max-width: 100%;/* background-size: contain; background-image: url('../images/stack_logos/javascript.png');*/ width:120px;height:120px !important; background-position:center;background-repeat:no-repeat;}

.all_enflagsmall { background-position: 0 100%; background-size: 2000%; }
    .contact_map{
        width:100%;
    }
    #picturePathsSection, #pictureBadgesSection, #pictureGoalsSection {
        display: block;
        padding-top: 0;
        padding-bottom: 30px;
        width:90%;
        margin:0 auto;

    }
    .verticalCenterAbsolute {
        -ms-transform:none;
        -webkit-transform:none;
        transform: none;
    }
    .languageBar {
        top:8px;
    }
    .langLettersFlags{
        vertical-align: top;
    }
    .mobLanguage{
        position:absolute;

    }
    .langLetters {
        display: none;
    }
    .logoText {
        font-size: 1.0rem;
    }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        .logoText{
           /* font-size:0.85rem !important;*/
        }
    }
    /* home mobile sprite classes */
    .all_enflagsmall {
        width: 20px;
    }



    .home_angear-stationary,
    .home_metal-caps-bg,
    .home_metal-caps-en,
    .home_metal-caps-hi,
    .home_metal-caps-ar,
    .home_metal-caps-zh,
    .home_metal-caps-es,
    .home_metal-caps-ru,
    .home_brain {
        max-width: 100%;
        background-size: 100%;
        background-image: url('../images/sprites/home-page/home_mob.gif');
    }

    .home_angear-stationary {
        background-position: 0 0%;
        background-size: 100%;
    }
    .home_metal-caps-bg {
        background-position: 0 37.514124%;
        background-size: 100%;
    }
    .home_metal-caps-en{padding-bottom:96%;}
    .home_metal-caps-en,
    .home_metal-caps-hi,
    .home_metal-caps-ar,
    .home_metal-caps-zh,
    .home_metal-caps-es,
    .home_metal-caps-ru {
        background-position: 0 71.412429%;
        background-size: 100%; }
    .home_brain {
        background-position: 0 100%;
        background-size: 108.142857%;
    }
  .home_mobile_home-background, .home_mobile_laravel-mvc, .home_mobile_webappnew, .home_mobile_matdesbg, .home_mobile_devices-screen-new,
.home_mobile_course1, .home_mobile_course2, .home_mobile_course3, .home_mobile_course4, .home_mobile_brain,
.home_mobile_consoles, .home_mobile_fr, .home_mobile_level1, .home_mobile_webapps, .home_mobile_node-js,
.home_mobile_email, .home_mobile_email-new, .home_mobile_default, .home_mobile_django, .home_mobile_laravel,
.home_mobile_new-game-setup, .home_mobile_css, .home_mobile_html, .home_mobile_cpp, .home_mobile_csharp,
.home_mobile_java, .home_mobile_javascript, .home_mobile_python, .home_mobile_ruby, .home_mobile_ruby-on-rails,
.home_mobile_sql, .home_mobile_terminal, .home_mobile_vinternship, .home_mobile_arflagsmall, .home_mobile_esflagsmall,
.home_mobile_hiflagsmall, .home_mobile_ruflagsmall, .home_mobile_zhflagsmall, .home_mobile_bgflagsmall, .home_mobile_enflagsmall,
.home_mobile_trflagsmall
{ max-width: 100%; background-size: 100%; background-image: url('../images/sprites/home-page/home-mob.gif'); }

.home_mobile_home-background { background-position: 0 0%; background-size: 100%; }
.home_mobile_laravel-mvc { background-position: 0 6.326202%; background-size: 109.589041%; }
.home_mobile_webappnew { background-position: 0 16.793191%; background-size: 109.589041%; }
.home_mobile_matdesbg { background-position: 0 25.555316%; background-size: 181.818182%; }
.home_mobile_devices-screen-new { background-position: 0 30.020013%; background-size: 200%; }
.home_mobile_course1 { background-position: 0 35.760588%; background-size: 243.902439%; }
.home_mobile_course2 { background-position: 0 39.520311%; background-size: 243.902439%; }
.home_mobile_course3 { background-position: 0 43.280035%; background-size: 243.902439%; }
.home_mobile_course4 { background-position: 0 47.039758%; background-size: 243.902439%; }
.home_mobile_brain { background-position: 0 51.944322%; background-size: 266.666667%; }
.home_mobile_consoles { background-position: 0 56.531095%; background-size: 266.666667%; }
.home_mobile_fr { background-position: 0 59.103463%; background-size: 266.666667%; }
.home_mobile_level1 { background-position: 0 61.91091%; background-size: 266.666667%; }
.home_mobile_webapps { background-position: 0 64.414702%; background-size: 266.666667%; }
.home_mobile_node-js { background-position: 0 66.574409%; background-size: 400%; }
.home_mobile_email { background-position: 0 67.700042%; background-size: 432.432432%; }
.home_mobile_email-new { background-position: 0 68.229385%; background-size: 536.912752%; }
.home_mobile_default { background-position: 0 70.21595%; background-size: 666.666667%; }
.home_mobile_django { background-position: 0 71.753315%; background-size: 800%; }
.home_mobile_laravel { background-position: 0 72.536688%; background-size: 800%; }
.home_mobile_new-game-setup { background-position: 0 73.873493%; background-size: 800%; }
.home_mobile_css { background-position: 0 76.250534%; background-size: 941.176471%; }
.home_mobile_html { background-position: 0 78.91811%; background-size: 941.176471%; }
.home_mobile_cpp { background-position: 0 81.070746%; background-size: 1000%; }
.home_mobile_csharp { background-position: 0 82.912868%; background-size: 1000%; }
.home_mobile_java { background-position: 0 84.714861%; background-size: 1000%; }
.home_mobile_javascript { background-position: 0 86.516854%; background-size: 1000%; }
.home_mobile_python { background-position: 0 88.318847%; background-size: 1000%; }
.home_mobile_ruby { background-position: 0 90.12084%; background-size: 1000%; }
.home_mobile_ruby-on-rails { background-position: 0 92.392926%; background-size: 1000%; }
.home_mobile_sql { background-position: 0 93.441244%; background-size: 1000%; }
.home_mobile_terminal { background-position: 0 95.046571%; background-size: 1095.890411%; }
.home_mobile_vinternship { background-position: 0 96.636344%; background-size: 1904.761905%; }
.home_mobile_arflagsmall { background-position: 0 97.093266%; background-size: 3478.26087%; }
.home_mobile_esflagsmall { background-position: 0 97.511501%; background-size: 3478.26087%; }
.home_mobile_hiflagsmall { background-position: 0 97.929737%; background-size: 3478.26087%; }
.home_mobile_ruflagsmall { background-position: 0 98.347972%; background-size: 3478.26087%; }
.home_mobile_zhflagsmall { background-position: 0 98.766207%; background-size: 3478.26087%; }
.home_mobile_bgflagsmall { background-position: 0 99.184442%; background-size: 3636.363636%; }
.home_mobile_enflagsmall { background-position: 0 99.602677%; background-size: 3636.363636%; }
.home_mobile_trflagsmall { background-position: 0 100%; background-size: 3636.363636%; }





    .all_matdesbg{
        height:100px;
    }
    .courseTileTextNotOnCourses{
        padding:16px 16px 45px  16px;
    }
    .courseTitle{
        margin-right:0;
    }
    .percentageDoneWithText{
        margin-top:7px;
    }
    /* end of home mobile classes */
    #bottom_stripe{
        width: -webkit-calc(100% - 10px);
        width: -moz-calc(100% - 10px);
        width: -ms-calc(100% - 10px);
        width: -o-calc(100% - 10px);
        width: calc(100% - 10px);
    }
    #brain {

        display:none;
    }

    .brainContainer{

        padding:20px;
    }
    .brainContainer .sectionTitle{
        text-align:center;
    }
    .home_angear-stationary {
        padding-bottom: 55%;
    }
    .percentageDone {
        float: none;
        -ms-transform: none;
        -webkit-transform: none;
        transform: none;
        position:absolute;
        width:-webkit-calc(100% - 22px);
        width:-moz-calc(100% - 22px);
        width:-ms-calc(100% - 22px);
        width:-o-calc(100% - 22px);
        width:calc(100% - 22px);
        bottom:92px;
        left:15px;
    }
    .percentageDoneNotOnCourses{
        position:absolute;
        width:-webkit-calc(100% - 32px);
        width:-moz-calc(100% - 32px);
        width:-ms-calc(100% - 32px);
        width:-o-calc(100% - 32px);
        width:calc(100% - 32px);
        bottom:35px;
        left:15px;
    }
    .courseTitle{
        font-size:1rem;
        float: none;
        width: 100%;
    }
    .courseTileHeader {
        height: 100px;
    }
    .courseDescription {
        display: none;
    }
    .courseTileButtons {
        font-size: 0.8rem;
        width: -webkit-calc(100% - 16px);
        width: -moz-calc(100% - 16px);
        width: -ms-calc(100% - 16px);
        width: -o-calc(100% - 16px);
        width: calc(100% - 16px);
        right: 8px;
        bottom: 8px;
    }
    .courseStartButton {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }
    .courseTileText {
        padding: 8px 8px 120px;
    }
    .content {
        margin-left: 5px;
        margin-right: 5px;
        padding-left: 8px;
        padding-right: 8px;
    
    }
    #path_pic {
        /*padding-bottom: 81.76%;*/
    }
    #goal_pic {
       /* padding-bottom: 59%;*/
    }
    #firstNewsChunk, #secondNewsChunk{
        display: none;
    }
    .mobNewsExpand {
        display: inline-block;
    }
    .class780 {
        width: 100%;
    }
    .pathTile:last-child {
        margin-left: 0;
    }
    #footer{
        width:100%;
    }
    #footerCourses, #footerPaths {
        display: none;
    }
    .footerBox {
        width: 48%;
    }
    .bottomStripeMenu {
        display: none;
    }
    .oneThird, .twoThirds {
        width: 100%;
    }
    .homeSectionPic {
        width: 50%;
        margin: 0 auto;
    }
    #sectionHomeImage {
        background-size: 40%;
    }
    #pathsWrapper {
        display: none;
    }
    #coursesWrapper.coursesWrapperOnCourse{
        display:block;
    }
    #coursesWrapper{
        display: none;
    }
    #coursesWrapper{
        margin-top:10px;
    }
    #pathsWrapper{
        display: none;
    }
    .newsSection {
        margin-top: 10px;
    }
    #secondTitleToShow {
        padding-top: 10px;
        padding-bottom: 20px;
    }
    .center780 {
        text-align: center;
    }
    .contact_info {
        margin-top: 20px;
        margin-bottom: 45px;
        margin-left: 0;
        width: 80%;
        text-align: center;
    }
    .contact_sep {
        width: 100%;
    }
    .test_img, .test_img_badge {
    /*width: 100%;*/
   /* margin: 0;*/
    }
    .heroDescription {
    width: 100%;

    }
    .lineBox, .pathArrowTop, .pathArrowBottom {
        display: none;
    }
    .pcBox {
        text-align: center;
    }
    .pcText {
        margin: 0;
    }
    .pcText p {
        width: auto;
    }
    .courseTree {
        width: 100%;
    }
    .courseInfoWrapper {
        /*width: 90%;
        transform: translateX(11%);*/
        width:100%;

    }
    .juniorStructure .infoRowIcon{
        float:left;
        position:static;
    }
    .pagePath {
        position:relative;
        top: -3px;

    }
    #expand {
        padding-bottom: 0;
    }
    .materialUl {
        margin: 0;
    }
    .modLiTitle{
        width:78%;
    }
    .sectionSubTitle {
        margin-bottom: 15px;
        text-align:center;
    }
    .heroTitle {
        font-size: 1.4rem;
        margin:0;
        text-align:center;
    }
    .startCourse{
        position:relative;
    }
    .courseStructureHeroTitle{
        text-align:center;
    }
    .prof_text h1 {
        font-size: 1.5em;
    }
    .continueWrapper {
        width: 80%;
    }
    .courseTile {
        display: inline-block;
        width: 46%;
    }
    .metal780 {
        margin: 0 auto;
        width: 30%;
    }
    .top_row{
        margin-top:28px;
    }
    .newsBitPic{
        width:13%;
    }
    .newsBitPic{
        height:70px;
    }
    .stripe{
        padding: 0 0;
    }
    .pcPic{
        position:absolute;
        -ms-transform: translate(-50%,0);
        -webkit-transform: translate(-50%,0);
        transform:translate(-50%,0);
        top:0;
        left:50%;
    }
    .pcText{
        margin-top:180px;
    }
    .pcBox{
        margin-bottom:25px;
    }
    #uploadForm {
        transform: translateY(0);
    }
    .supportMessageSentCaptcha {
        bottom: 20px;
    }
    #pictureBadgesSection .class1024Show {
        text-align: center;
    }
    #pictureBadgesSection .class1024Show img.test_img_badge{
        width:60%;
        margin-left:0;
    }
    #homeSectionPicWrap{
        width: 50%;
        margin:0 auto;
    }
    #hideGearMobile {
        display:none;

    }
    #terms_wrap > h1{
        font-size: 1.5em;
    }
    #terms_wrap .ok_button{
        font-size:0.9rem;
    }
    #terms_wrap .x_close{
        display:inline;
        float:right;
    }
    #terms_wrap{
        height:80%;
    }
    #terms_wrap .terms_inner{
        height: 65%;
    }
    /** NEW*//*
    .mobile_arflagsmall, .mobile_esflagsmall, .mobile_hiflagsmall, .mobile_ruflagsmall, .mobile_zhflagsmall,
.mobile_bgflagsmall, .mobile_enflagsmall
{ display: inline-block; background: url('../images/sprites/all-page/lang_sprite.gif') no-repeat; overflow: hidden; text-indent: -9999px; text-align: left; }

.mobile_arflagsmall { background-position: -5px -0px; width: 23px; height: 15px; }
.mobile_esflagsmall { background-position: -5px -20px; width: 23px; height: 15px; }
.mobile_hiflagsmall { background-position: -5px -40px; width: 23px; height: 15px; }
.mobile_ruflagsmall { background-position: -5px -60px; width: 23px; height: 15px; }
.mobile_zhflagsmall { background-position: -5px -80px; width: 23px; height: 15px; }
.mobile_bgflagsmall { background-position: -5px -100px; width: 22px; height: 15px; }
.mobile_enflagsmall { background-position: -5px -120px; width: 22px; height: 15px; }*/
    .fb-like{
        width:100%;
        overflow:hidden;
    }
    .fb_iframe_widget{
        width:100%;
            overflow:hidden;
    }
    #facebookBtns.fb-like{
        width:100%;
        overflow:hidden;
        position:absolute;
        margin-top:30px;
    }
    .paths_level .oneFourth{
      width:46%;
    }
    .gifFrame{
        margin:0 auto;
    }
    #gif1{
        width:41%;
    }
    #gif2{
        width:41%;
    }
    #gif3{
       /* width:80%;*/
       width:41%;
    }
    .top-bar{
        width:80%;
        margin:0 auto;
    }
    .overlayBanner{
        width:100%;
        box-sizing:border-box;
        margin-left:0;
    }
    .middleBannerTitle{
        font-size:1.2rem;
        padding-top:10px;
    }
    #devices{
        width:44%;
        height:88%;
    }
    .home_devices-screen-new{
        background-size:135.285714%;
    }
    .home_email-new{
        background-size:450.248322%;
    }
    .coursesLevels{
        position:static;
    }
    .secondTopStripe{
        margin-top:0;
    }
    .coursesWrapperInCourses{
        margin-left:0;
    }
    .oneFourth:first-child .path_number:after{
        border:none;
    }
    .oneFourth:nth-child(2) .path_number:before, .oneFourth:nth-child(3) .path_number:before{
        border:none;
    }
    .oneFourth:nth-child(2) .path_number:after, .oneFourth:nth-child(3) .path_number:after{
        border:none;
    }
    .oneFourth:nth-child(4) .path_number:after{
        border: none;
    }
    #gifsPanel{
        width:100%;
        box-sizing: border-box;
    }
    #test_info h1{

    }
    .levels .levelsTitle, .levels_on_course .levelsTitle{
        font-size:1rem;
    }
    .sub_path_description_title{
        font-size:1rem;
    }
    .pcText h1{
        font-size:1.4rem;
    }
    .sub_path_description_title{
        font-size:1.2rem;
    }
    .path_course_desc{
        font-size:1.1rem;
    }
    #modalStartTrial .pay_price{
        font-size:1.2rem;
    }
    .pricePopup{
        margin-top:10px;
    }
    .getFree , .getPremium{
        font-size:0.9rem;
    }
    .secondStepPremium .plan_popup{
        width:100%;
    }
    .emailPopup .valign_center{
        -ms-transform:none;
        -webkit-transform:none;
        transform:none;
    }
    #modalStartTrial .termsPaymentPopup{
        width:100%;
        font-size:0.7rem;
        display:none;
    }
    .payment_logos{
        width:50px;
        height:17px;
    }
    #modalStartTrial .paymentBtns{
       padding-left:60px;
    }
    #modalStartTrial .secondStepPremium .payments-check{
        font-size:2rem;
    }
    #modalStartTrial .secondStepPremium .payments-check{
        left:2px;
    }
    #modalStartTrial .thirdStepPremium .payment_label{
        font-size:1.8rem;
    }
    .s1,.s2,.s3,.s4{
        width:100%;
    }
    #modalStartTrial .radioButtonBox{
        width:100%;
    }
    #modalStartTrial .radioButtonBoxCash{
        width:100%;
    }
    #modalStartTrial .thirdStepPremium #bankCashTransfer .radioBtnsPersonPayment{
        width:100%;
    }
    #bank_cash_preview{
        display:none;
    }
    #modalStartTrial .thirdStepPremium .bank_cash_row{
        width:100%;
    }
    #modalStartTrial .thirdStepPremium .econtRadioBtns label{
        display:inline-block;
        width:49%;
    }
    #modalStartTrial .thirdStepPremium .econtDataPayment .radioBtnsPersonPayment{
        width:100%;
    }
    #modalStartTrial .econtDataPayment .plan_popup_row.s3{
        width:100%;
    }
    #modalStartTrial .econtDataPayment .plan_popup_row.s4{
        width:100%;
    }
    #modalStartTrial .thirdStepPremium .econtDataPayment .econt_same_height{
        /*height:200px;*/
        height:auto;
    }
    .office_delivery #econt-towns{
        width:100%;
    }
    .officesRight{
        height:150px;
    }
    #modalStartTrial{
       /* height:97%;*/
    }
    .logopayment_easypay{
        background-size:250%;
    }
    #modalStartTrial .secondStepPremium .button{
        font-size:0.9rem;
    }
    #select-econt-office{
        display:none;
    }
    .econt-offices-select{
        height: 45px;
        margin: 8px 0;
        border: 0;
        border: 1px solid black;
        background: #fff url(https://cdn3.iconfinder.com/data/icons/google-material-design-icons/48/ic_keyboard_arrow_down_48px-128.png) no-repeat;
        background-size: 20px;
        background-position: right 10px center;
        font-family: 'Arial';
        padding: 5px;
        -webkit-appearance: none;
        -moz-appearance: none;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        outline: none;
        width:100%;
    }
    #select-econt-office-mobile{
        height:auto;
    }
    .payment_steps_container{
        display:block;
    }
    .inline-title{
        display: block;
    }
    #modalStartTrial .radioBtnsPersonPayment{
        margin:0;
    }
    .single_path_tile{
        width:48%;
    }
    #gifsPanel .half{
        width:100%;
    }
    #firstHeader{
       /* background-attachment: scroll;*/
    }
    .pcBox{
        padding-left:0;
    }
    .pcText{
        width:100%;
    }
    .zopim{
        bottom:0 !important;
        right:0 !important;
    }
    #projectPreview{
        display:none;
    }
    .infoRowFirst .infoRowInline.right{
        display:none;
    }
    .single_course_in_structure{
        width:31%;
    }
    ._matdesbg{
        background-size:1587%;
    }
    .color-border{
        height:auto;
    }
    .email-pop-input{
        width:100%;
        margin-bottom:20px;
    }
    .roket-pop{
        top:7%;
        left:50%;
        -ms-transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }
    .choosePlan{
        margin-top:20%;
        font-size:1.8rem;
    }
    .start-popup-modal .fa-rocket{
        font-size:4rem;
    }
    .start-popup form{
        top:0;

    }
    .top-right-ribbon{
        top:25px !important;
        left:auto !important;

    }
    .current_course_arrow{
        top:-21%;
    }
    .single_course_in_structure{
        margin-bottom:50px;
    }
    .continuePaymentModal{
        width:90%;
    }
    .discount-period, .discount-price{
        width:44%;
        float:left;
    }
    /*.home_mob_home, .home_mob_plans, .home_mob_kids-bg, .home_mob_brain, .home_mob_gif1,
    .home_mob_secondgif, .home_mob_thirdgif, .home_mob_tripplebadge, .home_mob_roditeli-kontrol, .home_mob_consoles,
    .home_mob_gametocode, .home_mob_webappnew2, .home_mob_appstore, .home_mob_google-play-badge, .home_mob_email-new,
    .home_mob_hand, .home_mob_arflagsmall, .home_mob_esflagsmall, .home_mob_hiflagsmall, .home_mob_ruflagsmall,
    .home_mob_zhflagsmall, .home_mob_bgflagsmall, .home_mob_enflagsmall, .home_mob_trflagsmall,.home_mob_devices
    { max-width: 100%; background-size: 100%; background-image: url('../images/sprites/home-page/home_mob.png'); }

    .home_mob_home { background-position: 0 0%; background-size: 100%; }
    .home_mob_plans { background-position: 0 7.006205%; background-size: 102.163462%; }
    .home_mob_kids-bg { background-position: 0 19.266055%; background-size: 110.677083%; }
    .home_mob_brain { background-position: 0 33.077939%; background-size: 141.666667%; }
    .home_mob_gif1 { background-position: 0 43.75%; background-size: 141.666667%; }
    .home_mob_secondgif { background-position: 0 48.756339%; background-size: 141.666667%; }
    .home_mob_thirdgif { background-position: 0 55.949367%; background-size: 141.666667%; }
    .home_mob_tripplebadge { background-position: 0 64.349553%; background-size: 141.666667%; }
    .home_mob_roditeli-kontrol { background-position: 0 74.619943%; background-size: 154.545455%; }
    .home_mob_consoles { background-position: 0 80.129279%; background-size: 283.333333%; }
    .home_mob_gametocode { background-position: 0 86.597428%; background-size: 283.333333%; }
    .home_mob_webappnew2 { background-position: 0 90.734977%; background-size: 349.794239%; }
    .home_mob_appstore { background-position: 0 92.108335%; background-size: 527.950311%; }
    .home_mob_google-play-badge { background-position: 0 93.274171%; background-size: 566.666667%; }
    .home_mob_email-new { background-position: 0 93.871866%; background-size: 570.469799%; }
    .home_mob_hand { background-position: 0 96.238467%; background-size: 850%; }
    .home_mob_arflagsmall { background-position: 0 96.776438%; background-size: 3695.652174%; }
    .home_mob_esflagsmall { background-position: 0 97.24026%; background-size: 3695.652174%; }
    .home_mob_hiflagsmall { background-position: 0 97.704082%; background-size: 3695.652174%; }
    .home_mob_ruflagsmall { background-position: 0 98.167904%; background-size: 3695.652174%; }
    .home_mob_zhflagsmall { background-position: 0 98.631725%; background-size: 3695.652174%; }
    .home_mob_bgflagsmall { background-position: 0 99.095547%; background-size: 3863.636364%; }
    .home_mob_enflagsmall { background-position: 0 99.559369%; background-size: 3863.636364%; }
    .home_mob_trflagsmall { background-position: 0 100%; background-size: 3863.636364%; }*/
    /*.home_mob_home, .home_mob_plans, .home_mob_kids-bg, .home_mob_tripplebadge, .home_mob_roditeli-kontrol,
    .home_mob_brain, .home_mob_gif1, .home_mob_secondgif, .home_mob_thirdgif, .home_mob_consoles,
    .home_mob_gametocode, .home_mob_google-play-badge, .home_mob_webappnew2, .home_mob_email-new, .home_mob_appstore,
    .home_mob_hand, .home_mob_arflagsmall, .home_mob_esflagsmall, .home_mob_hiflagsmall, .home_mob_ruflagsmall,
    .home_mob_zhflagsmall, .home_mob_bgflagsmall, .home_mob_enflagsmall, .home_mob_trflagsmall,.home_mob_devices
    { max-width: 100%; background-size: 100%; background-image:  url('../images/sprites/home-page/home_mobile.png'); }

    .home_mob_home { background-position: 0 0%; background-size: 100%; }
    .home_mob_plans { background-position: 0 9.367439%; background-size: 102.163462%; }
    .home_mob_kids-bg { background-position: 0 25.880282%; background-size: 110.677083%; }
    .home_mob_tripplebadge { background-position: 0 41.008844%; background-size: 141.666667%; }
    .home_mob_roditeli-kontrol { background-position: 0 53.544391%; background-size: 154.545455%; }
    .home_mob_brain { background-position: 0 65.141188%; background-size: 283.333333%; }
    .home_mob_gif1 { background-position: 0 70.138249%; background-size: 314.814815%; }
    .home_mob_secondgif { background-position: 0 72.980416%; background-size: 314.814815%; }
    .home_mob_thirdgif { background-position: 0 77.749843%; background-size: 314.814815%; }
    .home_mob_consoles { background-position: 0 80.836131%; background-size: 566.666667%; }
    .home_mob_gametocode { background-position: 0 85.015576%; background-size: 566.666667%; }
    .home_mob_google-play-badge { background-position: 0 86.96704%; background-size: 566.666667%; }
    .home_mob_webappnew2 { background-position: 0 89.782142%; background-size: 566.666667%; }
    .home_mob_email-new { background-position: 0 90.729073%; background-size:369.469799%;}
    .home_mob_appstore { background-position: 0 92.028986%; background-size: 629.62963%; }
    .home_mob_hand { background-position: 0 95.110701%; background-size: 850%; }
    .home_mob_arflagsmall { background-position: 0 95.834582%; background-size: 3695.652174%; }
    .home_mob_esflagsmall { background-position: 0 96.433923%; background-size: 3695.652174%; }
    .home_mob_hiflagsmall { background-position: 0 97.033263%; background-size: 3695.652174%; }
    .home_mob_ruflagsmall { background-position: 0 97.632604%; background-size: 3695.652174%; }
    .home_mob_zhflagsmall { background-position: 0 98.231945%; background-size: 3695.652174%; }
    .home_mob_bgflagsmall { background-position: 0 98.831286%; background-size: 3863.636364%; }
    .home_mob_enflagsmall { background-position: 0 99.430626%; background-size: 3863.636364%; }
    .home_mob_trflagsmall { background-position: 0 100%; background-size: 3863.636364%; }*/

    /* COOMENT HERE .home_mob_roditeli-kontrol, .home_mob_home, .home_mob_kids-bg, .home_mob_devices-screen-new, .home_mob_tripplebadge,
    .home_mob_brain, .home_mob_gif1, .home_mob_secondgif, .home_mob_thirdgif, .home_mob_plans,
    .home_mob_consoles, .home_mob_gametocode, .home_mob_google-play-badge, .home_mob_webappnew2, .home_mob_email-new,
    .home_mob_appstore, .home_mob_hand, .home_mob_arflagsmall, .home_mob_esflagsmall, .home_mob_hiflagsmall,
    .home_mob_ruflagsmall, .home_mob_zhflagsmall, .home_mob_bgflagsmall, .home_mob_enflagsmall, .home_mob_trflagsmall,.home_mob_devices
    { max-width: 100%; background-size: 100%; background-image: url('../images/sprites/home-page/home-page-sprite-mob.png');  }

    .home_mob_roditeli-kontrol { background-position: 0 0%; background-size: 100%; }
    .home_mob_home { background-position: 0 20.950093%; background-size: 132.235294%; }
    .home_mob_kids-bg { background-position: 0 28.880282%; background-size:250.677083%; }
    .home_mob_devices { background-position: 0 44.776544%; background-size: 160.571429%; }
    .home_mob_tripplebadge { background-position: 0 55.950458%; background-size: 187.333333%; }
    .home_mob_brain { background-position: 0 63.655799%; background-size: 374.666667%; }
    .home_mob_gif1 { background-position: 0 67.875383%; background-size: 416.296296%; }
    .home_mob_secondgif { background-position: 0 70.246882%; background-size: 708.814815%; }
    .home_mob_thirdgif { background-position: 0 74.134791%; background-size: 416.296296%; }
    .home_mob_plans { background-size:100%;  display:none;}
    .home_mob_consoles {     background-position: 0 85.052819%; background-size: 749.333333%; }
    .home_mob_gametocode { background-position: 0 87.57427%; background-size: 749.333333%; }
    .home_mob_google-play-badge { background-position: 0 89.138105%; background-size: 749.333333%; }
    .home_mob_webappnew2 { background-position: 0 91.505102%; background-size: 749.333333%; }
    .home_mob_email-new { background-position: 0 92.263395%; background-size:369.469799%; }
    .home_mob_appstore { background-position: 0 93.355147%; background-size: 832.592593%; }
    .home_mob_hand { background-position: 0 95.936622%; background-size: 1124%; }
    .home_mob_arflagsmall { background-position: 0 96.523262%; background-size: 4886.956522%; }
    .home_mob_esflagsmall { background-position: 0 97.023512%; background-size: 4886.956522%; }
    .home_mob_hiflagsmall { background-position: 0 97.523762%; background-size: 4886.956522%; }
    .home_mob_ruflagsmall { background-position: 0 98.024012%; background-size: 4886.956522%; }
    .home_mob_zhflagsmall { background-position: 0 98.524262%; background-size: 4886.956522%; }
    .home_mob_bgflagsmall { background-position: 0 99.024512%; background-size: 5109.090909%; }
    .home_mob_enflagsmall { background-position: 0 99.524762%; background-size: 5109.090909%; }
    .home_mob_trflagsmall { background-position: 0 100%; background-size: 5109.090909%; }
*/

    .parents_consoles{
        background-position: -51px 80.201947%;
    }
    .parents_webappnew2{
        background-position: -44px 1%;
    }
    .parents_hand{
        background-position: -37px 60.321821%;
    }
    
    /*COURSES MOBILE SPRITES */
    .path_paths-new, .path_content-coursesbg, .path_paths-newbg, .path_levelsbg
    { max-width: 100%; background-size: 100%; background-image: url('../images/sprites/path-page/paths_sprite_last_mob.png'); }

    .path_paths-new { background-position: 0 0%; background-size: 200%; }
    .path_content-coursesbg { background-position: 0 54.261548%; background-size: 179.973118%; }
    .path_paths-newbg { background-position: 0 84.466019%; background-size: 320.945946%; }
    .path_levelsbg { background-position: 0 100%; background-size: 483.393502%; }


    /*LOGOS MOBILE SPRITE */
    .logo_secondgamebg, .logo_matdesbg, .logo_course1, .logo_course2, .logo_course3,
    .logo_course4, .logo_java, .logo_cpp, .logo_csharp, .logo_javascript,
    .logo_ruby, .logo_python, .logo_default, .logo_css, .logo_html,.logo_path_vprogramming,.logo_path_kcoder,.logo_path_csharp,.logo_path_ruby,.logo_path_javascript,.logo_path_java,
    .logo_path_python,.logo_path_cplus
    { max-width: 100%; background-size: 100%; background-image: url('../images/sprites/all-page/courses/cours_logos.png'); }
     .logo_default { background-position: 0 91.746768%; background-size: 875.833333%; }
    .logo_secondgamebg,.logo_path_kcoder { background-position: 0 0%; background-size: 170%; }
    .logo_matdesbg { background-position: 0 22.337924%; background-size: 238.863636%; }
    .logo_course1 { background-position: 0 27.96496%; background-size: 320.426829%; }
    .logo_course2,.logo_path_vprogramming { background-position: 0 33.827493%; background-size: 320.426829%; }
    .logo_course3 { background-position: 0 39.690027%; background-size: 320.426829%; }
    .logo_course4 { background-position: 0 45.552561%; background-size: 320.426829%; }
    .logo_java,.logo_path_java { background-position: 0 53.022933%; background-size: 405.791506%; }
    .logo_cpp,.logo_path_cplus { background-position: 0 61.46978%; background-size: 525.5%; }
    .logo_csharp,.logo_path_csharp { background-position: 0 68.777664%; background-size: 525.5%; }
    .logo_javascript,.logo_path_javascript { background-position: 0 75.757576%; background-size: 525.5%; }
    .logo_ruby,.logo_path_ruby { background-position: 0 82.737487%; background-size: 525.5%; }
    .logo_python,.logo_path_python { background-position: 0 87.570621%; background-size: 821.09375%; }
   
    .logo_css { background-position: 0 95.858184%; background-size: 1236.470588%; }
    .logo_html { background-position: 0 100%; background-size: 1236.470588%; }
    #devices{
        display:none;
    }
    .home_mob_gif1{
        background-size:514.814815%;
    }
    .home_mob_plans{
        width: 50%;
        margin: 0 auto;
    }
    .home_mob_tripplebadge{
        width:50%;
        height:170px;
    }
    .home_mob_secondgif{
        /*background-size:514.814815%;*/
        background-size:711.814815%;
    }
    .home_roditeli-kontrol{
        width:50%;
    }
    .home_mob_consoles{
        /*background-position: 0 80.836131%;
        background-size: 966.666667%;*/
    }
    .home_mob_webappnew2{
        /*background-size: 966.666667%;*/
    }
    .devicesText{
        width:100%;
    }
    .app_icons_home{
        height:32px;
    }
    .home_appstore{
        margin-bottom:0;
    }
    .home_win-store{
        margin-bottom:0;
    }
    #user_icons span{
        margin-top: 5px;
        display: inline-block;
        line-height:0;
    }/* Comemnt*/
} /**/
@media screen and (max-width: 480px) {
    .pathsStarted .courseLogo{
        /*height:50px;*/
        height:90px;
    }
    .home_mob_email-new {
        background-position: 0 92.263395%;
        background-size: 490.469799%;
    }
    .friends_box{
        width:100%;
    }
    .home_roditeli-kontrol{
        height:150px;
    }
    .home_plans{
       /* background-size: 199.076923%;
        background-position: 0 37.130484%;*/
    }
    #gif3{
        width:80%;
    }
    #gif1{
        width:80%;
    }
    #gif2{
        width:80%
    }
    .top-bar.narrowBar{
        width:80%;
    }
    .home_tripplebadge{
       /* background-size: 178.666667%;
        background-position: 0 82.909889%;*/
        height:135px;
    }
    .home_mob_plans{
        background-position: 47px 81.028939%;
        /*background-size:230.556391%;*/
    }
    .home_mob_gif1{
        background-size:678.814815%;
    }
    .dashboard .nav-menu .custom-fb-btn{
        width:100%;
    }
    .recordBox{
        width:100%;
        padding:10px 0;
    }
    .pathsActivitySlider{
        width:100%;
    }
    .courseActivity .continueInnerWrapper, .pathsActivity .continueInnerWrapper{
        margin-left:0;
        width:100%;
    }
    .prof_text{
        text-align: center;
        width:100%;
    }
    .dashboard .nav-menu .fa{
        width:100%;
        text-align: center;
    }
    .courseActivity .pcPic, .pathsActivity .pcPic{
        width:80px;
        height:80px;
        margin:0 auto;
    }
    .prof_badges .single_path_tile{
        width:100%;
    }
    .badges_placeholder{
        width:100%;
        text-align: center;
    }
    .col-dashboard-10{
        margin-top:0;
    }
    .level_status_box{
        width:47%;
    }
    .unlocked_path{
        top:0px;
        right:1px;
    }
    .locked_path{
        top:0px;
        right:1px;
    }
    .progressProfile .courseStatus{
        width:70px;
    }
    .courseActivity .textSize-title, .pathsActivity .textSize-title{
        width:100%;
    }
    .subscrioption_active{
        /*left:-12px;
         top:-8px;*/
        position:static;
        -ms-transform: none;
        -webkit-transform: none;
        transform:none;
        margin-top: -6px;
        width:100%;
        box-sizing: border-box;

    }
    .subscribtion_left{
        height:auto;
    }
    .tooltip-plan::after{
        border-width:0;
    }
    .logo_parents{
        height:150px;
    }
    .parents_box_container{
        width: -webkit-calc(100% - 30px);
        width: -moz-calc(100% - 30px);
        width: -ms-calc(100% - 30px);
        width: -o-calc(100% - 30px);
        width: calc(100% - 30px);
        margin-bottom:50px;
    }
    .boxes-left{
        width:50%;
    }
    .boxes-right{
        width:50%;
    }
    .parents_bg.logo_path_kcoder{
        height:100px;
    }
    .boxes-left {
        width:100%;
    }
    .boxes-right{
        width:100%;
    }
    .second_row_parents .parents_box{
        width:50%;
        margin-bottom:10px;
    }
    .secondBigTitle{
        font-size:2.1rem;
    }
    .secondBigSubTitle{
        font-size:1.3rem;
    }
    .left_testimonial{
        padding: 15px 0;
    }
    .parents_payment .payment_plan{
        width:100%;
    }
    .singleCourseLevel.activeCourseLevel:after{
        border:none;
    }

    html{
        font-size:13px;
    }
    .singleCourseLevel{
        width:25%;
        float:left;
        text-align: center;
        font-size:0.8rem;
        border-top-right-radius: 5px !important;
        border-bottom-right-radius:5px !important;
    }
  .coursesLevels{
        width:100%;
        float: none;
  }
  .coursesWrapperInCourses{
        width:100%;
  }
  .subTitleSmall{
        display:none;
  }
    .paths_level .oneFourth{
        width:100%;
        padding-bottom:15px;
    }
    .oneFourth .icon{

      width:80px;
      height:80px;
    }
    .overlay-headerStrip .repeatGif{
        left:50%;
        transform: translate(-50%,-50%);
    }
    #headerSlide1{
        height:200px;
    }
    .textLeft{
        width:100%;

    }
    .textRight{
        width:100%;
    }
    .textUpperSlider h1{
        margin-top:0;
        font-size:2rem;
    }
    .textUpperSlider h3{
        font-size:1rem;
    }
    .slides .play_now{
        margin-top:10px;
    }
    #subPathsNavWrapperHome{
        left:-60%;
    }
    .all_default{
       /* z-index:0;*/
        height:100px;
    }
    #logo_cpp{
        height:42px;
    }
    .levels  .mvcCourses .sub_stack_logos{
        width:70px;
    }
    .consoleCourses.courseProfileList{
        padding-left:10px;
    }
    .showHideNavigationMenu{
        top:20%;
    }
    .courseLogo{
        width:100px;
        height:100px;
    }
    #allCoursesFilter .courseLogo{
        height:50px !important;
    }
    #allCoursesFilter .all_ruby, #allCoursesFilter .all_default,#allCoursesFilter .logo_default, #allCoursesFilter .all_csharp,#allCoursesFilter .all_c_cpp, #allCoursesFilter .all_javascript, #allCoursesFilter .all_python,#allCoursesFilter .all_java{
        height:100px !important;
    }
    .all_matdesbg{
        background-size:407%;
    }
    .home_matdesbg{
            background-size: 532.727273%;
    }
    .subPathsNavWrapper{
        left:-60%;
    }
    #bottom_stripe{
        width:100%;
    }
    .paddingFooter{
        padding-top:30px;
        padding-bottom:5px;
    }
    #footerTeam{
        display:none;
    }
    .levels .levelsTitle, .levels_on_course .levelsTitle{
        text-align:center;

    }
    .levels .level_title{
        height:20px;
        display:block;
    }
    .rightPicCourses{
        float:none;
    }
    .paths_level .level_4{
        height:107px;
    }
    .paths_level .level_2{
        height:113px;
    }
    .paths_level .level_1{
        width:100%;
        height:100%;
    }
    .levels_container{
        /*width:100%;
        height:150px;*/
    }
    #brain_new{
        /*width:51%;*/
    }
    #devices{
        width:100%;
        position:static;
        height: 67%;
     background-size: cover;
     display:none;
    }
    .devicesText{
        height:auto;
    }
    .accessTitle{
        font-size:1.7rem;
    }
    .accessDesc{
        font-size:1.3rem;
        margin-bottom:30px;
    }
    .deviceContainer{
        height:auto;
    }
    .sectionHomeMargin{
        margin-top:20px;
    }
    .devicesText{
        position:static;
        width:100%;
    }
    .journey{
        font-size:1.8rem;
    }
    .oneFourth{
        width:100%;
    }
    .home_home-background{
        /*background:url('');*/
    }
    .titleBig{
        font-size:1.7rem;
        color:#000;
        padding-top:40px;
    }
    .bottomHeaderStripBG{
        margin-bottom:0;
        width:99%;
    }
    .subTitleSmall{
        font-size:1.4rem;
        color:#ffd486;
    }
    #typing-text{
        font-size:2rem;
    }
    .path_mvc{
        width:20%;
        margin-right:10px;
        margin-left:10px;
    }
    .path-kid{
        width:20%;
        margin-right:10px;
        margin-left:10px;
    }
    .path_console{
        width:20%;
        margin-right:10px;
        margin-left:10px;
    }
    .path_tie{

    }
    #path_2{
        width:28%;
    }
    #path_3{
        width:27%;
    }
    .arr_con_onHome{
        top:42%;
        font-size:20px;
    }
    .path_labels{
        overflow:hidden;
    }
    .single_path_label {
        font-size: 13px;
    }
    .thought {
        font-size:1.7rem;
    }
    .stack_logos {
        width: 35px;
        height:35px;
    }
    .stack_logos_fr {
        margin-right: 0;
        margin-left: 0;
        width:46px;
        height:30px;
    }
     #nodeLogo{
        width:150px;
        height:45px;
        margin-left:0;
    }
    #laravelLogo{
        width:80px;

    }
    #djangoLogo  {
        width:70px;

    }
    #railsLogo{
        width:42px;
        height:30px;
    }
    #tie{
        width: 163px;
        height: 51px;
        display: block;
        margin: 5px auto;
    }
    #part1Block{
        width: 65px;
        height: 35px;
    }
    #part1Code{
        width:120px;
        margin-left:5%;
        margin-right:0;
        height:35px;

    }
    .levels #panda{
        width:74px;
        height:50px;

    }
    .arrow_connection{
        font-size:20px;
    }
    .levels .levelsTitle, .levels_on_course .levelsTitl{
        margin-left:35px;
    }
    .justifyFlex{
        flex-direction:row !important;
        justify-content: space-around;
    }
    .juniorCourseTree .exerciseJuniorBox .junniorBoxCoinsCount {
        left: 50% !important;
        -ms-transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        font-size: 13px;
        bottom: 15px;
    }
    .juniorCourseTree .exerciseJuniorBox  .junniorBoxTitle{
        font-size:9px;
        top:15%;
    }
    .juniorCourseTree .exerciseJuniorBox  .junniorBoxMax{
        top:45%;
    }
    .juniorCourseTree .exerciseJuniorBox .junniorBoxSubTitle{
        font-size:9px;
       /* bottom:-11px;*/
    }
    .juniorCourseTree .exerciseJuniorBox  .junniorBoxCoinsCount{
        left:0;
        font-size:10px;
        bottom:15px;
    }
    .juniorCourseTree .exerciseJuniorBox:nth-child(3n+4) .fa-angle-down{
        top:-2%;
    }
    .juniorCourseTree .exerciseJuniorBox:nth-child(3n+3) .fa-angle-down{
        bottom:-41%;
    }
    .justifyFlex{
        flex-direction:column;
        justify-content: space-around;
    }
    .juniorCourseTree{
        width: 100%;
        height: 600px;
        overflow: hidden;
    }
    .juniorCourseTree .exBoxes{
         height: 235px;
             /*justify-content: space-around;*/
    }
    .juniorCourseTree .exerciseJuniorBox{
        width:33%;
        height:39%;
        margin-right:0;
        margin-bottom:40px;
    }
    .juniorCourseTree .exerciseJuniorBox .juniorBoxExTitle{
        display:none;
    }
    .juniorCourseTree .exerciseJuniorBox .screensEx .fa-dot-circle-o{
        font-size:13px;
    }
    .juniorCourseTree .exerciseJuniorBox:nth-child(3n+4) .arrowExDown{
            height: 15%;
            top: -15%;
    }
    .devicesContainer{
        height:auto;
    }
    #firstHeader{
        padding-top:0;
        padding-bottom:0;
    }
    .home_kids-bg{
        /*background-position: -104px 0%;*/
        /*background-position: 32% 22.929245%;*/
       /* background-position:32% 19.929245%;*/
        background-size:250%;
    }
    .home_home-old{
        /*background-position: 0 10.702602%;*/
        background-size: 319.470588%;
    }
    .start_now.play_now{
        margin-top:0;
    }
    .footerBox{
        width:100%;
    }
    .logo_structure_coin{
        width:15px;
        height:15px;
    }
    .juniorCourseTree .exerciseJuniorBox  .junniorBoxCoinsCount{
        width:100%;
    }
    .arrowExLong{
        /*left:-50%;*/
        left:50%;
    }
    .path_number{
        margin-bottom:0;
        z-index:10;
    }
    .middleBannerTitle span{
        display: none;
    }
    .home_default,.logo_default{
        width:50%;
        height:83px;
    }
    .paddingFooter{
        padding-bottom:0;
    }
    .bannerBlue .start_now_middle{
        margin-bottom:30px;
    }
    .single_path_tile{
        width:100%;
    }
    .pathLevels {
        width:100%;
        float:none;

    }
    .pathLevelsMobile{
         width:100%;
        float:none;
        display:inline-block;
    }
    .singleCourseLevel span{
        display:none;
    }
    .pathWrapper{
        width:100%;
        float:none
    }
    .corner-ribbon{
       font-size:0.6rem;
       line-height: 20px;
       top: 14px;
       left: -71px;
   }
   .course_start_button{
       width:100%;
   }
   .home_mob_kids-bg{
       /* background-position: 35% 25.880282%;*/
        background-position:35% 26.880282%;
        background-size: 250%;
    }
    .home_mob_plans{
        width:80%;
        /*background-position: 0 9.367439%;
        background-size: 120.163462%;*/
        height:120px;
    }
    .home_mob_tripplebadge{
        width:80%;
        /*background-position: 0 41.008844%;
        background-size: 141.666667%;*/
    }
    .home_roditeli-kontrol{
        width:80%;
    }
    .home_mob_thirdgif{
       /* background-size: 380.814815%;*/
       background-size:464.814815%;
    }
    .home_mob_secondgif{
        background-size: 865.814815%;
    }
    .home_mob_roditeli-kontrol{
        background-size: 103%;
    }
    .home_mob_consoles{
        /*background-position: 0 85.052819%;
        background-size: 749.333333%;*/
    }
}
@media screen and (max-width: 380px) {
    .levels .level_title{
        font-size:13px;
    }
    .path_mvc{
        width:20%;
        margin-right:5px;
        margin-left:5px;
    }
    .path-kid{
        width:20%;
        margin-right:5px;
        margin-left:5px;
    }
    .path_console{
        width:20%;
        margin-right:5px;
        margin-left:5px;
    }
    .path_tie{

    }
    #path_2{
        width:28%;
    }
    #path_3{
        width:27%;
    }
    .arr_con_onHome{
        top:42% !important;
        font-size:20px;
    }
    .path_labels{
        overflow:hidden;
    }
    .single_path_label {
        font-size: 13px;
    }
    .thought {
        font-size:1.7rem;
    }
    .stack_logos {
        width: 25px;
        height:25px;
    }
    .stack_logos_fr {
        margin-right: 0;
        margin-left: 0;
        width:46px;
        height:30px;
    }
     #nodeLogo{
       /* width:40px;*/
        margin-left:0;
    }
    #laravelLogo{
        width:60px;

    }
    #djangoLogo  {
        width:50px;

    }
    #railsLogo{
        width:32px;
        height:30px;
    }
    #tie{
        /*width:50px;
         height:40px;*/
    }
    #part1Block{
        width: 65px;
        height: 35px;
    }
    #part1Code{
        width:120px;
        margin-left:5%;
        margin-right:0;
        height:35px;

    }
    .levels #panda{
       /* width:74px;
         height:35px;
         display:none;*/
    }
    .arrow_connection{
        font-size:15px;
        top:62%;
    }
    .levels .levelsTitle, .levels_on_course .levelsTitl{
        margin-left:35px;

    }
    .sub_paths .profilePathHeader{
        padding:0;
    }
    .sub_paths .levels .levelsTitle{
        text-align:center;
        margin-bottom:0;
    }
    .path_sub_title{
        margin-bottom:0;
    }

    .j_logos,.c_logos,.f_logos{display:block;}
    .home_brain {
        background-position: 0 100%;
        background-size: 136.142857%;
    }
    .home_matdesbg{
        background-size: 632.727273%;
    }
}
@media screen and (max-width: 320px) {
    .all_matdesbg {
        background-size: 247%;
    }
    #devices{
        height:55%;
    }
     .home_tripplebadge{
           /* background-size: 208.666667%;
            background-position: 0 82.909889%;*/
            height:115px;
    }
    .home_mob_tripplebadge{
        width:80%;
        /*background-position: 0 41.008844%;
        background-size: 141.666667%;*/
    }
}
@media screen and (max-width: 400px) {
    .status400 {
        display: none;
    }
    .textHide400 {
        display: none;
    }
    .courseStatus {
        width: 10px;
        height: 10px;
        left: 70%;
        top: 50%;
        transform: translateY(-50%);
    }
    .profileCourseItem h3 {
        width: 68%;
    }
    .continueInnerWrapper {
        width: 100% !important;
    }
    #continueButton {
        position: relative;
        display: block;
        margin-top: 20px;
    }
    #subscribeForm input {
        display: block;
        width: 80%;
       margin: 10px auto;
    }
    .footerBox {
        width: 100%;
        height: auto;
    }
    .footerBtn, .social_icons {
        position: relative;
        margin: 20px 0;
    }
    #subsctibeBtn {
     font-size: 1rem;
    }
    #subscribeTitle {
        margin: 10px;
    }
    .profilePathTitle {
        width: 60%;
    }
    .badges {
        /*display: block;
        margin: 10px auto;*/
    }
    .newsBullet{
        font-size:2rem;
    }
    .newsBitPic{
        height:50px;
    }
    .sectionSubTitle{
        display:none;
    }
    .sectionTitleCourses{
        margin-bottom:30px;
        margin-right:10px;
    }
    #homeSectionPicWrap{
        width: 80%;
        margin:0 auto;
    }
    .metal780{
        width:50%;
    }
    .modLiTitle{
        width:75%;
    }
}
@media screen and (max-width: 375px) {
    .hero_text h1 {
        font-size:1.7rem;

    }
    .all_matdesbg{
        background-size:511%;
    }
}

.label-name{
    font-size:14px;
    font-weight: bold;
    padding: 3px;
    color:#039CE6;
}
.noti-popup{
    position: fixed;
        background-color: rgba(0, 0, 0, 0.7);
    top: 50%;
    color:white;
    font-weight: bold;
    font-size: 1.5rem;
    width: 70%;
    transform: translate(-50%,-50%);
    left: 50%;
    z-index: 31;
    border-radius: 5px;
    padding: 25px;
    text-align: center;
    display:none;
}
.noti-popup button{
    border: none;
    padding: 10px;
    font-size: 1rem;
    font-weight: bold;
    width: 70px;
    border-radius: 3px;
    color: #fff !important;
    background: -webkit-linear-gradient(top,#fff 0,#89cced 2%,#41ade3 100%) !important;
    cursor:pointer;
}
.noti-permission-img{
    background:url('../images/noti-permission.png') no-repeat;
    background-size:contain;
    height:200px;
    background-position:center;
}
#pathRestriction{
    position:fixed;
    top: 0;
    left: 0;
    right: 0;
   color:white;
   font-size: 1.5rem;
   font-weight: bold;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 35;
    padding: 20px;
    display: none;
}
/*style for eu cookies */

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @media screen and (max-width:1280px){
        /*.logoText{
            font-size:1.2rem !important;
        }
        a.menuLi{
            font-size:0.6rem !important;
        }*/
    }


}
.ok_btn{
    padding: 10px;
    text-transform: uppercase;
    background: #FFA500;
    font-weight: bold;
    display: inline-block;
    text-align: center;
    box-shadow: none;
    border-radius: 0;
    color: #fff !important;
    border-radius:3px;
    font-size: 0.8rem;

    bottom: 6px;
    left: 50%;
}
.CerInfo{
    padding: 10px;
}
.certificate-overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 30;
    display: none;
}

.closeModalCertificate{
    position: absolute;
    top: 4px;
    right: 5px;
    color: red;
    font-size: 22px;
    cursor: pointer;
}
.record_label_inactive{
        opacity: 0.3;
        color:rgb(64, 64, 64);
}
.record-title.record_label_inactive{
    font-weight:normal;
    opacity:1;
}

.inviter-avatar-wrap{
    text-align: center;
}
.avatar_invite{
    border-radius: 50%;
    height: auto;
    width: 100%;
}
#confirmEmailOverlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 30;
    display:none;
}
.changePasswordModal{
    position: fixed;
    background: #fff;
    top: 50%;
    width: 70%;
    transform: translate(-50%,-50%);
    left: 50%;
    z-index: 31;
    border-radius: 5px;
    padding: 25px;
    text-align: center;
}
.changePasswordOverlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 30;
}
.in_btn{
    background-color: #0077b5 !important;
    background-size: initial !important;
    cursor: pointer !important;
    border: 0 !important;
    border-right: 1px solid #066094 !important;


    padding: 3px;
    width: 60px !important;
    height: 20px !important;
   display: inline;
    position: relative;
    top: -4px;
    border-radius: 2px !important;
    -webkit-border-radius: 2px !important;
    border-top-right-radius: 2px !important;
    border-bottom-right-radius: 2px !important;
    -webkit-border-top-right-radius: 2px !important;
    -webkit-border-bottom-right-radius: 2px !important;
}
.in_logo{
    color:white;
    font-weight: bold;
    border-right: 1px solid #066094 !important;
    font-size: 17px;

}
.in_share{
  color: #fff !important;
    font-size: 11px !important;
    font-family: Arial, sans-serif !important;
    font-weight: bold !important;
    font-style: normal !important;
    -webkit-font-smoothing: antialiased !important;
    display: inline-block !important;
    background: transparent none !important;

    height: 18px !important;
    line-height: 20px !important;
    float: none !important;
}
.in_share:hover{
    background-color: #0077b5 ;
}
#sendNewConfirmEmailBtn{
    color: #fff !important;
    background: -webkit-linear-gradient(top,#fff 0,#89cced 2%,#41ade3 100%) !important;
    padding:10px 20px;
    width: 100px;
    margin:0 auto;
    border-radius: 3px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 20px;
}
.fb-btn{
    border: none;
    background: transparent;
    outline: none
}


.admin-menu a{
    font-weight: bold;
    color: black;
    margin-right: 10px;
    border: 2px solid white;
    border-radius: 3px;
    padding: 3px;
}
.admin-menu a:hover{
    color: white;
}
.adminBtn{
    color: white;
    font-weight:bold;
    border: 2px solid #000;
    border-radius: 3px;
    padding: 3px;
    display:inline-block;
}
.user_label{
    padding:5px;
    border-radius:5px;
    font-weight:bold;
    color:#fff;
}
.user_label_free{
    background:#FFA500;
}
.user_label_paid{
    background:#099;
}
.schoolAccountsModal{
    display: none;
    position:fixed;
    top:50%;
    left:50%;
    -ms-transform:translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform:translate(-50%,-50%);
    background:#fff;
    z-index:31;
    width:90%;
    padding:15px;
    max-height:90%;
    overflow-y:scroll;
}
.schoolAccountsOverlay{
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:30;
    background:rgba(0, 0, 0, 0.73);
    display:none;
}
.closeSchoolAccounts{
    position:absolute;
    top:2px;
    right: 2px;
    color:red;
    font-weight:bold;
    cursor:pointer;
    font-size:1.5rem;
}
.schoolAccountsModal h1{
    text-align: center;
}
.schoolAccountsModal table{
    width:100%;
    border-collapse: collapse;
}
.schoolAccountsModal table td, .schoolAccountsModal table th{
    padding:15px;
}
#createSchoolAccounts input{
    padding:10px;
    margin:5px 0;
    border-radius:5px;
    outline:none;
    border:1px solid #ccc;
}
#createSchoolAccounts input[type="submit"]{
    background:#FFA500;
    color:#fff;
    border:none;
}

#seeSchoolAccounts{
    padding:10px;
    margin:5px 0;
    background-color:#099;
    color:#fff;
    cursor:pointer;
    display:inline-block;
}
.alertSuccess{
    padding:10px;
    border-radius: 5px;
    background-color:#D4EDDA;
    
}
.alertDanger{
    padding:10px;
    border-radius: 5px;
    background-color:#F8D7DA;
    
}
.user_label_account{
    background:cornflowerblue;
    color:#fff;
}
.user_label_addPlan1{
    background:#607d8b;
}
.user_label_addPlan2{
    background:#607d8b;
}
.user_label_year{
    background:#039ce6;
}
.user_label_half_year{
    background:#099;
}
#viewScratcCodes{
    display: none;
    position:fixed;
    top:50%;
    left:50%;
    -ms-transform:translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform:translate(-50%,-50%);
    background:#fff;
    z-index:31;
    width:90%;
    padding:15px;
    max-height:90%;
    overflow-y:scroll;
}
#viewScratcCodesOverlay{
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:30;
    background:rgba(0, 0, 0, 0.73);
    display:none;
}
#closeScratch{
    color:red;
    position:absolute;
    top:2px;
    right:2px;
}
