/*@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,800,700,300);*/
footer{
	text-align: center;
	background-color:#313e44;
	color:#Fff;
}
#pageWrap:after{
	height:0;
}
#pageWrap{
	margin-bottom:0;
}

.home_header{
	margin-top:54px;

	text-align:center;
	/*background-image:url(../images/stripe-bg.png);*/
	color:#fff;
	background-position: center center;
    background-size: contain;
    background-attachment: fixed;
    box-shadow: none;
    overflow:hidden;
    height:500px;
}
.home_header:before{
	 content:"";
  width:100%;
  height:100%;
  position:absolute;
  top:0;
  left:0;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1.0, 1.0);
    transform: translateZ(0);
  background:#1B2030 url(../images/0.png) top center no-repeat;
  background-size:cover;
  background-attachment:fixed;
  animation: grow 60s  linear 10ms infinite;
  transition:all 0.2s ease-in-out;
  z-index:-2
}
.home_header:before{
	background: none;
	background-color: #1B2030;
    background-image: url(../images/0.png);
    background-position: center;
    background-repeat: no-repeat;
    animation: none;
    transition: none;
    z-index: 1;
    background-size: 100%;
}

.home_header:after{
	/*content:"";
  width:100%;
  height:40%;
  position:absolute;
  bottom:0;
  left:0;
  z-index:-1;
 background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(27,32,48,1) 100%);*/
 	 /*content: ""; background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAADCAYAAABWKLW/AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDY3IDc5LjE1Nzc0NywgMjAxNS8wMy8zMC0yMzo0MDo0MiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgTWFjaW50b3NoIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjI4RkZBQTgzNzg1NzExRTU4NTQyODc3OUM4MTZGMUREIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjI4RkZBQTg0Nzg1NzExRTU4NTQyODc3OUM4MTZGMUREIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MjhGRkFBODE3ODU3MTFFNTg1NDI4Nzc5QzgxNkYxREQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MjhGRkFBODI3ODU3MTFFNTg1NDI4Nzc5QzgxNkYxREQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz66uHInAAAAIUlEQVR42mL5//8/AyMj42YGIGBigABfEMEIkoEBgAADAKvuBwVS8BAjAAAAAElFTkSuQmCC); background-size: 3px 3px; position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 1;
	*/

	
}


.home_header h1{
	font-size:3rem;
	text-transform: uppercase;
	font-weight: bold;
	color:#fff;
}
.home_header h2{
	font-size:5rem;
	font-weight: bold;
	color:#fff;
}
.overlayHeaderHome{
	position: absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	background:rgba(35, 120, 135, 0.6784313725490196);
	z-index: 2;
}
.home_header .content{
	position:relative;
	z-index:3;
	height: 100%;
}
@keyframes grow{
  0% { transform:scale(1)}
  50% { transform:scale(1.4)}
}
.colorLine{
	width:100%;
	height:5px;
	position:relative;

	z-index:50;
	display: none;
}
.colorLine:after{
	
}
.cLetter{
	font-size:3.7rem;
	font-weight: bold;
	text-transform: uppercase;
	color:#007bff !important;
	font-family: 'Verdana';
	background:#fff;

}
.cLetter:nth-child(2){
	color:#9C27B0;
}
.cLetter:nth-child(3){
	color:#3F51B5
}
.cLetter:nth-child(4){
	color:#099;
}
.cLetter:nth-child(5){
	color:#039CE6;
}
.cLetter:nth-child(6){
	color:orange;
}
.cLetter:nth-child(7){
	color:#c66;
}
.cLetter:nth-child(8){
	color:#365899;
}
.cLetter:nth-child(9){
	color:#e91e63;
}
.cLetter:nth-child(10){
	color:#9C27B0;
}
.cLetter:nth-child(11){
	color:#3F51B5;
}
.cLetter:nth-child(12){
	color:#039CE6;
}
.cLetter:nth-child(13){
	color:#099;
}
.cLetter:nth-child(14){
	color:#039CE6;
}
.cLetter:nth-child(15){
	color:#9C27B0;
}
.cLetter:nth-child(16){
	color:orange;
}

.learn-more{
	display:inline-block;
	background:#099;
	padding:15px;
	box-sizing: border-box;
	cursor: pointer;
	text-transform: uppercase;
	font-weight:bold;
	border-radius: 5px;
	box-shadow:0 2px 24px rgba(0, 0, 0, 0.4);
}
.start-now{
	display:inline-block;
	background:orange;
	padding:15px;
	box-sizing: border-box;
	cursor: pointer;
	text-transform: uppercase;
	font-weight:bold;
	border-radius: 5px;
	box-shadow:0 2px 5px rgba(0, 0, 0, 0.21);
}
.girl-cartoon{
	background-image:url('../images/girl-cartoon.png');
	width: 500px;
    height: 250px;
	background-size:contain;
	background-repeat: no-repeat;
	position:absolute;
	z-index:1;
	bottom:0;
	background-position:left;
}
.heroes-cartoon {
	background-image:url('../images/leftImage.png');
	width: 500px;
    height: 300px;
    background-size:contain;
	background-repeat: no-repeat;
	position:absolute;
	z-index:3;
	bottom:0;
	background-position:left;
	left:0;
}
.boy-cartoon{
	background-image:url('../images/boy-cartoon.png');
	    width: 500px;
    height: 320px;
	background-size:contain;
	background-repeat: no-repeat;
	position:absolute;
	z-index:1;
	bottom:0;
	right:0;
	background-position:right;
}
#firstTopStripe{
	background:#3097D1;
}
.section_title{
	font-weight: 300;
	margin-top:0;
	margin-bottom:0;
	color:#4f4f4f;
	text-align:center;
	font-size:2.5rem;
	text-transform: uppercase;
	font-weight: bold;

}
.topStripe{
	background:#FFC730;
}
.leftContent, .rightContent{
	width:50%;
}
.leftContent{
	padding:35px;
	box-sizing: border-box;
}
.palette{
	background:url(../images/palette.png);
	background-size:contain;
	background-repeat: no-repeat;
	background-position: center;
	width:100%;
	height:250px;
}
.stripeBG{
	background:#f9f9f9;
}
.stripeCol{
	background:#DD8735 !important;
	color:#fff;
}
.stripeCol3{
	background:#237888 !important;
	color:#fff;
}
.stripeColor h1{
	font-size:2.5rem;
	margin:0;
}
.featuresBG{
	background:rgb(35, 120, 135);
}
.featureBox{
	width:31%;
	background:url(../images/bubble.png);
	background-size:cover;
	background-repeat:no-repeat;
	background-position:center center;
	text-align: center;
	padding:30px;
	box-sizing: border-box;
	height:auto;
	float:left;
	box-shadow: none;
}

.featureBox .fa{
	font-size:2.5rem;
}
.featureBox h1{
	margin-top:15px;
	font-size:1.5rem;
}
.featureBox p{
	color:#404040;
}
.featureBox .start-now{
	border-radius: 5px;
	font-size:1rem;
	padding:10px 15px;
	margin-top:15px;
	box-shadow:none;
	color:#fff;
}
.course_start_button{
	width:100%;
	text-align: right;
}
.courseTile{
	border-radius: 0;
}
.courseStartButton{
	background:#237888;
	color:#fff;
	margin-right:5px;
	border-radius: 5px;
}
.courseStartButton:hover{
	color:#fff;
}
.logo_matdesbg{
	background:url(../images/palette.png);
	background-size:contain;
	background-repeat: no-repeat;
	background-position: center center;
	z-index:5;
}
.navigation-courses{
	display: none;
}
.course_path{
	display: none;
}
.heroTitle{
	margin-left:0;
	margin-right: 0;
}
.heroLeft, .heroRight {
    display: inline-block;
    width: 49%;
}

.heroLeft{
	text-align: center;
}
.heroRight {
	padding:15px;
	box-sizing:border-box;

}

.btn-main {
	background:rgb(35, 120, 135);
}
.heroRight img{ 
	height:250px;
	float:right;
}
.startCourse{
	background:#fff;
	color:orange;
	border-radius: 5px;
	box-shadow:0 2px 24px rgba(0, 0, 0, 0.4);
	position:static;
}
#projectPreview{
	display: none;
}
.heroSection_course .content{
	padding:0;
}
.linkStartCourse{
	display: inline-block;
	margin-top:25px;
}
.courseStructureHeroTitle {
	margin:0;
}
.expandCollapse{
	margin-left: 5px;
}
.bodyBackgrounds{
	background:#237888;
}
.inactiveText{
	color:#Fff;
}
#loginButton, #registerButton{
	border-radius: 5px;

}
.panel{
	background-color:transparent;
	box-shadow: none;
	width:50%;
}
.panel .logo{
	display: none;
}
.login_input{
	background:#fff;
	padding:20px;
	box-sizing: border-box;
	border-radius: 5px;
}
.login_input:focus{
	background:#fff;
}

.placeholder{
	display: none;
}
.login_label{
	color:#fff;
	font-size:2rem;
}
.termsSmallText{
	color:#fff;
}
#agree_term{
	color:#fff;
}
.checkDiv label, .checkDivSmall label{
	border:none;
}
#uploadAvatar #user_profile_icon{
	/*margin-top:0;*/
}
#user_icons span{
	margin-top:0;
	color:#000;
}
#user_icons span:before{
	line-height:40px;
}
#user_icons{
	background:#fff;
}
#uploadAvatar{
	background:#fff;
}
#uploadAvatar #user_profile_icon{
	color:#313e44;
}
.footer-separator{
	display: none;
}
.logo_structure_project_console{
	background-image:none;
}
.courseContentTitle{
	margin-bottom:40px;
}
.courseContentTitle:after{
	border-bottom: 4px solid #099;
	width: 15%;
}
.infoRowIcon{
	left:0;
	top:-2px;
}
.infoRow, .infoRowFirst {
	box-shadow:0 2px 24px rgba(0, 0, 0, 0.4);
	padding:15px;
	box-sizing: border-box;
	border-radius: 5px;
	color:#fff;
}
.infoRowFirst {
	color:#000;
}
.infoRowName{
	margin-left:40px;
}
.infoRowHead{
	padding:5px 0;
	border:none;
	padding-bottom:15px;
}
.infoRow .infoRowHead:after{
	background:#fff;
	position: absolute;
    width: 60px;
    height: 4px;
    left: 0;
    content: "";
    bottom: 0;
}
.infoRow.clear{
	box-shadow:none;
}
.infoRowBody, .infoRowBodyFirst{
	border:none;
}
.infoRow:nth-child(3){
	background:#00bcd4;
}
.infoRow:nth-child(4){
	background:#F26D7B;
}
.infoRow:nth-child(5){
	background:#099;
}
.infoRow p,.infoRow span{
	background-color:transparent !important;
	color:#fff !important;
	font-weight: bold;
}
svg:not(:root) {
    overflow: hidden;
}
.stripe svg {
    height: 45px;
    position: absolute;
    width: 100%;
    z-index: 9;
    bottom: -1px;
    left: 0;
}
.padding_extra{
	padding-bottom:110px;
}
.material_tile{
	box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
	background:#fff;
}

.alert{
	position:fixed;
	top:5%;
	left:50%;
	transform: translateX(-50%);
	-webkit-transform:translateX(-50%);
	-ms-transform:translateX(-50%);
	display: none;

	z-index:50;
	background: #E4A300;
    color: #ffff;
}
.warnIcon{
	background:#FCB701;
	padding:25px;
	font-size:2rem;
}
.warningAlertText{
	padding:15px;
}
.greyBG{
	background:#f9f9f9;
}
#toRegister{
	background:orange;
	border-radius: 5px;
}
#wrapperMenuNotUser #topRightMenu{
	transform: none;
	-webkit-transform:none;
	-ms-transform:none;
	padding: 25px 0;
}
#wrapperMenu #topRightMenu{
	transform: none;
	-webkit-transform:none;
	-ms-transform:none;
	padding: 25px 0;
}
.user_profile{
	margin-top:28px;
}
.secondTopStripe{
	margin-top:90px;
}
.logoText img{
	width:75px;

}
#topRightMenu .activeLink{
	border:none;
}
a.menuLi{
	padding:10px 9px;
}
.graphicDesignBG{
	/*background-size:150%;
	width:100%;
	height:100%;
	background-position: left center;*/

	background-size: cover;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 12;
    position: relative;
}
#messages{
	display: none;
}
.percentageDoneNotOnCourses{
	width:100%;
	transform:none;
	-ms-transform:none;
	-webkit-transform:none;
	position: absolute;
	bottom:0;
}
.profileCoursePercent{
	color:#000;
	background:#Fff;
	display:inline-block;
	box-shadow:0 2px 5px rgba(0, 0, 0, 0.4);
	padding:2px;
}
.profileProgressWrap{
	bottom:0;
	top:auto;
	transform:none;
	-ms-transform:none;
	-webkit-transform:none;
	position:static;
}
.courseTitle{
	width: 100%;
	float:none;
}
.pathsActivity .pcPic{
	display: none;
}
.courseActivity .continueInnerWrapper, .pathsActivity .continueInnerWrapper{
	width:100%;
}
.courseActivity .pcPic{
	display:none;
}
.errorMsg ul li{
	background:#e91e63;
	color:#fff;
	padding:15px;
	box-sizing: border-box;
	font-weight: bold;
}
.errorMsg ul li .errorMsg{
	color:#fff;
}

.activePlan{
	display: block;
	margin:0 auto;
}
.payment_plan{
	width:40% !important;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4) !important;
	border-radius: 0;
	    transition: box-shadow 0.3s;
}
.payment_title{
	border-radius: 0;

	border-bottom-left-radius: 60%;
}
.plan_description .enroll_now{
	border-radius: 5px;
	margin-top:20px;
}
.smallPlan{
	font-size:4rem;
	text-transform: lowercase;
}
.plan_description ul{
	list-style: none;
	margin:0;
	padding:0;
}
.plan_description ul li{
	padding:5px;
}

/*======== payments styles =============*/
#modalStartTrial .secondStepPremium .button{
	border:1px solid #ccc;
	display: inline-block;
	padding:10px;
	border-radius: 5px;
	box-sizing:border-box;
	width:31%;
	margin:5px;
	text-align: center;
}
#modalStartTrial .secondStepPremium .button:hover{
	border:1px solid #099;
}
.payment-btn .justifyFlex{
	justify-content:left;
	-webkit-justify-content:left;
}
#modalStartTrial .paymentBtns .methodPayment{
	margin:0;
	padding:0;
}
#modalStartTrial .modern_input{
	border:1px solid #ccc;
	padding:15px;
	box-sizing: border-box;
	border-radius: 5px;
}
#modalStartTrial .inputWrap{
	margin-bottom:5px;
}
.closeModalTrial{
	background:#000;
	border-radius: 50%;
	padding:5px;
	color:#fff;
	top:5px;
	right:5px;
	width:30px;
	height:30px;
}
.closeModalTrial span{
	position: absolute;
	top:50%;
	left:50%;
	-ms-transform:translate(-50%,-50%);
	-webkit-transform:translate(-50%,-50%);
	transform:translate(-50%,-50%);
}
.inputWrap .payments_star{
	position: absolute;
	top:50%;
	right:10px;
	-ms-transform:translateY(-50%);
	-webkit-transform:translateY(-50%);
	transform: translateY(-50%);
}
.payments_star:after{
	font-size:1.7rem;
}
#modalStartTrial .radioButtonBox{
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
	border-radius: 5px;
	border-radius: 15px;
}


#headerVideo{
	position: absolute;
	top:0%; /* -50 */
	left:0;
	right:0;
	margin:0;
	width:100%;
	height:100%;

}

.headerContent{
	padding-top:5px;
}

.controlBtn{
	font-size:3rem;
	border:1px solid #fff;
	border-radius: 50%;
	display: inline-block;
	position: relative;
	width:70px;
	height:70px;
	cursor: pointer;
	background:#3097d1;
	color: #fff;
}

.controlBtn:hover {
	background:#fff;
	color:#000;
}

.controlBtn span {
	position:absolute;
	top:50%;
	left:50%;
	-ms-transform:translate(-50%,-50%);
	-webkit-transform:translate(-50%,-50%);
	transform:translate(-50%,-50%);
}
#playVideo span{
	left:55%;
}
.inactiveBtn{
    display: none;
}

.activeBtn{
	display: inline-block;
}

.playerControlsAbsolute{ 
	position:absolute;
	right: -50px;
	bottom:45px;
}
.playerControls #playVideo{
	margin-top:50px;
}
.playerControls.playerControlsAbsolute #playVideo{
	margin-top:0px;
}

.systemVideo{
	width:100%;
	height: 600px;
	margin-bottom: 20px;
}

.headingPadding{
	padding-bottom: 70px;

}

.videoStripe p { font-size:1.2rem; padding:15px; }
.textCenter { text-align:center;  }


.overlayBackground{
	position: absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	width:100%;
	height:100%;
	background-color:#237888;
	opacity: 1;
}

.overlayBackgroundImage{
	background-image:url(../images/math-bg.png);
	background-size:30%;
}

.heroSection_courses{
		
}

.heroBg{
	background:#fff;
	color:#000;
	padding:5px 25px;
}

.structureVideo{
	width:75%;
	margin:0 auto;
	display: block;
	float:none !important;
	box-shadow:0 2px 5px rgba(0, 0, 0, 0.4);
	border-radius: 5px;
	height: auto !important;
}

#regContent #headerVideo{
	position: static;
	height:400px;
}
.registerLeft {
	width:60%;
	padding:5px;
	box-sizing: border-box;
}
.registerRight{
	width:40%;
	background: rgba(0, 0, 0, 0.35);
}

.registerRight .panel {
	width:100%;
}

.registerBackground{
	
	background-color:237888;
	background-position:center;
	background-size:cover;
	background-repeat:no-repeat;
}

#regContent {
	padding-top:120px;
}

.gifHome{
	width:100%;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    border-radius: 15px;
}

.cardShadow{
	box-shadow:0 2px 24px rgba(0, 0, 0, 0.4);
}
.rightContent p, .leftContent p,.content p{
		font-size:1.2rem;
}

.courseLogo{
	height: 100%;
}

.continueInnerWrapper .pcPic{
	display: block;
}
.courseActivity .pcPic img{
	width:100%;
}
.continueInnerWrapperLeft{
	width:78%;
	display: inline-block
}
.continueInnerWrapperRight{
	width: 20%;
	display:inline-block;
}
.blurImgBg{
	background-size:cover;
	width:100%;
	height: 100%;
	filter: blur(4px);
    position: relative;
    z-index: -1;
    background-position: center;
}
.pathsActivity .continueInnerWrapper{
	margin-left:0;
}
.progressCourseBG{
	background-color: #007bff;
}

.klas{
	cursor: pointer;
}

@media screen and (max-width: 1366px) {
	.systemVideo{
		width: 57%;
    	height: 400px;
    	display: block;
    	margin:0 auto;
	}
}

@media screen and (max-width:1280px){
	.dashboard-content .content{
		width:auto !important;
	}
}


@media screen and (max-width: 1024px) {
	#user_icons span:before{
		line-height: 26px;
	}
	#uploadAvatar #user_profile_icon{
		line-height: 60px;
		margin-top: 0;
	}
	.systemVideo{
		width: 57%;
    	height: 300px;
    	display: block;
    	margin:0 auto;
	}
}



@media screen and (max-width:780px){
	.logoText img{
			width:35px;

	}
	.user_profile{
		margin-top:10px;
	}
	.featureBox{
		width:100%;
		max-width:390px;
		margin:10px auto;
	}
	.leftContent, .rightContent{
		width:100%;
		text-align: center;
	}
	.home_header{
		margin-top:0;
	}
	.girl-cartoon{
		width:200px;
		height:150px;
	}
	.boy-cartoon{
		width:200px;
		height: 150px;
	}
	.menuTrigger{
		cursor: pointer;
	}
	.heroes-cartoon{
		width: 200px;
    	height: 150px;
	}
	.home_header h2{
		font-size: 3rem;
	}
	.cLetter{
		font-size: 2.7rem;
	}
	.home_header h1{
		font-size: 2rem;
	}
	.section_title{
		font-size: 2rem;
	}
	.systemVideo{
		height: 200px;
		width: 100%;
	}
	.rightContent p, .leftContent p, .content p{
		font-size:1rem;
	}
	.leftContent{
		padding:5px;
	}
	.featureBox{
		height:auto;
	}
	.courseTile{
		width:50%;
	}
	.courseTileButtons{
		left: 50%;
    	transform: translateX(-50%);
    	-ms-transform:translateX(-50%);
    	-webkit-transform:translateX(-50%);
	}
	.btn-start{
		display: block;
		float:none !important;
		margin-top:15px;
	}
	.overlayBackgroundImage{
		background-size:75%;
	}
	.heroLeft, .heroRight{
		text-align: center;
		width:100%;
	}
	.payment_plan{
		width:100% !important;
	}
	.registerLeft{
		width: 100%;
	}
	.registerRight{
		width: 100%;
	}
	#regContent{
		padding-top:20px;
	}
	.panel{
		width:100%;
	}
	.col-dashboard-10{
		width:100%;
	}
	#wrapperMenu{
		margin-right:25px;
	}
	#user_icons span:before{
		line-height: 26px;
	}
	#uploadAvatar #user_profile_icon{
		line-height: 60px;
		margin-top: 0;
	}
	#uploadAvatar{
		margin-bottom: 10px;
	}
	.pathsStarted .courseLogo{
		width:100%;
		height:100%;
	}
	.percentageDoneNotOnCourses{
		left:0;
	}
	.pathsActivity .coursesPaths .courseTile{
		width: 50%;
	}
}

@media screen and (max-width: 480px) {
	.courseTile{
		width:100%;
	}
	.pathsActivity .coursesPaths .courseTile{
		width: 100%;
	}
}




